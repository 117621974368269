import { Empty, Result, Skeleton } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { ReactComponent as Edit } from "../../../assets/svg/edit.svg";
import { PrimaryCard } from "../../../Components/StyledComponents/Card";
import transition from "../../../Transition";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { fetchDashboardNotifications } from "../../../redux/slice/Dashboard/dashboardNotifications";
import { useTranslation } from "react-i18next";

function DashboardNotification() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { dashboardNotifications } = useSelector((state) => state);

  const notifications = dashboardNotifications?.data?.data?.notifications || [];
  const isLoading = dashboardNotifications?.isLoading;
  const hasNotificationData =
    dashboardNotifications?.data?.data?.notifications?.length > 0;
  const isError = dashboardNotifications?.isError;

  useEffect(() => {
    // Dispatch the action when the component mounts
    dispatch(fetchDashboardNotifications());
  }, [dispatch]);

  return (
    <div>
      <SimpleFlex
        className="dashboard-card"
        padding="1.563rem"
        width="auto"
        flexdirection="column"
        gap="1rem"
      >
        <SimpleFlex justifycontent="space-between" alignitems="center">
          <P fontsize="1.25rem" fontWeight="var(--font-500)">
            {t("notifications")}
          </P>
          <SimpleFlex width="auto" gap="1rem" alignitems="center">
            <SimpleFlex alignitems="center" gap="0.3rem">
              <P
                width="0.5rem"
                height="0.5rem"
                backgroundcolor="var(--warning-color)"
              ></P>
              <P fontsize="0.688rem" fontWeight="var(--font-500)">
                {t("critical")}
              </P>
            </SimpleFlex>
            <SimpleFlex alignitems="center" gap="0.3rem">
              <P
                width="0.5rem"
                height="0.5rem"
                backgroundcolor="var(--snow-pea-green)"
              ></P>
              <P fontsize="0.688rem" fontWeight="var(--font-500)">
                {t("normal")}
              </P>
            </SimpleFlex>
            <SimpleFlex alignitems="center" gap="0.3rem">
              <P
                width="0.5rem"
                height="0.5rem"
                backgroundcolor="var(--informative-color)"
              ></P>
              <P fontsize="0.688rem" fontWeight="var(--font-500)">
                {t("informative")}
              </P>
            </SimpleFlex>
          </SimpleFlex>
        </SimpleFlex>
        <SimpleFlex width="auto" flexdirection="column" gap="1rem">
          {isLoading && <Skeleton active loading={true} />}
          {!isLoading &&
            hasNotificationData &&
            notifications.map((notification, index) => (
              <NotificationCard key={index} payload={notification} />
            ))}
          {!isLoading && !hasNotificationData && !isError && (
            // Show "no data" state if there is no data
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
          {isError && (
            // Show error state if there's an error
            <Result
              status="500"
              title="500"
              subTitle="Sorry, Failed to fetch notifications."
            />
          )}
        </SimpleFlex>
        {!isLoading && hasNotificationData && (
          <SimpleFlex flexdirection="row-reverse">
            <P
              fontWeight="var(--font-500)"
              color="var(--nav-color)"
              cursor="pointer"
            >
              {t("viewAll")}
            </P>
          </SimpleFlex>
        )}
      </SimpleFlex>
    </div>
  );
}

function NotificationCard({ payload }) {
  const { t } = useTranslation();

  const category =
    payload.category.toLowerCase() === "unplanned"
      ? "unplanned-tag"
      : payload.category.toLowerCase() === "planned"
      ? "planned-tag"
      : "";

  let severity = "";
  let border = "";
  switch (payload.severity.toLowerCase()) {
    case "critical":
      severity = "notification-critical";
      border = "alert-shadow";
      break;
    case "normal":
      severity = "notification-normal";
      border = "ok-shadow";
      break;
    case "informative":
      severity = "notification-informative";
      border = "informative-shadow";
      break;
    default:
      break;
  }

  return (
    <PrimaryCard bodypadding="0 !important">
      <SimpleFlex className={border} padding="0.5rem" alignitems="flex-end">
        <SimpleFlex gap="1rem" padding="1rem">
          <SimpleFlex flexdirection="column" gap="0.5rem">
            <SimpleFlex>
              <P color="var(--nav-color)">{t("type")} : &nbsp;</P>
              <P> {payload.type}</P>
            </SimpleFlex>
            <SimpleFlex>
              <P color="var(--nav-color)">{t("duration")} : &nbsp;</P>
              <P>{payload.duration}</P>
            </SimpleFlex>
            <SimpleFlex>
              {" "}
              <P color="var(--nav-color)">{t("status")} : &nbsp;</P>
              <P>{payload.status}</P>
            </SimpleFlex>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="0.5rem">
            <SimpleFlex>
              <P color="var(--nav-color)">{t("severity")} : &nbsp;</P>
              <P className={severity}>{payload.severity}</P>
            </SimpleFlex>
            <SimpleFlex>
              <P color="var(--nav-color)">{t("reason")} : &nbsp;</P>
              <P>{payload.reason}</P>
            </SimpleFlex>
            <SimpleFlex minwidth="15rem" flexwrap="wrap">
              {" "}
              <P color="var(--nav-color)">{t("dateTime")} : &nbsp;</P>
              <P>{payload.date}</P>
            </SimpleFlex>
          </SimpleFlex>
          <SimpleFlex>
            <SimpleFlex>
              {" "}
              <P color="var(--nav-color)">{t("category")} : &nbsp;</P>
              <P className={category}>{payload.category}</P>
            </SimpleFlex>
          </SimpleFlex>
        </SimpleFlex>
        <SimpleButton borderradius="50%" padding="1rem" margin="0 0 1rem 0">
          <Edit />
        </SimpleButton>
      </SimpleFlex>
    </PrimaryCard>
  );
}

export default transition(DashboardNotification);
