import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Action
export const fetchDashboardRejectionHistory = createAsyncThunk(
  "dashboard/fetchDashboardRejectionHistory",
  async () => {
    // Replace the URL with your actual endpoint to fetch dashboard data
    // const response = await fetch("https://api.example.com/dashboard");
    // return response.json();

    await new Promise((resolve) => setTimeout(resolve, 2000));

    return {
      data: {
        rejectionHistory: [
          {
            production_order: "PO-575456467",
            reason: "General",
            reason_type: "Power loss",
            rejection_count: "45",
            product: "Switch Board",
            severity: "critical",
          },
          {
            production_order: "PO-575456467",
            reason: "General",
            reason_type: "Power loss",
            rejection_count: "45",
            product: "Switch Board",
            severity: "normal",
          },
          {
            production_order: "PO-575456467",
            reason: "General",
            reason_type: "Power loss",
            rejection_count: "45",
            product: "Switch Board",
            severity: "informative",
          },
        ],
      },
    };
  }
);

// Dashboard Slice
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDashboardRejectionHistory.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(
      fetchDashboardRejectionHistory.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(
      fetchDashboardRejectionHistory.rejected,
      (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching dashboard data:", action.error.message);
      }
    );
  },
});

export default dashboardSlice.reducer;
