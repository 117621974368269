import React from "react";
import styled from "styled-components";
import { ConfigProvider, Space, Checkbox } from "antd";

const CustomCheckBox = styled(Checkbox)``;

export const PrimaryCheckBox = (props) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#21223d",
        },
      }}
    >
      <Space>
        <CustomCheckBox {...props}>{props.children}</CustomCheckBox>
      </Space>
    </ConfigProvider>
  );
};
