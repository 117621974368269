import { PercentageOutlined } from "@ant-design/icons";
import { Avatar, Col, Progress, Row, Space } from "antd";
import { PrimaryFlex } from "../../Components/StyledComponents/Flex";
import { P } from "../../Components/StyledComponents/Paragraph";
import { PrimaryButton } from "../../Components/StyledComponents/Button";
import { SimpleButton } from "../../Components/StyledComponents/SimpleButton";
import { ReactComponent as Download } from "../../assets/svg/download.svg";
import { ReactComponent as DownArrow } from "../../assets/svg/downArrow.svg";
import { ReactComponent as Availability } from "../../assets/svg/availability.svg";
import { ReactComponent as Performance } from "../../assets/svg/performance.svg";
import { ReactComponent as Quality } from "../../assets/svg/quality.svg";
import { ReactComponent as Edit } from "../../assets/svg/edit.svg";
import { PrimaryCard } from "../../Components/StyledComponents/Card";
import transition from "../../Transition";
import { SimpleFlex } from "../../Components/StyledComponents/SimpleFlex";
import DashboardNotification from "./Notification/index";
import DashboardRejectionHistory from "./RejectionHistory/index";
import DashboardFeed from "./Feed/index";
import DashboardProductionStats from "./ProductionStats/index";
import DashboardReporting from "./Reporting/index";
import "./Dashboard.scss";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Dashboard() {
  const [selectedCard, setSelectedCard] = useState(null);
  const { t } = useTranslation();

  const handleCardClick = (title) => {
    setSelectedCard((prevSelected) => (prevSelected === title ? null : title));
  };

  return (
    <div
      style={{
        width: "100%",
        gap: "1rem",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "scroll",
      }}
    >
      <PrimaryFlex
        padding="2rem"
        width="auto"
        flexdirection="column"
        gap="2rem"
      >
        <PrimaryFlex justifycontent="space-between">
          <PrimaryFlex flexdirection="column">
            <PrimaryFlex>
              <P fontsize="2.25rem" fontWeight="var(--font-300)">
                {t("liveBoard")}
              </P>
              <P
                fontsize="2.25rem"
                fontWeight="var(--font-400)"
                margin="0 0.5rem"
              >
                :
              </P>
              <P fontsize="2.25rem" fontWeight="var(--font-500)">
                Coil Machine 1
              </P>
            </PrimaryFlex>
            <PrimaryFlex>
              <P
                fontsize="0.75rem"
                fontWeight="var(--font-500)"
                color="var(--nav-color)"
              >
                13 Oct 2023, 06:00 PM
              </P>
            </PrimaryFlex>
          </PrimaryFlex>
          <PrimaryFlex gap="1rem">
            <SimpleButton padding="0.5rem 1rem" gap="0.5rem">
              {t("report")} <Download />
            </SimpleButton>
            <PrimaryButton padding="0.5rem 1rem">
              <P
                color="var(--columbia-gray-color)"
                margin="0 0.5rem 0 0"
                cursor="pointer"
              >
                {t("action")}
              </P>{" "}
              <DownArrow />
            </PrimaryButton>
          </PrimaryFlex>
        </PrimaryFlex>
        <PrimaryFlex alignitems="end" gap="1rem" justifycontent="space-between">
          <PrimaryFlex alignitems="end" gap="0.5rem">
            <PrimaryCard
              className="sea-pea-green-shadow"
              // loading={true}
              bodypadding="1.25rem !important"
              bodymaxwidth="14rem"
              bodyWidth="14rem"
            >
              <Space direction="horizontal">
                <P
                  color="var(--snow-pea-green)"
                  fontsize="5.5rem"
                  fontWeight="var(--font-700)"
                >
                  57
                </P>
                <PercentageOutlined
                  style={{
                    color: "var(--snow-pea-green)",
                    fontSize: "1.75rem",
                  }}
                />
              </Space>
              <PrimaryFlex flexdirection="column">
                <P>{t("overallEquipment")}</P>
                <P>{t("effectiveness")}</P>
              </PrimaryFlex>
            </PrimaryCard>
            <DashboardCard
              icon={
                <PercentageOutlined
                  style={{
                    fontSize: "1rem",
                  }}
                />
              }
              cardIcon={<Availability />}
              title={t("effectiveness")}
              value={95}
              bgColor={"var(--availability-color)"}
            />
            <DashboardCard
              icon={
                <PercentageOutlined
                  style={{
                    fontSize: "1rem",
                  }}
                />
              }
              cardIcon={<Performance />}
              title={t("performance")}
              value={95}
              bgColor={"var(--performance-color)"}
            />
            <DashboardCard
              icon={
                <PercentageOutlined
                  style={{
                    fontSize: "1rem",
                  }}
                />
              }
              cardIcon={<Quality />}
              title={t("quality")}
              value={95}
              bgColor={"var(--quality-color)"}
            />
          </PrimaryFlex>
          <PrimaryCard
            // loading={true}
            bodypadding="1.563rem !important"
            bodymaxwidth="14rem"
            bodyWidth="14rem"
            borderradius="18px"
            className="profile-shadow "
          >
            <Space direction="horizontal">
              <Avatar
                className="border-columbia-gray"
                size={{
                  xs: 30,
                  sm: 30,
                  md: 30,
                  lg: 35,
                  xl: 40,
                  xxl: 50,
                }}
                src={
                  <img
                    src={
                      "https://fastly.picsum.photos/id/40/4106/2806.jpg?hmac=MY3ra98ut044LaWPEKwZowgydHZ_rZZUuOHrc3mL5mI"
                    }
                    alt="avatar"
                  />
                }
              />
              <P fontsize="0.938rem" fontWeight="var(--font-500)">
                Mohammed
              </P>
            </Space>
            <PrimaryFlex flexdirection="column">
              <PrimaryFlex flexdirection="column">
                <P
                  fontsize="0.813rem"
                  fontWeight="var(--font-600)"
                  color="var(--nav-color)"
                >
                  Coil Machine 1{" "}
                </P>
                <P fontsize="0.625rem" color="var(--nav-color)">
                  Operator{" "}
                </P>
              </PrimaryFlex>
              <PrimaryFlex>
                <PrimaryFlex flexdirection="column">
                  <P fontsize="0.688rem" color="var(--nav-color)">
                    {t("shift")} :
                  </P>
                  <P fontsize="0.688rem" color="var(--nav-color)">
                    {t("date")} :
                  </P>
                  <P fontsize="0.688rem" color="var(--nav-color)">
                    {t("po")} :
                  </P>
                </PrimaryFlex>
                <PrimaryFlex flexdirection="column" margin="0 0 0 0.5rem">
                  <P fontsize="0.75rem" fontWeight="var(--font-500)">
                    1st Shift
                  </P>
                  <P fontsize="0.75rem" fontWeight="var(--font-500)">
                    04/28/2023
                  </P>
                  <P fontsize="0.75rem" fontWeight="var(--font-500)">
                    R-1234
                  </P>
                </PrimaryFlex>
              </PrimaryFlex>
            </PrimaryFlex>
          </PrimaryCard>
        </PrimaryFlex>
      </PrimaryFlex>
      <PrimaryFlex
        flexdirection="column"
        margin="1.563rem"
        gap="2rem"
        backgroundcolor="transparent"
        width="auto"
      >
        <DashboardProductionStats />
        <DashboardReporting />
        <PrimaryFlex className="dashboard-card" padding="1.563rem" width="auto">
          <PrimaryFlex width="auto" flexdirection="column">
            <P fontsize="1.25rem" fontWeight="var(--font-500)">
              Running Time
            </P>
            <PrimaryFlex gap="2rem" margin="1.5rem 0 0 0">
              <DashboardReportCard
                title={"Report Down Time"}
                value={"Report when production  is not happening"}
              />
              <DashboardReportCard
                title={"Report Change Over"}
                value={"Report change over from one product to another"}
              />
              <DashboardReportCard
                title={"Report Quality"}
                value={"Report Rejections or good items"}
              />
              <DashboardReportCard
                title={"Start Break Time"}
                value={"Report when machine is not working"}
              />
            </PrimaryFlex>
          </PrimaryFlex>
        </PrimaryFlex>
        <SimpleFlex
          backgroundcolor="transparent"
          width="auto"
          justifycontent="space-between"
          gap="1rem"
        >
          <SimpleFlex
            gap="2rem"
            flexdirection="column"
            backgroundcolor="transparent"
          >
            <DashboardNotification />
            <DashboardRejectionHistory />
          </SimpleFlex>
          <SimpleFlex
            className="dashboard-card dashboard-side-list"
            flexdirection="column"
            width="35%"
            padding="1.563rem"
          >
            <DashboardFeed />
          </SimpleFlex>
        </SimpleFlex>
      </PrimaryFlex>
    </div>
  );
}

function DashboardCard({ title, value, cardIcon, icon, bgColor }) {
  return (
    <PrimaryCard
      // loading={true}
      bodymaxwidth="13rem"
      bodyWidth="13rem"
      bodyminwidth="13rem"
      backgroundcolor={bgColor}
      bodypadding="1.25rem !important"
      border="none"
    >
      <PrimaryFlex flexdirection="column" backgroundcolor={bgColor}>
        {cardIcon}
        <PrimaryFlex flexdirection="column">
          <PrimaryFlex backgroundcolor={bgColor}>
            <P
              margin="0 0.5rem 0 0"
              fontsize="2rem"
              fontWeight="var(--font-500)"
            >
              {value}
            </P>
            {icon}
          </PrimaryFlex>
          <P backgroundcolor={bgColor}>{title} </P>
        </PrimaryFlex>
      </PrimaryFlex>
    </PrimaryCard>
  );
}

function DashboardReportCard({ title, value, onClick, selected }) {
  return (
    <PrimaryCard
      // loading={true}
      bodymaxwidth="15rem"
      bodyWidth="15rem"
      bodyminwidth="15rem"
      bodypadding="1.25rem !important"
      cursor="pointer"
      hoverable
      bordered={true}
      style={{
        border: selected
          ? "3px solid var(--Nav-Text-Color, #63658E)"
          : "1px solid var(--anti-flash-white)",
      }}
      onClick={onClick}
    >
      <PrimaryFlex flexdirection="column">
        <P fontWeight="var(--font-600)" cursor="pointer">
          {title}
        </P>
        <P color="var(--nav-color)" cursor="pointer">
          {value}
        </P>
      </PrimaryFlex>
    </PrimaryCard>
  );
}

function NotificationCard({ payload }) {
  const category =
    payload.category.toLowerCase() === "unplanned"
      ? "unplanned-tag"
      : payload.category.toLowerCase() === "planned"
      ? "planned-tag"
      : "";

  let severity = "";
  let border = "";
  switch (payload.severity.toLowerCase()) {
    case "critical":
      severity = "notification-critical";
      border = "alert-shadow";
      break;
    case "normal":
      severity = "notification-normal";
      border = "ok-shadow";
      break;
    case "informative":
      severity = "notification-informative";
      border = "informative-shadow";
      break;
    default:
      break;
  }

  return (
    <PrimaryCard bodypadding="0 !important">
      <SimpleFlex className={border} padding="0.5rem" alignitems="flex-end">
        <SimpleFlex gap="1rem" padding="1rem">
          <SimpleFlex flexdirection="column" gap="0.5rem">
            <SimpleFlex>
              <P color="var(--nav-color)">Type : &nbsp;</P>
              <P> {payload.type}</P>
            </SimpleFlex>
            <SimpleFlex>
              <P color="var(--nav-color)">Duration : &nbsp;</P>
              <P>{payload.duration}</P>
            </SimpleFlex>
            <SimpleFlex>
              {" "}
              <P color="var(--nav-color)">Status : &nbsp;</P>
              <P>{payload.status}</P>
            </SimpleFlex>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="0.5rem">
            <SimpleFlex>
              <P color="var(--nav-color)">Severity : &nbsp;</P>
              <P className={severity}>{payload.severity}</P>
            </SimpleFlex>
            <SimpleFlex>
              <P color="var(--nav-color)">Reason : &nbsp;</P>
              <P>{payload.reason}</P>
            </SimpleFlex>
            <SimpleFlex minwidth="15rem" flexwrap="wrap">
              {" "}
              <P color="var(--nav-color)">Date/Time : &nbsp;</P>
              <P>{payload.date}</P>
            </SimpleFlex>
          </SimpleFlex>
          <SimpleFlex>
            <SimpleFlex>
              {" "}
              <P color="var(--nav-color)">Category : &nbsp;</P>
              <P className={category}>{payload.category}</P>
            </SimpleFlex>
          </SimpleFlex>
        </SimpleFlex>
        <SimpleButton borderradius="50%" padding="1rem" margin="0 0 1rem 0">
          <Edit />
        </SimpleButton>
      </SimpleFlex>
    </PrimaryCard>
  );
}

function RejectionHistoryCard({ payload }) {
  let border = "";
  switch (payload.severity.toLowerCase()) {
    case "critical":
      border = "alert-shadow";
      break;
    case "normal":
      border = "ok-shadow";
      break;
    case "informative":
      border = "informative-shadow";
      break;
    default:
      break;
  }

  return (
    <PrimaryCard
      bodypadding="0 !important"
      // loading={true}
    >
      <SimpleFlex className={border} padding="0.5rem" alignitems="flex-end">
        <SimpleFlex gap="0.5rem" flexdirection="column" padding="1rem">
          <SimpleFlex gap="1rem">
            <SimpleFlex width="auto">
              <P color="var(--nav-color)">Production Order : &nbsp;</P>
              <P> {payload.production_order}</P>
            </SimpleFlex>
            <SimpleFlex width="auto">
              <P color="var(--nav-color)">Reason : &nbsp;</P>
              <P>{payload.reason}</P>
            </SimpleFlex>
            <SimpleFlex width="auto">
              {" "}
              <P color="var(--nav-color)">Rejection Count : &nbsp;</P>
              <P>{payload.rejection_count}</P>
            </SimpleFlex>
          </SimpleFlex>
          <SimpleFlex gap="1rem">
            <SimpleFlex width="auto">
              <P color="var(--nav-color)">Reason Type : &nbsp;</P>
              <P>{payload.reason_type}</P>
            </SimpleFlex>
            <SimpleFlex width="auto">
              <P color="var(--nav-color)">Product : &nbsp;</P>
              <P>{payload.product}</P>
            </SimpleFlex>
          </SimpleFlex>
        </SimpleFlex>
        <SimpleButton borderradius="50%" padding="1rem" margin="0 0 1rem 0">
          <Edit />
        </SimpleButton>
      </SimpleFlex>
    </PrimaryCard>
  );
}

export default transition(Dashboard);
