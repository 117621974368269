import { configureStore } from "@reduxjs/toolkit";
import todoReducer from "./slice/todo";
import registerReducer from "./slice/register";
import loginReducer from "./slice/login";
import loginQRReducer from "./slice/loginQR";
import dashboardNotificationReducer from "./slice/Dashboard/dashboardNotifications";
import dashboardFeedReducer from "./slice/Dashboard/dashboardFeeds";
import dashboardRejectionHistoryReducer from "./slice/Dashboard/dashboardRejectionHistory";
import productionStatsReducer from "./slice/Dashboard/productionStats";
import userReducer from "./slice/Users/user";
import userGroupReducer from "./slice/UserGroups/userGroup";
import userGroupDetailReducer from "./slice/UserGroupDetail/userGroupDetail";
import plantReducer from "./slice/Plants/plant";
import standardDashboardListtReducer from "./slice/StandardDashboards/standardDashboardListt";
import displayBoardReducer from "./slice/Displayboards/displayboardList";
import shiftReducer from "./slice/Shifts/shift";
import configurationReducer from "./slice/ConfigurationMgmt/Configuration";
import lineReducer from "./slice/Lines/line";
import roleReducer from "./slice/Roles/role";
import productReducer from "./slice/Products/product";
import condAndRuleReducer from "./slice/CondAndRules/CondAndRule";
import tagReducer from "./slice/Tags/tag";
import machineReducer from "./slice/Machines/machine";
import gatewayConfigurationReducer from "./slice/GatewayConfigurations/gatewayConfiguration";
import holidayRecucer from "./slice/Holidays/holiday";
import alertsDashboardDowntimeReducer from "./slice/Alerts/Dashboard/Downtime/downtimeAlert";
import alertsDashboardProductionReducer from "./slice/Alerts/Dashboard/Production/productionAlert";
import alertsDashboardSystemReducer from "./slice/Alerts/Dashboard/System/systemAlert";

export const store = configureStore({
  reducer: {
    todo: todoReducer,
    registration: registerReducer,
    login: loginReducer,
    loginQR: loginQRReducer,
    dashboardNotifications: dashboardNotificationReducer,
    dashboardFeeds: dashboardFeedReducer,
    dashboardRejectionHistory: dashboardRejectionHistoryReducer,
    productionStats: productionStatsReducer,
    users: userReducer,
    userGroups: userGroupReducer,
    userGroupDetail: userGroupDetailReducer,
    plants: plantReducer,
    standardDashboardList: standardDashboardListtReducer,
    displayBoardList: displayBoardReducer,
    shifts: shiftReducer,
    configurations: configurationReducer,
    holidays: holidayRecucer,
    lines: lineReducer,
    roles: roleReducer,
    products: productReducer,
    tags: tagReducer,
    machines: machineReducer,
    condAndRules: condAndRuleReducer,
    gatewayConfigurations: gatewayConfigurationReducer,
    downtimeAlert: alertsDashboardDowntimeReducer,
    productionAlert: alertsDashboardProductionReducer,
    systemAlert: alertsDashboardSystemReducer,
  },
});
