import {
  Drawer,
  Form,
  ColorPicker,
  Slider,
  InputNumber,
  Row,
  Col,
  Radio,
} from "antd";
import { SimpleFlex } from "../../../../Components/StyledComponents/SimpleFlex";
import { P } from "../../../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../../../Components/StyledComponents/SimpleButton";
import { ReactComponent as LeftArrowFilled } from "../../../../assets/svg/leftArrowFilled.svg";
import { ReactComponent as RightArrowFilled } from "../../../../assets/svg/rightArrowFilled.svg";
import { ReactComponent as EditLeftFacedOutlined } from "../../../../assets/svg/editLeftFacedOutlined.svg";
import { ReactComponent as CancelCircledGray } from "../../../../assets/svg/cancelCircledGray.svg";
import { useState } from "react";
import { SimpleSwitch } from "../../../../Components/StyledComponents/Switch";
import { PrimaryInputNumber } from "../../../../Components/StyledComponents/InputNumber";
import { PrimarySelect } from "../../../../Components/StyledComponents/Select";
import { PrimaryButton } from "../../../../Components/StyledComponents/Button";
import { SimpleInput } from "../../../../Components/StyledComponents/SimpleInput";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import "./ChartConfigII.scss";

function ChartConfigII({ onClose, isOpen }) {
  return (
    <Drawer
      title="Chart Configuration"
      placement="right"
      onClose={onClose}
      open={isOpen}
      mask={false}
      width={480}
    >
      <SimpleFlex
        justifycontent="center"
        height="auto"
        flexdirection="column"
        gap="1rem"
      >
        <SimpleFlex alignitems="center" className="border-bottom">
          <EditLeftFacedOutlined cursor="pointer" />
          <SimpleInput bordered={false} fontsize="1rem" />
        </SimpleFlex>
        <P
          fontsize="1rem"
          fontWeight="var(--font-500)"
          color="var(--primary-color)"
        >
          Table Color
        </P>
        <TableColorCard />
        <SimpleFlex justifycontent="space-between">
          <P
            fontsize="1rem"
            fontWeight="var(--font-500)"
            color="var(--primary-color)"
          >
            Conditional Formating
          </P>
          <P
            fontsize="0.875rem"
            fontWeight="var(--font-500)"
            color="var(--primary-color)"
            cursor="pointer"
          >
            Reset
          </P>
        </SimpleFlex>
        <ConditionalFormatingCard />
        <P
          fontsize="1rem"
          fontWeight="var(--font-500)"
          color="var(--primary-color)"
        >
          Widget Styling
        </P>
        <WidgetStylingCard />
        <P
          fontsize="1rem"
          fontWeight="var(--font-500)"
          color="var(--primary-color)"
        >
          Widget Header
        </P>
        <WidgetHeaderCard />
        <SimpleFlex justifycontent="flex-end">
          <PrimaryButton padding="0.5rem 1.5rem">Save</PrimaryButton>
        </SimpleFlex>
      </SimpleFlex>
    </Drawer>
  );
}

function TableColorCard() {
  const [widgetStyling, setWidgetStyling] = useState({
    backgroundColor: "#273D92",
    header: "#273D92",
    headerText: "#CBD5E1",
    headerLine: "#CBD5E1",
    cell: "#FFFFFF",
    cellText: "#273D92",
    cellLine: "#CBD5E1",
    opacity: 0,
    borderColor: "#CBD5E1",
    borderPosition: "All",
    borderRadius: 1,
    borderWeight: 0,
  });

  const handleChange = (value, field) => {
    setWidgetStyling((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleColorChange = (newColor, field) => {
    setWidgetStyling((prevState) => ({
      ...prevState,
      [field]: newColor,
    }));
  };

  const handleClearColor = (field) => {
    setWidgetStyling((prevState) => ({
      ...prevState,
      [field]: "#FFFFFF",
    }));
  };

  return (
    <Form>
      <SimpleFlex
        borderradius="1.125rem"
        border="1px solid var(--columbia-gray-color)"
        padding="1rem"
        width="auto"
        flexdirection="column"
        gap="1rem"
      >
        <SimpleFlex gap="0.8rem" width="auto">
          <SimpleFlex flexdirection="column" gap="0.5rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Header
            </P>
            <SimpleFlex alignitems="center" gap="0.5rem">
              <ColorPicker
                showText
                allowClear
                value={widgetStyling?.header}
                onChange={(color) => {
                  handleColorChange(color.toHexString(), "header");
                }}
              />
              <CancelCircledGray
                cursor="pointer"
                onClick={() => {
                  handleClearColor("header");
                }}
              />
            </SimpleFlex>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="0.5rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Header Text
            </P>
            <SimpleFlex alignitems="center" gap="0.5rem">
              <ColorPicker
                showText
                allowClear
                value={widgetStyling?.headerText}
                onChange={(color) => {
                  handleColorChange(color.toHexString(), "headerText");
                }}
              />
              <CancelCircledGray
                cursor="pointer"
                onClick={() => {
                  handleClearColor("headerText");
                }}
              />
            </SimpleFlex>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="0.5rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Line
            </P>
            <SimpleFlex alignitems="center" gap="0.5rem">
              <ColorPicker
                showText
                allowClear
                value={widgetStyling?.headerLine}
                onChange={(color) => {
                  handleColorChange(color.toHexString(), "headerLine");
                }}
              />
              <CancelCircledGray
                cursor="pointer"
                onClick={() => {
                  handleClearColor("headerLine");
                }}
              />
            </SimpleFlex>
          </SimpleFlex>
        </SimpleFlex>
        <SimpleFlex gap="0.8rem" width="auto">
          <SimpleFlex flexdirection="column" gap="0.5rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Cell
            </P>
            <SimpleFlex alignitems="center" gap="0.5rem">
              <ColorPicker
                showText
                allowClear
                value={widgetStyling?.cell}
                onChange={(color) => {
                  handleColorChange(color.toHexString(), "cell");
                }}
              />
              <CancelCircledGray
                cursor="pointer"
                onClick={() => {
                  handleClearColor("cell");
                }}
              />
            </SimpleFlex>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="0.5rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Cell Text
            </P>
            <SimpleFlex alignitems="center" gap="0.5rem">
              <ColorPicker
                showText
                allowClear
                value={widgetStyling?.cellText}
                onChange={(color) => {
                  handleColorChange(color.toHexString(), "cellText");
                }}
              />
              <CancelCircledGray
                cursor="pointer"
                onClick={() => {
                  handleClearColor("cellText");
                }}
              />
            </SimpleFlex>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="0.5rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Line
            </P>
            <SimpleFlex alignitems="center" gap="0.5rem">
              <ColorPicker
                showText
                allowClear
                value={widgetStyling?.cellLine}
                onChange={(color) => {
                  handleColorChange(color.toHexString(), "cellLine");
                }}
              />
              <CancelCircledGray
                cursor="pointer"
                onClick={() => {
                  handleClearColor("cellLine");
                }}
              />
            </SimpleFlex>
          </SimpleFlex>
        </SimpleFlex>
        <SimpleFlex flexdirection="column" gap="0.2rem">
          <P
            color="var(--nav-color)"
            fontWeight="var(--font-600)"
            fontsize="0.75rem"
          >
            Border
          </P>
          <SimpleFlex flexdirection="column" gap="1.5rem">
            <Row gutter={16}>
              <Col span={10}>
                <SimpleFlex flexdirection="column" gap="0.5rem" width="auto">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Border-Color
                  </P>

                  <SimpleFlex alignitems="center" gap="0.5rem">
                    <ColorPicker
                      showText
                      allowClear
                      value={widgetStyling?.borderColor}
                      onChange={(color) => {
                        handleColorChange(color.toHexString(), "borderColor");
                      }}
                    />
                    <CancelCircledGray
                      cursor="pointer"
                      onClick={() => {
                        handleClearColor("borderColor");
                      }}
                    />
                  </SimpleFlex>
                </SimpleFlex>
              </Col>
              <Col span={14}>
                <SimpleFlex flexdirection="column" gap="0.2rem">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Border-Position
                  </P>
                  <Form.Item
                    name="borderPosition"
                    className="margin-0"
                    rules={[
                      {
                        required: true,
                        message: "Please input Border-Position!",
                      },
                    ]}
                    initialValue={widgetStyling?.borderPosition}
                  >
                    <PrimarySelect
                      bordered={false}
                      className="user-select"
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "Abdul Nizam",
                          label: "Abdul Nizam",
                        },
                        {
                          value: "Billal Ahmed",
                          label: "Billal Ahmed",
                        },
                        {
                          value: "Mohammed Waris",
                          label: "Mohammed Waris",
                        },
                        {
                          value: "Mohammed Rizwan",
                          label: "Mohammed Rizwan",
                        },
                        {
                          value: "Babar Azam",
                          label: "Babar Azam",
                        },
                      ]}
                    />
                  </Form.Item>
                </SimpleFlex>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={10}>
                <SimpleFlex flexdirection="column" gap="0.2rem">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Border-Radius
                  </P>
                  <Form.Item
                    name="borderRadius"
                    className="margin-0"
                    rules={[
                      {
                        required: true,
                        message: "Please input Border-Radius!",
                      },
                    ]}
                    initialValue={widgetStyling?.borderRadius}
                  >
                    <PrimarySelect
                      bordered={false}
                      className="user-select"
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "Abdul Nizam",
                          label: "Abdul Nizam",
                        },
                        {
                          value: "Billal Ahmed",
                          label: "Billal Ahmed",
                        },
                        {
                          value: "Mohammed Waris",
                          label: "Mohammed Waris",
                        },
                        {
                          value: "Mohammed Rizwan",
                          label: "Mohammed Rizwan",
                        },
                        {
                          value: "Babar Azam",
                          label: "Babar Azam",
                        },
                      ]}
                    />
                  </Form.Item>
                </SimpleFlex>
              </Col>
              <Col span={14}>
                <SimpleFlex flexdirection="column" gap="0.5rem">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Border-Weight
                  </P>
                  <SimpleFlex alignitems="center" gap="0.5rem">
                    <Slider
                      className="w-100"
                      defaultValue={widgetStyling?.borderWeight}
                      onChange={(e) => {
                        handleChange(e, "borderWeight");
                      }}
                    />
                    <SimpleInput
                      fontsize="0.875rem"
                      width="5rem"
                      value={widgetStyling?.borderWeight}
                    />
                  </SimpleFlex>
                </SimpleFlex>
              </Col>
            </Row>
          </SimpleFlex>
        </SimpleFlex>
      </SimpleFlex>
    </Form>
  );
}

function ConditionalFormatingCard() {
  const [widgetStyling, setWidgetStyling] = useState({
    element: "Text",
    condition: "Greater than",
    value: "2",
    color: "#273D92",
  });

  const handleChange = (value, field) => {
    setWidgetStyling((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleClear = (field) => {
    setWidgetStyling((prevState) => ({
      ...prevState,
      [field]: "",
    }));
    console.log("object: ", widgetStyling);
  };

  const handleColorChange = (newColor, field) => {
    setWidgetStyling((prevState) => ({
      ...prevState,
      [field]: newColor,
    }));
  };

  const handleClearColor = (field) => {
    setWidgetStyling((prevState) => ({
      ...prevState,
      [field]: "#FFFFFF",
    }));
  };

  return (
    <Form>
      <SimpleFlex
        borderradius="1.125rem"
        border="1px solid var(--columbia-gray-color)"
        padding="1rem"
        width="auto"
        flexdirection="column"
        gap="1rem"
      >
        <SimpleFlex flexdirection="column" gap="0.2rem">
          <SimpleFlex flexdirection="column" gap="1.5rem">
            <Radio.Group name="radiogroup" defaultValue={1}>
              <Radio value={1}>Metric</Radio>
              <Radio value={2}>Group By</Radio>
            </Radio.Group>
            <SimpleFlex flexdirection="column" gap="0.2rem">
              <P color="var(--nav-color)" fontsize="0.75rem">
                Element
              </P>
              <Form.Item
                name="element"
                className="margin-0"
                rules={[
                  {
                    required: true,
                    message: "Please input Element!",
                  },
                ]}
                initialValue={widgetStyling?.element}
              >
                <PrimarySelect
                  bordered={false}
                  className="user-select"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "Abdul Nizam",
                      label: "Abdul Nizam",
                    },
                    {
                      value: "Billal Ahmed",
                      label: "Billal Ahmed",
                    },
                    {
                      value: "Mohammed Waris",
                      label: "Mohammed Waris",
                    },
                    {
                      value: "Mohammed Rizwan",
                      label: "Mohammed Rizwan",
                    },
                    {
                      value: "Babar Azam",
                      label: "Babar Azam",
                    },
                  ]}
                />
              </Form.Item>
            </SimpleFlex>

            <Row gutter={16}>
              <Col span={8}>
                <SimpleFlex flexdirection="column" gap="0.2rem">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Condition
                  </P>
                  <Form.Item
                    name="condition"
                    className="margin-0"
                    rules={[
                      {
                        required: true,
                        message: "Please input Condition!",
                      },
                    ]}
                    initialValue={widgetStyling?.condition}
                  >
                    <PrimarySelect
                      bordered={false}
                      className="user-select"
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "Abdul Nizam",
                          label: "Abdul Nizam",
                        },
                        {
                          value: "Billal Ahmed",
                          label: "Billal Ahmed",
                        },
                        {
                          value: "Mohammed Waris",
                          label: "Mohammed Waris",
                        },
                        {
                          value: "Mohammed Rizwan",
                          label: "Mohammed Rizwan",
                        },
                        {
                          value: "Babar Azam",
                          label: "Babar Azam",
                        },
                      ]}
                    />
                  </Form.Item>
                </SimpleFlex>
              </Col>
              <Col span={8}>
                <SimpleFlex flexdirection="column" gap="0.5rem" width="auto">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Value
                  </P>

                  <Form.Item
                    name="value"
                    className="margin-0"
                    rules={[
                      {
                        required: true,
                        message: "Please input Value!",
                      },
                    ]}
                  >
                    <SimpleFlex alignitems="center" gap="0.5rem">
                      <SimpleInput
                        value={widgetStyling?.value}
                        fontsize="1rem"
                        padding="0.2rem 0.5rem"
                        onChange={(e) => {
                          handleChange(e.target.value, "value");
                        }}
                      />
                      <CancelCircledGray
                        cursor="pointer"
                        onClick={() => {
                          handleClear("value");
                        }}
                      />
                    </SimpleFlex>
                  </Form.Item>
                </SimpleFlex>
              </Col>
              <Col span={8}>
                <SimpleFlex flexdirection="column" gap="0.5rem" width="auto">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Color
                  </P>

                  <SimpleFlex alignitems="center" gap="0.5rem">
                    <ColorPicker
                      showText
                      allowClear
                      value={widgetStyling?.color}
                      onChange={(color) => {
                        handleColorChange(color.toHexString(), "color");
                      }}
                    />
                    <CancelCircledGray
                      cursor="pointer"
                      onClick={() => {
                        handleClearColor("color");
                      }}
                    />
                  </SimpleFlex>
                </SimpleFlex>
              </Col>
            </Row>
            <SimpleFlex>
              <P
                color="var(--navy-seal-color)"
                fontWeight="var(--font-500)"
                cursor="pointer"
              >
                Add condition <PlusOutlined />
              </P>
            </SimpleFlex>
          </SimpleFlex>
        </SimpleFlex>
      </SimpleFlex>
    </Form>
  );
}

function WidgetStylingCard() {
  const [widgetStyling, setWidgetStyling] = useState({
    backgroundColor: "#273D92",
    opacity: 0,
    borderColor: "#CBD5E1",
    borderPosition: "All",
    borderRadius: 1,
    borderWeight: 0,
  });

  const handleChange = (value, field) => {
    setWidgetStyling((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleColorChange = (newColor, field) => {
    setWidgetStyling((prevState) => ({
      ...prevState,
      [field]: newColor,
    }));
  };

  const handleClearColor = (field) => {
    setWidgetStyling((prevState) => ({
      ...prevState,
      [field]: "#FFFFFF",
    }));
  };

  return (
    <Form>
      <SimpleFlex
        borderradius="1.125rem"
        border="1px solid var(--columbia-gray-color)"
        padding="1rem"
        width="auto"
        flexdirection="column"
        gap="1rem"
      >
        <Row gutter={16} className="w-100">
          <Col span={10}>
            <SimpleFlex flexdirection="column" gap="0.5rem">
              <P color="var(--nav-color)" fontsize="0.75rem">
                Background
              </P>
              <SimpleFlex alignitems="center" gap="0.5rem">
                <ColorPicker
                  showText
                  allowClear
                  value={widgetStyling?.backgroundColor}
                  onChange={(color) => {
                    handleColorChange(color.toHexString(), "backgroundColor");
                  }}
                />
                <CancelCircledGray
                  cursor="pointer"
                  onClick={() => {
                    handleClearColor("backgroundColor");
                  }}
                />
              </SimpleFlex>
            </SimpleFlex>
          </Col>
          <Col span={14}>
            <SimpleFlex flexdirection="column" gap="0.5rem">
              <P color="var(--nav-color)" fontsize="0.75rem">
                Opacity
              </P>
              <SimpleFlex alignitems="center" gap="0.5rem">
                <Slider
                  className="w-100"
                  defaultValue={widgetStyling?.opacity}
                  onChange={(e) => {
                    handleChange(e, "opacity");
                  }}
                />
                <SimpleInput
                  fontsize="0.875rem"
                  width="5rem"
                  value={widgetStyling?.opacity}
                />
              </SimpleFlex>
            </SimpleFlex>
          </Col>
        </Row>
        <SimpleFlex flexdirection="column" gap="0.2rem">
          <P
            color="var(--nav-color)"
            fontWeight="var(--font-600)"
            fontsize="0.75rem"
          >
            Border
          </P>
          <SimpleFlex flexdirection="column" gap="1.5rem">
            <Row gutter={16}>
              <Col span={10}>
                <SimpleFlex flexdirection="column" gap="0.5rem" width="auto">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Border-Color
                  </P>

                  <SimpleFlex alignitems="center" gap="0.5rem">
                    <ColorPicker
                      showText
                      allowClear
                      value={widgetStyling?.borderColor}
                      onChange={(color) => {
                        handleColorChange(color.toHexString(), "borderColor");
                      }}
                    />
                    <CancelCircledGray
                      cursor="pointer"
                      onClick={() => {
                        handleClearColor("borderColor");
                      }}
                    />
                  </SimpleFlex>
                </SimpleFlex>
              </Col>
              <Col span={14}>
                <SimpleFlex flexdirection="column" gap="0.2rem">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Border-Position
                  </P>
                  <Form.Item
                    name="borderPosition"
                    className="margin-0"
                    rules={[
                      {
                        required: true,
                        message: "Please input Border-Position!",
                      },
                    ]}
                    initialValue={widgetStyling?.borderPosition}
                  >
                    <PrimarySelect
                      bordered={false}
                      className="user-select"
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "Abdul Nizam",
                          label: "Abdul Nizam",
                        },
                        {
                          value: "Billal Ahmed",
                          label: "Billal Ahmed",
                        },
                        {
                          value: "Mohammed Waris",
                          label: "Mohammed Waris",
                        },
                        {
                          value: "Mohammed Rizwan",
                          label: "Mohammed Rizwan",
                        },
                        {
                          value: "Babar Azam",
                          label: "Babar Azam",
                        },
                      ]}
                    />
                  </Form.Item>
                </SimpleFlex>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={10}>
                <SimpleFlex flexdirection="column" gap="0.2rem">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Border-Radius
                  </P>
                  <Form.Item
                    name="borderRadius"
                    className="margin-0"
                    rules={[
                      {
                        required: true,
                        message: "Please input Border-Radius!",
                      },
                    ]}
                    initialValue={widgetStyling?.borderRadius}
                  >
                    <PrimarySelect
                      bordered={false}
                      className="user-select"
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "Abdul Nizam",
                          label: "Abdul Nizam",
                        },
                        {
                          value: "Billal Ahmed",
                          label: "Billal Ahmed",
                        },
                        {
                          value: "Mohammed Waris",
                          label: "Mohammed Waris",
                        },
                        {
                          value: "Mohammed Rizwan",
                          label: "Mohammed Rizwan",
                        },
                        {
                          value: "Babar Azam",
                          label: "Babar Azam",
                        },
                      ]}
                    />
                  </Form.Item>
                </SimpleFlex>
              </Col>
              <Col span={14}>
                <SimpleFlex flexdirection="column" gap="0.5rem">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Border-Weight
                  </P>
                  <SimpleFlex alignitems="center" gap="0.5rem">
                    <Slider
                      className="w-100"
                      defaultValue={widgetStyling?.borderWeight}
                      onChange={(e) => {
                        handleChange(e, "borderWeight");
                      }}
                    />
                    <SimpleInput
                      fontsize="0.875rem"
                      width="5rem"
                      value={widgetStyling?.borderWeight}
                    />
                  </SimpleFlex>
                </SimpleFlex>
              </Col>
            </Row>
          </SimpleFlex>
        </SimpleFlex>
      </SimpleFlex>
    </Form>
  );
}

function WidgetHeaderCard() {
  const [widgetHeader, setWidgetHeader] = useState({
    color: "#273D92",
    showTitle: true,
  });

  const handleColorChange = (newColor, field) => {
    setWidgetHeader((prevState) => ({
      ...prevState,
      [field]: newColor,
    }));
  };

  const handleClearColor = (field) => {
    setWidgetHeader((prevState) => ({
      ...prevState,
      [field]: "#FFFFFF",
    }));
  };

  const handleSwitchChange = () => {
    setWidgetHeader((currentData) => ({
      ...currentData,
      showTitle: !currentData.showTitle,
    }));
  };

  return (
    <Form>
      <SimpleFlex
        borderradius="1.125rem"
        border="1px solid var(--columbia-gray-color)"
        padding="1rem"
        width="auto"
        flexdirection="column"
        gap="1rem"
      >
        <SimpleFlex flexdirection="column" gap="0.5rem">
          <P color="var(--nav-color)" fontsize="0.75rem">
            Color
          </P>

          <SimpleFlex alignitems="center" justifycontent="space-between">
            <SimpleFlex alignitems="center" gap="0.5rem">
              <ColorPicker
                showText
                allowClear
                value={widgetHeader?.color}
                onChange={(color) => {
                  handleColorChange(color.toHexString(), "color");
                }}
              />
              <CancelCircledGray
                cursor="pointer"
                onClick={() => {
                  handleClearColor("color");
                }}
              />
            </SimpleFlex>
            <SimpleFlex
              gap="1rem"
              alignitems="center"
              justifycontent="flex-end"
            >
              <P fontsize="0.75rem" color="var(--nav-color)">
                Show Title
              </P>
              <SimpleButton
                width="auto"
                padding="0.5rem 0.625rem"
                justifycontent="center"
                gap="0.5rem"
              >
                {widgetHeader?.showTitle ? "Yes" : "No"}
                <SimpleSwitch
                  onChange={handleSwitchChange}
                  size="small"
                  checked={widgetHeader?.showTitle}
                ></SimpleSwitch>
              </SimpleButton>
            </SimpleFlex>
          </SimpleFlex>
        </SimpleFlex>
      </SimpleFlex>
    </Form>
  );
}

export default ChartConfigII;
