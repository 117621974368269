import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Simulate an API call to fetch products
export const fetchProducts = createAsyncThunk("fetchProducts", async () => {
  try {
    // Make an API call to fetch products
    return [];
  } catch (error) {
    throw error;
  }
});

// Simulate an API call to add a new product
export const addProduct = createAsyncThunk("addProduct", async (newProduct) => {
  try {
    // Make an API call to add a new product
    return newProduct;
  } catch (error) {
    throw error;
  }
});

// Simulate an API call to delete a product
export const deleteProduct = createAsyncThunk(
  "deleteProduct",
  async (productId) => {
    try {
      // Make an API call to delete the product
      return productId;
    } catch (error) {
      throw error;
    }
  }
);

// Simulate an API call to edit a product
export const editProduct = createAsyncThunk(
  "editProduct",
  async ({ productId, updatedProduct }) => {
    try {
      // Make an actual API call here to edit the product
      // For now, return the updated product
      return updatedProduct;
    } catch (error) {
      throw error;
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchProducts.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.data.push(action.payload);
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.data = state.data.filter(
          (product) => product.id !== action.payload
        );
      })
      .addCase(editProduct.fulfilled, (state, action) => {
        const updatedProduct = action.payload;
        const index = state.data.findIndex(
          (product) => product.id === updatedProduct.id
        );
        if (index !== -1) {
          state.data[index] = updatedProduct;
        }
      });
  },
});

export default productSlice.reducer;
