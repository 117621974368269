import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Col,
  Drawer,
  Dropdown,
  List,
  Menu,
  Modal,
  Row,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { getComments, getOrders } from "../../API";
import { ReactComponent as Proton } from "../../assets/svg/Proton.svg";
import Alfanar from "../../assets/png/Alfanar.png";
import { ReactComponent as RightArrowDark } from "../../assets/svg/rightArrowDark.svg";
import { ReactComponent as RightArrow } from "../../assets/svg/rightArrow.svg";
import { ReactComponent as LeftArrow } from "../../assets/svg/leftArrow.svg";
import { ReactComponent as CaretDownFilled } from "../../assets/svg/caretDownFilled.svg";
import { ReactComponent as Edit } from "../../assets/svg/edit.svg";
import { useAuth } from "../Auth/AuthContext";

import "./AppHeader.css";
import { PrimaryFlex } from "../StyledComponents/Flex";
import { SimpleFlex } from "../StyledComponents/SimpleFlex";
import { useNavigate } from "react-router-dom";
import { PrimarySelect } from "../StyledComponents/Select";
import { LANGUAGES } from "../../Constants/languages";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";

function AppHeader() {
  const navigate = useNavigate();
  const [comments, setComments] = useState([]);
  const [orders, setOrders] = useState([]);
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const { logout } = useAuth();

  useEffect(() => {
    getComments().then((res) => {
      setComments(res.comments);
    });
    getOrders().then((res) => {
      setOrders(res.products);
    });
  }, []);
  const [messageApi, contextHolder] = message.useMessage();
  const handleLogout = () => {
    logout();
  };

  const handleProfile = () => {
    return navigate("user-profile");
  };

  const menu = [
    {
      label: (
        <SimpleFlex
          alignitems="center"
          gap="0.5rem"
          backgroundcolor="transparent"
          cursor="pointer"
          onClick={handleProfile}
        >
          <UserOutlined /> Profile
        </SimpleFlex>
      ),
      key: "profile",
    },
    {
      label: (
        <SimpleFlex
          alignitems="center"
          gap="0.5rem"
          backgroundcolor="transparent"
          cursor="pointer"
          onClick={handleLogout}
        >
          <LogoutOutlined /> Logout
        </SimpleFlex>
      ),
      key: "logout",
    },
  ];

  return (
    <div className="d-flex justify-between align-center AppHeader">
      {contextHolder}
      <Row gutter={[140, 16]}>
        <Col>
          <Proton />
        </Col>
        <Col>
          <img src={Alfanar} alt="Alfanar" width={100} height={30} />
        </Col>
      </Row>
      <PrimaryFlex gap="1rem" alignitems="center">
        {/* <Badge count={comments.length} dot>
          <MailOutlined
            style={{ fontsize: 24 }}
            onClick={() => {
              setCommentsOpen(true);
            }}
          />
        </Badge> */}
        <div className="gap-8 d-flex align-center mr-2">
          <LeftArrow />
          <div className="gap-8 alert-box-wbb HeaderCardPadding border-columbia-gray d-flex align-center">
            <div className="d-flex flex-direction-col">
              <span className="font-weight-600 font-color-primary">
                Maintenance
              </span>
              <span className="font-weight-300 font-color-nav">
                Triggered Due to : Planned weekly maintenance
              </span>
            </div>
            <div
              className="border-columbia-gray cursor-pointer pb-1 pt-1 pr-2 pl-2 border-radius-6"
              onClick={() => {
                setNotificationsOpen(true);
              }}
            >
              <Edit />
            </div>
            <div className="d-flex align-center cursor-pointer">
              <span
                className="font-weight-500 font-color-nav mr-1"
                onClick={() => {
                  setCommentsOpen(true);
                }}
              >
                View
              </span>
              <RightArrowDark />
            </div>
          </div>
          <RightArrow />
        </div>

        <LanguageSwitcher />
        <Dropdown
          trigger={["click"]}
          menu={{
            items: menu,
          }}
        >
          <a onClick={(e) => e.preventDefault()} className="color-black">
            <PrimaryFlex alignitems="center" cursor="pointer" className="gap-6">
              <Avatar
                className="border-columbia-gray"
                size={{
                  xs: 30,
                  sm: 30,
                  md: 30,
                  lg: 35,
                  xl: 40,
                  xxl: 50,
                }}
                src={
                  <img
                    src={
                      "https://fastly.picsum.photos/id/40/4106/2806.jpg?hmac=MY3ra98ut044LaWPEKwZowgydHZ_rZZUuOHrc3mL5mI"
                    }
                    alt="avatar"
                  />
                }
              />

              <div className="d-flex flex-direction-col">
                <span className="font-weight-700" style={{ marginLeft: 8 }}>
                  Jhon Methew
                </span>
                <span className="font-weight-300" style={{ marginLeft: 8 }}>
                  Machine Operator
                </span>
              </div>
              <CaretDownFilled />
            </PrimaryFlex>
          </a>
        </Dropdown>
      </PrimaryFlex>
      <Drawer
        title="Comments"
        open={commentsOpen}
        onClose={() => {
          setCommentsOpen(false);
        }}
        maskClosable
      >
        <List
          dataSource={comments}
          renderItem={(item) => {
            return <List.Item>{item.body}</List.Item>;
          }}
        ></List>
      </Drawer>

      <Modal
        title="Edit Card"
        wrapClassName="vertical-center-modal"
        open={notificationsOpen}
        onOk={() => setNotificationsOpen(false)}
        onCancel={() => {
          setNotificationsOpen(false);
        }}
        onClose={() => {
          setNotificationsOpen(false);
        }}
      >
        <p>some contents...</p>
        <p>some contents...</p>
        <p>some contents...</p>
        <p>some contents...</p>
        <p>some contents...</p>
        <p>some contents...</p>
        <p>some contents...</p>
        <p>some contents...</p>
        <p>some contents...</p>
        <p>some contents...</p>
        <p>some contents...</p>
        <p>some contents...</p>
      </Modal>
    </div>
  );
}
export default AppHeader;
