import { useEffect, useState } from "react";
import { PrimaryModal } from "../../../Components/StyledComponents/Modal";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { useDispatch } from "react-redux";
import { editHoliday } from "../../../redux/slice/Holidays/holiday";
import { ReactComponent as CalendarSVG } from "../../../assets/svg/calendar.svg";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../Components/StyledComponents/Button";
import { Calendar, Form } from "antd";
import { SimpleInput } from "../../../Components/StyledComponents/SimpleInput";
import moment from "moment";
import "./EditHoliday.scss";

function EditHoliday({ closeButton, payload }) {
  const dispatch = useDispatch();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedRow, setSelectedRow] = useState({
    date: payload?.date
      ? moment(moment(payload?.date, "DD/MM/YYYY")).format("D MMMM, YYYY")
      : moment().format("D MMMM, YYYY"),
    holidayName: payload?.holidayName || "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedRow((prevState) => {
      const newState = {
        ...prevState,
        [name]: value,
      };

      return newState;
    });
  };

  const handleOk = async () => {
    setLoading(true);

    try {
      dispatch(editHoliday(selectedRow));
      await new Promise((resolve) => setTimeout(resolve, 2000));
      console.log("Holiday edited successfully!");
    } catch (error) {
      console.error("Error editing holiday:", error);
    } finally {
      setLoading(false);
      closeButton();
    }
  };

  const handleCancel = () => {
    closeButton();
  };

  useEffect(() => {
    const { date, holidayName } = selectedRow;
    const formComplete = date?.trim() !== "" && holidayName?.trim() !== "";

    setIsFormComplete(formComplete);
  }, [selectedRow]);

  const EditModalfooter = (
    <SimpleFlex
      margin="1.5rem 0 0 0"
      alignitems="center"
      justifycontent="flex-end"
    >
      <SimpleFlex gap="1rem" width="auto">
        <SimpleButton onClick={handleCancel} padding="0.5rem 1rem">
          Cancel
        </SimpleButton>
        <PrimaryButton
          disabled={!isFormComplete}
          loading={loading}
          onClick={handleOk}
          padding="0.5rem 1rem"
        >
          Save Changes
        </PrimaryButton>
      </SimpleFlex>
    </SimpleFlex>
  );

  const handleDateChange = (value) => {
    const formattedDate = moment(value?.$d).format("D MMMM, YYYY");
    setSelectedRow((prevState) => {
      const newState = {
        ...prevState,
        date: formattedDate,
      };

      return newState;
    });
  };
  return (
    <PrimaryModal
      centered
      title="Edit Holiday"
      open
      onOk={handleOk}
      onCancel={handleCancel}
      footer={EditModalfooter}
      maskClosable={false}
    >
      <Form>
        <SimpleFlex flexdirection="column" gap="1rem">
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Choose Date:
            </P>
            <SimpleFlex
              justifycontent="space-between"
              alignitems="center"
              className="border-bottom"
            >
              <P>{selectedRow?.date}</P>
              <CalendarSVG />
            </SimpleFlex>
            <Form.Item
              className="margin-0"
              rules={[
                {
                  required: true,
                  message: "Please input description!",
                },
              ]}
            >
              <Calendar
                className="custom-calendar"
                fullscreen={false}
                onChange={(value) => {
                  handleDateChange(value);
                }}
              />
            </Form.Item>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Holiday Name
            </P>
            <Form.Item
              name="holidayName"
              className="margin-0"
              rules={[
                {
                  required: true,
                  message: "Please input Holiday Name!",
                },
              ]}
            >
              <SimpleInput
                bordered={false}
                name="holidayName"
                defaultValue={selectedRow?.shortName}
                onChange={handleInputChange}
                style={{ borderBottom: "1px solid black" }}
                className="user-input"
                padding="0 0 0.3rem 0"
                color="var(--black)"
                borderradius="0"
                fontsize="1rem"
              />
            </Form.Item>
          </SimpleFlex>
        </SimpleFlex>
      </Form>
    </PrimaryModal>
  );
}
export default EditHoliday;
