import { Form } from "antd";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { ReactComponent as CancelIcon } from "../../../assets/svg/cancel.svg";
import "./Filter.scss";
import { PrimarySelect } from "../../../Components/StyledComponents/Select";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../Components/StyledComponents/Button";
import { motion } from "framer-motion";

function Filter({ onClose }) {
  const item = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: 100 },
  };

  return (
    <motion.div initial="hidden" animate="visible" variants={item}>
      <SimpleFlex
        padding="1rem 2rem"
        width="auto"
        alignitems="baseline"
        className="border-radius"
      >
        <SimpleFlex flexdirection="column" gap="2rem">
          <SimpleFlex height="auto" alignitems="center" gap="12rem">
            <P fontWeight="var(--font-500)" fontsize="1.25rem">
              Filter
            </P>
            <p onClick={onClose}>
              <CancelIcon cursor="pointer" />
            </p>
          </SimpleFlex>
          <Form>
            <SimpleFlex flexdirection="column" gap="2rem">
              <SimpleFlex flexdirection="column" gap="0.2rem">
                <P color="var(--nav-color)" fontsize="0.75rem">
                  Machine
                </P>
                <Form.Item
                  name="machine"
                  className="margin-0"
                  rules={[
                    {
                      required: true,
                      message: "Please input Machine!",
                    },
                  ]}
                >
                  <PrimarySelect
                    bordered={false}
                    className="user-select"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={[
                      {
                        value: "Abdul Nizam",
                        label: "Abdul Nizam",
                      },
                      {
                        value: "Billal Ahmed",
                        label: "Billal Ahmed",
                      },
                      {
                        value: "Mohammed Waris",
                        label: "Mohammed Waris",
                      },
                      {
                        value: "Mohammed Rizwan",
                        label: "Mohammed Rizwan",
                      },
                      {
                        value: "Babar Azam",
                        label: "Babar Azam",
                      },
                    ]}
                  />
                </Form.Item>
              </SimpleFlex>
              <SimpleFlex flexdirection="column" gap="0.2rem">
                <P color="var(--nav-color)" fontsize="0.75rem">
                  Security Level
                </P>
                <Form.Item
                  name="securityLevel"
                  className="margin-0"
                  rules={[
                    {
                      required: true,
                      message: "Please input Security Level!",
                    },
                  ]}
                >
                  <PrimarySelect
                    bordered={false}
                    className="user-select"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={[
                      {
                        value: "critical",
                        label: "Critical",
                      },
                      {
                        value: "normal",
                        label: "Normal",
                      },
                      {
                        value: "danger",
                        label: "Danger",
                      },
                    ]}
                  />
                </Form.Item>
              </SimpleFlex>
              <SimpleFlex flexdirection="column" gap="0.2rem">
                <P color="var(--nav-color)" fontsize="0.75rem">
                  Actions
                </P>
                <Form.Item
                  name="action"
                  className="margin-0"
                  rules={[
                    {
                      required: true,
                      message: "Please input Action!",
                    },
                  ]}
                >
                  <PrimarySelect
                    bordered={false}
                    className="user-select"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={[
                      {
                        value: "emailNotifications",
                        label: "Email Notifications",
                      },
                      {
                        value: "phoneNotifications",
                        label: "Phone Notifications",
                      },
                      {
                        value: "callNotifications",
                        label: "Call Notifications",
                      },
                    ]}
                  />
                </Form.Item>
              </SimpleFlex>
              <SimpleFlex justifycontent="space-between">
                <SimpleButton padding="0.5rem 2.125rem">Reset</SimpleButton>
                <PrimaryButton padding="0.5rem 2.125rem">Apply</PrimaryButton>
              </SimpleFlex>
            </SimpleFlex>
          </Form>
        </SimpleFlex>
      </SimpleFlex>
    </motion.div>
  );
}
export default Filter;
