import { useEffect, useState } from "react";
import { PrimaryModal } from "../../../Components/StyledComponents/Modal";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { PrimarySelect } from "../../../Components/StyledComponents/Select";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { useDispatch } from "react-redux";
import { addGatewayConfiguration } from "../../../redux/slice/GatewayConfigurations/gatewayConfiguration";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../Components/StyledComponents/Button";
import { Form } from "antd";
import { SimpleInput } from "../../../Components/StyledComponents/SimpleInput";
import { ReactComponent as Copy } from "../../../assets/svg/copy.svg";
import "./AddGatewayConfiguration.scss";

function AddGatewayConfiguration({ closeButton }) {
  const dispatch = useDispatch();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedRow, setSelectedRow] = useState({
    gateway: "",
    token: "",
    status: "",
  });

  const handleSelectChange = (selectedValues, field) => {
    setSelectedRow((prevState) => ({
      ...prevState,
      [field]: selectedValues ?? "",
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedRow((prevState) => {
      const newState = {
        ...prevState,
        [name]: value,
      };

      return newState;
    });
  };

  const handleOk = async () => {
    setLoading(true);

    try {
      dispatch(addGatewayConfiguration(selectedRow));
      await new Promise((resolve) => setTimeout(resolve, 2000));
      console.log("Gatewat added successfully!");
    } catch (error) {
      console.error("Error adding gateway:", error);
    } finally {
      setLoading(false);
      closeButton();
    }
  };

  const handleCancel = () => {
    closeButton();
  };

  const handleCopyClick = (record) => {
    navigator?.clipboard
      .writeText(record)
      .then(() => {
        console.log("Record copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy record: ", err);
      });
  };

  useEffect(() => {
    const { gateway, token, status } = selectedRow;
    const formComplete =
      gateway?.trim() !== "" && token?.trim() !== "" && status?.trim() !== "";

    setIsFormComplete(formComplete);
  }, [selectedRow]);

  const AddModalfooter = (
    <SimpleFlex
      margin="1.5rem 0 0 0"
      alignitems="center"
      justifycontent="flex-end"
    >
      <SimpleFlex gap="1rem" width="auto">
        <SimpleButton onClick={handleCancel} padding="0.5rem 1rem">
          Cancel
        </SimpleButton>
        <PrimaryButton
          disabled={!isFormComplete}
          loading={loading}
          onClick={handleOk}
          padding="0.5rem 1rem"
        >
          Generate Token
        </PrimaryButton>
      </SimpleFlex>
    </SimpleFlex>
  );

  return (
    <PrimaryModal
      centered
      title="Add Gateway"
      open
      onOk={handleOk}
      onCancel={handleCancel}
      footer={AddModalfooter}
      maskClosable={false}
    >
      <Form>
        <SimpleFlex flexdirection="column" gap="1rem">
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Gateway Name
            </P>
            <Form.Item
              name="gateway"
              className="margin-0"
              rules={[
                {
                  required: true,
                  message: "Please input gateway!",
                },
              ]}
              initialValue={selectedRow?.gateway}
            >
              <SimpleInput
                bordered={false}
                name="gateway"
                onChange={handleInputChange}
                style={{ borderBottom: "1px solid black" }}
                className="user-input"
                padding="0 0 0.3rem 0"
                color="var(--black)"
                borderradius="0"
                fontsize="1rem"
              />
            </Form.Item>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Status
            </P>
            <Form.Item
              name="status"
              className="margin-0"
              rules={[
                {
                  required: true,
                  message: "Please input Status!",
                },
              ]}
              initialValue={selectedRow?.status}
            >
              <PrimarySelect
                bordered={false}
                className="user-select"
                showSearch
                allowClear
                onChange={(value) => {
                  handleSelectChange(value, "status");
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "Abdul Nizam",
                    label: "Abdul Nizam",
                  },
                  {
                    value: "Billal Ahmed",
                    label: "Billal Ahmed",
                  },
                  {
                    value: "Mohammed Waris",
                    label: "Mohammed Waris",
                  },
                  {
                    value: "Mohammed Rizwan",
                    label: "Mohammed Rizwan",
                  },
                  {
                    value: "Babar Azam",
                    label: "Babar Azam",
                  },
                ]}
              />
            </Form.Item>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Token
            </P>
            <Form.Item
              name="token"
              className="margin-0"
              rules={[
                {
                  required: true,
                  message: "Please input token!",
                },
              ]}
              initialValue={selectedRow?.token}
            >
              <SimpleFlex alignitems="center" className="border-bottom">
                <SimpleInput
                  bordered={false}
                  name="token"
                  onChange={handleInputChange}
                  padding="0 0 0.3rem 0"
                  color="var(--black)"
                  borderradius="0"
                  fontsize="1rem"
                />
                <SimpleButton padding="0.2rem 0.8rem" margin="0 0 0.5rem 0">
                  <SimpleFlex
                    alignitems="center"
                    gap="0.5rem"
                    cursor="pointer"
                    onClick={() => {
                      handleCopyClick(selectedRow?.token);
                    }}
                  >
                    Copy <Copy />
                  </SimpleFlex>
                </SimpleButton>
              </SimpleFlex>
            </Form.Item>
          </SimpleFlex>
        </SimpleFlex>
      </Form>
    </PrimaryModal>
  );
}
export default AddGatewayConfiguration;
