import { Divider, Empty, List, Result, Skeleton } from "antd";
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { P } from "../../../Components/StyledComponents/Paragraph";
import transition from "../../../Transition";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { fetchDashboardFeeds } from "../../../redux/slice/Dashboard/dashboardFeeds";
import { useTranslation } from "react-i18next";
import "../Dashboard.scss";

function DashboardFeed() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dashboardFeeds } = useSelector((state) => state);

  const feed = dashboardFeeds?.data?.data?.feeds || [];
  const isLoading = dashboardFeeds?.isLoading;
  const hasFeedData = dashboardFeeds?.data?.data?.feeds?.length > 0;
  const isError = dashboardFeeds?.isError;

  useEffect(() => {
    // Dispatch the action when the component mounts
    dispatch(fetchDashboardFeeds());
  }, [dispatch]);

  return (
    <div>
      <P fontWeight="var(--font-500)" fontsize="1.25rem">
        {t("feeds")}
      </P>
      <SimpleFlex width="auto" flexdirection="column" gap="1rem">
        {isLoading && <Skeleton active loading={true} />}
        {!isLoading && !hasFeedData && !isError && (
          // Show "no data" state if there is no data
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        {isError && (
          // Show error state if there's an error
          <Result
            status="500"
            title="500"
            subTitle="Sorry, Failed to fetch notifications."
          />
        )}
        {!isLoading && hasFeedData && (
          <div>
            <List
              itemLayout="horizontal"
              dataSource={feed}
              renderItem={(item, index) => (
                <List.Item>
                  <Skeleton loading={isLoading} active>
                    <List.Item className="abcd">
                      <SimpleFlex flexdirection="column" gap="0.5rem">
                        <P fontWeight="var(--font-500)">{item.title}</P>
                        <SimpleFlex flexdirection="column">
                          <SimpleFlex width="auto" gap="0.2rem">
                            <P fontsize="0.75rem" color="var(--nav-color)">
                              {t("dateTime")} :{" "}
                            </P>
                            <P fontsize="0.75rem">{item.date}</P>
                          </SimpleFlex>
                          <SimpleFlex width="auto" gap="0.2rem">
                            <P fontsize="0.75rem" color="var(--nav-color)">
                              {t("sender")} :
                            </P>
                            <P fontsize="0.75rem">{item.sender}</P>
                          </SimpleFlex>
                          <P
                            fontsize="0.75rem"
                            fontWeight="var(--font-500)"
                            cursor="pointer"
                          >
                            {t("more")}
                          </P>
                        </SimpleFlex>
                      </SimpleFlex>
                    </List.Item>
                  </Skeleton>
                </List.Item>
              )}
            />
            <Divider></Divider>
            <SimpleFlex flexdirection="row-reverse" cursor="pointer">
              <P
                fontWeight="var(--font-500)"
                color="var(--nav-color)"
                textAlign="end"
                margin="1rem 0 0 0"
                cursor="pointer"
              >
                {t("viewAll")}
              </P>
            </SimpleFlex>
          </div>
        )}
      </SimpleFlex>
    </div>
  );
}

export default transition(DashboardFeed);
