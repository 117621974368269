import React from "react";
import styled from "styled-components";
import { ConfigProvider, Space, Carousel } from "antd";

const CustomCarousel = styled(Carousel)``;

export const PrimaryCarousel = (props) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#21223d",
        },
      }}
    >
      <Space>
        <CustomCarousel {...props}>{props.children}</CustomCarousel>
      </Space>
    </ConfigProvider>
  );
};
