import { useEffect } from "react";
import { Space, Typography } from "antd";
import Highcharts from "highcharts";
import { SimpleFlex } from "../../Components/StyledComponents/SimpleFlex";
import highchartsMore from "highcharts/highcharts-more";

highchartsMore(Highcharts);

function Customers() {
  useEffect(() => {
    Highcharts.chart("container", {
      chart: {
        type: "columnrange",
        inverted: true,
      },
      xAxis: { visible: false },
      yAxis: {
        type: "datetime",
        tickInterval: 3600000, // 1 hour
      },
      series: [
        {
          name: "Downtime",
          data: [
            [Date.UTC(2023, 11, 28, 1, 0), Date.UTC(2023, 11, 28, 2, 0)],
            [Date.UTC(2023, 11, 28, 2, 0), Date.UTC(2023, 11, 28, 3, 0)],
            [Date.UTC(2023, 11, 28, 4, 0), Date.UTC(2023, 11, 28, 4, 15)],
            [Date.UTC(2023, 11, 28, 5, 0), Date.UTC(2023, 11, 28, 5, 15)],
            [Date.UTC(2023, 11, 28, 6, 0), Date.UTC(2023, 11, 28, 7, 0)],
            [Date.UTC(2023, 11, 28, 8, 0), Date.UTC(2023, 11, 28, 8, 45)],
            [Date.UTC(2023, 11, 28, 8, 45), Date.UTC(2023, 11, 28, 9, 0)],
          ],
          color: "var(--warning-color)",
        },
        {
          name: "Planned Downtime",
          data: [
            [Date.UTC(2023, 11, 28, 3, 0), Date.UTC(2023, 11, 28, 4, 0)],
            [Date.UTC(2023, 11, 28, 7, 0), Date.UTC(2023, 11, 28, 8, 0)],
          ],
          color: "var(--snow-pea-green)",
        },
        {
          name: "Unplanned Downtime",
          data: [
            [Date.UTC(2023, 11, 28, 4, 15), Date.UTC(2023, 11, 28, 5, 0)],
            [Date.UTC(2023, 11, 28, 5, 15), Date.UTC(2023, 11, 28, 6, 0)],
          ],
          color: "var(--nav-color)",
        },
      ],
    });
  }, []); // Run the effect only once after the component is mounted

  return (
    <SimpleFlex flexdirection="column" overflow="auto">
      <Typography.Title level={4}>Customers</Typography.Title>
      <SimpleFlex>
        <figure className="highcharts-figure" style={{ width: "100%" }}>
          <div id="container"></div>
        </figure>
      </SimpleFlex>
    </SimpleFlex>
  );
}

export default Customers;
