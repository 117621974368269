import { Breadcrumb, Result, Table, Tabs } from "antd";
import { SimpleFlex } from "../../Components/StyledComponents/SimpleFlex";
import { P } from "../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../Components/StyledComponents/Button";
import { ReactComponent as Plus } from "../../assets/svg/plus.svg";
import { ReactComponent as Edit } from "../../assets/svg/edit.svg";
import { useEffect, useState } from "react";
import { fetchShifts } from "../../redux/slice/Shifts/shift";

import EditShift from "./EditShiftModal";
import AddShift from "./AddShiftModal";
import { useDispatch, useSelector } from "react-redux";
import { PrimarySelect } from "../../Components/StyledComponents/Select";
import transition from "../../Transition";
import "./Shifts.scss";

function Shifts() {
  const dispatch = useDispatch();
  const { shifts = [] } = useSelector((state) => state);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedFactory, setSelectedFactory] = useState("Switch Factory");
  const dataSource = shifts?.data || [];
  const [filteredData, setFilteredData] = useState(dataSource);
  const [activeKey, setActiveKey] = useState("mon");

  useEffect(() => {
    setFilteredData(dataSource);
  }, [dataSource]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const breadcrumbItems = [
    { title: "Dashboard", href: "/proton/live-dashboard" },
    { title: "Shift Management", href: "" },
  ];

  const columns = [
    {
      title: "Shift Name",
      dataIndex: "shiftName",
      key: "shiftName",
      sorter: (a, b) => a.shiftName.localeCompare(b.shiftName),
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      sorter: (a, b) => a.startTime.localeCompare(b.startTime),
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
    },
    {
      title: "Planned Stop",
      dataIndex: "plannedStop",
      key: "plannedStop",
      render: (record) => (
        <SimpleFlex
          flexdirection="column"
          height="auto"
          backgroundcolor="transparent"
          gap="0.5rem"
        >
          {record.map((stop, index) => (
            <SimpleFlex
              flexdirection="column"
              height="auto"
              backgroundcolor="transparent"
            >
              <P>{stop.time}</P>
              <P fontsize="0.75rem" color="var(--nav-color)">
                {stop.description}
              </P>
            </SimpleFlex>
          ))}
        </SimpleFlex>
      ),
    },
    {
      title: "Total Planned Hours",
      dataIndex: "totalPlannedHours",
      key: "totalPlannedHours",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (record) => (
        <SimpleFlex gap="1rem" backgroundcolor="transparent">
          <SimpleButton
            padding="0.4rem 0.8rem"
            onClick={() => {
              setSelectedRow(record);
              setShowEditModal(true);
            }}
          >
            <SimpleFlex cursor="pointer" alignitems="center" gap="0.2rem">
              <P color="var(--black)" cursor="pointer" fontsize="1rem">
                Edit
              </P>
              <P
                fontsize="0.3rem"
                color="var(--primary-color)"
                cursor="pointer"
              >
                <Edit />
              </P>
            </SimpleFlex>
          </SimpleButton>
        </SimpleFlex>
      ),
    },
  ];

  const tabItems = [
    {
      key: "mon",
      label: "Mon",
    },
    {
      key: "tue",
      label: "Tue",
    },
    {
      key: "wed",
      label: "Wed",
    },
    {
      key: "thu",
      label: "Thu",
    },
    {
      key: "fri",
      label: "Fri",
    },
    {
      key: "sat",
      label: "Sat",
    },
    {
      key: "sun",
      label: "Sun",
    },
  ];

  const deleteButton = () => {
    return (
      <SimpleButton
        padding="0.5rem 2rem"
        disabled={selectedRowKeys.length === 0}
      >
        Delete
      </SimpleButton>
    );
  };

  const fetchShiftByCategoryAndDay = (category, day) => {
    dispatch(fetchShifts({ category, day }));
  };

  const handleFactoryChange = (value) => {
    setSelectedFactory(value);
    fetchShiftByCategoryAndDay(value, activeKey);
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
    fetchShiftByCategoryAndDay(selectedFactory, key);
  };

  // Dispatch the action when the component mounts
  useEffect(() => {
    fetchShiftByCategoryAndDay(selectedFactory, activeKey);
  }, [dispatch]);

  if (shifts?.isError) {
    return (
      <SimpleFlex justifycontent="center" backgroundcolor="transparent">
        <Result
          status="500"
          title="500"
          subTitle="Sorry, Failed to fetch shifts"
        />
      </SimpleFlex>
    );
  }

  return (
    <SimpleFlex
      backgroundcolor="transparent"
      padding="1rem 2rem"
      overflow="scroll"
      flexdirection="column"
      gap="1rem"
      width="auto"
      height="auto"
    >
      <SimpleFlex
        width="auto"
        height="fit-content"
        backgroundcolor="transparent"
      >
        <Breadcrumb separator=">">
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>
              <a href={item.href}>{item.title}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        justifycontent="space-between"
      >
        <P fontsize="2.25rem" fontWeight="var(--font-600)">
          Shift Management
        </P>
        <SimpleFlex width="auto" gap="1rem" backgroundcolor="transparent">
          <PrimaryButton
            padding="0.75rem 1.625rem"
            onClick={() => {
              setShowAddModal(true);
            }}
          >
            <SimpleFlex
              cursor="pointer"
              alignitems="center"
              gap="0.5rem"
              backgroundcolor="transparent"
            >
              <P
                color="var(--columbia-gray-color)"
                fontWeight="var(--font-500)"
                cursor="pointer"
              >
                Add Shift
              </P>{" "}
              <Plus />
            </SimpleFlex>{" "}
          </PrimaryButton>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        flexdirection="column"
        gap="1rem"
      >
        <SimpleFlex
          backgroundcolor="transparent"
          justifycontent="space-between"
        >
          <SimpleFlex
            width="auto"
            backgroundcolor="transparent"
            className="transparent-search"
            flexdirection="column"
            gap="0.625rem"
          >
            <P fontsize="0.75rem" color="var(--nav-color)">
              Select Factory
            </P>
            <PrimarySelect
              className="select-factory"
              showSearch
              allowClear
              value={selectedFactory}
              onChange={handleFactoryChange}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "Switch Factory",
                  label: "Switch Factory",
                },
                {
                  value: "critical",
                  label: "critical",
                },
                {
                  value: "normal",
                  label: "normal",
                },
              ]}
            />
          </SimpleFlex>
        </SimpleFlex>
        <SimpleFlex flexdirection="column" backgroundcolor="transparent">
          <Tabs
            className="tabs"
            tabBarExtraContent={deleteButton()}
            items={tabItems}
            onChange={handleTabChange}
          />
          <Table
            loading={shifts?.isLoading}
            rowSelection={rowSelection}
            dataSource={filteredData}
            columns={columns}
            bordered
            className="w-100 custom-table"
            pagination={{
              position: ["bottomCenter"],
              pageSize: 10,
            }}
          />
        </SimpleFlex>
      </SimpleFlex>
      {!!showEditModal && (
        <EditShift
          payload={selectedRow}
          closeButton={() => {
            setShowEditModal(false);
          }}
        />
      )}

      {!!showAddModal && (
        <AddShift
          closeButton={() => {
            setShowAddModal(false);
          }}
        />
      )}
    </SimpleFlex>
  );
}
export default transition(Shifts);
