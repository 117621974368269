import { useEffect, useState } from "react";
import { PrimaryModal } from "../../../Components/StyledComponents/Modal";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { PrimarySelect } from "../../../Components/StyledComponents/Select";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { useDispatch } from "react-redux";
import { addShift } from "../../../redux/slice/Shifts/shift";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../Components/StyledComponents/Button";
import { Col, Form, Row, Tabs } from "antd";
import { SimpleInput } from "../../../Components/StyledComponents/SimpleInput";
import "./AddShift.scss";

function AddShift({ closeButton }) {
  const dispatch = useDispatch();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedRow, setSelectedRow] = useState({
    stopTimeName: "",
    days: [],
    startHours: "",
    startMins: "",
    startMeridiem: "",
    endHours: "",
    endMins: "",
    endMeridiem: "",
    totalPlannedHours: "",
  });

  const handleSelectChange = (selectedValues, field) => {
    setSelectedRow((prevState) => ({
      ...prevState,
      [field]: selectedValues ?? "",
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedRow((prevState) => {
      const newState = {
        ...prevState,
        [name]: value,
      };

      return newState;
    });
  };

  const handleOk = async () => {
    setLoading(true);

    try {
      dispatch(addShift(selectedRow));
      await new Promise((resolve) => setTimeout(resolve, 2000));
      console.log("Shift added successfully!");
    } catch (error) {
      console.error("Error adding shift:", error);
    } finally {
      setLoading(false);
      closeButton();
    }
  };

  const handleCancel = () => {
    closeButton();
  };

  const tabItems = [
    {
      key: "Basic Details",
      label: "Basic Details",
      children: (
        <Form>
          <SimpleFlex flexdirection="column" gap="1rem">
            <SimpleFlex flexdirection="column" gap="1rem">
              <P color="var(--nav-color)" fontsize="0.75rem">
                Stop Time Name
              </P>
              <Form.Item
                name="stopTimeName"
                className="margin-0"
                rules={[
                  {
                    required: true,
                    message: "Please input Stop Time Name!",
                  },
                ]}
                initialValue={selectedRow?.stopTimeName}
              >
                <PrimarySelect
                  bordered={false}
                  className="user-select"
                  showSearch
                  allowClear
                  onChange={(value) => {
                    handleSelectChange(value, "stopTimeName");
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "morning",
                      label: "Morning",
                    },
                    {
                      value: "evening",
                      label: "Evening",
                    },
                    {
                      value: "general",
                      label: "General",
                    },
                    {
                      value: "night",
                      label: "Night",
                    },
                  ]}
                />
              </Form.Item>
            </SimpleFlex>
            <SimpleFlex flexdirection="column" gap="1rem">
              <P color="var(--nav-color)" fontsize="0.75rem">
                Days
              </P>
              <Form.Item
                name="days"
                className="margin-0"
                rules={[
                  {
                    required: true,
                    message: "Please input Day!",
                  },
                ]}
                initialValue={selectedRow?.days}
              >
                <PrimarySelect
                  bordered={false}
                  className="user-select"
                  mode="multiple"
                  showSearch
                  allowClear
                  onChange={(value) => {
                    handleSelectChange(value, "days");
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "mon",
                      label: "Mon",
                    },
                    {
                      value: "tue",
                      label: "Tue",
                    },
                    {
                      value: "wed",
                      label: "Wed",
                    },
                    {
                      value: "thu",
                      label: "Thu",
                    },
                    {
                      value: "fri",
                      label: "Fri",
                    },
                    {
                      value: "sat",
                      label: "Sat",
                    },
                    {
                      value: "sun",
                      label: "Sun",
                    },
                  ]}
                />
              </Form.Item>
            </SimpleFlex>
            <SimpleFlex flexdirection="column" gap="1rem">
              <P color="var(--nav-color)" fontsize="0.75rem">
                Start Time
              </P>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="startHours"
                    className="margin-0"
                    rules={[
                      {
                        required: true,
                        message: "Please input Stop Time Name!",
                      },
                    ]}
                    initialValue={selectedRow?.startHours}
                  >
                    <PrimarySelect
                      bordered={false}
                      className="user-select"
                      showSearch
                      allowClear
                      onChange={(value) => {
                        handleSelectChange(value, "startHours");
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "1",
                          label: "1 Hrs",
                        },
                        {
                          value: "2",
                          label: "2 Hrs",
                        },
                        {
                          value: "3",
                          label: "3 Hrs",
                        },
                        {
                          value: "4",
                          label: "4 Hrs",
                        },
                        {
                          value: "5",
                          label: "5 Hrs",
                        },
                        {
                          value: "6",
                          label: "6 Hrs",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="startMins"
                    className="margin-0"
                    rules={[
                      {
                        required: true,
                        message: "Please input Stop Time Name!",
                      },
                    ]}
                    initialValue={selectedRow?.startMins}
                  >
                    <PrimarySelect
                      bordered={false}
                      className="user-select"
                      showSearch
                      allowClear
                      onChange={(value) => {
                        handleSelectChange(value, "startMins");
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "10",
                          label: "10 Mins",
                        },
                        {
                          value: "20",
                          label: "20 Mins",
                        },
                        {
                          value: "30",
                          label: "30 Mins",
                        },
                        {
                          value: "40",
                          label: "40 Mins",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="startMeridiem"
                    className="margin-0"
                    rules={[
                      {
                        required: true,
                        message: "Please input Time Period!",
                      },
                    ]}
                    initialValue={selectedRow?.startMeridiem}
                  >
                    <PrimarySelect
                      bordered={false}
                      className="user-select"
                      showSearch
                      allowClear
                      onChange={(value) => {
                        handleSelectChange(value, "startMeridiem");
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "am",
                          label: "AM",
                        },
                        {
                          value: "pm",
                          label: "PM",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </SimpleFlex>
            <SimpleFlex flexdirection="column" gap="1rem">
              <P color="var(--nav-color)" fontsize="0.75rem">
                End Time
              </P>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="endHours"
                    className="margin-0"
                    rules={[
                      {
                        required: true,
                        message: "Please input Stop Time Name!",
                      },
                    ]}
                    initialValue={selectedRow?.endHours}
                  >
                    <PrimarySelect
                      bordered={false}
                      className="user-select"
                      showSearch
                      allowClear
                      onChange={(value) => {
                        handleSelectChange(value, "endHours");
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "1",
                          label: "1 Hrs",
                        },
                        {
                          value: "2",
                          label: "2 Hrs",
                        },
                        {
                          value: "3",
                          label: "3 Hrs",
                        },
                        {
                          value: "4",
                          label: "4 Hrs",
                        },
                        {
                          value: "5",
                          label: "5 Hrs",
                        },
                        {
                          value: "6",
                          label: "6 Hrs",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="endMins"
                    className="margin-0"
                    rules={[
                      {
                        required: true,
                        message: "Please input Stop Time Name!",
                      },
                    ]}
                    initialValue={selectedRow?.endMins}
                  >
                    <PrimarySelect
                      bordered={false}
                      className="user-select"
                      showSearch
                      allowClear
                      onChange={(value) => {
                        handleSelectChange(value, "endMins");
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "10",
                          label: "10 Mins",
                        },
                        {
                          value: "20",
                          label: "20 Mins",
                        },
                        {
                          value: "30",
                          label: "30 Mins",
                        },
                        {
                          value: "40",
                          label: "40 Mins",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="endMeridiem"
                    className="margin-0"
                    rules={[
                      {
                        required: true,
                        message: "Please input Time Period!",
                      },
                    ]}
                    initialValue={selectedRow?.endMeridiem}
                  >
                    <PrimarySelect
                      bordered={false}
                      className="user-select"
                      showSearch
                      allowClear
                      onChange={(value) => {
                        handleSelectChange(value, "endMeridiem");
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "am",
                          label: "AM",
                        },
                        {
                          value: "pm",
                          label: "PM",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </SimpleFlex>
            <SimpleFlex flexdirection="column" gap="1rem">
              <P color="var(--nav-color)" fontsize="0.75rem">
                Total Planned Hours
              </P>
              <Form.Item
                name="totalPlannedHours"
                className="margin-0"
                rules={[
                  {
                    required: true,
                    message: "Please input Total Planned Hours!",
                  },
                ]}
                initialValue={selectedRow?.totalPlannedHours}
              >
                <SimpleInput
                  bordered={false}
                  name="totalPlannedHours"
                  onChange={handleInputChange}
                  style={{ borderBottom: "1px solid black" }}
                  className="user-input"
                  padding="0 0 0.3rem 0"
                  color="var(--black)"
                  borderradius="0"
                  fontsize="1rem"
                />
              </Form.Item>
            </SimpleFlex>
          </SimpleFlex>
        </Form>
      ),
    },
    {
      key: "Add Stoptimes",
      label: "Add Stoptimes",
      children: (
        <Form>
          <SimpleFlex flexdirection="column" gap="1rem">
            <SimpleFlex
              flexdirection="column"
              padding="1rem"
              width="auto"
              borderradius="1.125rem"
              border="1px solid var(--anti-flash-white)"
              gap="1rem"
            >
              <SimpleFlex flexdirection="column" gap="1rem">
                <P color="var(--nav-color)" fontsize="0.75rem">
                  Stop Time Name
                </P>
                <Form.Item
                  name="stopTimeName"
                  className="margin-0"
                  rules={[
                    {
                      required: true,
                      message: "Please input Stop Time Name!",
                    },
                  ]}
                >
                  <PrimarySelect
                    bordered={false}
                    className="user-select"
                    showSearch
                    allowClear
                    onChange={(value) => {
                      handleSelectChange(value, "stopTimeName");
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={[
                      {
                        value: "morning",
                        label: "Morning",
                      },
                      {
                        value: "evening",
                        label: "Evening",
                      },
                      {
                        value: "general",
                        label: "General",
                      },
                      {
                        value: "night",
                        label: "Night",
                      },
                    ]}
                  />
                </Form.Item>
              </SimpleFlex>
              <SimpleFlex justifycontent="space-between" gap="1rem">
                <SimpleFlex flexdirection="column" gap="1rem">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Start Time
                  </P>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        name="startHours"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Stop Time Name!",
                          },
                        ]}
                      >
                        <PrimarySelect
                          bordered={false}
                          className="user-select"
                          showSearch
                          allowClear
                          onChange={(value) => {
                            handleSelectChange(value, "startHours");
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "1",
                              label: "1 Hrs",
                            },
                            {
                              value: "2",
                              label: "2 Hrs",
                            },
                            {
                              value: "3",
                              label: "3 Hrs",
                            },
                            {
                              value: "4",
                              label: "4 Hrs",
                            },
                            {
                              value: "5",
                              label: "5 Hrs",
                            },
                            {
                              value: "6",
                              label: "6 Hrs",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="startMins"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Stop Time Name!",
                          },
                        ]}
                      >
                        <PrimarySelect
                          bordered={false}
                          className="user-select"
                          showSearch
                          allowClear
                          onChange={(value) => {
                            handleSelectChange(value, "startMins");
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "10",
                              label: "10 Mins",
                            },
                            {
                              value: "20",
                              label: "20 Mins",
                            },
                            {
                              value: "30",
                              label: "30 Mins",
                            },
                            {
                              value: "40",
                              label: "40 Mins",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="startMeridiem"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Time Period!",
                          },
                        ]}
                      >
                        <PrimarySelect
                          bordered={false}
                          className="user-select"
                          showSearch
                          allowClear
                          onChange={(value) => {
                            handleSelectChange(value, "startMeridiem");
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "am",
                              label: "AM",
                            },
                            {
                              value: "pm",
                              label: "PM",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </SimpleFlex>
                <SimpleFlex
                  flexdirection="column"
                  gap="1rem"
                  justifycontent="flex-end"
                  width="30%"
                >
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Duration
                  </P>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name="duration"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Duration!",
                          },
                        ]}
                      >
                        <SimpleInput
                          bordered={false}
                          name="totalPlannedHours"
                          allowClear
                          onChange={handleInputChange}
                          style={{ borderBottom: "1px solid black" }}
                          className="user-input"
                          padding="0"
                          color="var(--black)"
                          borderradius="0"
                          fontsize="1rem"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </SimpleFlex>
              </SimpleFlex>
            </SimpleFlex>
            <SimpleFlex
              flexdirection="column"
              padding="1rem"
              width="auto"
              borderradius="1.125rem"
              border="1px solid var(--anti-flash-white)"
              gap="1rem"
            >
              <SimpleFlex flexdirection="column" gap="1rem">
                <P color="var(--nav-color)" fontsize="0.75rem">
                  Stop Time Name
                </P>
                <Form.Item
                  name="stopTimeName"
                  className="margin-0"
                  rules={[
                    {
                      required: true,
                      message: "Please input Stop Time Name!",
                    },
                  ]}
                  initialValue={selectedRow?.stopTimeName}
                >
                  <PrimarySelect
                    bordered={false}
                    className="user-select"
                    showSearch
                    allowClear
                    onChange={(value) => {
                      handleSelectChange(value, "stopTimeName");
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={[
                      {
                        value: "morning",
                        label: "Morning",
                      },
                      {
                        value: "evening",
                        label: "Evening",
                      },
                      {
                        value: "general",
                        label: "General",
                      },
                      {
                        value: "night",
                        label: "Night",
                      },
                    ]}
                  />
                </Form.Item>
              </SimpleFlex>
              <SimpleFlex justifycontent="space-between" gap="1rem">
                <SimpleFlex flexdirection="column" gap="1rem">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Start Time
                  </P>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        name="startHours"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Stop Time Name!",
                          },
                        ]}
                        initialValue={selectedRow?.startHours}
                      >
                        <PrimarySelect
                          bordered={false}
                          className="user-select"
                          showSearch
                          allowClear
                          onChange={(value) => {
                            handleSelectChange(value, "startHours");
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "1",
                              label: "1 Hrs",
                            },
                            {
                              value: "2",
                              label: "2 Hrs",
                            },
                            {
                              value: "3",
                              label: "3 Hrs",
                            },
                            {
                              value: "4",
                              label: "4 Hrs",
                            },
                            {
                              value: "5",
                              label: "5 Hrs",
                            },
                            {
                              value: "6",
                              label: "6 Hrs",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="startMins"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Stop Time Name!",
                          },
                        ]}
                        initialValue={selectedRow?.startMins}
                      >
                        <PrimarySelect
                          bordered={false}
                          className="user-select"
                          showSearch
                          allowClear
                          onChange={(value) => {
                            handleSelectChange(value, "startMins");
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "10",
                              label: "10 Mins",
                            },
                            {
                              value: "20",
                              label: "20 Mins",
                            },
                            {
                              value: "30",
                              label: "30 Mins",
                            },
                            {
                              value: "40",
                              label: "40 Mins",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="startMeridiem"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Time Period!",
                          },
                        ]}
                        initialValue={selectedRow?.startMeridiem}
                      >
                        <PrimarySelect
                          bordered={false}
                          className="user-select"
                          showSearch
                          allowClear
                          onChange={(value) => {
                            handleSelectChange(value, "startMeridiem");
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "am",
                              label: "AM",
                            },
                            {
                              value: "pm",
                              label: "PM",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </SimpleFlex>
                <SimpleFlex
                  flexdirection="column"
                  gap="1rem"
                  justifycontent="flex-end"
                  width="30%"
                >
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Duration
                  </P>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name="duration"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Duration!",
                          },
                        ]}
                        initialValue={selectedRow?.startHours}
                      >
                        <SimpleInput
                          bordered={false}
                          name="totalPlannedHours"
                          allowClear
                          onChange={handleInputChange}
                          style={{ borderBottom: "1px solid black" }}
                          className="user-input"
                          padding="0"
                          color="var(--black)"
                          borderradius="0"
                          fontsize="1rem"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </SimpleFlex>
              </SimpleFlex>
            </SimpleFlex>
          </SimpleFlex>
        </Form>
      ),
    },
  ];

  useEffect(() => {
    const {
      stopTimeName,
      days,
      startHours,
      startMins,
      startMeridiem,
      endHours,
      endMins,
      endMeridiem,
      totalPlannedHours,
    } = selectedRow;
    const formComplete =
      stopTimeName?.trim() !== "" &&
      days?.length > 0 &&
      startHours?.trim() !== "" &&
      startMins?.trim() !== "" &&
      startMeridiem?.trim() !== "" &&
      endHours?.trim() !== "" &&
      endMins?.trim() !== "" &&
      endMeridiem?.trim() !== "" &&
      totalPlannedHours?.trim() !== "";

    setIsFormComplete(formComplete);
  }, [selectedRow]);

  const AddModalfooter = (
    <SimpleFlex margin="1.5rem 0 0 0" alignitems="center">
      <SimpleFlex gap="1rem" width="auto">
        <SimpleButton onClick={handleCancel} padding="0.5rem 1.5rem">
          Cancel
        </SimpleButton>
        <PrimaryButton
          disabled={!isFormComplete}
          loading={loading}
          onClick={handleOk}
          padding="0.5rem 2rem"
        >
          Add
        </PrimaryButton>
      </SimpleFlex>
    </SimpleFlex>
  );

  return (
    <PrimaryModal
      centered
      title="Add Shift"
      open
      onOk={handleOk}
      onCancel={handleCancel}
      footer={AddModalfooter}
      maskClosable={false}
    >
      <Tabs className="tabs custom-tab-title" items={tabItems} />
    </PrimaryModal>
  );
}
export default AddShift;
