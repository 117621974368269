import "./App.css";
import AppHeader from "./Components/AppHeader/AppHeader";
import SideMenu from "./Components/SideMenu/SideMenu";
import { AuthProvider, useAuth } from "./Components/Auth/AuthContext";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import Login from "./Pages/Login";
import LoginEmail from "./Pages/LoginEmail";
import LoginQR from "./Pages/LoginQR";
import Dashboard from "./Pages/Dashboard";
import PrivateRoute from "./Components/Auth/PrivateRoute";
import Customers from "./Pages/Customers";
import Register from "./Pages/Register";
import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import LoginSuccess from "./Components/LoginSuccess/LoginSuccess";
import Machines from "./Pages/Dashboard/Machines";
import Child from "./Pages/Dashboard/Machines/:child";
import Users from "./Pages/User";
import Products from "./Pages/Product";
import AlertsDashboard from "./Pages/Alert/Dashboard";
import NewStandardDashBoard from "./Pages/Dashboard/NewStandardDashBoard";
import Plants from "./Pages/Plant";
import Lines from "./Pages/Line";
import Roles from "./Pages/Role";
import RolePermissions from "./Pages/RolePermission";
import UserGroups from "./Pages/UserGroup";
import UserGroupDetail from "./Pages/UserGroupDetail";
import Shifts from "./Pages/Shift";
import Holidays from "./Pages/Holiday";
import Configurations from "./Pages/ConfigurationMgmt";
import Tags from "./Pages/Tag";
import GatewayConfigurations from "./Pages/GatewayConfiguration";
import CondAndRules from "./Pages/CondAndRules";
import NewCondAndRule from "./Pages/NewCondAndRule";
import Machine from "./Pages/Machine";
import AddMachine from "./Pages/Machine/AddMachine";
import UserProfile from "./Pages/UserProfile";
import StandardDashBoard from "./Pages/Dashboard/StandardDashBoard";
import StandardDashboardList from "./Pages/StandardDashboardList";
import DisplayBoard from "./Pages/Dashboard/DisplayBoard";
import NewDisplayBoard from "./Pages/Dashboard/DisplayBoard/NewDisplayBoard";
import EditDisplayBoard from "./Pages/Dashboard/DisplayBoard/EditDisplayBoard";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";

function App() {
  const location = useLocation();
  return (
    <AuthProvider>
      <AnimatePresence mode="wait">
        <Routes>
          <Route path="/" element={<Navigate to="/proton" />} />
          <Route path="login" key="login" element={<Login />} />
          <Route
            path="login/forgot-password"
            key="login"
            element={<ForgotPassword />}
          />
          <Route
            path="login/reset-password"
            key="login"
            element={<ResetPassword />}
          />
          <Route
            path="login/email-password"
            key="login/email-password"
            element={<LoginEmail />}
          />
          <Route
            path="login/qr-code"
            key="login/qr-code"
            element={<LoginQR />}
          />
          <Route path="register" element={<Register />} />
          <Route
            path="proton"
            element={<PrivateRoute element={<AuthenticatedApp />} />}
          >
            <Route index element={<Navigate to="live-dashboard" />} />
            <Route path="user-profile" element={<UserProfile />} />
            <Route path="live-dashboard" element={<Dashboard />} />
            <Route path="standard" element={<StandardDashboardList />} />
            <Route
              path="standard/machines/:machineId/changeover"
              element={<Machines />}
            />
            <Route
              path="standard/machines/:machineId/changeover/production-summary"
              element={<Child />}
            />

            <Route path="display-board" element={<DisplayBoard />} />
            <Route path="new-display-board" element={<NewDisplayBoard />} />
            <Route path="edit-display-board" element={<EditDisplayBoard />} />
            <Route path="new-dash-board" element={<NewStandardDashBoard />} />
            <Route path="new-dash-board/:id" element={<StandardDashBoard />} />

            <Route path="alerts-dashboard" element={<AlertsDashboard />} />
            <Route path="alerts-cond-and-rules" element={<CondAndRules />} />
            <Route
              path="alerts-cond-and-rules/new"
              element={<NewCondAndRule />}
            />
            <Route path="plants" element={<Plants />} />
            <Route path="lines" element={<Lines />} />
            <Route path="machines" element={<Machine />} />
            <Route path="machines/new" element={<AddMachine />} />
            <Route path="tags" element={<Tags />} />
            <Route path="custom-lists" element={<Customers />} />
            <Route path="users" element={<Users />} />
            <Route path="user-groups" element={<UserGroups />} />
            <Route path="user-groups/detail" element={<UserGroupDetail />} />
            <Route path="products" element={<Products />} />
            <Route path="roles" element={<Roles />} />
            <Route path="roles/permission" element={<RolePermissions />} />
            <Route path="shifts" element={<Shifts />} />
            <Route path="holidays" element={<Holidays />} />
            <Route path="configurations" element={<Configurations />} />
            <Route
              path="gateway-configuration"
              element={<GatewayConfigurations />}
            />
          </Route>
        </Routes>
      </AnimatePresence>
    </AuthProvider>
  );
}

function AuthenticatedApp() {
  return (
    <div className="App">
      <AppHeader />
      <LoginSuccess />
      <div className="SideMenuAndPageContent">
        <SideMenu />
        <Outlet />
      </div>
    </div>
  );
}

export default App;
