import { useEffect, useState } from "react";
import { PrimaryModal } from "../../../Components/StyledComponents/Modal";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { PrimarySelect } from "../../../Components/StyledComponents/Select";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { useDispatch } from "react-redux";
import { editConfiguration } from "../../../redux/slice/ConfigurationMgmt/Configuration";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../Components/StyledComponents/Button";
import { Form, Upload } from "antd";
import { SimpleInput } from "../../../Components/StyledComponents/SimpleInput";
import "./EditConfiguration.scss";

function EditConfiguration({ closeButton, payload }) {
  const dispatch = useDispatch();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedRow, setSelectedRow] = useState({
    type: payload?.type || "",
    label: payload?.label || "",
    value: payload?.value || "",
  });

  const handleSelectChange = (selectedValues, field) => {
    setSelectedRow((prevState) => ({
      ...prevState,
      [field]: selectedValues ?? "",
    }));
  };

  const handleOk = async () => {
    setLoading(true);

    try {
      dispatch(editConfiguration(selectedRow));
      await new Promise((resolve) => setTimeout(resolve, 2000));
      console.log("Configuration edited successfully!");
    } catch (error) {
      console.error("Error editing configuration:", error);
    } finally {
      setLoading(false);
      closeButton();
    }
  };

  const handleCancel = () => {
    closeButton();
  };

  useEffect(() => {
    const { type, label, value } = selectedRow;
    const formComplete =
      type?.trim() !== "" && label?.trim() !== "" && value?.trim() !== "";
    setIsFormComplete(formComplete);
  }, [selectedRow]);

  const uploadProps = {
    name: "file",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        console.log("file uploaded successfully");
      } else if (info.file.status === "error") {
        console.log("file uploaded failed");
      }
    },
  };

  const EditModalfooter = (
    <SimpleFlex margin="1.5rem 0 0 0" alignitems="center">
      <SimpleFlex>
        <Upload accept="image/*" maxCount={1} className="custom-upload">
          <P textdecorationline="underline" cursor="pointer">
            Upload File
          </P>
        </Upload>
      </SimpleFlex>
      <SimpleFlex gap="1rem" width="auto">
        <SimpleButton onClick={handleCancel} padding="0.5rem 1rem">
          Cancel
        </SimpleButton>
        <PrimaryButton
          disabled={!isFormComplete}
          loading={loading}
          onClick={handleOk}
          padding="0.5rem 1rem"
        >
          Save Changes
        </PrimaryButton>
      </SimpleFlex>
    </SimpleFlex>
  );

  return (
    <PrimaryModal
      centered
      title="Edit Configuration"
      open
      onOk={handleOk}
      onCancel={handleCancel}
      footer={EditModalfooter}
      maskClosable={false}
    >
      <Form>
        <SimpleFlex flexdirection="column" gap="1rem">
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Type
            </P>
            <Form.Item
              name="type"
              className="margin-0"
              rules={[
                {
                  required: true,
                  message: "Please input Type!",
                },
              ]}
              initialValue={selectedRow?.type}
            >
              <PrimarySelect
                bordered={false}
                className="user-select"
                showSearch
                allowClear
                onChange={(value) => {
                  handleSelectChange(value, "type");
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "Work Center A",
                    label: "Work Center A",
                  },
                  {
                    value: "Work Center C",
                    label: "Work Center C",
                  },
                  {
                    value: "Work Center D",
                    label: "Work Center D",
                  },
                ]}
              />
            </Form.Item>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Label
            </P>
            <Form.Item
              name="label"
              className="margin-0"
              rules={[
                {
                  required: true,
                  message: "Please input Label!",
                },
              ]}
              initialValue={selectedRow?.label}
            >
              <PrimarySelect
                bordered={false}
                className="user-select"
                showSearch
                allowClear
                onChange={(value) => {
                  handleSelectChange(value, "label");
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "Department 1",
                    label: "Department 1",
                  },
                  {
                    value: "Department 2",
                    label: "Department 2",
                  },
                  {
                    value: "Department 3",
                    label: "Department 3",
                  },
                ]}
              />
            </Form.Item>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Value
            </P>
            <Form.Item
              name="value"
              className="margin-0"
              rules={[
                {
                  required: true,
                  message: "Please input Owner!",
                },
              ]}
              initialValue={selectedRow?.value}
            >
              <PrimarySelect
                bordered={false}
                className="user-select"
                showSearch
                allowClear
                onChange={(value) => {
                  handleSelectChange(value, "value");
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "Dep 1",
                    label: "Dep 1",
                  },
                  {
                    value: "Dep 2",
                    label: "Dep 2",
                  },
                  {
                    value: "Dep 10",
                    label: "Dep 10",
                  },
                ]}
              />
            </Form.Item>
          </SimpleFlex>
        </SimpleFlex>
      </Form>
    </PrimaryModal>
  );
}
export default EditConfiguration;
