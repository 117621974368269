import { Form, Table } from "antd";
import { useEffect, useState } from "react";
import { SimpleInput } from "../../../Components/StyledComponents/SimpleInput";
import { PrimarySelect } from "../../../Components/StyledComponents/Select";
import "./RejectionCode.scss";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { PrimaryInputSearch } from "../../../Components/StyledComponents/InputSearch";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { PlusOutlined } from "@ant-design/icons";
import { P } from "../../../Components/StyledComponents/Paragraph";
import AddRejectionCode from "./AddRejectionCode";

function RejectionCode() {
  const dataSource = [
    {
      key: 1,
      rejectionCode: "Reject_color_diff",
      description: "Color differences",
      severity: "high",
    },
    {
      key: 2,
      rejectionCode: "Reject_color_diff",
      description: "Color differences",
      severity: "medium",
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [originalData, setOriginalData] = useState(dataSource);
  const [filteredData, setFilteredData] = useState(dataSource);
  const [searchValue, setSearchValue] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    if (searchValue) {
      setFilteredData(
        originalData.filter(
          (item) =>
            item.rejectionCode
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            item.description
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            item.severity.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else {
      setFilteredData(originalData);
    }
  }, [searchValue, originalData]);

  const handleInputChange = (e, key) => {
    setOriginalData((prevState) =>
      prevState.map((item) =>
        item.key === key
          ? { ...item, [e.target.name]: e.target.value ?? "" }
          : item
      )
    );
  };

  const handleSelectChange = (selectedValues, field, key) => {
    setOriginalData((prevState) =>
      prevState.map((item) =>
        item.key === key ? { ...item, [field]: selectedValues ?? "" } : item
      )
    );
  };

  const columns = [
    {
      title: "Rejection Code",
      dataIndex: "rejectionCode",
      key: "rejectionCode",
      sorter: (a, b) => a.rejectionCode.localeCompare(b.rejectionCode),
      render: (text, record) => (
        <Form>
          <Form.Item
            name="rejectionCode"
            className="margin-0"
            rules={[
              {
                required: true,
                message: "Please input Rejection Code!",
              },
            ]}
            initialValue={record?.rejectionCode}
          >
            <PrimarySelect
              bordered={false}
              className="select-min-width"
              showSearch
              allowClear
              onChange={(value) => {
                handleSelectChange(value, "rejectionCode", record?.key);
              }}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "medium",
                  label: "Medium",
                },
                {
                  value: "high",
                  label: "High",
                },
              ]}
            ></PrimarySelect>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
      render: (text, record) => (
        <Form>
          <Form.Item
            name="description"
            className="margin-0"
            rules={[
              {
                required: true,
                message: "Please input Description!",
              },
            ]}
            initialValue={record?.description}
          >
            <SimpleInput
              fontsize="0.875rem"
              name="description"
              onChange={(e) => handleInputChange(e, record?.key)}
            />
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",
      render: (text, record) => (
        <Form>
          <Form.Item
            name="severity"
            className="margin-0"
            rules={[
              {
                required: true,
                message: "Please input Severity!",
              },
            ]}
            initialValue={record?.severity}
          >
            <PrimarySelect
              bordered={false}
              className="select-min-width"
              showSearch
              allowClear
              onChange={(value) => {
                handleSelectChange(value, "severity", record?.key);
              }}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "medium",
                  label: "Medium",
                },
                {
                  value: "high",
                  label: "High",
                },
              ]}
            ></PrimarySelect>
          </Form.Item>
        </Form>
      ),
    },
  ];

  return (
    <SimpleFlex flexdirection="column" gap="1rem" padding="1.5rem" width="auto">
      <SimpleFlex
        width="auto"
        alignitems="center"
        className="transparent-search"
        justifycontent="space-between"
      >
        <SimpleFlex width="auto" maxwidth="12rem">
          <PrimaryInputSearch
            allowClear
            placeholder="Search"
            onSearch={(value) => setSearchValue(value)}
          />
        </SimpleFlex>
        <SimpleFlex gap="0.5rem" width="auto">
          <SimpleButton
            padding="0.5rem 1rem"
            onClick={() => setShowAddModal(true)}
          >
            <SimpleFlex gap="0.5rem" cursor="pointer">
              Add Rejection Code <PlusOutlined />
            </SimpleFlex>
          </SimpleButton>
          <SimpleButton
            padding="0.5rem 1rem"
            disabled={selectedRowKeys.length === 0}
          >
            Delete
          </SimpleButton>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex alignitems="center" gap="0.5rem">
        <P
          color="var(--nav-color)"
          fontsize="1.125rem"
          fontWeight="var(--font-500)"
        >
          Rejection codes :
        </P>
        <P color="var(--nav-color)" fontsize="0.75rem">
          {" "}
          View and edit machine’s Rejection codes
        </P>
      </SimpleFlex>
      <Table
        rowSelection={rowSelection}
        dataSource={filteredData}
        columns={columns}
        bordered={false}
        className="w-100 "
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
        }}
      />
      {!!showAddModal && (
        <AddRejectionCode
          closeButton={() => {
            setShowAddModal(false);
          }}
        />
      )}
    </SimpleFlex>
  );
}

export default RejectionCode;
