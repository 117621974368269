import React from "react";
import styled from "styled-components";
import { ConfigProvider, Space, Input } from "antd";

const CustomInput = styled(Input.Password)`
  border-radius: ${(props) => props.borderradius || "0.25rem"};
  border-color: ${(props) => props.borderColor || "var(--input-border-color)"};
  background-color: ${(props) => props.backgroundcolor || "transparent"};
  color: ${(props) => props.color || "var(--nav-color)"} !important;
  padding: ${(props) => props.padding || "1rem"} !important;
  font-size: ${(props) => props.fontsize || "1.125rem"} !important;
  font-weight: ${(props) => props.fontWeight || "var(--font-100)"} !important;
  letter-spacing: ${(props) => props.letterSpacing || "0rem"};
  opacity: ${(props) => props.opacity || "1"} !important;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  max-width: ${(props) => props.maxWidth || "100%"};
  max-height: ${(props) => props.maxHeight || "100%"};
`;

export const PasswordInput = (props) => {
  const { onChange } = props;

  const handleChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#21223d",
        },
      }}
    >
      <Space>
        <CustomInput {...props} onChange={handleChange}>
          {props.children}
        </CustomInput>
      </Space>
    </ConfigProvider>
  );
};
