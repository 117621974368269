import { ConfigProvider, Select } from "antd";
import styled from "styled-components";
import { ReactComponent as DownArrow } from "../../assets/svg/downArrow.svg";
import { ReactComponent as Remove } from "../../assets/svg/remove.svg";
export const CustomSelect = styled(Select)`
  .ant-select-selector {
    border-radius: ${(props) => props.borderradius || "0.25rem"};
    border-color: ${(props) =>
      props.borderColor || "var(--input-border-color)"};
    background-color: ${(props) => props.backgroundcolor || "transparent"};
    height: ${(props) => props.height || "auto"};
  }
`;

export const PrimarySelect = (props) => {
  const { onChange } = props;

  const handleChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#CBD5E1",
        },
      }}
    >
      <CustomSelect
        suffixIcon={<DownArrow />}
        removeIcon={<Remove />}
        {...props}
        onChange={handleChange}
      >
        {props.children}
      </CustomSelect>
    </ConfigProvider>
  );
};
