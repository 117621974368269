import { Menu } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Dashboard } from "../../assets/svg/Dashboard.svg";
import { ReactComponent as Alerts } from "../../assets/svg/Alerts.svg";
import { ReactComponent as GateWayConfig } from "../../assets/svg/GateWayConfig.svg";
import { ReactComponent as IndustrialMgmt } from "../../assets/svg/IndustrialMgmt.svg";
import { ReactComponent as UserMgmt } from "../../assets/svg/UserMgmt.svg";
import { SimpleFlex } from "../StyledComponents/SimpleFlex";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { PrimaryButton } from "../StyledComponents/Button";
import { useTranslation } from "react-i18next";
import "./SideMenu.css";

function SideMenu() {
  const { t } = useTranslation();

  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState("/");
  const [openKeys, setOpenKeys] = useState(["live-dashboard"]);
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const pathName = location.pathname;
    setSelectedKeys(pathName);
  }, [location.pathname]);

  const navigate = useNavigate();

  const rootSubmenuKeys = [
    "/",
    "alerts",
    "industrial-management",
    "user-management",
    "gateway-configuration",
  ];

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
    // setOpenKeys(keys);
  };

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem(t("dashboards"), "/", <Dashboard />, [
      getItem(t("liveDashboard"), "/proton/live-dashboard"),
      getItem(t("standard"), "/proton/standard"),
      getItem(t("displayBoard"), "/proton/display-board"),
    ]),
    { type: "divider" },
    getItem(t("alerts"), "alerts", <Alerts />, [
      getItem(t("dashboard"), "/proton/alerts-dashboard"),
      getItem(t("conditionAndRules"), "/proton/alerts-cond-and-rules"),
    ]),
    { type: "divider" },
    getItem(
      t("industrialManagement"),
      "industrial-management",
      <IndustrialMgmt />,
      [
        getItem(t("plants"), "/proton/plants"),
        getItem(t("products"), "/proton/products"),
        getItem(t("lines"), "/proton/lines"),
        getItem(t("machines"), "/proton/machines"),
        getItem(t("tags"), "/proton/tags"),
        getItem(t("customLists"), "/proton/custom-lists"),
      ]
    ),
    { type: "divider", colorSplit: "rgba(5, 5, 5, 0.06)" },
    getItem(t("userManagement"), "user-management", <UserMgmt />, [
      getItem(t("users"), "/proton/users"),
      getItem(t("roles"), "/proton/roles"),
      getItem(t("shifts"), "/proton/shifts"),
      getItem(t("holidays"), "/proton/holidays"),
    ]),
    { type: "divider" },
    getItem(
      t("gatewayConfiguration"),
      "/proton/gateway-configuration",
      <GateWayConfig />
    ),
  ];

  useEffect(() => {
    const matchingItem = items?.find((item) =>
      item?.children?.some((child) => child?.key === location?.pathname)
    );
    setOpenKeys([matchingItem?.key]);
  }, [location.pathname]);

  return (
    <SimpleFlex
      className="SideMenu"
      backgroundcolor="var(--primary-color)"
      flexdirection="column"
      width="auto"
      borderradius="0 0.75rem 0 0"
      position="relative"
    >
      <Menu
        inlineCollapsed={collapsed}
        className="SideMenuVertical"
        theme="dark"
        mode="inline"
        openKeys={openKeys}
        onOpenChange={(keys) => {
          onOpenChange(keys);
        }}
        onClick={(item) => {
          navigate(item.key);
        }}
        width={"26px"}
        selectedKeys={[selectedKeys]}
        items={items}
      />
      <SimpleFlex
        width="auto"
        height="auto"
        backgroundcolor="transparent"
        justifycontent="center"
        onClick={toggleCollapsed}
      >
        <PrimaryButton padding="0.6rem">
          {collapsed ? (
            <MenuUnfoldOutlined />
          ) : (
            <SimpleFlex
              backgroundcolor="transparent"
              gap="0.5rem"
              cursor="pointer"
            >
              <MenuFoldOutlined />
              {t("closeMenu")}
            </SimpleFlex>
          )}
        </PrimaryButton>
      </SimpleFlex>
    </SimpleFlex>
  );
}

export default SideMenu;
