// registrationSlice.js
import { createSlice } from "@reduxjs/toolkit";

const registrationSlice = createSlice({
  name: "registration",
  initialState: {
    isLoading: false,
    isRegistered: false,
    isError: false,
  },
  reducers: {
    registerPending: (state) => {
      state.isLoading = true;
    },
    registerSuccess: (state) => {
      state.isLoading = false;
      state.isRegistered = true;
    },
    registerInitial: (state) => {
      state.isLoading = false;
      state.isRegistered = false;
    },
    registerFailure: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
  },
});

export const {
  registerPending,
  registerSuccess,
  registerInitial,
  registerFailure,
} = registrationSlice.actions;
export default registrationSlice.reducer;
