import { Breadcrumb, Result, Table } from "antd";
import { SimpleFlex } from "../../Components/StyledComponents/SimpleFlex";
import { P } from "../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../Components/StyledComponents/SimpleButton";
import { ReactComponent as UploadFile } from "../../assets/svg/uploadFile.svg";
import { ReactComponent as Edit } from "../../assets/svg/edit.svg";
import { useEffect, useState } from "react";
import { PrimaryInputSearch } from "../../Components/StyledComponents/InputSearch";
import { fetchTags } from "../../redux/slice/Tags/tag";
import EditTag from "./EditTagModal";
import { useDispatch, useSelector } from "react-redux";
import transition from "../../Transition";
import { useTranslation } from "react-i18next";
import "./Tag.scss";

function Tags() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tags = [] } = useSelector((state) => state);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const dataSource = tags?.data || [];
  const [filteredData, setFilteredData] = useState(dataSource);

  useEffect(() => {
    setFilteredData(dataSource);
  }, [dataSource]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const breadcrumbItems = [
    { title: t("dashboard"), href: "/proton/live-dashboard" },
    { title: t("tagManagement"), href: "" },
  ];

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t("dataType"),
      dataIndex: "dataType",
      key: "dataType",
      sorter: (a, b) => a.dataType.localeCompare(b.dataType),
    },
    {
      title: t("unit"),
      dataIndex: "unit",
      key: "unit",
      sorter: (a, b) => a.unit.localeCompare(b.unit),
    },
    {
      title: t("description"),
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: t("lastReadAt"),
      dataIndex: "lastReadat",
      key: "lastReadat",
      sorter: (a, b) => a.lastReadat.localeCompare(b.lastReadat),
    },
    {
      title: t("dataSourceType"),
      dataIndex: "dataSourceType",
      key: "dataSourceType",
      sorter: (a, b) => a.dataSourceType.localeCompare(b.dataSourceType),
    },
    {
      title: t("channelSource"),
      dataIndex: "channelSource",
      key: "channelSource",
      sorter: (a, b) => a.channelSource.localeCompare(b.channelSource),
    },
    {
      title: t("action"),
      dataIndex: "",
      key: "x",
      render: (record) => (
        <SimpleFlex gap="1rem" backgroundcolor="transparent">
          <SimpleButton
            padding="0.4rem 0.8rem"
            onClick={() => {
              setSelectedRow(record);
              setShowEditModal(true);
            }}
          >
            <SimpleFlex cursor="pointer" alignitems="center" gap="0.2rem">
              <P color="var(--black)" cursor="pointer" fontsize="1rem">
                {t("edit")}
              </P>
              <P
                fontsize="0.3rem"
                color="var(--primary-color)"
                cursor="pointer"
              >
                <Edit />
              </P>
            </SimpleFlex>
          </SimpleButton>
        </SimpleFlex>
      ),
    },
  ];

  // Dispatch the action when the component mounts
  useEffect(() => {
    dispatch(fetchTags());
  }, [dispatch]);

  //search in table
  useEffect(() => {
    if (searchValue) {
      setFilteredData(
        dataSource.filter(
          (item) =>
            item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.dataType.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.unit.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.description
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            item.lastReadat.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.dataSourceType
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            item.channelSource.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else {
      setFilteredData(dataSource);
    }
  }, [searchValue]);

  if (tags?.isError) {
    return (
      <SimpleFlex justifycontent="center" backgroundcolor="transparent">
        <Result
          status="500"
          title="500"
          subTitle="Sorry, Failed to fetch plants"
        />
      </SimpleFlex>
    );
  }

  return (
    <SimpleFlex
      backgroundcolor="transparent"
      padding="1rem 2rem"
      overflow="scroll"
      flexdirection="column"
      gap="1rem"
      width="auto"
      height="auto"
    >
      <SimpleFlex
        width="auto"
        height="fit-content"
        backgroundcolor="transparent"
      >
        <Breadcrumb separator=">">
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>
              <a href={item.href}>{item.title}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        justifycontent="space-between"
      >
        <P fontsize="2.25rem" fontWeight="var(--font-600)">
          {t("tagManagement")}
        </P>
        <SimpleFlex width="auto" gap="1rem" backgroundcolor="transparent">
          <SimpleButton padding="0.75rem 1.625rem">
            <SimpleFlex cursor="pointer" alignitems="center" gap="0.5rem">
              <P
                color="var(--nav-color)"
                fontWeight="var(--font-500)"
                cursor="pointer"
              >
                {t("uploadFile")}
              </P>{" "}
              <UploadFile />
            </SimpleFlex>
          </SimpleButton>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        flexdirection="column"
        gap="1rem"
      >
        <SimpleFlex
          backgroundcolor="transparent"
          justifycontent="space-between"
        >
          <SimpleFlex
            width="auto"
            alignitems="center"
            backgroundcolor="transparent"
            className="transparent-search"
          >
            <PrimaryInputSearch
              allowClear
              placeholder={t("search")}
              onSearch={(value) => setSearchValue(value)}
            />
          </SimpleFlex>
          <SimpleFlex backgroundcolor="transparent" width="auto" gap="0.5rem">
            <SimpleButton
              padding="0.75rem 1.625rem"
              disabled={selectedRowKeys.length === 0}
            >
              {t("delete")}
            </SimpleButton>
          </SimpleFlex>
        </SimpleFlex>
        <Table
          loading={tags?.isLoading}
          rowSelection={rowSelection}
          dataSource={filteredData}
          columns={columns}
          bordered
          className="w-100 custom-table"
          pagination={{
            position: ["bottomCenter"],
            pageSize: 10,
          }}
        />
      </SimpleFlex>
      {!!showEditModal && (
        <EditTag
          payload={selectedRow}
          closeButton={() => {
            setShowEditModal(false);
          }}
        />
      )}
    </SimpleFlex>
  );
}
export default transition(Tags);
