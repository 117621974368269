import { Empty, Result, Skeleton } from "antd";
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { ReactComponent as Edit } from "../../../assets/svg/edit.svg";
import { PrimaryCard } from "../../../Components/StyledComponents/Card";
import transition from "../../../Transition";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { fetchDashboardRejectionHistory } from "../../../redux/slice/Dashboard/dashboardRejectionHistory";
import { useTranslation } from "react-i18next";

function DashboardRejectionHistory() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dashboardRejectionHistory } = useSelector((state) => state);

  const rejectionHistory =
    dashboardRejectionHistory?.data?.data?.rejectionHistory || [];
  const isLoading = dashboardRejectionHistory?.isLoading;
  const hasRejectionData =
    dashboardRejectionHistory?.data?.data?.rejectionHistory?.length > 0;
  const isError = dashboardRejectionHistory?.isError;

  useEffect(() => {
    // Dispatch the action when the component mounts
    dispatch(fetchDashboardRejectionHistory());
  }, [dispatch]);

  return (
    <div>
      <SimpleFlex
        className="dashboard-card"
        padding="1.563rem"
        width="auto"
        flexdirection="column"
        gap="1rem"
      >
        <SimpleFlex justifycontent="space-between" alignitems="center">
          <P fontsize="1.25rem" fontWeight="var(--font-500)">
            {t("rejectionHistory")}
          </P>
          <SimpleFlex width="auto" gap="1rem" alignitems="center">
            <SimpleFlex alignitems="center" gap="0.3rem">
              <P
                width="0.5rem"
                height="0.5rem"
                backgroundcolor="var(--warning-color)"
              ></P>
              <P fontsize="0.688rem" fontWeight="var(--font-500)">
                {t("critical")}
              </P>
            </SimpleFlex>
            <SimpleFlex alignitems="center" gap="0.3rem">
              <P
                width="0.5rem"
                height="0.5rem"
                backgroundcolor="var(--snow-pea-green)"
              ></P>
              <P fontsize="0.688rem" fontWeight="var(--font-500)">
                {t("normal")}
              </P>
            </SimpleFlex>
            <SimpleFlex alignitems="center" gap="0.3rem">
              <P
                width="0.5rem"
                height="0.5rem"
                backgroundcolor="var(--informative-color)"
              ></P>
              <P fontsize="0.688rem" fontWeight="var(--font-500)">
                {t("informative")}
              </P>
            </SimpleFlex>
          </SimpleFlex>
        </SimpleFlex>
        <SimpleFlex width="auto" flexdirection="column" gap="1rem">
          {isLoading && <Skeleton active loading={true} />}
          {!isLoading &&
            hasRejectionData &&
            rejectionHistory.map((rejection, index) => (
              <RejectionHistoryCard key={index} payload={rejection} />
            ))}
          {!isLoading && !hasRejectionData && !isError && (
            // Show "no data" state if there is no data
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
          {isError && (
            // Show error state if there's an error
            <Result
              status="500"
              title="500"
              subTitle="Sorry, Failed to fetch rejection history."
            />
          )}
        </SimpleFlex>
        {!isLoading && hasRejectionData && (
          <SimpleFlex flexdirection="row-reverse">
            <P
              fontWeight="var(--font-500)"
              color="var(--nav-color)"
              cursor="pointer"
            >
              {t("viewAll")}
            </P>
          </SimpleFlex>
        )}
      </SimpleFlex>
    </div>
  );
}

function RejectionHistoryCard({ payload }) {
  const { t } = useTranslation();

  let border = "";
  switch (payload.severity.toLowerCase()) {
    case "critical":
      border = "alert-shadow";
      break;
    case "normal":
      border = "ok-shadow";
      break;
    case "informative":
      border = "informative-shadow";
      break;
    default:
      break;
  }

  return (
    <PrimaryCard
      bodypadding="0 !important"
      // loading={true}
    >
      <SimpleFlex className={border} padding="0.5rem" alignitems="flex-end">
        <SimpleFlex gap="0.5rem" flexdirection="column" padding="1rem">
          <SimpleFlex gap="1rem">
            <SimpleFlex width="auto">
              <P color="var(--nav-color)">{t("productionOrder")} : &nbsp;</P>
              <P> {payload.production_order}</P>
            </SimpleFlex>
            <SimpleFlex width="auto">
              <P color="var(--nav-color)">{t("reason")} : &nbsp;</P>
              <P>{payload.reason}</P>
            </SimpleFlex>
            <SimpleFlex width="auto">
              {" "}
              <P color="var(--nav-color)">{t("rejectionCount")} : &nbsp;</P>
              <P>{payload.rejection_count}</P>
            </SimpleFlex>
          </SimpleFlex>
          <SimpleFlex gap="1rem">
            <SimpleFlex width="auto">
              <P color="var(--nav-color)">{t("reasonType")} : &nbsp;</P>
              <P>{payload.reason_type}</P>
            </SimpleFlex>
            <SimpleFlex width="auto">
              <P color="var(--nav-color)">{t("product")} : &nbsp;</P>
              <P>{payload.product}</P>
            </SimpleFlex>
          </SimpleFlex>
        </SimpleFlex>
        <SimpleButton borderradius="50%" padding="1rem" margin="0 0 1rem 0">
          <Edit />
        </SimpleButton>
      </SimpleFlex>
    </PrimaryCard>
  );
}

export default transition(DashboardRejectionHistory);
