import { SimpleFlex } from "../../../../../../Components/StyledComponents/SimpleFlex";
import { SimpleButton } from "../../../../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../../../../Components/StyledComponents/Button";
import { useEffect, useState } from "react";
import { PrimaryModal } from "../../../../../../Components/StyledComponents/Modal";
import TextArea from "antd/es/input/TextArea";
import "./AddComment.scss";

function AddComment({ showModal, closeButton }) {
  const [visible, setVisible] = useState(showModal);

  const [data, setData] = useState(null);

  useEffect(() => {
    if (showModal) {
      setData(null);
    }
    setVisible(showModal);
  }, [showModal]);

  const handleOk = () => {
    setVisible(false);
    closeButton();
  };

  const handleCancel = () => {
    setData(null);
    setVisible(false);
    closeButton();
  };

  const handleDataChange = (newData) => {
    setData(newData);
  };

  const Modalfooter = (
    <SimpleFlex gap="1rem" margin="1.5rem 0 0 0" justifycontent="end">
      <SimpleButton onClick={handleCancel} padding="0.5rem 1.5rem">
        Cancel
      </SimpleButton>
      <PrimaryButton onClick={handleOk} padding="0.5rem 1.5rem">
        Add
      </PrimaryButton>
    </SimpleFlex>
  );

  return (
    <SimpleFlex flexdirection="column" backgroundcolor="transparent">
      <PrimaryModal
        centered
        className="add-comment-modal"
        title="Add Comment"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        onClose={handleCancel}
        footer={Modalfooter}
        maskClosable={false}
      >
        <SimpleFlex flexdirection="column" gap="0.3rem">
          <TextArea
            allowClear
            autoSize={{
              minRows: 8,
              maxRows: 8,
            }}
            placeholder="Enter Comment"
          />
        </SimpleFlex>
      </PrimaryModal>
    </SimpleFlex>
  );
}

export default AddComment;
