import { useState } from "react";
import { SimpleFlex } from "../../../../Components/StyledComponents/SimpleFlex";
import { Breadcrumb } from "antd";
import DownTime from "./downtime";
import Rejections from "./rejections";
import ProductionSummary from "./productionSummary";
import "./Child.scss";

function Child() {
  const [selectedTab, setSelectedTab] = useState("tab1");

  const handleTabChange = (key) => {
    setSelectedTab(key);
  };

  const tabs = [
    { key: "tab1", label: "Production Summary" },
    { key: "downTime", label: "Downtime- Total 25m 0s" },
    { key: "tab3", label: "Rejections- Total: 10" },
    { key: "tab4", label: "Good Quantity- Total: 90" },
    { key: "tab5", label: "Production Details" },
  ];

  const renderTabContent = () => {
    switch (selectedTab) {
      case "tab1":
        return <ProductionSummary />;
      case "downTime":
        return <DownTime />;
      case "tab3":
        return <Rejections />;
      default:
        return null;
    }
  };

  const breadcrumbItems = [
    { title: "Dashboard", href: "/proton/live-dashboard" },
    { title: "Machines", href: "" },
    { title: "Application List", href: "" },
    { title: "Change Over" },
  ];

  return (
    <div
      style={{
        width: "100%",
        gap: "1rem",
        display: "flex",
        height: "100%",
        overflow: "scroll",
        padding: "0 2rem",
      }}
    >
      <SimpleFlex
        backgroundcolor="transparent"
        flexdirection="column"
        gap="0.5rem"
      >
        <SimpleFlex
          margin="1rem 0 0 0"
          backgroundcolor="transparent"
          height="auto"
        >
          <Breadcrumb separator=">">
            {breadcrumbItems.map((item, index) => (
              <Breadcrumb.Item key={index}>
                <a href={item.href}>{item.title}</a>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </SimpleFlex>
        <div>{renderTabContent()}</div>
      </SimpleFlex>
      <SimpleFlex backgroundcolor="transparent" width="auto">
        <div className="custom-tabs-container">
          <ul className="custom-tabs">
            {tabs.map((tab) => (
              <li
                key={tab.key}
                className={`custom-tab ${
                  selectedTab === tab.key ? "selected" : "unselected"
                }`}
                onClick={() => handleTabChange(tab.key)}
              >
                {tab.label}
              </li>
            ))}
          </ul>
        </div>
      </SimpleFlex>
    </div>
  );
}

export default Child;
