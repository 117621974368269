import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

// Action to fetch all plants
export const fetchGatewayConfiguration = createAsyncThunk(
  "fetchGatewayConfiguration",
  async () => {
    // const response = await fetch("https://your-api-endpoint/plants");
    // return response.json();

    // throw new Error("");
    // await new Promise((resolve) => setTimeout(resolve, 2000));
    return [
      {
        key: "1",
        gateway: "Gateway 1",
        token: "464684",
        status: "Not Configured",
      },
      {
        key: "2",
        gateway: "Gateway 2",
        token: "464684",
        status: "Active",
      },
      {
        key: "3",
        gateway: "Gateway 1",
        token: "464684",
        status: "Error",
      },
      {
        key: "4",
        gateway: "Gateway 1",
        token: "464684",
        status: "In Active",
      },
    ];
  }
);

// Action to add a new plant
export const addGatewayConfiguration = createAsyncThunk(
  "addGatewayConfiguration",
  async (newGatewayConfiguration) => {
    const response = await fetch("https://your-api-endpoint/plants", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newGatewayConfiguration),
    });
    return response.json();
  }
);

// Action to delete a plant
export const deleteGatewayConfiguration = createAsyncThunk(
  "deleteGatewayConfiguration",
  async (gatewayConfigurationId) => {
    const response = await fetch(
      `https://your-api-endpoint/plants/${gatewayConfigurationId}`,
      {
        method: "DELETE",
      }
    );
    return response.json();
  }
);

// Action to edit a plant
export const editGatewayConfiguration = createAsyncThunk(
  "editGatewayConfiguration",
  async (editGatewayConfiguration) => {
    // const response = await fetch(
    //   `https://your-api-endpoint/plants/${editedPlant.key}`,
    //   {
    //     method: "PUT",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(editGatewayConfiguration),
    //   }
    // );
    // return response.json();

    // plant is edited successfully
    const dispatch = useDispatch();
    dispatch(fetchGatewayConfiguration());
    return editGatewayConfiguration;
  }
);

const gatewayConfigurationSlice = createSlice({
  name: "gatewayConfigurations",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    selectedGatewayConfiguration: null,
  },
  reducers: {
    setSelectedGatewayConfiguration: (state, action) => {
      state.selectedPlant = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGatewayConfiguration.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchGatewayConfiguration.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchGatewayConfiguration.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(addGatewayConfiguration.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
    });
    builder.addCase(deleteGatewayConfiguration.fulfilled, (state, action) => {
      state.data = state.data.filter(
        (gatewayConfiguration) =>
          gatewayConfiguration.key !== action.payload.key
      );
    });
    builder.addCase(editGatewayConfiguration.fulfilled, (state, action) => {
      state.data = state.data.map((gatewayConfiguration) =>
        gatewayConfiguration.key === action.payload.key
          ? action.payload
          : gatewayConfiguration
      );
      state.selectedGatewayConfiguration = null; // Clear selected plant after edit
    });
  },
});

export const { setSelectedGatewayConfiguration } =
  gatewayConfigurationSlice.actions;

export default gatewayConfigurationSlice.reducer;
