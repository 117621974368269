import { ConfigProvider, Space } from "antd";
import styled from "styled-components";

export const CustomP = styled.p`
  margin: ${(props) => props.margin || "0rem"};
  line-height: ${(props) => props.lineheight || ""};
  height: ${(props) => props.height || ""};
  width: ${(props) => props.width || ""};
  position: ${(props) => props.position || ""};
  cursor: ${(props) => props.cursor || "auto"};
  padding: ${(props) => props.padding || "0rem"};
  color: ${(props) => props.color || "var(--primary-color)"};
  font-weight: ${(props) => props.fontWeight || "var(--font-400)"};
  font-size: ${(props) => props.fontsize || "0.875rem"};
  text-align: ${(props) => props.textAlign || "left"};
  background-color: ${(props) => props.backgroundcolor || "transparent"};
  text-decoration-line: ${(props) => props.textdecorationline || ""};
  opacity: ${(props) => props.opacity || "1"};
`;

export const P = (props) => {
  const { onClick } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#21223d",
        },
      }}
    >
      <Space>
        <CustomP {...props} onClick={handleClick}>
          {props.children}
        </CustomP>
      </Space>
    </ConfigProvider>
  );
};
