import { SimpleFlex } from "../../../../../Components/StyledComponents/SimpleFlex";
import { Col, Input, Row, Table } from "antd";
import { P } from "../../../../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../../../Components/StyledComponents/Button";
import { ReactComponent as Complete } from "../../../../../assets/svg/complete.svg";
import "./ProductionSummary.scss";
import { useState } from "react";
import { PrimaryInput } from "../../../../../Components/StyledComponents/Input";
import { PrimaryDivider } from "../../../../../Components/StyledComponents/Divider";

function ProductionSummary() {
  const [editableField, setEditableField] = useState(null);
  const [editedData, setEditedData] = useState([
    {
      key: "1",
      timeSlot: "Total Qty Produced",
      reported: 100,
      actual: 100,
      outBy: 0,
      actionsNeeded: "10 rejections no reasons",
    },
    {
      key: "2",
      timeSlot: "Good Quantity",
      reported: 90,
      actual: 95,
      outBy: 0,
      actionsNeeded: "",
    },
    {
      key: "3",
      timeSlot: "Total Rejections",
      reported: 10,
      actual: 5,
      outBy: 0,
      actionsNeeded: "",
    },
  ]);

  const handleFieldClick = (field, index) => {
    setEditableField({ field, index });
  };

  const handleFieldBlur = () => {
    setEditableField(null);
  };

  const handleInputChange = (value) => {
    const newData = [...editedData];
    newData[editableField.index][editableField.field] = value;
    setEditedData(newData);
  };

  const handleSave = () => {
    // Here you can handle saving the edited data
    // For simplicity, let's just log it to the console
    console.log("Edited Data:", editedData);

    // Reset the editable field state
    setEditableField(null);
  };
  const columns = [
    {
      title: "",
      dataIndex: "timeSlot",
      key: "timeSlot",
    },
    {
      title: "Reported",
      dataIndex: "reported",
      key: "reported",
    },
    {
      title: "Actual",
      dataIndex: "actual",
      key: "actual",
    },
    {
      title: "Out By",
      dataIndex: "outBy",
      key: "outBy",
    },
    {
      title: "Actions Needed",
      dataIndex: "actionsNeeded",
      key: "actionsNeeded",
    },
  ];

  const headers = [
    { title: "" },
    { title: "Reported" },
    { title: "Actual" },
    { title: "Out By" },
    { title: "Actions Needed" },
  ];
  return (
    <SimpleFlex flexdirection="column" backgroundcolor="transparent" gap="1rem">
      <SimpleFlex backgroundcolor="transparent" justifycontent="space-between">
        <P fontsize="2.25rem" fontWeight="var(--font-600)">
          Production Summary
        </P>
        <SimpleFlex gap="1rem" backgroundcolor="transparent" width="auto">
          <SimpleButton padding="0.75rem 1.625rem" fontWeight="var(--font-500)">
            Save and finish later
          </SimpleButton>
          <PrimaryButton
            padding="0.75rem 1.625rem"
            fontWeight="var(--font-500)"
          >
            <SimpleFlex
              backgroundcolor="transparent"
              cursor="pointer"
              alignitems="center"
              gap="0.5rem"
            >
              Complete <Complete />
            </SimpleFlex>
          </PrimaryButton>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex
        backgroundcolor="transparent"
        flexdirection="column"
        gap="1rem"
      >
        <P fontsize="1.75rem" fontWeight="var(--font-600)">
          Coil Machine 1
        </P>
        <SimpleFlex gap="2rem" backgroundcolor="transparent" width="auto">
          <SimpleFlex backgroundcolor="transparent" width="auto">
            <P
              fontWeight="var(--font-500)"
              color="var(--nav-color)"
              fontsize="0.688rem"
            >
              Product :{" "}
            </P>
            <P fontWeight="var(--font-500)" fontsize="0.75rem">
              &nbsp;Switch Board{" "}
            </P>
          </SimpleFlex>
          <SimpleFlex backgroundcolor="transparent" width="auto">
            <P
              fontWeight="var(--font-500)"
              color="var(--nav-color)"
              fontsize="0.688rem"
            >
              PO :{" "}
            </P>
            <P fontWeight="var(--font-500)" fontsize="0.75rem">
              &nbsp;R-1234{" "}
            </P>
          </SimpleFlex>
          <SimpleFlex backgroundcolor="transparent" width="auto">
            <P
              fontWeight="var(--font-500)"
              color="var(--nav-color)"
              fontsize="0.688rem"
            >
              Shift :{" "}
            </P>
            <P fontWeight="var(--font-500)" fontsize="0.75rem">
              &nbsp;1st Shift{" "}
            </P>
          </SimpleFlex>
          <SimpleFlex backgroundcolor="transparent" width="auto">
            <P
              fontWeight="var(--font-500)"
              color="var(--nav-color)"
              fontsize="0.688rem"
            >
              Date :{" "}
            </P>
            <P fontWeight="var(--font-500)" fontsize="0.75rem">
              &nbsp;04/28/2023{" "}
            </P>
          </SimpleFlex>
          <SimpleFlex backgroundcolor="transparent" width="auto">
            <P
              fontWeight="var(--font-500)"
              color="var(--nav-color)"
              fontsize="0.688rem"
            >
              Unknown Down Time :
            </P>
            <P fontWeight="var(--font-500)" fontsize="0.75rem">
              &nbsp;20 Mins{" "}
            </P>
          </SimpleFlex>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex borderradius="1.125rem" flexdirection="column">
        <P margin="1rem 1.5rem" fontWeight="var(--font-500)">
          3 in blank : 3 Round Blank
        </P>
        <PrimaryDivider margin="0" />
        <SimpleFlex
          borderradius="0 0 1.25rem 1.125rem"
          flexdirection="column"
          height="auto"
        >
          <Row gutter={16}>
            {headers.map((header, index) => (
              <Col key={index} span={4}>
                <P
                  color="var(--nav-color)"
                  fontWeight="var(--font-500)"
                  margin="1rem 0"
                >
                  {header.title}
                </P>
              </Col>
            ))}
          </Row>
          {editedData.map((row, index) => (
            <Row
              key={row.key}
              gutter={16}
              className="custom-prod-summary-table-row"
            >
              <Col span={4} className="text-align-right">
                <P color="var(--nav-color)" margin="1rem 0">
                  {row.timeSlot}
                </P>
              </Col>
              <Col span={4}>
                {editableField?.field === "reported" &&
                editableField.index === index ? (
                  <PrimaryInput
                    padding="0.5rem"
                    width="5rem"
                    defaultValue={row.reported}
                    onBlur={handleFieldBlur}
                    autoFocus
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === "Escape") {
                        handleFieldBlur();
                      }
                    }}
                    onChange={(e) => handleInputChange(e.target.value)}
                  />
                ) : (
                  <P
                    fontsize="1rem"
                    cursor="pointer"
                    onClick={() => handleFieldClick("reported", index)}
                  >
                    {row.reported}
                  </P>
                )}
              </Col>
              <Col span={4}>
                {editableField?.field === "actual" &&
                editableField.index === index ? (
                  <PrimaryInput
                    padding="0.5rem"
                    width="5rem"
                    defaultValue={row.actual}
                    onBlur={handleFieldBlur}
                    autoFocus
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === "Escape") {
                        handleFieldBlur();
                      }
                    }}
                    onChange={(e) => handleInputChange(e.target.value)}
                  />
                ) : (
                  <P
                    fontsize="1rem"
                    cursor="pointer"
                    onClick={() => handleFieldClick("actual", index)}
                  >
                    {row.actual}
                  </P>
                )}
              </Col>
              <Col span={4}>
                <Col span={4}>
                  {editableField?.field === "outBy" &&
                  editableField.index === index ? (
                    <PrimaryInput
                      padding="0.5rem"
                      width="5rem"
                      defaultValue={row.outBy}
                      onBlur={handleFieldBlur}
                      autoFocus
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === "Escape") {
                          handleFieldBlur();
                        }
                      }}
                      onChange={(e) => handleInputChange(e.target.value)}
                    />
                  ) : (
                    <P
                      fontsize="1rem"
                      cursor="pointer"
                      onClick={() => handleFieldClick("outBy", index)}
                    >
                      {row.outBy}
                    </P>
                  )}
                </Col>
              </Col>
              <Col span={8}>
                {row.actionsNeeded && (
                  <SimpleFlex gap="0.3rem">
                    <P fontsize="1rem">{row.actionsNeeded} -</P>
                    <P
                      textdecorationline="underline"
                      fontsize="1rem"
                      cursor="pointer"
                    >
                      Report
                    </P>
                  </SimpleFlex>
                )}
              </Col>
              {index !== editedData.length - 1 && (
                <PrimaryDivider margin="0.5rem 0" />
              )}
            </Row>
          ))}
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex
        backgroundcolor="transparent"
        width="auto"
        gap="1rem"
        justifycontent="end"
      >
        <SimpleButton padding="0.938rem 2.125rem">Reset</SimpleButton>
        <PrimaryButton padding="0.938rem 2.125rem">Save</PrimaryButton>
      </SimpleFlex>
    </SimpleFlex>
  );
}

export default ProductionSummary;
