import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

// Action to fetch all plants
export const fetchHolidays = createAsyncThunk("fetchHolidays", async () => {
  // const response = await fetch("https://your-api-endpoint/plants");
  // return response.json();

  // throw new Error("");
  // await new Promise((resolve) => setTimeout(resolve, 2000));
  return [
    {
      key: "1",
      date: "22/02/2023",
      holidayName: "Foundation Day",
    },
    {
      key: "2",
      date: "21/04/2023",
      holidayName: "Eid al-Fitr",
    },
    {
      key: "3",
      date: "27/06/2023",
      holidayName: "Eid -al- Adha",
    },
  ];
});

// Action to add a new plant
export const addHoliday = createAsyncThunk("addHoliday", async (newHoliday) => {
  const response = await fetch("https://your-api-endpoint/plants", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newHoliday),
  });
  return response.json();
});

// Action to delete a plant
export const deleteHoliday = createAsyncThunk(
  "deleteHoliday",
  async (holidayId) => {
    const response = await fetch(
      `https://your-api-endpoint/holidays/${holidayId}`,
      {
        method: "DELETE",
      }
    );
    return response.json();
  }
);

export const editHoliday = createAsyncThunk(
  "editHoliday",
  async (editHoliday) => {
    // const response = await fetch(
    //   `https://your-api-endpoint/plants/${editedPlant.key}`,
    //   {
    //     method: "PUT",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(editHoliday),
    //   }
    // );
    // return response.json();

    // plant is edited successfully
    const dispatch = useDispatch();
    dispatch(fetchHolidays());
    return editHoliday;
  }
);

const holidaySlice = createSlice({
  name: "holidays",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHolidays.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHolidays.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchHolidays.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(addHoliday.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
    });
    builder.addCase(deleteHoliday.fulfilled, (state, action) => {
      state.data = state.data.filter(
        (holiday) => holiday.key !== action.payload.key
      );
    });
    builder.addCase(editHoliday.fulfilled, (state, action) => {
      state.data = state.data.map((holiday) =>
        holiday.key === action.payload.key ? action.payload : holiday
      );
    });
  },
});

export default holidaySlice.reducer;
