import { Drawer, Form, DatePicker } from "antd";
import { SimpleFlex } from "../../../../Components/StyledComponents/SimpleFlex";
import { P } from "../../../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../../../Components/StyledComponents/SimpleButton";
import { useState } from "react";
import { PrimarySelect } from "../../../../Components/StyledComponents/Select";
import { PrimaryButton } from "../../../../Components/StyledComponents/Button";
import "./Filter.scss";

function Filter({ onClose, isOpen }) {
  const [data, setData] = useState({
    machines: ["Coil Machine 1"],
    devices: ["sensorM1645H", "M16SL45H"],
    type: "System",
    severity: "Informative",
    duration: "2m",
    dateFrom: null,
    dateTo: null,
    shift: ["Current Shift", "Night Shift"],
  });

  const handleSelectChange = (selectedValues, field) => {
    setData((prevState) => ({
      ...prevState,
      [field]: selectedValues,
    }));
  };

  const handleDateChange = (value, dateType) => {
    if (dateType === "dateFrom") {
      setData((prevData) => ({ ...prevData, dateFrom: value }));
      if (data?.dateTo && value > data.dateTo) {
        setData((prevData) => ({ ...prevData, dateTo: value }));
      }
    } else if (dateType === "dateTo") {
      setData((prevData) => ({ ...prevData, dateTo: value }));
    }
  };

  const disabledDate = (current) => {
    // Disable all dates before dateFrom
    return current && current < data?.dateFrom;
  };

  return (
    <Drawer
      title="Filter"
      placement="right"
      onClose={onClose}
      open={isOpen}
      mask={false}
      width={480}
    >
      <Form>
        <SimpleFlex
          justifycontent="center"
          height="auto"
          flexdirection="column"
          gap="1rem"
        >
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Machine(s)
            </P>
            <Form.Item
              name="machines"
              className="margin-0"
              rules={[
                {
                  required: true,
                  message: "Please input Machine!",
                },
              ]}
              initialValue={data?.machines}
            >
              <PrimarySelect
                bordered={false}
                className="user-select"
                mode="multiple"
                showSearch
                allowClear
                onChange={(value) => handleSelectChange(value, "machines")}
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "Abdul Nizam",
                    label: "Abdul Nizam",
                  },
                  {
                    value: "Billal Ahmed",
                    label: "Billal Ahmed",
                  },
                  {
                    value: "Mohammed Waris",
                    label: "Mohammed Waris",
                  },
                  {
                    value: "Mohammed Rizwan",
                    label: "Mohammed Rizwan",
                  },
                  {
                    value: "Babar Azam",
                    label: "Babar Azam",
                  },
                ]}
              />
            </Form.Item>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              device(s)
            </P>
            <Form.Item
              name="devices"
              className="margin-0"
              rules={[
                {
                  required: true,
                  message: "Please input Device!",
                },
              ]}
              initialValue={data?.devices}
            >
              <PrimarySelect
                bordered={false}
                className="user-select"
                mode="multiple"
                showSearch
                allowClear
                onChange={(value) => handleSelectChange(value, "devices")}
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "Abdul Nizam",
                    label: "Abdul Nizam",
                  },
                  {
                    value: "Billal Ahmed",
                    label: "Billal Ahmed",
                  },
                  {
                    value: "Mohammed Waris",
                    label: "Mohammed Waris",
                  },
                  {
                    value: "Mohammed Rizwan",
                    label: "Mohammed Rizwan",
                  },
                  {
                    value: "Babar Azam",
                    label: "Babar Azam",
                  },
                ]}
              />
            </Form.Item>
          </SimpleFlex>
          <SimpleFlex gap="0.8rem" alignitems="center">
            <SimpleFlex flexdirection="column" gap="0.5rem" width="33%">
              <P color="var(--nav-color)" fontsize="0.75rem">
                Type
              </P>
              <Form.Item
                name="type"
                className="margin-0"
                rules={[
                  {
                    required: true,
                    message: "Please input Type!",
                  },
                ]}
                initialValue={data?.type}
              >
                <PrimarySelect
                  bordered={false}
                  className="user-select"
                  showSearch
                  allowClear
                  onChange={(value) => handleSelectChange(value, "type")}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "Abdul Nizam",
                      label: "Abdul Nizam",
                    },
                    {
                      value: "Billal Ahmed",
                      label: "Billal Ahmed",
                    },
                    {
                      value: "Mohammed Waris",
                      label: "Mohammed Waris",
                    },
                    {
                      value: "Mohammed Rizwan",
                      label: "Mohammed Rizwan",
                    },
                    {
                      value: "Babar Azam",
                      label: "Babar Azam",
                    },
                  ]}
                />
              </Form.Item>
            </SimpleFlex>
            <SimpleFlex flexdirection="column" gap="0.5rem" width="33%">
              <P color="var(--nav-color)" fontsize="0.75rem">
                Severity
              </P>
              <Form.Item
                name="severity"
                className="margin-0"
                rules={[
                  {
                    required: true,
                    message: "Please input Severity!",
                  },
                ]}
                initialValue={data?.severity}
              >
                <PrimarySelect
                  bordered={false}
                  className="user-select"
                  showSearch
                  allowClear
                  onChange={(value) => handleSelectChange(value, "severity")}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "Abdul Nizam",
                      label: "Abdul Nizam",
                    },
                    {
                      value: "Billal Ahmed",
                      label: "Billal Ahmed",
                    },
                    {
                      value: "Mohammed Waris",
                      label: "Mohammed Waris",
                    },
                    {
                      value: "Mohammed Rizwan",
                      label: "Mohammed Rizwan",
                    },
                    {
                      value: "Babar Azam",
                      label: "Babar Azam",
                    },
                  ]}
                />
              </Form.Item>
            </SimpleFlex>
            <SimpleFlex flexdirection="column" gap="0.5rem" width="33%">
              <P color="var(--nav-color)" fontsize="0.75rem">
                Duration
              </P>
              <Form.Item
                name="duration"
                className="margin-0"
                rules={[
                  {
                    required: true,
                    message: "Please input Duration!",
                  },
                ]}
                initialValue={data?.duration}
              >
                <PrimarySelect
                  bordered={false}
                  className="user-select"
                  showSearch
                  allowClear
                  onChange={(value) => handleSelectChange(value, "duration")}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "Abdul Nizam",
                      label: "Abdul Nizam",
                    },
                    {
                      value: "Billal Ahmed",
                      label: "Billal Ahmed",
                    },
                    {
                      value: "Mohammed Waris",
                      label: "Mohammed Waris",
                    },
                    {
                      value: "Mohammed Rizwan",
                      label: "Mohammed Rizwan",
                    },
                    {
                      value: "Babar Azam",
                      label: "Babar Azam",
                    },
                  ]}
                />
              </Form.Item>
            </SimpleFlex>
          </SimpleFlex>
          <SimpleFlex gap="1rem">
            <SimpleFlex flexdirection="column" gap="0.3rem" width="auto">
              <P fontsize="0.75rem" color="var(--nav-color)">
                Date From
              </P>
              <DatePicker
                value={data?.dateFrom}
                onChange={(value) => handleDateChange(value, "dateFrom")}
                format="YYYY-MM-DD"
              />
            </SimpleFlex>
            <SimpleFlex flexdirection="column" gap="0.3rem" width="auto">
              <P fontsize="0.75rem" color="var(--nav-color)">
                Date To
              </P>
              <DatePicker
                value={data?.dateTo}
                onChange={(value) => handleDateChange(value, "dateTo")}
                format="YYYY-MM-DD"
                disabledDate={disabledDate}
              />
            </SimpleFlex>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              shift
            </P>
            <Form.Item
              name="shift"
              className="margin-0"
              rules={[
                {
                  required: true,
                  message: "Please input Shift!",
                },
              ]}
              initialValue={data?.shift}
            >
              <PrimarySelect
                bordered={false}
                className="user-select"
                mode="multiple"
                showSearch
                allowClear
                onChange={(value) => handleSelectChange(value, "shift")}
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "Abdul Nizam",
                    label: "Abdul Nizam",
                  },
                  {
                    value: "Billal Ahmed",
                    label: "Billal Ahmed",
                  },
                  {
                    value: "Mohammed Waris",
                    label: "Mohammed Waris",
                  },
                  {
                    value: "Mohammed Rizwan",
                    label: "Mohammed Rizwan",
                  },
                  {
                    value: "Babar Azam",
                    label: "Babar Azam",
                  },
                ]}
              />
            </Form.Item>
          </SimpleFlex>
          <SimpleFlex justifycontent="flex-end" gap="1rem">
            <SimpleButton padding="0.5rem 1.5rem">Reset</SimpleButton>
            <PrimaryButton padding="0.5rem 1.5rem">Filter</PrimaryButton>
          </SimpleFlex>
        </SimpleFlex>
      </Form>
    </Drawer>
  );
}

export default Filter;
