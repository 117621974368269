import { Avatar, Breadcrumb, Col, Divider, Form, Row } from "antd";
import { PrimaryFlex } from "../../Components/StyledComponents/Flex";
import { P } from "../../Components/StyledComponents/Paragraph";
import { SimpleFlex } from "../../Components/StyledComponents/SimpleFlex";
import { SettingOutlined, UserOutlined } from "@ant-design/icons";
import { ReactComponent as RightArrow } from "../../assets/svg/rightArrow.svg";
import { ReactComponent as RightArrowDark } from "../../assets/svg/rightArrowDark.svg";
import { useState } from "react";
import "./UserProfile.scss";
import { PasswordInput } from "../../Components/StyledComponents/PasswordInput";
import { PrimaryButton } from "../../Components/StyledComponents/Button";

function UserProfile() {
  const [selectedItem, setSelectedItem] = useState("Personal Information");
  const breadcrumbItems = [
    { title: "Dashboard", href: "/proton/live-dashboard" },
    { title: "User Profile", href: "" },
  ];

  const [userDetails, setUserDetails] = useState({
    fullName: "Andrew Mathew",
    displayName: "Andrew",
    email: "andrew@proton.com",
    phoneNo: null,
    oldPassword: null,
    newPassword: null,
    confirmPassword: null,
  });

  const profileOptions = [
    { icon: <UserOutlined />, title: "Personal Information" },
    { icon: <SettingOutlined />, title: "Security Settings" },
  ];

  const isSaveDisabled = !(
    !!userDetails?.oldPassword?.length &&
    !!userDetails?.newPassword?.length &&
    !!userDetails?.confirmPassword?.length
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserDetails((prevState) => {
      const newState = {
        ...prevState,
        [name]: value,
      };

      return newState;
    });
  };

  return (
    <SimpleFlex
      backgroundcolor="transparent"
      padding="1rem 2rem"
      overflow="scroll"
      height="auto"
      flexdirection="column"
      gap="1rem"
    >
      <SimpleFlex
        width="auto"
        height="fit-content"
        backgroundcolor="transparent"
      >
        <Breadcrumb separator=">">
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>
              <a href={item.href}>{item.title}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        justifycontent="space-between"
      >
        <P fontsize="2.25rem" fontWeight="var(--font-600)">
          User Profile
        </P>
      </SimpleFlex>
      {/* <SimpleFlex gap="1rem" backgroundcolor="transparent" height="auto"> */}
      <Row className="w-100" gutter={16}>
        <Col span={8}>
          <SimpleFlex
            height="auto"
            borderradius="0.375rem"
            padding="2rem"
            flexdirection="column"
            width="auto"
          >
            <PrimaryFlex alignitems="center" cursor="pointer" className="gap-6">
              <Avatar
                className="border-columbia-gray"
                size={{
                  xs: 30,
                  sm: 30,
                  md: 30,
                  lg: 35,
                  xl: 40,
                  xxl: 50,
                }}
                src={
                  <img
                    src={
                      "https://fastly.picsum.photos/id/40/4106/2806.jpg?hmac=MY3ra98ut044LaWPEKwZowgydHZ_rZZUuOHrc3mL5mI"
                    }
                    alt="avatar"
                  />
                }
              />

              <div className="d-flex flex-direction-col">
                <span className="font-weight-700" style={{ marginLeft: 8 }}>
                  Jhon Methew
                </span>
                <span className="font-weight-300" style={{ marginLeft: 8 }}>
                  Machine Operator
                </span>
              </div>
            </PrimaryFlex>
            <Divider />
            <SimpleFlex flexdirection="column" height="auto" gap="0.5rem">
              {profileOptions.map((item) => {
                return (
                  <SimpleFlex
                    width="auto"
                    justifycontent="space-between"
                    alignitems="center"
                    onClick={() => {
                      setSelectedItem(item.title);
                    }}
                    className={
                      selectedItem === item.title
                        ? "item-selected"
                        : "item-unselected"
                    }
                  >
                    <SimpleFlex
                      alignitems="center"
                      gap="0.5rem"
                      cursor="pointer"
                      backgroundcolor="transparent"
                    >
                      {item.icon}
                      <P fontsize="1rem" cursor="pointer">
                        {item.title}
                      </P>
                    </SimpleFlex>
                    <RightArrowDark cursor="pointer" height="0.6rem" />
                  </SimpleFlex>
                );
              })}
            </SimpleFlex>
          </SimpleFlex>
        </Col>
        <Col span={16}>
          {selectedItem === "Personal Information" && (
            <SimpleFlex
              height="auto"
              padding="2rem"
              flexdirection="column"
              borderradius="0.375rem"
              gap="1rem"
              width="auto"
            >
              <SimpleFlex flexdirection="column" gap="0.2rem" height="auto">
                <P
                  fontsize="1rem"
                  color="var(--nav-color)"
                  fontWeight="var(--font-600)"
                >
                  Personal Information
                </P>
                <P color="var(--nav-color)">
                  Basic info, like your name and address, that you use on Proton
                </P>
              </SimpleFlex>
              <SimpleFlex height="auto" flexdirection="column" gap="1.5rem">
                <P
                  padding="0.8rem 1rem"
                  color="var(--nav-color)"
                  backgroundcolor="var(--disabled-color)"
                  width="fit-content"
                >
                  Basic Info
                </P>
                <Row gutter={16} className="w-100">
                  <Col span={11}>
                    <P color="var(--nav-color)">Full Name</P>
                  </Col>
                  <Col span={12}>
                    <P>Andrew Mathew</P>
                  </Col>
                  <Col span={1}>
                    <RightArrowDark cursor="pointer" height="0.6rem" />
                  </Col>
                </Row>
                <Divider className="margin-0" />
                <Row gutter={16} className="w-100">
                  <Col span={11}>
                    <P color="var(--nav-color)">Display Name</P>
                  </Col>
                  <Col span={12}>
                    <P>Andrew</P>
                  </Col>
                  <Col span={1}>
                    <RightArrowDark cursor="pointer" height="0.6rem" />
                  </Col>
                </Row>
                <Divider className="margin-0" />
                <Row gutter={16} className="w-100">
                  <Col span={11}>
                    <P color="var(--nav-color)">Email</P>
                  </Col>
                  <Col span={12}>
                    <P>andrew@proton.com</P>
                  </Col>
                  <Col span={1}>
                    <RightArrowDark cursor="pointer" height="0.6rem" />
                  </Col>
                </Row>
                <Divider className="margin-0" />
                <Row gutter={16} className="w-100">
                  <Col span={11}>
                    <P color="var(--nav-color)">Phone no.</P>
                  </Col>
                  <Col span={12}>
                    <P>Not added yet</P>
                  </Col>
                  <Col span={1}>
                    <RightArrowDark cursor="pointer" height="0.6rem" />
                  </Col>
                </Row>
                <Divider className="margin-0" />
              </SimpleFlex>
              <SimpleFlex height="auto" flexdirection="column" gap="1.5rem">
                <P
                  padding="0.8rem 1rem"
                  color="var(--nav-color)"
                  backgroundcolor="var(--disabled-color)"
                  width="fit-content"
                >
                  Preferences
                </P>
                <Row gutter={16} className="w-100">
                  <Col span={11}>
                    <P color="var(--nav-color)">Full Name</P>
                  </Col>
                  <Col span={11}>
                    <P>Andrew Mathew</P>
                  </Col>
                  <Col span={2}>
                    <P>Change</P>
                  </Col>
                </Row>
                <Divider className="margin-0" />
                <Row gutter={16} className="w-100">
                  <Col span={11}>
                    <P color="var(--nav-color)">Display Name</P>
                  </Col>
                  <Col span={11}>
                    <P>Andrew</P>
                  </Col>
                  <Col span={2}>
                    <P>Change</P>
                  </Col>
                </Row>
              </SimpleFlex>
            </SimpleFlex>
          )}
          {selectedItem === "Security Settings" && (
            <Form>
              <SimpleFlex
                height="auto"
                padding="2rem"
                flexdirection="column"
                borderradius="0.375rem"
                gap="1rem"
                width="auto"
              >
                <SimpleFlex flexdirection="column" gap="0.2rem" height="auto">
                  <P
                    fontsize="1rem"
                    color="var(--nav-color)"
                    fontWeight="var(--font-600)"
                  >
                    Security Settings
                  </P>
                </SimpleFlex>

                <SimpleFlex
                  height="auto"
                  flexdirection="column"
                  gap="1.5rem"
                  margin="1rem 0 0 0"
                >
                  <Row gutter={16} className="w-100">
                    <Col span={12}>
                      <SimpleFlex>
                        <P color="var(--nav-color)">Old Password</P>
                      </SimpleFlex>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="oldPassword"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Password!",
                          },
                        ]}
                        className="margin-0"
                        initialValue={userDetails?.oldPassword}
                      >
                        <PasswordInput
                          placeholder="Old Password"
                          padding="0.5rem"
                          fontsize="0.875rem"
                          name="oldPassword"
                          onChange={handleInputChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider className="margin-0" />
                  <Row gutter={16} className="w-100">
                    <Col span={12}>
                      <SimpleFlex>
                        <P color="var(--nav-color)">New Password</P>
                      </SimpleFlex>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="newPassword"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Password!",
                          },
                        ]}
                        className="margin-0"
                        initialValue={userDetails?.newPassword}
                      >
                        <PasswordInput
                          placeholder="New Password"
                          padding="0.5rem"
                          fontsize="0.875rem"
                          name="newPassword"
                          onChange={handleInputChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider className="margin-0" />
                  <Row gutter={16} className="w-100">
                    <Col span={12}>
                      <SimpleFlex>
                        <P color="var(--nav-color)">Confirm Password</P>
                      </SimpleFlex>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="confirmPassword"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Password!",
                          },
                        ]}
                        className="margin-0"
                        initialValue={userDetails?.confirmPassword}
                      >
                        <PasswordInput
                          placeholder="Confirm Password"
                          padding="0.5rem"
                          fontsize="0.875rem"
                          name="confirmPassword"
                          onChange={handleInputChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </SimpleFlex>
                <SimpleFlex justifycontent="flex-end">
                  <PrimaryButton
                    padding="0.8rem 2rem"
                    disabled={isSaveDisabled}
                  >
                    Save
                  </PrimaryButton>
                </SimpleFlex>
              </SimpleFlex>
            </Form>
          )}
        </Col>
      </Row>
      {/* </SimpleFlex> */}
    </SimpleFlex>
  );
}

export default UserProfile;
