import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

// Action to fetch all plants
export const fetchShifts = createAsyncThunk("fetchShifts", async (payload) => {
  //get data by payload?.category, payload?.day

  // const response = await fetch("https://your-api-endpoint/plants");
  // return response.json();

  // throw new Error("");
  // await new Promise((resolve) => setTimeout(resolve, 2000));
  return [
    {
      key: "1",
      shiftName: "Morning",
      startTime: "7:00 AM",
      endTime: "4:00 PM",
      plannedStop: [
        {
          time: "1:00 PM - 2:00 PM",
          description: "Lunch Break",
        },
        {
          time: "3:00 PM - 3:15 PM",
          description: "Other Break",
        },
      ],
      totalPlannedHours: "09 Hrs",
    },
    {
      key: "2",
      shiftName: "Evening",
      startTime: "5:00 PM",
      endTime: "2:00 AM",
      plannedStop: [
        {
          time: "1:00 PM - 2:00 PM",
          description: "Dinner Break",
        },
      ],
      totalPlannedHours: "09 Hrs",
    },
    {
      key: "3",
      shiftName: "General",
      startTime: "9:00 AM",
      endTime: "4:00 PM",
      plannedStop: [
        {
          time: "1:00 PM - 2:00 PM",
          description: "Lunch Break",
        },
        {
          time: "3:00 PM - 3:15 PM",
          description: "Other Break",
        },
      ],
      totalPlannedHours: "09 Hrs",
    },
    {
      key: "4",
      shiftName: "Night",
      startTime: "2:00 AM",
      endTime: "4:00 PM",
      plannedStop: [
        {
          time: "1:00 PM - 2:00 PM",
          description: "Break",
        },
      ],
      totalPlannedHours: "09 Hrs",
    },
  ];
});

// Action to add a new plant
export const addShift = createAsyncThunk("addShift", async (newShift) => {
  const response = await fetch("https://your-api-endpoint/plants", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newShift),
  });
  return response.json();
});

// Action to delete a plant
export const deleteShift = createAsyncThunk("deleteShift", async (plantId) => {
  const response = await fetch(`https://your-api-endpoint/plants/${plantId}`, {
    method: "DELETE",
  });
  return response.json();
});

// Action to edit a plant
export const editShift = createAsyncThunk("editShift", async (editShift) => {
  // const response = await fetch(
  //   `https://your-api-endpoint/plants/${editedPlant.key}`,
  //   {
  //     method: "PUT",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(editShift),
  //   }
  // );
  // return response.json();

  // plant is edited successfully
  const dispatch = useDispatch();
  dispatch(fetchShifts());
  return editShift;
});

const shiftSlice = createSlice({
  name: "shifts",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchShifts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchShifts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchShifts.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(addShift.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
    });
    builder.addCase(deleteShift.fulfilled, (state, action) => {
      state.data = state.data.filter(
        (shift) => shift.key !== action.payload.key
      );
    });
    builder.addCase(editShift.fulfilled, (state, action) => {
      state.data = state.data.map((shift) =>
        shift.key === action.payload.key ? action.payload : shift
      );
      state.selectedPlant = null; // Clear selected plant after edit
    });
  },
});

export default shiftSlice.reducer;
