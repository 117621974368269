import { Breadcrumb, Form, Table } from "antd";
import { SimpleFlex } from "../../../../Components/StyledComponents/SimpleFlex";
import { P } from "../../../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../../Components/StyledComponents/Button";
import { ReactComponent as ThreeDots } from "../../../../assets/svg/threeDots.svg";
import { ReactComponent as PieChart } from "../../../../assets/svg/pieChart.svg";
import { ReactComponent as BarChart } from "../../../../assets/svg/barChart.svg";
import { ReactComponent as Tile } from "../../../../assets/svg/tile.svg";
import { ReactComponent as TableIcon } from "../../../../assets/svg/table.svg";
import { ReactComponent as Timeline } from "../../../../assets/svg/timeLine.svg";
import { ReactComponent as CellStatus } from "../../../../assets/svg/cellStatus.svg";
import { ReactComponent as CheckCircleFilled } from "../../../../assets/svg/checkCircleFilled.svg";
import { ReactComponent as Edit } from "../../../../assets/svg/edit.svg";
import { ReactComponent as DeleteOutlined } from "../../../../assets/svg/deleteOutlined.svg";
import { PrimaryTooltip } from "../../../../Components/StyledComponents/Tooltip";
import {
  InfoCircleOutlined,
  MenuOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { SimpleInput } from "../../../../Components/StyledComponents/SimpleInput";
import { PrimarySelect } from "../../../../Components/StyledComponents/Select";
import React, { useState } from "react";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import AddMetricCard from "../AddMetricCard";
import EditMetricCard from "../EditMetricCard";
import transition from "../../../../Transition";
import "./NewDisplayBoard.scss";

function NewDisplayBoard() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [displayboardData, setDisplayboardData] = useState({
    name: "",
    factories: [],
    factoryLocations: [],
    depts: [],
    lines: [],
    machines: [],
    intervalTime: "",
    displayOption: "",
  });

  const handleSelectChange = (selectedValues, field) => {
    setDisplayboardData((prevState) => ({
      ...prevState,
      [field]: selectedValues ?? "",
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDisplayboardData((prevState) => {
      const newState = {
        ...prevState,
        [name]: value,
      };

      return newState;
    });
  };

  const columns = [
    {
      title: "Fields",
      dataIndex: "fields",
      sorter: (a, b) => a.fields.localeCompare(b.fields),
    },
    {
      title: "Label",
      dataIndex: "label",
      sorter: (a, b) => a?.label?.localeCompare(b?.label),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "6rem",
      render: (text) => (
        <SimpleFlex gap="0.5rem" backgroundcolor="transparent" width="auto">
          <SimpleButton
            padding="0.8rem"
            borderradius="50%"
            onClick={() => {
              setShowEditModal(true);
            }}
          >
            <Edit />
          </SimpleButton>
          <SimpleButton padding="0.8rem" borderradius="50%">
            <DeleteOutlined />
          </SimpleButton>
        </SimpleFlex>
      ),
    },
    {
      title: "Set Order",
      key: "sort",
      align: "center",
      width: "6rem",
    },
  ];
  const Row = ({ children, ...props }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1,
        }
      ),
      transition,
      ...(isDragging
        ? {
            position: "relative",
            zIndex: 9999,
          }
        : {}),
    };
    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if (child.key === "sort") {
            return React.cloneElement(child, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{
                    touchAction: "none",
                    cursor: "move",
                  }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  const [dataSource, setDataSource] = useState([
    {
      key: "1",
      fields: "Current Production OEE",
      label: "OEE",
      action:
        "Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long ",
    },
    {
      key: "2",
      fields: "Current Production Shift Duration",
      label: "Shift Duration",
      action: "London No. 1 Lake Park",
    },
    {
      key: "3",
      fields: "Current Production Actual Products/Minute",
      label: "Performance",
      action: "Sidney No. 1 Lake Park",
    },
  ]);
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  const breadcrumbItems = [
    { title: "Dashboard", href: "/proton/live-dashboard" },
    { title: "Create Display Board", href: "" },
  ];

  const isSaveDisabled =
    !!displayboardData?.name.length &&
    !!displayboardData?.factories.length &&
    !!displayboardData?.factoryLocations.length &&
    !!displayboardData?.depts.length &&
    !!displayboardData?.lines.length &&
    !!displayboardData?.machines.length &&
    !!displayboardData?.intervalTime.length &&
    !!displayboardData?.displayOption.length;

  const FACTORY_OPTIONS = ["Coil Machine 1", "P10", "P002"];
  const FACTORY_LOCATION_OPTIONS = ["Factory 1", "Factory 2", "Factory 3"];
  const DEPT_OPTIONS = ["Wiring", "Packaging", "Production Unit"];
  const LINE_OPTIONS = ["Line 1", "Line 2", "Line 3"];
  const MACHINE_OPTIONS = ["Coil Machine 1", "P10", "P002", "P003"];

  const [selectedOption, setSelectedOption] = useState(
    displayboardData?.displayOption
  );

  return (
    <SimpleFlex
      height="auto"
      width="auto"
      backgroundcolor="transparent"
      padding="1rem 2rem"
      overflow="scroll"
      flexdirection="column"
      gap="1rem"
    >
      {/* <QualityCard />
      <DisplayCard />
      <DisplayCardVariant />
      <LinkCard /> */}
      <SimpleFlex
        width="auto"
        height="fit-content"
        backgroundcolor="transparent"
      >
        <Breadcrumb separator=">">
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>
              <a href={item.href}>{item.title}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        alignitems="center"
        justifycontent="space-between"
      >
        <P fontsize="2.25rem" fontWeight="var(--font-600)">
          Create Display Board
        </P>
        <SimpleFlex
          backgroundcolor="transparent"
          alignitems="center"
          width="auto"
          gap="1.5rem"
        >
          <ThreeDots />
          <SimpleButton padding="0.75rem 1.625rem">Share</SimpleButton>
          <PrimaryButton padding="0.75rem 1.625rem">New Board</PrimaryButton>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex
        flexdirection="column"
        borderradius="1.125rem"
        height="auto"
        padding="1rem"
        width="auto"
      >
        <Form>
          <SimpleFlex flexdirection="column" gap="1.5rem">
            <SimpleFlex flexdirection="column" gap="0.5rem">
              <SimpleFlex justifycontent="space-between">
                <P fontsize="0.75rem" color="var(--nav-color)">
                  Display Board Name
                </P>
                <SimpleFlex width="auto" gap="0.3rem">
                  <PrimaryTooltip title={"Unique"} placement="top">
                    <P color="var(--nav-color)" cursor="pointer">
                      <InfoCircleOutlined />
                    </P>
                  </PrimaryTooltip>
                  <P fontsize="0.75rem" color="var(--nav-color)">
                    This will be unique
                  </P>
                </SimpleFlex>
              </SimpleFlex>
              <SimpleFlex>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input Name!",
                    },
                  ]}
                  initialValue={displayboardData?.name}
                  className="w-100 margin-0"
                >
                  <SimpleInput
                    name="name"
                    className="input-border-bottom"
                    bordered={false}
                    onChange={(e) => handleInputChange(e)}
                    borderradius="0"
                    padding="0.3rem 0"
                    fontWeight="var(--font-500)"
                    fontsize="1.688rem"
                  ></SimpleInput>
                </Form.Item>
              </SimpleFlex>
            </SimpleFlex>
            <SimpleFlex flexdirection="column" gap="0.5rem">
              <SimpleFlex justifycontent="space-between">
                <P fontsize="0.75rem" color="var(--nav-color)">
                  Select factories
                </P>
                <SimpleFlex width="auto" gap="0.3rem">
                  <PrimaryTooltip title={"Unique"} placement="top">
                    <P color="var(--nav-color)" cursor="pointer">
                      <InfoCircleOutlined />
                    </P>
                  </PrimaryTooltip>
                  <P fontsize="0.75rem" color="var(--nav-color)">
                    Where goods are being produced
                  </P>
                </SimpleFlex>
              </SimpleFlex>
              <SimpleFlex>
                <Form.Item
                  name="factories"
                  rules={[
                    {
                      required: true,
                      message: "Please input Factory!",
                    },
                  ]}
                  initialValue={displayboardData?.factories}
                  className="w-100 margin-0"
                >
                  <PrimarySelect
                    className="multi-select"
                    mode="multiple"
                    bordered={false}
                    allowClear={true}
                    showSearch
                    placeholder="Search to Select"
                    onChange={(value) => handleSelectChange(value, "factories")}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={FACTORY_OPTIONS.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                  />
                </Form.Item>
              </SimpleFlex>
            </SimpleFlex>
            <SimpleFlex flexdirection="column" gap="0.5rem">
              <SimpleFlex justifycontent="space-between">
                <P fontsize="0.75rem" color="var(--nav-color)">
                  Select Factory Location
                </P>
                <SimpleFlex width="auto" gap="0.3rem">
                  <PrimaryTooltip title={"Unique"} placement="top">
                    <P color="var(--nav-color)" cursor="pointer">
                      <InfoCircleOutlined />
                    </P>
                  </PrimaryTooltip>
                  <P fontsize="0.75rem" color="var(--nav-color)">
                    Where the factory is located
                  </P>
                </SimpleFlex>
              </SimpleFlex>
              <SimpleFlex>
                <Form.Item
                  name="factoryLocations"
                  rules={[
                    {
                      required: true,
                      message: "Please input Name!",
                    },
                  ]}
                  initialValue={displayboardData?.factoryLocations}
                  className="w-100 margin-0"
                >
                  <PrimarySelect
                    className="multi-select"
                    mode="multiple"
                    bordered={false}
                    allowClear={true}
                    showSearch
                    placeholder="Search to Factory Location"
                    onChange={(value) =>
                      handleSelectChange(value, "factoryLocations")
                    }
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={FACTORY_LOCATION_OPTIONS.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                  />
                </Form.Item>
              </SimpleFlex>
            </SimpleFlex>
            <SimpleFlex flexdirection="column" gap="0.5rem">
              <SimpleFlex justifycontent="space-between">
                <P fontsize="0.75rem" color="var(--nav-color)">
                  Select Departments
                </P>
                <SimpleFlex width="auto" gap="0.3rem">
                  <PrimaryTooltip title={"Unique"} placement="top">
                    <P color="var(--nav-color)" cursor="pointer">
                      <InfoCircleOutlined />
                    </P>
                  </PrimaryTooltip>
                  <P fontsize="0.75rem" color="var(--nav-color)">
                    Specialized section within the organization
                  </P>
                </SimpleFlex>
              </SimpleFlex>
              <SimpleFlex>
                <Form.Item
                  name="depts"
                  rules={[
                    {
                      required: true,
                      message: "Please input Department!",
                    },
                  ]}
                  initialValue={displayboardData?.depts}
                  className="w-100 margin-0"
                >
                  <PrimarySelect
                    className="multi-select"
                    mode="multiple"
                    bordered={false}
                    allowClear={true}
                    showSearch
                    placeholder="Search to Select"
                    onChange={(value) => handleSelectChange(value, "depts")}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={DEPT_OPTIONS.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                  />
                </Form.Item>
              </SimpleFlex>
            </SimpleFlex>
            <SimpleFlex flexdirection="column" gap="0.5rem">
              <SimpleFlex justifycontent="space-between">
                <P fontsize="0.75rem" color="var(--nav-color)">
                  Select Line
                </P>
                <SimpleFlex width="auto" gap="0.3rem">
                  <PrimaryTooltip title={"Unique"} placement="top">
                    <P color="var(--nav-color)" cursor="pointer">
                      <InfoCircleOutlined />
                    </P>
                  </PrimaryTooltip>
                  <P fontsize="0.75rem" color="var(--nav-color)">
                    Specific assembly or production process involving a series
                    of workstations or stages
                  </P>
                </SimpleFlex>
              </SimpleFlex>
              <SimpleFlex>
                <Form.Item
                  name="lines"
                  rules={[
                    {
                      required: true,
                      message: "Please input Line!",
                    },
                  ]}
                  initialValue={displayboardData?.lines}
                  className="w-100 margin-0"
                >
                  <PrimarySelect
                    className="multi-select"
                    mode="multiple"
                    bordered={false}
                    allowClear={true}
                    showSearch
                    placeholder="Search to Select"
                    onChange={(value) => handleSelectChange(value, "lines")}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={LINE_OPTIONS.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                  />
                </Form.Item>
              </SimpleFlex>
            </SimpleFlex>
            <SimpleFlex flexdirection="column" gap="0.5rem">
              <SimpleFlex justifycontent="space-between">
                <P fontsize="0.75rem" color="var(--nav-color)">
                  Select Machines
                </P>
                <SimpleFlex width="auto" gap="0.3rem">
                  <PrimaryTooltip title={"Unique"} placement="top">
                    <P color="var(--nav-color)" cursor="pointer">
                      <InfoCircleOutlined />
                    </P>
                  </PrimaryTooltip>
                  <P fontsize="0.75rem" color="var(--nav-color)">
                    Specific assembly or production process involving a series
                    of workstations or stages
                  </P>
                </SimpleFlex>
              </SimpleFlex>
              <SimpleFlex>
                <Form.Item
                  name="machines"
                  rules={[
                    {
                      required: true,
                      message: "Please input Machine!",
                    },
                  ]}
                  initialValue={displayboardData?.machines}
                  className="w-100 margin-0"
                >
                  <PrimarySelect
                    className="multi-select"
                    mode="multiple"
                    bordered={false}
                    allowClear={true}
                    showSearch
                    placeholder="Search to Select"
                    onChange={(value) => handleSelectChange(value, "machines")}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={MACHINE_OPTIONS.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                  />
                </Form.Item>
              </SimpleFlex>
            </SimpleFlex>
            <SimpleFlex flexdirection="column" gap="0.5rem">
              <P fontsize="0.75rem" color="var(--nav-color)">
                Refresh Interval Time
              </P>
              <SimpleFlex>
                <Form.Item
                  name="intervalTime"
                  rules={[
                    {
                      required: true,
                      message: "Please input Interval Time!",
                    },
                  ]}
                  initialValue={displayboardData?.intervalTime}
                  className="w-100 margin-0"
                >
                  <PrimarySelect
                    className="ant-select-min-width select"
                    bordered={false}
                    showSearch
                    allowClear
                    onChange={(value) =>
                      handleSelectChange(value, "intervalTime")
                    }
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={[
                      {
                        value: "informative",
                        label: "informative",
                      },
                      {
                        value: "critical",
                        label: "critical",
                      },
                      {
                        value: "normal",
                        label: "normal",
                      },
                    ]}
                  ></PrimarySelect>
                </Form.Item>
              </SimpleFlex>
            </SimpleFlex>
            <SimpleFlex flexdirection="column" gap="0.5rem">
              <SimpleFlex justifycontent="space-between">
                <P fontsize="0.75rem" color="var(--nav-color)">
                  Select Display Options
                </P>
                <SimpleFlex width="auto" gap="0.3rem">
                  <PrimaryTooltip title={"Unique"} placement="top">
                    <P color="var(--nav-color)" cursor="pointer">
                      <InfoCircleOutlined />
                    </P>
                  </PrimaryTooltip>
                  <P fontsize="0.75rem" color="var(--nav-color)">
                    This is how your data will appear on the board
                  </P>
                </SimpleFlex>
              </SimpleFlex>
              <SimpleFlex>
                <Form.Item
                  name="displayOption"
                  rules={[
                    {
                      required: true,
                      message: "Please input Display Option!",
                    },
                  ]}
                  initialValue={displayboardData?.displayOption}
                  className="w-100 margin-0"
                >
                  <PrimarySelect
                    className="multi-select"
                    bordered={false}
                    showSearch
                    allowClear
                    onChange={(value) => {
                      handleSelectChange(value, "displayOption");
                      setSelectedOption(value);
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input)
                    }
                    options={[
                      {
                        value: "pie",
                        label: "Pie Chart",
                      },
                      {
                        value: "bar",
                        label: "Bar Chart",
                      },
                      {
                        value: "tile",
                        label: "Tile",
                      },
                      {
                        value: "table",
                        label: "Table",
                      },
                      {
                        value: "timeline",
                        label: "Timeline",
                      },
                      {
                        value: "cellStatus",
                        label: "Cell Status",
                      },
                    ]}
                  ></PrimarySelect>
                </Form.Item>
              </SimpleFlex>
            </SimpleFlex>
            <SimpleFlex
              height="auto"
              justifycontent="space-between"
              gap="0.5rem"
            >
              <SimpleFlex
                className={
                  selectedOption === "pie" ? "selected-display-border" : ""
                }
                flexdirection="column"
                gap="0.5rem"
                backgroundcolor="var(--disabled-color)"
                width="auto"
                padding="1rem 1.5rem"
                borderradius="0.375rem"
                height="auto"
                alignitems="center"
                maxwidth="8rem"
                position="relative"
              >
                <PieChart />
                <P textAlign="center" color="var(--nav-color)">
                  Pie or Donut Chart
                </P>
                {selectedOption === "pie" && (
                  <div className="tick-mark-position">
                    <CheckCircleFilled />
                  </div>
                )}
              </SimpleFlex>
              <SimpleFlex
                className={
                  selectedOption === "bar" ? "selected-display-border" : ""
                }
                flexdirection="column"
                gap="0.5rem"
                backgroundcolor="var(--disabled-color)"
                width="auto"
                padding="1rem 1.5rem"
                borderradius="0.375rem"
                height="auto"
                alignitems="center"
                maxwidth="8rem"
                position="relative"
              >
                <BarChart />
                <P textAlign="center" color="var(--nav-color)">
                  Line/Bar Area Chart
                </P>
                {selectedOption === "bar" && (
                  <div className="tick-mark-position">
                    <CheckCircleFilled />
                  </div>
                )}
              </SimpleFlex>
              <SimpleFlex
                className={
                  selectedOption === "tile" ? "selected-display-border" : ""
                }
                flexdirection="column"
                gap="0.5rem"
                backgroundcolor="var(--disabled-color)"
                width="auto"
                padding="1rem 1.5rem"
                borderradius="0.375rem"
                height="auto"
                alignitems="center"
                maxwidth="8rem"
                position="relative"
              >
                <Tile />
                <P textAlign="center" color="var(--nav-color)">
                  Tile
                </P>
                {selectedOption === "tile" && (
                  <div className="tick-mark-position">
                    <CheckCircleFilled />
                  </div>
                )}
              </SimpleFlex>
              <SimpleFlex
                className={
                  selectedOption === "table" ? "selected-display-border" : ""
                }
                flexdirection="column"
                gap="0.5rem"
                backgroundcolor="var(--disabled-color)"
                width="auto"
                padding="1rem 1.5rem"
                borderradius="0.375rem"
                height="auto"
                alignitems="center"
                maxwidth="8rem"
                position="relative"
              >
                <TableIcon />
                <P textAlign="center" color="var(--nav-color)">
                  Table
                </P>
                {selectedOption === "table" && (
                  <div className="tick-mark-position">
                    <CheckCircleFilled />
                  </div>
                )}
              </SimpleFlex>
              <SimpleFlex
                className={
                  selectedOption === "timeline" ? "selected-display-border" : ""
                }
                flexdirection="column"
                gap="0.5rem"
                backgroundcolor="var(--disabled-color)"
                width="auto"
                padding="1rem 1.5rem"
                borderradius="0.375rem"
                height="auto"
                alignitems="center"
                maxwidth="8rem"
                position="relative"
              >
                <Timeline />
                <P textAlign="center" color="var(--nav-color)">
                  Timeline
                </P>
                {selectedOption === "timeline" && (
                  <div className="tick-mark-position">
                    <CheckCircleFilled />
                  </div>
                )}
              </SimpleFlex>

              <SimpleFlex
                className={
                  selectedOption === "cellStatus"
                    ? "selected-display-border"
                    : ""
                }
                flexdirection="column"
                gap="0.5rem"
                backgroundcolor="var(--disabled-color)"
                width="auto"
                padding="1rem 1.5rem"
                borderradius="0.375rem"
                height="auto"
                alignitems="center"
                maxwidth="8rem"
                justifycontent="space-around"
                position="relative"
              >
                <CellStatus />
                <P textAlign="center" color="var(--nav-color)">
                  Cell Status
                </P>
                {selectedOption === "cellStatus" && (
                  <div className="tick-mark-position">
                    <CheckCircleFilled />
                  </div>
                )}
              </SimpleFlex>
            </SimpleFlex>
            <SimpleFlex justifycontent="flex-end">
              <PrimaryButton
                padding="0.75rem 1.625rem"
                disabled={!isSaveDisabled}
              >
                Save Board
              </PrimaryButton>
            </SimpleFlex>
          </SimpleFlex>
        </Form>
      </SimpleFlex>
      <SimpleFlex
        flexdirection="column"
        backgroundcolor="transparent"
        gap="1rem"
      >
        <SimpleFlex
          backgroundcolor="transparent"
          justifycontent="space-between"
        >
          <P fontWeight="var(--font-600)" fontsize="1.438rem">
            Fields
          </P>
          <SimpleButton
            padding="0.75rem 1.625rem"
            onClick={() => {
              setShowAddModal(true);
            }}
          >
            <SimpleFlex gap="0.3rem" alignitems="center" cursor="pointer">
              Add Field{" "}
              <P color="var(--nav-color)" fontsize="0.875rem" cursor="pointer">
                <PlusOutlined />
              </P>
            </SimpleFlex>
          </SimpleButton>
        </SimpleFlex>
        <SimpleFlex backgroundcolor="transparent">
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={onDragEnd}
          >
            <SortableContext
              // rowKey array
              items={dataSource.map((i) => i.key)}
              strategy={verticalListSortingStrategy}
            >
              <Table
                bordered
                components={{
                  body: {
                    row: Row,
                  },
                }}
                rowKey="key"
                columns={columns}
                dataSource={dataSource}
                className="custom-table"
                pagination={false}
              />
            </SortableContext>
          </DndContext>
        </SimpleFlex>
      </SimpleFlex>
      <AddMetricCard
        showModal={showAddModal}
        closeButton={() => setShowAddModal(false)}
      />
      <EditMetricCard
        showModal={showEditModal}
        closeButton={() => setShowEditModal(false)}
      />
    </SimpleFlex>
  );
}

export default transition(NewDisplayBoard);
