import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

// Action to fetch all plants
export const fetchMachines = createAsyncThunk("fetchMachines", async () => {
  // const response = await fetch("https://your-api-endpoint/plants");
  // return response.json();

  // throw new Error("");
  // await new Promise((resolve) => setTimeout(resolve, 2000));
  return [
    {
      key: "1",
      machine: "Tank Assembly #1",
      shifts: "coilP10",
      shortName: "morning",
      workCenter: "A",
      productionLine: "Line 3",
      section: "Building Wire",
      department: "Production",
      pinLocation: "Location",
    },
    {
      key: "2",
      machine: "Tank Assembly #1",
      shifts: "coilP10",
      shortName: "morning",
      workCenter: "A",
      productionLine: "Line 3",
      section: "Building Wire",
      department: "prodc",
      pinLocation: "Location",
    },
    {
      key: "3",
      machine: "Tank Assembly #1",
      shifts: "coilP10",
      shortName: "morning",
      workCenter: "A",
      productionLine: "Line 3",
      section: "Building Wire",
      department: "Production",
      pinLocation: "Location",
    },
  ];
});

// Action to add a new plant
export const addMachine = createAsyncThunk("addMachine", async (newMachine) => {
  const response = await fetch("https://your-api-endpoint/machines", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newMachine),
  });
  return response.json();
});

// Action to delete a plant
export const deleteMachine = createAsyncThunk(
  "deleteMachine",
  async (machineId) => {
    const response = await fetch(
      `https://your-api-endpoint/machines/${machineId}`,
      {
        method: "DELETE",
      }
    );
    return response.json();
  }
);

// Action to edit a plant
export const editMachine = createAsyncThunk(
  "editMachine",
  async (editMachine) => {
    // const response = await fetch(
    //   `https://your-api-endpoint/plants/${editedPlant.key}`,
    //   {
    //     method: "PUT",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(editMachine),
    //   }
    // );
    // return response.json();

    // plant is edited successfully
    const dispatch = useDispatch();
    dispatch(fetchMachines());
    return editMachine;
  }
);

const machineSlice = createSlice({
  name: "machines",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    selectedMachine: null,
  },
  reducers: {
    setSelectedMachine: (state, action) => {
      state.selectedMachine = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMachines.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMachines.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchMachines.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(addMachine.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
    });
    builder.addCase(deleteMachine.fulfilled, (state, action) => {
      state.data = state.data.filter(
        (machine) => machine.key !== action.payload.key
      );
    });
    builder.addCase(editMachine.fulfilled, (state, action) => {
      state.data = state.data.map((machine) =>
        machine.key === action.payload.key ? action.payload : machine
      );
      state.selectedMachine = null; // Clear selected plant after edit
    });
  },
});

export const { setSelectedMachine } = machineSlice.actions;

export default machineSlice.reducer;
