import React from "react";
import styled from "styled-components";
import { ConfigProvider, Switch } from "antd";

const CustomSwitch = styled(Switch)``;

export const SimpleSwitch = (props) => {
  const { onChange } = props;

  const handleChange = (checked) => {
    if (onChange) {
      onChange(checked);
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#63658e",
        },
      }}
    >
      <CustomSwitch {...props} onChange={handleChange}>
        {props.children}
      </CustomSwitch>
    </ConfigProvider>
  );
};
