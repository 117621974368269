import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Action
export const fetchSystemAlert = createAsyncThunk(
  "dashboard/fetchSystemAlert",
  async () => {
    // Replace the URL with your actual endpoint to fetch dashboard data
    // const response = await fetch("https://api.example.com/dashboard");
    // return response.json();

    // await new Promise((resolve) => setTimeout(resolve, 2000));

    return {
      data: {
        dashboard: [
          {
            title: "Low Voltage",
            triggered_due_to: "Low input of raw material",
            category: "Downtime",
            status: "Open",
            duration: "2m",
            created_at: "20 Oct 2023, 07:42 PM",
            severity: "critical",
          },
          {
            title: "High Temperature",
            triggered_due_to: "Low electrical voltage",
            category: "Downtime",
            status: "Open",
            duration: "2m",
            created_at: "20 Oct 2023, 07:42 PM",
            severity: "critical",
          },
          {
            title: "Low Voltage",
            triggered_due_to: "Employee break",
            category: "Downtime",
            status: "Open",
            duration: "2m",
            created_at: "20 Oct 2023, 07:42 PM",
            severity: "normal",
          },
          {
            title: "Order complete",
            triggered_due_to: "Order change over",
            category: "Downtime",
            status: "Open",
            duration: "2m",
            created_at: "20 Oct 2023, 07:42 PM",
            severity: "informative",
          },
          {
            title: "Low input",
            triggered_due_to: "PO-575456467",
            category: "Downtime",
            status: "Open",
            duration: "2m",
            created_at: "20 Oct 2023, 07:42 PM",
            severity: "normal",
          },
        ],
      },
    };
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSystemAlert.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(fetchSystemAlert.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchSystemAlert.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      console.error("Error fetching dashboard data:", action.error.message);
    });
  },
});

export default dashboardSlice.reducer;
