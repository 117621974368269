import React, { useState, useEffect } from "react";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { PrimaryModal } from "../../../Components/StyledComponents/Modal";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../Components/StyledComponents/Button";

const StopwatchCard = ({ onTimerToggle }) => {
  const [startTime, setStartTime] = useState(
    localStorage.getItem("startTime") || null
  );
  const [elapsedTime, setElapsedTime] = useState(0);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const showConfirmModal = () => {
    setConfirmModalVisible(true);
  };

  const handleOk = () => {
    setConfirmModalVisible(false);
    toggleTimer();
  };

  const handleCancel = () => {
    setConfirmModalVisible(false);
  };

  const Modalfooter = (
    <SimpleFlex gap="1rem" margin="1.5rem 0 0 0" justifycontent="end">
      <SimpleButton onClick={handleCancel} padding="0.5rem 1.5rem">
        Cancel
      </SimpleButton>
      <PrimaryButton onClick={handleOk} padding="0.5rem 1.5rem">
        OK
      </PrimaryButton>
    </SimpleFlex>
  );

  const toggleTimer = () => {
    if (startTime) {
      // If timer is running, stop and reset it
      setStartTime(null);
      localStorage.removeItem("startTime");
      setElapsedTime(0);
    } else {
      // If timer is stopped, start it
      const now = new Date().getTime();
      setStartTime(now);
      localStorage.setItem("startTime", now);
    }
    onTimerToggle(!startTime);
  };

  useEffect(() => {
    if (startTime) {
      const intervalId = setInterval(() => {
        const now = new Date().getTime();
        const elapsed = now - startTime;
        setElapsedTime(elapsed);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [startTime]);

  const formatTime = (time) => {
    const hours = Math.floor(time / (60 * 60 * 1000));
    const minutes = Math.floor((time % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((time % (60 * 1000)) / 1000);

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  return (
    <>
      <SimpleFlex onClick={showConfirmModal} cursor="pointer">
        <P
          cursor="pointer"
          color="var(--warning-color)"
          fontWeight="var(--font-600)"
        >
          {formatTime(elapsedTime)}
        </P>
      </SimpleFlex>
      <PrimaryModal
        title="Confirm Action"
        open={confirmModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={Modalfooter}
      >
        <p>
          {startTime
            ? "Are you sure you want to stop and reset the timer?"
            : "Are you sure you want to start the timer?"}
        </p>
      </PrimaryModal>
    </>
  );
};

export default StopwatchCard;
