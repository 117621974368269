import axios from "axios";
// import { STONLINE_LOGIN_URL } from "../constants/helper";
const axios_util = axios.create({
  baseURL: "https://proton.saasant.com/mock-service/",
});

const forceLogin = () => {
  window.location.href = "";
};

axios_util.defaults.xsrfHeaderName = "X-CSRFTOKEN";

axios_util.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios_util.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error

    if (error.response && error.response.status === 409) {
      forceLogin();
    }
    if (
      error.response &&
      error.response.data &&
      error.response.data.error === "QBO_AUTH_ERROR"
    ) {
      return Promise.reject("QBO_AUTH_ERROR");
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.error === "AUTH_ERROR"
    ) {
      return Promise.reject("QBO_AUTH_ERROR");
    } else if (error.response && error.response.status === 500) {
      return Promise.resolve({
        response: { error: "Unable to process the request" },
      });
    }
    return Promise.reject(error);
  }
);

export default axios_util;
