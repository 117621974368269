import { Breadcrumb, Result, Table } from "antd";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../Components/StyledComponents/Button";
import { ReactComponent as Plus } from "../../../assets/svg/plus.svg";
import { ReactComponent as Edit } from "../../../assets/svg/edit.svg";
import { ReactComponent as Eye } from "../../../assets/svg/eye.svg";
import { useEffect, useState } from "react";
import { PrimaryInputSearch } from "../../../Components/StyledComponents/InputSearch";
import {
  fetchDisplayboards,
  setSelectedDisplayboard,
} from "../../../redux/slice/Displayboards/displayboardList";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./DisplayBoard.scss";

function DisplayBoard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { displayBoardList = [] } = useSelector((state) => state);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const dataSource = displayBoardList?.data || [];
  const [filteredData, setFilteredData] = useState(dataSource);

  useEffect(() => {
    setFilteredData(dataSource);
  }, [dataSource]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const breadcrumbItems = [
    { title: t("dashboard"), href: "/proton/live-dashboard" },
    { title: t("displayBoardManagement"), href: "" },
  ];

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("factory"),
      dataIndex: "factories",
      key: "factories",
      render: (record) => {
        if (record.length > 2) {
          return (
            <SimpleFlex backgroundcolor="transparent">
              {record.slice(0, 2).join(", ") + ", ..."}
            </SimpleFlex>
          );
        } else {
          return (
            <SimpleFlex backgroundcolor="transparent">
              {record.join(", ")}
            </SimpleFlex>
          );
        }
      },
    },
    {
      title: t("factoryLocation"),
      dataIndex: "factoryLocations",
      key: "factoryLocations",
      render: (record) => {
        if (record.length > 2) {
          return (
            <SimpleFlex backgroundcolor="transparent">
              {record.slice(0, 2).join(", ") + ", ..."}
            </SimpleFlex>
          );
        } else {
          return (
            <SimpleFlex backgroundcolor="transparent">
              {record.join(", ")}
            </SimpleFlex>
          );
        }
      },
    },
    {
      title: t("department"),
      dataIndex: "depts",
      key: "depts",
      render: (record) => {
        if (record.length > 2) {
          return (
            <SimpleFlex backgroundcolor="transparent">
              {record.slice(0, 2).join(", ") + ", ..."}
            </SimpleFlex>
          );
        } else {
          return (
            <SimpleFlex backgroundcolor="transparent">
              {record.join(", ")}
            </SimpleFlex>
          );
        }
      },
    },
    {
      title: t("line"),
      dataIndex: "lines",
      key: "lines",
      render: (record) => {
        if (record.length > 2) {
          return (
            <SimpleFlex backgroundcolor="transparent">
              {record.slice(0, 2).join(", ") + ", ..."}
            </SimpleFlex>
          );
        } else {
          return (
            <SimpleFlex backgroundcolor="transparent">
              {record.join(", ")}
            </SimpleFlex>
          );
        }
      },
    },
    {
      title: t("machine"),
      dataIndex: "machines",
      key: "machines",
      render: (record) => {
        if (record.length > 2) {
          return (
            <SimpleFlex backgroundcolor="transparent">
              {record.slice(0, 2).join(", ") + ", ..."}
            </SimpleFlex>
          );
        } else {
          return (
            <SimpleFlex backgroundcolor="transparent">
              {record.join(", ")}
            </SimpleFlex>
          );
        }
      },
    },
    {
      title: t("interval"),
      dataIndex: "intervalTime",
      key: "intervalTime",
    },
    {
      title: t("displayOption"),
      dataIndex: "displayOption",
      key: "displayOption",
    },
    {
      title: t("action"),
      dataIndex: "",
      key: "x",
      width: "5rem",
      render: (record) => (
        <SimpleFlex gap="1rem" backgroundcolor="transparent">
          <SimpleButton
            padding="0.4rem 0.8rem"
            onClick={() => {
              setSelectedRow(record);
              setSelectedDisplayboard(record);
              navigate("/proton/edit-display-board", { state: record });
            }}
            gap="0.5rem"
          >
            {t("edit")}
            <P fontsize="0.3rem" color="var(--primary-color)" cursor="pointer">
              <Edit />
            </P>
          </SimpleButton>
        </SimpleFlex>
      ),
    },
  ];

  // Dispatch the action when the component mounts
  useEffect(() => {
    dispatch(fetchDisplayboards());
  }, [dispatch]);

  //search in table
  useEffect(() => {
    if (searchValue) {
      const searchLower = searchValue.toLowerCase().trim();
      setFilteredData(
        dataSource.filter(
          (item) =>
            item.name.toLowerCase().includes(searchLower.toLowerCase()) ||
            item.displayOption
              .toLowerCase()
              .includes(searchLower.toLowerCase()) ||
            item.intervalTime
              .toLowerCase()
              .includes(searchLower.toLowerCase()) ||
            item.factories.some((fc) =>
              fc.toLowerCase().includes(searchLower)
            ) ||
            item.factoryLocations.some((fcl) =>
              fcl.toLowerCase().includes(searchLower)
            ) ||
            item.depts.some((dept) =>
              dept.toLowerCase().includes(searchLower)
            ) ||
            item.lines.some((line) =>
              line.toLowerCase().includes(searchLower)
            ) ||
            item.machines.some((machine) =>
              machine.toLowerCase().includes(searchLower)
            )
        )
      );
    } else {
      setFilteredData(dataSource);
    }
  }, [searchValue]);

  if (displayBoardList?.isError) {
    return (
      <SimpleFlex justifycontent="center" backgroundcolor="transparent">
        <Result
          status="500"
          title="500"
          subTitle="Sorry, Failed to fetch plants"
        />
      </SimpleFlex>
    );
  }

  return (
    <SimpleFlex
      backgroundcolor="transparent"
      padding="1rem 2rem"
      overflow="scroll"
      flexdirection="column"
      gap="1rem"
    >
      <SimpleFlex
        width="auto"
        height="fit-content"
        backgroundcolor="transparent"
      >
        <Breadcrumb separator=">">
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>
              <a href={item.href}>{item.title}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        justifycontent="space-between"
      >
        <P fontsize="2.25rem" fontWeight="var(--font-600)">
          {t("displayBoardManagement")}
        </P>
        <SimpleFlex width="auto" gap="1rem" backgroundcolor="transparent">
          <PrimaryButton
            padding="0.75rem 1.625rem"
            onClick={() => {
              navigate("/proton/new-display-board");
            }}
          >
            <SimpleFlex
              cursor="pointer"
              alignitems="center"
              gap="0.5rem"
              backgroundcolor="transparent"
            >
              <P
                color="var(--columbia-gray-color)"
                fontWeight="var(--font-500)"
                cursor="pointer"
              >
                {t("addDisplayBoard")}
              </P>{" "}
              <Plus />
            </SimpleFlex>{" "}
          </PrimaryButton>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        flexdirection="column"
        gap="1rem"
      >
        <SimpleFlex
          backgroundcolor="transparent"
          justifycontent="space-between"
        >
          <SimpleFlex
            width="auto"
            alignitems="center"
            backgroundcolor="transparent"
            className="transparent-search"
          >
            <PrimaryInputSearch
              allowClear
              placeholder={t("search")}
              onSearch={(value) => setSearchValue(value)}
            />
          </SimpleFlex>
          <SimpleFlex backgroundcolor="transparent" width="auto" gap="0.5rem">
            <SimpleButton
              padding="0.75rem 1.625rem"
              disabled={selectedRowKeys.length === 0}
            >
              {t("delete")}
            </SimpleButton>
            <SimpleButton
              padding="0.75rem 1.625rem"
              disabled={selectedRowKeys.length === 0}
            >
              {t("share")}
            </SimpleButton>
          </SimpleFlex>
        </SimpleFlex>
        <Table
          loading={displayBoardList?.isLoading}
          rowSelection={rowSelection}
          dataSource={filteredData}
          columns={columns}
          bordered
          className="w-100 custom-table"
          pagination={{
            position: ["bottomCenter"],
            pageSize: 10,
          }}
        />
      </SimpleFlex>
    </SimpleFlex>
  );
}
export default DisplayBoard;
