import { ConfigProvider, InputNumber } from "antd";
import styled from "styled-components";

export const CustomInputNumber = styled(InputNumber)``;

export const PrimaryInputNumber = (props) => {
  const { onChange } = props;

  const handleChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#CBD5E1",
        },
      }}
    >
      <CustomInputNumber {...props} onChange={handleChange}>
        {props.children}
      </CustomInputNumber>
    </ConfigProvider>
  );
};
