import { useState } from "react";
import { PrimaryInput } from "../../../Components/StyledComponents/Input";
import { PrimaryModal } from "../../../Components/StyledComponents/Modal";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../Components/StyledComponents/Button";
import { PrimarySelect } from "../../../Components/StyledComponents/Select";

function CustomField({ closeButton, payload }) {
  const [selectedRow, setSelectedRow] = useState({
    name: payload?.name || "",
    text: payload?.text || "",
    type: payload?.type || "",
  });

  const handleOk = () => {
    closeButton();
  };

  const handleCancel = () => {
    closeButton();
  };

  const CustomFieldModalfooter = (
    <SimpleFlex
      margin="1.5rem 0 0 0"
      justifycontent="flex-end"
      alignitems="center"
    >
      <SimpleFlex gap="1rem" width="auto">
        <SimpleButton onClick={handleCancel} padding="0.5rem 1rem">
          Cancel
        </SimpleButton>
        <PrimaryButton onClick={handleOk} padding="0.5rem 1rem">
          Save
        </PrimaryButton>
      </SimpleFlex>
    </SimpleFlex>
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedRow((prevState) => {
      const newState = {
        ...prevState,
        [name]: value,
      };
      return newState;
    });
  };

  return (
    <PrimaryModal
      centered
      title="Add Custom Fields"
      open
      onOk={handleOk}
      onCancel={handleCancel}
      footer={CustomFieldModalfooter}
    >
      <SimpleFlex flexdirection="column" gap="1.5rem">
        <SimpleFlex flexdirection="column" gap="1rem">
          <P color="var(--nav-color)" fontsize="0.75rem">
            Custom Field Name
          </P>
          <PrimaryInput
            bordered={false}
            name="name"
            defaultValue={selectedRow?.name}
            onChange={handleInputChange}
            style={{ borderBottom: "1px solid black" }}
            className="user-input"
            padding="0 0 0.3rem 0"
            color="var(--black)"
            borderradius="0"
            fontsize="1rem"
          />
        </SimpleFlex>
        <SimpleFlex flexdirection="column" gap="1rem">
          <P color="var(--nav-color)" fontsize="0.75rem">
            Type :
          </P>
          <PrimarySelect
            bordered={false}
            name="type"
            value={selectedRow?.type}
            onChange={(value) =>
              handleInputChange({
                target: {
                  name: "type",
                  value,
                },
              })
            }
            className="user-select"
            showSearch
            allowClear
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={[
              {
                value: "notIdentified",
                label: "Not Identified",
              },
              {
                value: "closed",
                label: "Closed",
              },
              {
                value: "communicated",
                label: "Communicated",
              },
              {
                value: "identified",
                label: "Identified",
              },
              {
                value: "resolved",
                label: "Resolved",
              },
              {
                value: "cancelled",
                label: "Cancelled",
              },
            ]}
          ></PrimarySelect>
        </SimpleFlex>
        <SimpleFlex flexdirection="column" gap="1rem">
          <P color="var(--nav-color)" fontsize="0.75rem">
            Custom Text Field
          </P>
          <PrimaryInput
            bordered={false}
            name="text"
            defaultValue={selectedRow?.text}
            onChange={handleInputChange}
            style={{ borderBottom: "1px solid black" }}
            className="user-input"
            padding="0 0 0.3rem 0"
            color="var(--black)"
            borderradius="0"
            fontsize="1rem"
          />
        </SimpleFlex>
      </SimpleFlex>
    </PrimaryModal>
  );
}
export default CustomField;
