import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Action
export const fetchDashboardNotifications = createAsyncThunk(
  "dashboard/fetchDashboardNotifications",
  async () => {
    // Replace the URL with your actual endpoint to fetch dashboard data
    // const response = await fetch("https://api.example.com/dashboard");
    // return response.json();

    await new Promise((resolve) => setTimeout(resolve, 2000));

    return {
      data: {
        notifications: [
          {
            type: "Down Time",
            duration: "2m",
            status: "Open",
            severity: "Critical",
            reason: "Machine breakdown",
            date: "20 Oct 2023, 07:42 PM",
            category: "Unplanned",
          },
          {
            type: "Down Time",
            duration: "2m",
            status: "Open",
            severity: "Normal",
            reason: "Machine breakdown",
            date: "20 Oct 2023, 07:42 PM",
            category: "Planned",
          },
          {
            type: "Down Time",
            duration: "2m",
            status: "Open",
            severity: "Informative",
            reason: "Machine breakdown",
            date: "20 Oct 2023, 07:42 PM",
            category: "Unplanned",
          },
        ],
      },
    };
  }
);

// Dashboard Slice
const dashboardSlice = createSlice({
  name: "dashboardNotifications",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDashboardNotifications.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(fetchDashboardNotifications.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchDashboardNotifications.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      console.error("Error fetching dashboard data:", action.error.message);
    });
  },
});

export default dashboardSlice.reducer;
