import { ConfigProvider, Modal } from "antd";
import styled from "styled-components";

export const CustomModal = styled(Modal)`
  min-width: ${(props) => props.minwidth || ""};
`;

export const PrimaryModal = (props) => {
  const { onOk, onCancel } = props;

  const handleOk = () => {
    if (onOk) {
      onOk();
    }
  };
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#21223d",
        },
      }}
    >
      <CustomModal {...props} onOk={handleOk} onCancel={handleCancel}>
        {props.children}
      </CustomModal>
    </ConfigProvider>
  );
};
