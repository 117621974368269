import { Breadcrumb, Table } from "antd";
import { SimpleFlex } from "../../Components/StyledComponents/SimpleFlex";
import { P } from "../../Components/StyledComponents/Paragraph";
import { useState } from "react";
import { PrimaryInputSearch } from "../../Components/StyledComponents/InputSearch";
import { ReactComponent as No } from "../../assets/svg/no.svg";
import { ReactComponent as Yes } from "../../assets/svg/yes.svg";
import { ReactComponent as LeftArrowColered } from "../../assets/svg/leftArrowColered.svg";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

function RolePermissions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  const location = useLocation();
  const receivedData = location.state;

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const breadcrumbItems = [
    { title: "Dashboard", href: "/proton/live-dashboard" },
    { title: "Role Management", href: "/proton/roles" },
    { title: "View Permission ", href: "" },
  ];

  const assetsColumns = [
    {
      title: "Assets",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "View",
      dataIndex: "permissions",
      key: "view",
      render: (permissions) =>
        permissions?.view ? (
          <SimpleFlex
            alignitems="center"
            gap="0.5rem"
            backgroundcolor="transparent"
          >
            <Yes />
            Yes
          </SimpleFlex>
        ) : (
          <SimpleFlex
            alignitems="center"
            gap="0.5rem"
            backgroundcolor="transparent"
          >
            <No />
            No
          </SimpleFlex>
        ),
    },
    {
      title: "Modify",
      dataIndex: "permissions",
      key: "modify",
      render: (permissions) =>
        permissions?.modify ? (
          <SimpleFlex
            alignitems="center"
            gap="0.5rem"
            backgroundcolor="transparent"
          >
            <Yes />
            Yes
          </SimpleFlex>
        ) : (
          <SimpleFlex
            alignitems="center"
            gap="0.5rem"
            backgroundcolor="transparent"
          >
            <No />
            No
          </SimpleFlex>
        ),
    },
    {
      title: "Delete",
      dataIndex: "permissions",
      key: "delete",
      render: (permissions) =>
        permissions?.delete ? (
          <SimpleFlex
            alignitems="center"
            gap="0.5rem"
            backgroundcolor="transparent"
          >
            <Yes />
            Yes
          </SimpleFlex>
        ) : (
          <SimpleFlex
            alignitems="center"
            gap="0.5rem"
            backgroundcolor="transparent"
          >
            <No />
            No
          </SimpleFlex>
        ),
    },
    {
      title: "Others",
      dataIndex: "permissions",
      key: "others",
      render: (permissions) =>
        permissions?.others === true ? (
          <SimpleFlex
            alignitems="center"
            gap="0.5rem"
            backgroundcolor="transparent"
          >
            <Yes />
            Yes
          </SimpleFlex>
        ) : permissions?.others === false ? (
          <SimpleFlex
            alignitems="center"
            gap="0.5rem"
            backgroundcolor="transparent"
          >
            <No />
            No
          </SimpleFlex>
        ) : (
          "-"
        ),
    },
  ];
  const userManagementColumns = [
    {
      title: "User Management",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "View",
      dataIndex: "permissions",
      key: "view",
      render: (permissions) =>
        permissions?.view ? (
          <SimpleFlex
            alignitems="center"
            gap="0.5rem"
            backgroundcolor="transparent"
          >
            <Yes />
            Yes
          </SimpleFlex>
        ) : (
          <SimpleFlex
            alignitems="center"
            gap="0.5rem"
            backgroundcolor="transparent"
          >
            <No />
            No
          </SimpleFlex>
        ),
    },
    {
      title: "Modify",
      dataIndex: "permissions",
      key: "modify",
      render: (permissions) =>
        permissions?.modify ? (
          <SimpleFlex
            alignitems="center"
            gap="0.5rem"
            backgroundcolor="transparent"
          >
            <Yes />
            Yes
          </SimpleFlex>
        ) : (
          <SimpleFlex
            alignitems="center"
            gap="0.5rem"
            backgroundcolor="transparent"
          >
            <No />
            No
          </SimpleFlex>
        ),
    },
    {
      title: "Delete",
      dataIndex: "permissions",
      key: "delete",
      render: (permissions) =>
        permissions?.delete ? (
          <SimpleFlex
            alignitems="center"
            gap="0.5rem"
            backgroundcolor="transparent"
          >
            <Yes />
            Yes
          </SimpleFlex>
        ) : (
          <SimpleFlex
            alignitems="center"
            gap="0.5rem"
            backgroundcolor="transparent"
          >
            <No />
            No
          </SimpleFlex>
        ),
    },
    {
      title: "Others",
      dataIndex: "permissions",
      key: "others",
      render: (permissions) =>
        permissions?.others === true ? (
          <SimpleFlex
            alignitems="center"
            gap="0.5rem"
            backgroundcolor="transparent"
          >
            <Yes />
            Yes
          </SimpleFlex>
        ) : permissions?.others === false ? (
          <SimpleFlex
            alignitems="center"
            gap="0.5rem"
            backgroundcolor="transparent"
          >
            <No />
            No
          </SimpleFlex>
        ) : (
          "-"
        ),
    },
  ];

  const assetsData = receivedData?.permissions?.assets;
  const userManagement = receivedData?.permissions?.userManagement;

  return (
    <SimpleFlex
      backgroundcolor="transparent"
      padding="1rem 2rem"
      overflow="scroll"
      flexdirection="column"
      gap="1rem"
      height="auto"
    >
      <SimpleFlex
        width="auto"
        height="fit-content"
        backgroundcolor="transparent"
      >
        <Breadcrumb separator=">">
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>
              <a href={item.href}>{item.title}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        justifycontent="space-between"
        flexdirection="column"
        gap="0.5rem"
      >
        <P fontsize="2.25rem" fontWeight="var(--font-600)">
          Role Management
        </P>
        <SimpleFlex
          alignitems="center"
          gap="0.5rem"
          backgroundcolor="transparent"
          cursor="pointer"
          onClick={handleGoBack}
        >
          <LeftArrowColered />
          <P fontsize="0.75rem" color="var(--nav-color)" cursor="pointer">
            Back to Role
          </P>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        flexdirection="column"
        gap="1rem"
      >
        <SimpleFlex
          backgroundcolor="transparent"
          justifycontent="space-between"
        >
          <SimpleFlex
            height="auto"
            backgroundcolor="transparent"
            justifycontent="space-between"
            alignitems="center"
          >
            <P fontsize="1.75rem" fontWeight="var(--font-600)">
              Billing Admin
            </P>
          </SimpleFlex>
          <SimpleFlex
            width="auto"
            alignitems="center"
            backgroundcolor="transparent"
            className="transparent-search"
          >
            <PrimaryInputSearch
              allowClear
              placeholder="Search name, email, group..."
              onSearch={(value) => setSearchValue(value)}
            />
          </SimpleFlex>
        </SimpleFlex>
        <Table
          bordered
          className="w-100 custom-table"
          dataSource={assetsData}
          columns={assetsColumns}
          pagination={false}
        />
        <Table
          bordered
          className="w-100 custom-table"
          dataSource={userManagement}
          columns={userManagementColumns}
          pagination={false}
        />
      </SimpleFlex>
    </SimpleFlex>
  );
}
export default RolePermissions;
