import { Empty, Pagination, Result, Skeleton } from "antd";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { P } from "../../../../Components/StyledComponents/Paragraph";
import { ReactComponent as RightArrow } from "../../../../assets/svg/rightArrow.svg";
import { SimpleFlex } from "../../../../Components/StyledComponents/SimpleFlex";
import { fetchAlertsDashboard } from "../../../../redux/slice/Alerts/Dashboard/Downtime/downtimeAlert";
import { PrimaryButton } from "../../../../Components/StyledComponents/Button";
import AlertCardDetail from "../CardDetail";

function DashboardAlert({ searchQuery }) {
  const dispatch = useDispatch();
  const { downtimeAlert } = useSelector((state) => state);
  const [showModal, setShowModal] = useState(false);
  const [clickedCard, setClickedCard] = useState(null);

  const dashboard = downtimeAlert?.data?.data?.dashboard || [];
  const filteredDashboard = dashboard.filter((item) => {
    const searchTerms = searchQuery.toLowerCase().split(" ");
    return searchTerms.every((term) =>
      Object.values(item).some((value) =>
        value.toString().toLowerCase().includes(term)
      )
    );
  });

  const isLoading = downtimeAlert?.isLoading;
  const hasDashboardData = filteredDashboard.length > 0;
  const isError = downtimeAlert?.isError;

  const [currentPage, setCurrentPage] = useState(1);
  const numCardsPerPage = 4; // Number of cards per page
  const minIndex = (currentPage - 1) * numCardsPerPage;
  const maxIndex = currentPage * numCardsPerPage;
  const cardsToDisplay = filteredDashboard.slice(minIndex, maxIndex);

  useEffect(() => {
    // Dispatch the action when the component mounts
    dispatch(fetchAlertsDashboard());
  }, [dispatch]);

  const handleCardClick = (cardData) => {
    setClickedCard(cardData);
  };

  return (
    <SimpleFlex
      width="auto"
      flexdirection="column"
      gap="1rem"
      backgroundcolor="transparent"
    >
      <SimpleFlex
        width="auto"
        flexdirection="column"
        gap="1rem"
        backgroundcolor="transparent"
      >
        {isLoading && <Skeleton active loading={true} />}
        {!isLoading &&
          hasDashboardData &&
          cardsToDisplay.map((data, index) => (
            <DashboardCard
              key={index}
              payload={data}
              setShowModal={() => {
                setShowModal(true);
              }}
              handleCardClick={handleCardClick}
            />
          ))}
        {!isLoading && !hasDashboardData && !isError && (
          // Show "no data" state if there is no data
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        {isError && (
          // Show error state if there's an error
          <Result
            status="500"
            title="500"
            subTitle="Sorry, Failed to fetch rejection history."
          />
        )}
        {hasDashboardData && (
          <Pagination
            current={currentPage}
            total={filteredDashboard.length}
            pageSize={numCardsPerPage}
            onChange={(page) => setCurrentPage(page)}
          />
        )}
      </SimpleFlex>
      <AlertCardDetail
        showModal={showModal}
        closeButton={() => setShowModal(false)}
        payload={clickedCard}
      />
    </SimpleFlex>
  );
}

function DashboardCard({ payload, setShowModal, handleCardClick }) {
  let border = "";
  switch (payload.severity.toLowerCase()) {
    case "critical":
      border = "alert-shadow";
      break;
    case "normal":
      border = "ok-shadow";
      break;
    case "informative":
      border = "informative-shadow";
      break;
    default:
      break;
  }

  const handleClick = () => {
    handleCardClick(payload);
  };

  return (
    <SimpleFlex
      className={border}
      backgroundcolor="transparent"
      padding="1.5rem"
      alignitems="center"
      width="auto"
    >
      <SimpleFlex flexdirection="column" gap="0.5rem">
        <P fontWeight="var(--font-600)" fontsize="1rem">
          {payload.title}
        </P>
        <SimpleFlex gap="1rem">
          <SimpleFlex width="auto">
            <P color="var(--nav-color)">Triggered Due to : &nbsp;</P>
            <P> {payload.triggered_due_to}</P>
          </SimpleFlex>
        </SimpleFlex>
        <SimpleFlex gap="1rem">
          <SimpleFlex width="auto">
            <P color="var(--nav-color)">Severity : &nbsp;</P>
            <P>{payload.severity}</P>
          </SimpleFlex>
          <SimpleFlex width="auto">
            <P color="var(--nav-color)">Category : &nbsp;</P>
            <P>{payload.category}</P>
          </SimpleFlex>
          <SimpleFlex width="auto">
            <P color="var(--nav-color)">Status : &nbsp;</P>
            <P>{payload.status}</P>
          </SimpleFlex>
          <SimpleFlex width="auto">
            <P color="var(--nav-color)">Duration : &nbsp;</P>
            <P>{payload.duration}</P>
          </SimpleFlex>
          <SimpleFlex width="auto">
            <P color="var(--nav-color)">Created at : &nbsp;</P>
            <P>{payload.created_at}</P>
          </SimpleFlex>
        </SimpleFlex>
      </SimpleFlex>

      <PrimaryButton
        padding="0.5rem 1.5rem"
        onClick={() => {
          setShowModal();
          handleClick();
        }}
      >
        <SimpleFlex
          alignitems="center"
          cursor="pointer"
          backgroundcolor="transparent"
          gap="0.5rem"
        >
          <P
            fontWeight="var(--font-500)"
            color="var(--columbia-gray-color)"
            cursor="pointer"
          >
            View
          </P>
          <RightArrow />
        </SimpleFlex>
      </PrimaryButton>
    </SimpleFlex>
  );
}

export default DashboardAlert;
