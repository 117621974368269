import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { processGet } from "../../../HttpRequest";

// Action to fetch all users
export const fetchUsers = createAsyncThunk("fetchUsers", async () => {
  const response = await processGet("user/all");

  return response.map((item, index) => {
    return {
      ...item,
      key: index,
    };
  });
});

// Action to add a new user
export const addUser = createAsyncThunk("addUser", async (newUser) => {
  const response = await fetch("https://your-api-endpoint/users", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newUser),
  });
  return response.json();
});

// Action to delete a user
export const deleteUser = createAsyncThunk("deleteUser", async (userId) => {
  const response = await fetch(`https://your-api-endpoint/users/${userId}`, {
    method: "DELETE",
  });
  return response.json();
});

// Action to edit a user
export const editUser = createAsyncThunk("editUser", async (editedUser) => {
  // const response = await fetch(
  //   `https://your-api-endpoint/users/${editedUser.key}`,
  //   {
  //     method: "PUT",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(editedUser),
  //   }
  // );
  // return response.json();

  // user is edited successfully
  const dispatch = useDispatch();
  dispatch(fetchUsers());
  await new Promise((resolve) => setTimeout(resolve, 2000));
  return editedUser;
});

const usersSlice = createSlice({
  name: "users",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    selectedUser: null,
  },
  reducers: {
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchUsers.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.data = state.data.filter((user) => user.key !== action.payload.key);
    });
    builder.addCase(editUser.fulfilled, (state, action) => {
      state.data = state.data.map((user) =>
        user.key === action.payload.key ? action.payload : user
      );
      state.selectedUser = null; // Clear selected user after edit
    });
  },
});

export const { setSelectedUser } = usersSlice.actions;

export default usersSlice.reducer;
