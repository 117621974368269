import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Action to fetch all roles
export const fetchRoles = createAsyncThunk("fetchRoles", async () => {
  // const response = await fetch("https://your-api-endpoint/roles");
  // return response.json();

  // throw new Error("");
  // await new Promise((resolve) => setTimeout(resolve, 2000));
  return [
    {
      key: "1",
      role: "System Accounts",
      type: "Standard",
      permissions: {
        assets: [
          {
            title: "Machines",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Conditions",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Incidents",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Lifecycle Overrides",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
        ],
        userManagement: [
          {
            title: "Roles",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "User",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Shifts",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Holidays",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
        ],
      },
    },
    {
      key: "2",
      role: "Billing Admin",
      type: "Standard",
      permissions: {
        assets: [
          {
            title: "Machines",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Conditions",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Incidents",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Lifecycle Overrides",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
        ],
        userManagement: [
          {
            title: "Roles",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "User",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Shifts",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Holidays",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
        ],
      },
    },
    {
      key: "3",
      role: "Master Admin",
      type: "Standard",
      permissions: {
        assets: [
          {
            title: "Machines",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Conditions",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Incidents",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Lifecycle Overrides",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
        ],
        userManagement: [
          {
            title: "Roles",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "User",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Shifts",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Holidays",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
        ],
      },
    },
    {
      key: "4",
      role: "Line Leader",
      type: "Standard",
      permissions: {
        assets: [
          {
            title: "Machines",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Conditions",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Incidents",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Lifecycle Overrides",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
        ],
        userManagement: [
          {
            title: "Roles",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "User",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Shifts",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Holidays",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
        ],
      },
    },
    {
      key: "5",
      role: "Production Engineer",
      type: "Standard",
      permissions: {
        assets: [
          {
            title: "Machines",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Conditions",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Incidents",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Lifecycle Overrides",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
        ],
        userManagement: [
          {
            title: "Roles",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "User",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Shifts",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
          {
            title: "Holidays",
            permissions: {
              view: true,
              modify: false,
              delete: true,
              others: null,
            },
          },
        ],
      },
    },
  ];
});

const roleSlice = createSlice({
  name: "roles",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchRoles.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchRoles.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchRoles.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export default roleSlice.reducer;
