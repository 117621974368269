import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { processPost } from "../../HttpRequest";

// Action
export const loginQRUser = createAsyncThunk("loginQRUser", async (userData) => {
  try {
    const response = await processPost("user/validate", {
      code: userData?.code,
    });
    console.log("response: ", response);
    return userData;
  } catch (error) {
    throw error;
  }
});

const loginQRSlice = createSlice({
  name: "loginQR",
  initialState: {
    isLoading: false,
    user: JSON.parse(localStorage.getItem("user")) || null,
    isError: false,
    userLoginSuccess: false,
  },
  reducers: {
    setUser: (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload));
      state.user = action.payload;
    },
    clearQRUser: (state) => {
      localStorage.removeItem("user");
      state.user = null;
    },
    setShowUserLoginQRSuccess: (state, action) => {
      state.userLoginSuccess = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginQRUser.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(loginQRUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
      state.userLoginSuccess = true;
      localStorage.setItem("user", JSON.stringify(action.payload));
    });
    builder.addCase(loginQRUser.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.user = null;
    });
  },
});

export const { setUser, clearQRUser, setShowUserLoginQRSuccess } =
  loginQRSlice.actions;
export const selectUser = JSON.parse(localStorage.getItem("user")) || null;
export const selectLoading = (state) => state.login.isLoading;

export default loginQRSlice.reducer;
