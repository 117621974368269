import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

// Action to fetch all plants
export const fetchCondAndRules = createAsyncThunk(
  "fetchCondAndRules",
  async () => {
    // const response = await fetch("https://your-api-endpoint/plants");
    // return response.json();

    // throw new Error("");
    // await new Promise((resolve) => setTimeout(resolve, 2000));
    return [
      {
        key: "1",
        title: "Machine Availability",
        createdOn: "20 Oct 2023, 07:42 PM",
        isEnabled: false,
        data: [
          {
            title: "Condition 1",
            description:
              "If machine performance is less than 26% for at least 5 mins. Severity level critical",
            data: [
              {
                title: "Action 1",
                description:
                  "Emailed notification to : John doe, Mathew, Cinderella",
              },
            ],
          },
        ],
      },
      {
        key: "2",
        title: "Machine Availability XYZ",
        createdOn: "20 Oct 2023, 07:42 PM",
        isEnabled: true,
        data: [
          {
            title: "Condition 1",
            description:
              "If machine performance is less than 26% for at least 5 mins. Severity level critical",
          },
          {
            title: "Condition 2",
            description:
              "If machine performance is equal to 10% for alteast 5 mins. Severity level critical",
            data: [
              {
                title: "Action 1",
                description:
                  "Emailed notification to : John doe, Mathew, Cinderella",
              },
            ],
          },
        ],
      },
      {
        key: "3",
        title: "Machine ABCD",
        createdOn: "20 Oct 2023, 07:42 PM",
        isEnabled: true,
        data: [
          {
            title: "Condition 1",
            description:
              "If machine performance is less than 26% for at least 5 mins. Severity level critical",
          },
          {
            title: "Condition 2",
            description:
              "If machine performance is equal to 10% for alteast 5 mins. Severity level critical",
            data: [
              {
                title: "Action 1",
                description:
                  "Emailed notification to : John doe, Mathew, Cinderella",
              },
              {
                title: "Action 1",
                description:
                  "Called notification to : John doe, Mathew, Cinderella",
              },
              {
                title: "Action 1",
                description: "Downtime alert",
              },
            ],
          },
        ],
      },
    ];
  }
);

// Action to add a new plant
export const addCondAndRules = createAsyncThunk(
  "addCondAndRules",
  async (newCondAndRule) => {
    const response = await fetch("https://your-api-endpoint/CondAndRules", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newCondAndRule),
    });
    return response.json();
  }
);

// Action to delete a plant
export const deleteCondAndRules = createAsyncThunk(
  "deleteCondAndRules",
  async (condAndRuleId) => {
    const response = await fetch(
      `https://your-api-endpoint/CondAndRules/${condAndRuleId}`,
      {
        method: "DELETE",
      }
    );
    return response.json();
  }
);

// Action to edit a plant
export const editCondAndRules = createAsyncThunk(
  "editCondAndRules",
  async (editCondAndRules) => {
    // const response = await fetch(
    //   `https://your-api-endpoint/plants/${editedPlant.key}`,
    //   {
    //     method: "PUT",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(editCondAndRules),
    //   }
    // );
    // return response.json();

    // plant is edited successfully
    const dispatch = useDispatch();
    dispatch(fetchCondAndRules());
    return editCondAndRules;
  }
);

const condAndRuleSlice = createSlice({
  name: "condAndRules",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    selectedCondAndRules: null,
  },
  reducers: {
    setSelectedCondAndRules: (state, action) => {
      state.selectedCondAndRules = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCondAndRules.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCondAndRules.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchCondAndRules.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(addCondAndRules.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
    });
    builder.addCase(deleteCondAndRules.fulfilled, (state, action) => {
      state.data = state.data.filter(
        (condAndRule) => condAndRule.key !== action.payload.key
      );
    });
    builder.addCase(editCondAndRules.fulfilled, (state, action) => {
      state.data = state.data.map((condAndRule) =>
        condAndRule.key === action.payload.key ? action.payload : condAndRule
      );
      state.selectedCondAndRules = null; // Clear selected plant after edit
    });
  },
});

export const { setSelectedCondAndRules } = condAndRuleSlice.actions;

export default condAndRuleSlice.reducer;
