import { useEffect, useState } from "react";
import { Breadcrumb, Card, Pagination, Tabs } from "antd";
import Highcharts from "highcharts";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { ReactComponent as Download } from "../../../assets/svg/download.svg";
import { ReactComponent as Filter } from "../../../assets/svg/filter.svg";
import { PrimaryInputSearch } from "../../../Components/StyledComponents/InputSearch";
import DashboardAlert from "./DownTime";
import "./Dashboard.scss";
import ProductionAlert from "./Production";
import SystemAlert from "./System";
import FilterCard from "./FilterCard";
import transition from "../../../Transition";

function AlertsDashboard() {
  useEffect(() => {
    Highcharts.chart("container", {
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: [
          "12:00 PM",
          "1:00 PM",
          "2:00 PM",
          "3:00 PM",
          "4:00 PM",
          "5:00 PM",
          "6:00 PM",
          "7:00 PM",
          "8:00 PM",
          "9:00 PM",
        ],
        title: {
          text: "",
        },
      },
      yAxis: {
        gridLineDashStyle: "Dash",
        min: 0,
        max: 50,
        tickInterval: 10,
        title: {
          text: "",
        },
      },
      tooltip: {
        headerFormat: "<b>{point.key}</b><br/>",
        formatter: function () {
          // Get the series name
          var seriesName = this.series.name;

          // Define colors based on series name
          var textColor =
            seriesName === "DownTime"
              ? "var(--warning-color)"
              : seriesName === "Start"
              ? "var(--primary-color)"
              : "var(--snow-pea-green)";

          // Return formatted tooltip with custom text color
          return "<P color=" + textColor + ">" + seriesName + "</P>";
        },
      },
      plotOptions: {
        column: {
          stacking: "normal",
        },
      },
      series: [
        {
          name: "DownTime",
          data: [5, 10, 15, 20, 10, 5, 8, 15, 35, 20],
          stack: "time",
          color: "var(--warning-color)",
          borderRadius: "6px 6px 0 0",
        },
        {
          name: "Start",
          data: [5, 10, 15, 20, 10, 5, 8, 15, 10, 25],
          stack: "time",
          color: "var(--primary-color)",
          borderRadius: "6px 6px 0 0",
        },
        {
          name: "In-progress",
          data: [15, 20, 15, 10, 15, 20, 17, 15, 5, 5],
          stack: "time",
          color: "var(--snow-pea-green)",
          borderRadius: "6px 6px 0 0",
        },
      ],
    });
  }, []); // Run the effect only once after the component is mounted

  const [showModal, setShowModal] = useState(false);

  const breadcrumbItems = [
    { title: "Dashboard", href: "/proton/live-dashboard" },
    { title: "Alerts", href: "" },
  ];

  const [activeKey, setActiveKey] = useState("1");
  const [searchQuery, setSearchQuery] = useState("");

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  const items = [
    {
      key: "1",
      label: "All",
      children: activeKey === "1" && (
        <DashboardAlert searchQuery={searchQuery} />
      ),
    },
    {
      key: "2",
      label: "Downtime Alerts",
      children: activeKey === "2" && (
        <DashboardAlert searchQuery={searchQuery} />
      ),
    },
    {
      key: "3",
      label: "Production Alerts",
      children: activeKey === "3" && (
        <ProductionAlert searchQuery={searchQuery} />
      ),
    },
    {
      key: "4",
      label: "System Alerts",
      children: activeKey === "4" && <SystemAlert searchQuery={searchQuery} />,
    },
  ];

  const tabBarExtraContent = () => {
    return (
      <SimpleFlex
        backgroundcolor="transparent"
        width="auto"
        className="transparent-search"
        alignitems="center"
        gap="1.5rem"
      >
        <SimpleFlex
          width="auto"
          gap="1rem"
          backgroundcolor="transparent"
          alignitems="center"
        >
          <P
            fontweight="var(--font-500)"
            fontsize="0.688rem"
            color="var(--nav-color)"
          >
            Severity:
          </P>
          <SimpleFlex
            alignitems="center"
            gap="0.3rem"
            backgroundcolor="transparent"
          >
            <P
              width="0.5rem"
              height="0.5rem"
              backgroundcolor="var(--warning-color)"
            ></P>
            <P fontsize="0.688rem" fontWeight="var(--font-500)">
              Critical
            </P>
          </SimpleFlex>
          <SimpleFlex
            alignitems="center"
            gap="0.3rem"
            backgroundcolor="transparent"
          >
            <P
              width="0.5rem"
              height="0.5rem"
              backgroundcolor="var(--snow-pea-green)"
            ></P>
            <P fontsize="0.688rem" fontWeight="var(--font-500)">
              Normal
            </P>
          </SimpleFlex>
          <SimpleFlex
            alignitems="center"
            gap="0.3rem"
            backgroundcolor="transparent"
          >
            <P
              width="0.5rem"
              height="0.5rem"
              backgroundcolor="var(--informative-color)"
            ></P>
            <P fontsize="0.688rem" fontWeight="var(--font-500)">
              Informative
            </P>
          </SimpleFlex>
        </SimpleFlex>
        <PrimaryInputSearch
          onSearch={(value) => setSearchQuery(value)}
          placeholder="Search"
          allowClear
        />
      </SimpleFlex>
    );
  };

  return (
    <SimpleFlex
      backgroundcolor="transparent"
      padding="1rem 2rem"
      overflow="scroll"
      flexdirection="column"
      gap="1rem"
      height="auto"
      width="auto"
    >
      <SimpleFlex
        width="auto"
        height="fit-content"
        backgroundcolor="transparent"
      >
        <Breadcrumb separator=">">
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>
              <a href={item.href}>{item.title}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </SimpleFlex>
      <SimpleFlex
        backgroundcolor="transparent"
        height="auto"
        justifycontent="space-between"
      >
        <P fontWeight="var(--font-600)" fontsize="2.25rem">
          Alerts
        </P>
        <SimpleFlex backgroundcolor="transparent" width="auto" gap="1rem">
          <SimpleButton padding="0.5rem 1.625rem">
            <SimpleFlex alignitems="center" gap="0.5rem" cursor="pointer">
              <P color="var(--nav-color)" cursor="pointer">
                Report
              </P>{" "}
              <Download />
            </SimpleFlex>
          </SimpleButton>
          <SimpleButton
            padding="0.5rem 1.625rem"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <SimpleFlex alignitems="center" gap="0.5rem" cursor="pointer">
              <P color="var(--nav-color)" cursor="pointer">
                Filter
              </P>{" "}
              <Filter />
            </SimpleFlex>
          </SimpleButton>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex height="auto">
        <figure className="highcharts-figure" style={{ width: "100%" }}>
          <div id="container"></div>
        </figure>
      </SimpleFlex>
      <SimpleFlex className="tab-container" backgroundcolor="transparent">
        <Tabs
          className="tabs"
          items={items}
          tabBarExtraContent={tabBarExtraContent()}
          activeKey={activeKey}
          onChange={handleTabChange}
        />
      </SimpleFlex>
      <FilterCard
        showModal={showModal}
        closeButton={() => setShowModal(false)}
      />
    </SimpleFlex>
  );
}

export default transition(AlertsDashboard);
