import { Breadcrumb, Modal, Result, Table } from "antd";
import { SimpleFlex } from "../../Components/StyledComponents/SimpleFlex";
import { P } from "../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../Components/StyledComponents/Button";
import { ReactComponent as UploadToCloud } from "../../assets/svg/uploadToCloud.svg";
import { ReactComponent as Plus } from "../../assets/svg/plus.svg";
import { ReactComponent as Edit } from "../../assets/svg/edit.svg";
import { ReactComponent as Eye } from "../../assets/svg/eye.svg";
import { useEffect, useState } from "react";
import { PrimaryInputSearch } from "../../Components/StyledComponents/InputSearch";
import { fetchUsers } from "../../redux/slice/Users/user";
import EditUser from "./EditUserModal";
import AddUser from "./AddUserModal";
import { useDispatch, useSelector } from "react-redux";
import transition from "../../Transition";
import { useTranslation } from "react-i18next";
import "./User.scss";

function Users() {
  const { t } = useTranslation();
  const tableScrollConfig = {
    x: 2300,
    y: 500,
  };

  const dispatch = useDispatch();
  const { users = [] } = useSelector((state) => state);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const dataSource = users?.data || [];
  const [filteredData, setFilteredData] = useState(dataSource);

  useEffect(() => {
    setFilteredData(dataSource);
  }, [dataSource]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const breadcrumbItems = [
    { title: t("dashboard"), href: "/proton/live-dashboard" },
    { title: t("userManagement"), href: "" },
  ];

  const columns = [
    {
      title: "UserId",
      dataIndex: "userId",
      key: "userId",
      fixed: "left",
    },
    {
      title: "Password Set",
      dataIndex: "passwordSet",
      key: "passwordSet",
      render: (record) => {
        const text = record ? "True" : "False";
        return <P>{text}</P>;
      },
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      render: (_, object) => {
        return (
          <SimpleFlex backgroundcolor="transparent">
            {object.firstName + " "} {object.lastName}
          </SimpleFlex>
        );
      },
    },
    {
      title: t("First Name"),
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: t("Last Name"),
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("phoneNumber"),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: t("whatsappNumber"),
      dataIndex: "whatsapp",
      key: "whatsapp",
    },
    {
      title: "Login Secret",
      dataIndex: "loginSecret",
      key: "loginSecret",
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: t("employeeID"),
      dataIndex: "empId",
      key: "empId",
    },
    {
      title: t("externalID"),
      dataIndex: "externalId",
      key: "externalId",
    },
    {
      title: t("Groups"),
      dataIndex: "groups",
      key: "groups",
      render: (record) => {
        return record.map((item) => (
          <SimpleFlex backgroundcolor="transparent">{item.name}</SimpleFlex>
        ));
      },
    },
    {
      title: t("Roles"),
      dataIndex: "roles",
      key: "roles",
      render: (record) => {
        return record.map((item) => (
          <SimpleFlex backgroundcolor="transparent">{item.name}</SimpleFlex>
        ));
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <SimpleFlex gap="0.5rem" backgroundcolor="transparent">
          <P className={getColorForStatus(text)}>{getTextForStatus(text)}</P>
        </SimpleFlex>
      ),
    },
    {
      title: t("action"),
      dataIndex: "",
      key: "x",
      fixed: "right",
      render: (record) => (
        <SimpleButton
          padding="0.4rem 0.8rem"
          onClick={() => {
            setSelectedRow(record);
            setShowEditModal(true);
          }}
        >
          <SimpleFlex cursor="pointer" alignitems="center" gap="0.2rem">
            <P color="var(--black)" cursor="pointer" fontsize="1rem">
              {t("edit")}
            </P>{" "}
            <P fontsize="0.3rem" color="var(--primary-color)" cursor="pointer">
              <Edit />
            </P>
          </SimpleFlex>
        </SimpleButton>
      ),
    },
  ];

  // Dispatch the action when the component mounts
  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  //search in table
  useEffect(() => {
    if (searchValue) {
      setFilteredData(
        dataSource.filter((item) => {
          const groupNames = item.groups.map((group) => group.name);
          const roleNames = item.roles.map((role) => role.name);
          const passwordSetString = item.passwordSet ? "true" : "false";

          return (
            passwordSetString.includes(searchValue.toLowerCase()) ||
            item.userId
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.loginSecret
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            item.designation
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            item.email.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.phone.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.whatsapp.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.empId.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.externalId.toLowerCase().includes(searchValue.toLowerCase()) ||
            groupNames.some((name) =>
              name.toLowerCase().includes(searchValue.toLowerCase())
            ) ||
            roleNames.some((name) =>
              name.toLowerCase().includes(searchValue.toLowerCase())
            )
          );
        })
      );
    } else {
      setFilteredData(dataSource);
    }
  }, [searchValue]);

  const getColorForStatus = (status) =>
    ({
      0: "deleted-tag",
      1: "invite-pending-tag",
      2: "active-tag",
    }[status] || "default");

  const getTextForStatus = (status) => {
    const statusText = {
      0: "Deleted",
      1: "Invite Pending",
      2: "Active",
      default: "Default",
    };

    return statusText[status] || statusText.default;
  };

  if (users?.isError) {
    return (
      <SimpleFlex justifycontent="center" backgroundcolor="transparent">
        <Result
          status="500"
          title="500"
          subTitle="Sorry, Failed to fetch users"
        />
      </SimpleFlex>
    );
  }

  return (
    <SimpleFlex
      backgroundcolor="transparent"
      padding="1rem 2rem"
      overflow="scroll"
      flexdirection="column"
      gap="1rem"
      width="auto"
      height="auto"
    >
      <SimpleFlex
        width="auto"
        height="fit-content"
        backgroundcolor="transparent"
      >
        <Breadcrumb separator=">">
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>
              <a href={item.href}>{item.title}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        justifycontent="space-between"
      >
        <P fontsize="2.25rem" fontWeight="var(--font-600)">
          {t("userManagement")}
        </P>
        <SimpleFlex width="auto" gap="1rem" backgroundcolor="transparent">
          <SimpleButton padding="0.75rem 1.625rem">
            <SimpleFlex cursor="pointer" alignitems="center" gap="0.5rem">
              <P
                color="var(--nav-color)"
                fontWeight="var(--font-500)"
                cursor="pointer"
              >
                {t("addBulkUser")}
              </P>{" "}
              <UploadToCloud />
            </SimpleFlex>
          </SimpleButton>
          <PrimaryButton
            padding="0.75rem 1.625rem"
            onClick={() => {
              setShowAddModal(true);
            }}
          >
            <SimpleFlex
              cursor="pointer"
              alignitems="center"
              gap="0.5rem"
              backgroundcolor="transparent"
            >
              <P
                color="var(--columbia-gray-color)"
                fontWeight="var(--font-500)"
                cursor="pointer"
              >
                {t("addUser")}
              </P>{" "}
              <Plus />
            </SimpleFlex>{" "}
          </PrimaryButton>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        flexdirection="column"
        gap="1rem"
      >
        <SimpleFlex
          backgroundcolor="transparent"
          justifycontent="space-between"
        >
          <SimpleFlex
            width="auto"
            alignitems="center"
            backgroundcolor="transparent"
            className="transparent-search"
          >
            <PrimaryInputSearch
              allowClear
              placeholder={t("search")}
              onSearch={(value) => setSearchValue(value)}
            />
          </SimpleFlex>
          <SimpleFlex backgroundcolor="transparent" width="auto" gap="0.5rem">
            <SimpleButton
              padding="0.75rem 1.625rem"
              disabled={selectedRowKeys.length === 0}
            >
              {t("delete")}
            </SimpleButton>
            <SimpleButton
              padding="0.75rem 1.625rem"
              disabled={selectedRowKeys.length === 0}
            >
              {t("sendInvite")}
            </SimpleButton>
            <SimpleButton
              padding="0.75rem 1.625rem"
              disabled={selectedRowKeys.length === 0}
            >
              {t("update")}
            </SimpleButton>
          </SimpleFlex>
        </SimpleFlex>
        <Table
          scroll={tableScrollConfig}
          loading={users?.isLoading}
          rowSelection={rowSelection}
          dataSource={filteredData}
          columns={columns}
          bordered
          className="w-100 custom-table"
          pagination={{
            position: ["bottomCenter"],
            pageSize: 10,
          }}
        />
      </SimpleFlex>
      {!!showEditModal && (
        <EditUser
          payload={selectedRow}
          closeButton={() => {
            setShowEditModal(false);
          }}
        />
      )}

      {!!showAddModal && (
        <AddUser
          closeButton={() => {
            setShowAddModal(false);
          }}
        />
      )}
    </SimpleFlex>
  );
}

export default transition(Users);
