import React, { useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Form, message } from "antd";
import { ReactComponent as Proton } from "../../assets/svg/Proton.svg";
import { PrimaryButton } from "../../Components/StyledComponents/Button";
import { P } from "../../Components/StyledComponents/Paragraph";
import { PrimaryFlex } from "../../Components/StyledComponents/Flex";
import { PrimaryCheckBox } from "../../Components/StyledComponents/CheckBox";
import { PrimaryInput } from "../../Components/StyledComponents/Input";
import { PrimaryTooltip } from "../../Components/StyledComponents/Tooltip";
import { PasswordInput } from "../../Components/StyledComponents/PasswordInput";
import RegisterPageBg from "../../assets/png/RegisterPageBg.png";
import transition from "../../Transition";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/slice/login";
import EntryPageCarousel from "../../Components/EntryPageCarousel/EntryPageCarousel";
import { SimpleFlex } from "../../Components/StyledComponents/SimpleFlex";
import LanguageSwitcher from "../../Components/LanguageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import "./LoginEmail.css";

function LoginEmail() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await dispatch(
        loginUser({ username: values.username, password: values.password })
      ).unwrap();

      setLoading(false);
      navigate("/proton");
    } catch (error) {
      console.error("Login failed:", error);
      messageApi.open({
        type: "error",
        content: t("loginFailed"),
      });
      setLoading(false);
    }
  };

  return (
    <div
      className="container"
      style={{
        background: `url("${RegisterPageBg}"), lightgray 50% / cover no-repeat`,
      }}
    >
      {contextHolder}
      <SimpleFlex
        justifycontent="flex-end"
        height="auto"
        width="auto"
        backgroundcolor="transparent"
        position="absolute"
        className="positioning"
      >
        <LanguageSwitcher />
      </SimpleFlex>
      <div
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          zIndex: 1,
          opacity: 0.85,
          background: "#21223D",
        }}
      ></div>
      <div className="d-flex h-100">
        <Card
          style={{
            width: "auto",
            margin: "auto",
            zIndex: 2,
          }}
        >
          <PrimaryFlex flexdirection="column" gap="1rem">
            <PrimaryFlex justifycontent="space-between">
              <Proton />
              <P
                cursor="pointer"
                margin="0 0 0.5rem 0"
                onClick={() => {
                  return navigate("/login/qr-code");
                }}
              >
                Login with QR Code{" "}
              </P>
            </PrimaryFlex>
            <PrimaryFlex gap="6.25rem">
              <PrimaryFlex>
                <EntryPageCarousel />
              </PrimaryFlex>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                <PrimaryFlex flexdirection="column" gap="1.875rem">
                  <PrimaryFlex flexdirection="column">
                    <P fontWeight="bolder" fontsize="2.5rem">
                      {t("login")}
                    </P>
                  </PrimaryFlex>
                  <PrimaryFlex flexdirection="column">
                    <P
                      fontWeight="300"
                      fontsize="0.938rem"
                      color="var(--nav-color)"
                      margin="0 0 0.5rem 1rem"
                    >
                      {t("emailID")}
                      <PrimaryTooltip title="Mandatory" placement="top">
                        <InfoCircleOutlined style={{ marginLeft: "0.25rem" }} />
                      </PrimaryTooltip>
                    </P>
                    <Form.Item
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Username!",
                        },
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                      ]}
                    >
                      <PrimaryInput
                        width="22.875rem"
                        height="3.375rem"
                        placeholder={t("userName")}
                      />
                    </Form.Item>
                    <P
                      fontWeight="300"
                      fontsize="0.938rem"
                      color="var(--nav-color)"
                      margin="0 0 0.5rem 1rem"
                    >
                      {t("enterPassword")}
                      <PrimaryTooltip title="Mandatory" placement="top">
                        <InfoCircleOutlined style={{ marginLeft: "0.25rem" }} />
                      </PrimaryTooltip>
                    </P>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Password!",
                        },
                      ]}
                    >
                      <PasswordInput
                        width="22.875rem"
                        height="3.375rem"
                        placeholder={t("password")}
                      />
                    </Form.Item>
                    <SimpleFlex justifycontent="flex-end">
                      <P
                        cursor="pointer"
                        onClick={() => {
                          return navigate("/login/forgot-password");
                        }}
                      >
                        {t("forgotPassword")}
                      </P>
                    </SimpleFlex>
                    <Form.Item>
                      <Form.Item
                        name="remember"
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(
                                    "Please accept the terms and conditions"
                                  ),
                          },
                        ]}
                      >
                        <PrimaryCheckBox>
                          <P
                            fontWeight="300"
                            color="var(--nav-color)"
                            fontsize="0.75rem"
                          >
                            {" "}
                            I Accept{" "}
                          </P>{" "}
                          <P fontsize="0.75rem">Terms & Conditions </P>{" "}
                          <P
                            fontWeight="300"
                            color="var(--nav-color)"
                            fontsize="0.75rem"
                          >
                            &{" "}
                          </P>{" "}
                          <P fontsize="0.75rem">Privacy Policy</P>
                        </PrimaryCheckBox>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item>
                      <PrimaryButton
                        fontWeight="600"
                        padding="0 0.625rem"
                        width="22.875rem"
                        height="3.125rem"
                        fontsize="1.125rem"
                        htmlType="submit"
                        loading={loading}
                        justifycontent="center"
                      >
                        {t("submit")}
                      </PrimaryButton>
                    </Form.Item>
                  </PrimaryFlex>
                </PrimaryFlex>
              </Form>
            </PrimaryFlex>
          </PrimaryFlex>
        </Card>
      </div>
    </div>
  );
}
export default transition(LoginEmail);
