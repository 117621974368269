import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

// Action to fetch all plants
export const fetchDisplayboards = createAsyncThunk(
  "fetchDisplayboards",
  async () => {
    // const response = await fetch("https://your-api-endpoint/plants");
    // return response.json();

    // throw new Error("");
    // await new Promise((resolve) => setTimeout(resolve, 2000));
    return [
      {
        key: "1",
        name: "Latest DB 1",
        factories: ["Coil Machine 1", "P001", "P002"],
        factoryLocations: ["Factory 1", "Factory 3"],
        depts: ["Packaging", "Wiring"],
        lines: ["Line 2", "Line 3"],
        machines: ["P10", "P003"],
        intervalTime: "2m",
        displayOption: "pie",
      },
      {
        key: "2",
        name: "Latest DB 2",
        factories: ["Coil Machine 1", "P001"],
        factoryLocations: ["Factory 1", "Factory 3", "Factory 2"],
        depts: ["Packaging", "Wiring"],
        lines: ["Line 2", "Line 3"],
        machines: ["P10", "P003"],
        intervalTime: "2m",
        displayOption: "cellStatus",
      },
      {
        key: "3",
        name: "Latest DB 3",
        factories: ["Coil Machine 1", "P001"],
        factoryLocations: ["Factory 1", "Factory 3"],
        depts: ["Packaging", "Wiring"],
        lines: ["Line 2", "Line 3"],
        machines: ["P10", "P003"],
        intervalTime: "2m",
        displayOption: "tile",
      },
    ];
  }
);

// Action to add a new plant
export const addDisplayboard = createAsyncThunk(
  "addDisplayboard",
  async (newDisplayboard) => {
    const response = await fetch("https://your-api-endpoint/plants", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newDisplayboard),
    });
    return response.json();
  }
);

// Action to delete a plant
export const deleteDisplayboard = createAsyncThunk(
  "deleteDisplayboard",
  async (displayboardId) => {
    const response = await fetch(
      `https://your-api-endpoint/displayboards/${displayboardId}`,
      {
        method: "DELETE",
      }
    );
    return response.json();
  }
);

// Action to edit a plant
export const editDisplayboard = createAsyncThunk(
  "editDisplayboard",
  async (editDisplayboard) => {
    // const response = await fetch(
    //   `https://your-api-endpoint/plants/${editedPlant.key}`,
    //   {
    //     method: "PUT",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(editDisplayboard),
    //   }
    // );
    // return response.json();

    // plant is edited successfully
    const dispatch = useDispatch();
    dispatch(fetchDisplayboards());
    return editDisplayboard;
  }
);

const displayboardSlice = createSlice({
  name: "displayboards",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    selectedDisplayboard: null,
  },
  reducers: {
    setSelectedDisplayboard: (state, action) => {
      console.log("reached");
      state.selectedDisplayboard = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDisplayboards.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDisplayboards.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchDisplayboards.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(addDisplayboard.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
    });
    builder.addCase(deleteDisplayboard.fulfilled, (state, action) => {
      state.data = state.data.filter(
        (displayboard) => displayboard.key !== action.payload.key
      );
    });
    builder.addCase(editDisplayboard.fulfilled, (state, action) => {
      state.data = state.data.map((displayboard) =>
        displayboard.key === action.payload.key ? action.payload : displayboard
      );
      state.selectedDisplayboard = null; // Clear selected plant after edit
    });
  },
});

export const { setSelectedDisplayboard } = displayboardSlice.actions;

export default displayboardSlice.reducer;
