import { useState } from "react";
import { PrimaryInput } from "../../../Components/StyledComponents/Input";
import { PrimaryModal } from "../../../Components/StyledComponents/Modal";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../Components/StyledComponents/Button";
import CustomField from "../CustomFieldModal";

function EditProduct({ closeButton, payload }) {
  const [selectedRow, setSelectedRow] = useState({
    productName: payload?.productName || "",
    description: payload?.description || "",
    shortName: payload?.shortName || "",
    sku: payload?.sku || "",
    externalID: payload?.externalID || "",
  });
  const [showCustomFieldModal, setShowCustomFieldModal] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedRow((prevState) => {
      const newState = {
        ...prevState,
        [name]: value,
      };

      return newState;
    });
  };

  const handleOk = () => {
    closeButton();
  };

  const handleCancel = () => {
    closeButton();
  };

  const EditModalfooter = (
    <SimpleFlex
      margin="1.5rem 0 0 0"
      justifycontent="space-between"
      alignitems="center"
    >
      <SimpleFlex>
        <P
          cursor="pointer"
          textdecorationline="underline"
          onClick={() => {
            setShowCustomFieldModal(true);
          }}
        >
          Add Custom Fields
        </P>
      </SimpleFlex>
      <SimpleFlex gap="1rem" width="auto">
        <SimpleButton onClick={handleCancel} padding="0.5rem 1rem">
          Cancel
        </SimpleButton>
        <PrimaryButton onClick={handleOk} padding="0.5rem 1rem">
          Save Changes
        </PrimaryButton>
      </SimpleFlex>
    </SimpleFlex>
  );

  return (
    <PrimaryModal
      centered
      title="Edit Product"
      open
      onOk={handleOk}
      onCancel={handleCancel}
      footer={EditModalfooter}
      maskClosable={false}
    >
      <SimpleFlex flexdirection="column" gap="1.5rem">
        <SimpleFlex flexdirection="column" gap="1rem">
          <P color="var(--nav-color)" fontsize="0.75rem">
            Product Name
          </P>
          <PrimaryInput
            bordered={false}
            name="productName"
            defaultValue={selectedRow?.productName}
            onChange={handleInputChange}
            style={{ borderBottom: "1px solid black" }}
            className="user-input"
            padding="0 0 0.3rem 0"
            color="var(--black)"
            borderradius="0"
            fontsize="1rem"
          />
        </SimpleFlex>
        <SimpleFlex flexdirection="column" gap="1rem">
          <P color="var(--nav-color)" fontsize="0.75rem">
            Description
          </P>
          <PrimaryInput
            bordered={false}
            name="description"
            defaultValue={selectedRow?.description}
            onChange={handleInputChange}
            style={{ borderBottom: "1px solid black" }}
            className="user-input"
            padding="0 0 0.3rem 0"
            color="var(--black)"
            borderradius="0"
            fontsize="1rem"
          />
        </SimpleFlex>
        <SimpleFlex flexdirection="column" gap="1rem">
          <P color="var(--nav-color)" fontsize="0.75rem">
            Short Name
          </P>
          <PrimaryInput
            bordered={false}
            name="shortName"
            defaultValue={selectedRow?.shortName}
            onChange={handleInputChange}
            style={{ borderBottom: "1px solid black" }}
            className="user-input"
            padding="0 0 0.3rem 0"
            color="var(--black)"
            borderradius="0"
            fontsize="1rem"
          />
        </SimpleFlex>
        <SimpleFlex gap="1rem">
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              SKU
            </P>
            <PrimaryInput
              bordered={false}
              name="sku"
              defaultValue={selectedRow?.sku}
              onChange={handleInputChange}
              style={{ borderBottom: "1px solid black" }}
              className="user-input"
              padding="0 0 0.3rem 0"
              color="var(--black)"
              borderradius="0"
              fontsize="1rem"
            />
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              External ID
            </P>
            <PrimaryInput
              bordered={false}
              name="externalID"
              defaultValue={selectedRow?.externalID}
              onChange={handleInputChange}
              style={{ borderBottom: "1px solid black" }}
              className="user-input"
              padding="0 0 0.3rem 0"
              color="var(--black)"
              borderradius="0"
              fontsize="1rem"
            />
          </SimpleFlex>
        </SimpleFlex>
      </SimpleFlex>
      {!!showCustomFieldModal && (
        <CustomField
          closeButton={() => {
            setShowCustomFieldModal(false);
          }}
        />
      )}
    </PrimaryModal>
  );
}
export default EditProduct;
