import { useEffect, useState } from "react";
import { PrimaryModal } from "../../../Components/StyledComponents/Modal";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { PrimarySelect } from "../../../Components/StyledComponents/Select";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { useDispatch } from "react-redux";
import { addUserGroup } from "../../../redux/slice/UserGroups/userGroup";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../Components/StyledComponents/Button";
import { Form } from "antd";
import { SimpleInput } from "../../../Components/StyledComponents/SimpleInput";

function AddUserGroup({ closeButton }) {
  const dispatch = useDispatch();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const USERS = [
    {
      userId: 1,
      name: "John",
      email: "john@saasant.com",
    },
    {
      userId: 2,
      name: "Mohan",
      email: "mohan@saasant.com",
    },
  ];

  const [selectedRow, setSelectedRow] = useState({
    description: "",
    name: "",
    users: [],
  });

  const handleSelectChange = (selectedValues) => {
    setSelectedRow((prevState) => ({
      ...prevState,
      users: selectedValues,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedRow((prevState) => {
      const newState = {
        ...prevState,
        [name]: value,
      };

      return newState;
    });
  };

  const handleOk = async () => {
    setLoading(true);

    try {
      await dispatch(addUserGroup(selectedRow)).unwrap();
      console.log("User Group added successfully!");
    } catch (error) {
      console.error("Error adding user group:", error);
    } finally {
      setLoading(false);
      closeButton();
    }
  };

  const handleCancel = () => {
    closeButton();
  };

  useEffect(() => {
    const { description, name, users } = selectedRow;
    const formComplete =
      description?.trim() !== "" && name?.trim() !== "" && users?.length > 0;

    setIsFormComplete(formComplete);
  }, [selectedRow]);

  const AddModalfooter = (
    <SimpleFlex margin="1.5rem 0 0 0" alignitems="center">
      <SimpleFlex gap="1rem" width="auto">
        <SimpleButton onClick={handleCancel} padding="0.5rem 1rem">
          Cancel
        </SimpleButton>
        <PrimaryButton
          disabled={!isFormComplete}
          loading={loading}
          onClick={handleOk}
          padding="0.5rem 1rem"
        >
          Save Changes
        </PrimaryButton>
      </SimpleFlex>
    </SimpleFlex>
  );

  return (
    <PrimaryModal
      centered
      title="Add New User Group"
      open
      onOk={handleOk}
      onCancel={handleCancel}
      footer={AddModalfooter}
      maskClosable={false}
    >
      <Form>
        <SimpleFlex flexdirection="column" gap="1rem">
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              User Group Name
            </P>
            <Form.Item
              name="name"
              className="margin-0"
              rules={[
                {
                  required: true,
                  message: "Please input User Group Name!",
                },
              ]}
              initialValue={selectedRow?.name}
            >
              <SimpleInput
                bordered={false}
                name="name"
                onChange={handleInputChange}
                style={{ borderBottom: "1px solid black" }}
                className="user-input"
                padding="0 0 0.3rem 0"
                color="var(--black)"
                borderradius="0"
                fontsize="1rem"
              />
            </Form.Item>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Description
            </P>
            <Form.Item
              name="description"
              className="margin-0"
              rules={[
                {
                  required: true,
                  message: "Please input description!",
                },
              ]}
              initialValue={selectedRow?.description}
            >
              <SimpleInput
                bordered={false}
                name="description"
                onChange={handleInputChange}
                style={{ borderBottom: "1px solid black" }}
                className="user-input"
                padding="0 0 0.3rem 0"
                color="var(--black)"
                borderradius="0"
                fontsize="1rem"
              />
            </Form.Item>
          </SimpleFlex>

          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Assign Owners
            </P>
            <Form.Item
              name="users"
              className="margin-0"
              rules={[
                {
                  required: true,
                  message: "Please input User!",
                },
              ]}
              initialValue={selectedRow?.users}
            >
              <PrimarySelect
                bordered={false}
                className="user-select"
                mode="multiple"
                showSearch
                allowClear
                onChange={(value) => {
                  handleSelectChange(
                    value.map((val) => {
                      return { userId: val };
                    })
                  );
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={USERS}
                fieldNames={{ label: "email", value: "userId" }}
              />
            </Form.Item>
          </SimpleFlex>
        </SimpleFlex>
      </Form>
    </PrimaryModal>
  );
}
export default AddUserGroup;
