import { SimpleFlex } from "../../../../../Components/StyledComponents/SimpleFlex";
import { Table } from "antd";
import { P } from "../../../../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../../../Components/StyledComponents/Button";
import { ReactComponent as Complete } from "../../../../../assets/svg/complete.svg";
import { ReactComponent as Filter } from "../../../../../assets/svg/filter.svg";
import { ReactComponent as DownloadUnderlined } from "../../../../../assets/svg/downloadUnderlined.svg";
import { ReactComponent as Sort } from "../../../../../assets/svg/sort.svg";
import { useState } from "react";
import { SimpleSwitch } from "../../../../../Components/StyledComponents/Switch";
import {
  InfoCircleFilled,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import "./Downtime.scss";
import { PrimaryModal } from "../../../../../Components/StyledComponents/Modal";
import AddDownTime from "./add";

function DownTime() {
  const [filter, setFilter] = useState(false);
  const [showAddDownTime, setShowAddDownTime] = useState(false);
  const dataSource = [
    {
      key: "1",
      date: "13 Oct 2023 / 7:42 PM",
      category: "Unplanned",
      duration: "5m",
      type: "Maintenance 1",
      reason: "Scheduled Monthly Maintenance",
    },
    {
      key: "2",
      date: "14 Oct 2023 / 7:42 PM",
      category: "Unplanned",
      duration: "5m",
      type: "Maintenance 2",
      reason: "",
    },
    {
      key: "3",
      date: "15 Oct 2023 / 7:42 PM",
      category: "Planned",
      duration: "7m",
      type: "Maintenance",
      reason: "Scheduled Monthly Maintenance",
    },
    {
      key: "4",
      date: "13 Oct 2023 / 7:42 PM",
      category: "Unplanned",
      duration: "5m",
      type: "Maintenance",
      reason: "",
    },
    {
      key: "5",
      date: "13 Oct 2023 / 7:42 PM",
      category: "Unplanned",
      duration: "9m",
      type: "Maintenance",
      reason: "",
    },
    {
      key: "6",
      date: "13 Oct 2023 / 7:42 PM",
      category: "Unplanned",
      duration: "5m",
      type: "Maintenance",
      reason: "Scheduled Monthly Maintenance",
    },
    {
      key: "7",
      date: "13 Oct 2023 / 7:42 PM",
      category: "Unplanned",
      duration: "5m",
      type: "Maintenance",
      reason: "Scheduled Monthly Maintenance",
    },
    {
      key: "8",
      date: "13 Oct 2023 / 7:42 PM",
      category: "Unplanned",
      duration: "5m",
      type: "Under Maintenance",
      reason: "",
    },
    {
      key: "9",
      date: "13 Oct 2023 / 7:42 PM",
      category: "Unplanned",
      duration: "5m",
      type: "Under Maintenance",
      reason: "",
    },
    {
      key: "10",
      date: "13 Oct 2023 / 7:42 PM",
      category: "Unplanned",
      duration: "5m",
      type: "Under Maintenance",
      reason: "Maintenance",
    },
  ];
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      render: (text) => {
        const parts = text.split("/");
        const firstHalf = parts[0]?.trim() || "";
        const secondHalf = parts[1]?.trim() || "";
        return (
          <SimpleFlex
            backgroundcolor="transparent"
            alignitems="center"
            gap="0.2rem"
          >
            <P>{firstHalf}</P>
            <P color="var(--anti-flash-white)">/</P>
            <P>{secondHalf}</P>
          </SimpleFlex>
        );
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      sorter: (a, b) => a.category.localeCompare(b.category),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      sorter: (a, b) => a.duration - b.duration,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      sorter: (a, b) => a.reason.localeCompare(b.reason),
      render: (text, record) => {
        return (
          text || (
            <SimpleFlex backgroundcolor="transparent" gap="0.2rem">
              <P>No Reason :</P>
              <P
                textdecorationline="underline"
                lineheight="126%"
                color="var(--nav-color)"
                cursor="pointer"
              >
                {" "}
                Add Reason
              </P>
            </SimpleFlex>
          )
        );
      },
    },
  ];

  const handleFilterChange = (checked) => {
    setFilter(checked);
  };

  const filteredDataSource = dataSource.filter((record) => {
    return filter ? Boolean(record.reason) : true;
  });

  const noReasonCount = dataSource.filter((record) => !record.reason).length;

  return (
    <SimpleFlex flexdirection="column" backgroundcolor="transparent" gap="1rem">
      <SimpleFlex backgroundcolor="transparent" justifycontent="space-between">
        <SimpleFlex
          backgroundcolor="transparent"
          gap="0.5rem"
          alignitems="center"
        >
          <P fontsize="2.25rem" fontWeight="var(--font-600)">
            Downtime :
          </P>
          <P fontsize="2.25rem" fontWeight="var(--font-300)">
            Total 25m 0s{" "}
          </P>
        </SimpleFlex>
        <SimpleFlex gap="1rem" backgroundcolor="transparent" width="auto">
          <SimpleButton padding="0.75rem 1.625rem" fontWeight="var(--font-500)">
            Save and finish later
          </SimpleButton>
          <PrimaryButton
            padding="0.75rem 1.625rem"
            fontWeight="var(--font-500)"
          >
            <SimpleFlex
              backgroundcolor="transparent"
              cursor="pointer"
              alignitems="center"
              gap="0.5rem"
            >
              Complete <Complete />
            </SimpleFlex>
          </PrimaryButton>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex
        backgroundcolor="transparent"
        flexdirection="column"
        gap="1rem"
      >
        <P fontsize="1.75rem" fontWeight="var(--font-600)">
          Coil Machine 1
        </P>
        <SimpleFlex gap="2rem" backgroundcolor="transparent" width="auto">
          <SimpleFlex backgroundcolor="transparent" width="auto">
            <P
              fontWeight="var(--font-500)"
              color="var(--nav-color)"
              fontsize="0.688rem"
            >
              Product :{" "}
            </P>
            <P fontWeight="var(--font-500)" fontsize="0.75rem">
              &nbsp;Switch Board{" "}
            </P>
          </SimpleFlex>
          <SimpleFlex backgroundcolor="transparent" width="auto">
            <P
              fontWeight="var(--font-500)"
              color="var(--nav-color)"
              fontsize="0.688rem"
            >
              PO :{" "}
            </P>
            <P fontWeight="var(--font-500)" fontsize="0.75rem">
              &nbsp;R-1234{" "}
            </P>
          </SimpleFlex>
          <SimpleFlex backgroundcolor="transparent" width="auto">
            <P
              fontWeight="var(--font-500)"
              color="var(--nav-color)"
              fontsize="0.688rem"
            >
              Shift :{" "}
            </P>
            <P fontWeight="var(--font-500)" fontsize="0.75rem">
              &nbsp;1st Shift{" "}
            </P>
          </SimpleFlex>
          <SimpleFlex backgroundcolor="transparent" width="auto">
            <P
              fontWeight="var(--font-500)"
              color="var(--nav-color)"
              fontsize="0.688rem"
            >
              Date :{" "}
            </P>
            <P fontWeight="var(--font-500)" fontsize="0.75rem">
              &nbsp;04/28/2023{" "}
            </P>
          </SimpleFlex>
          <SimpleFlex backgroundcolor="transparent" width="auto">
            <P
              fontWeight="var(--font-500)"
              color="var(--nav-color)"
              fontsize="0.688rem"
            >
              Unknown Down Time :
            </P>
            <P fontWeight="var(--font-500)" fontsize="0.75rem">
              &nbsp;20 Mins{" "}
            </P>
          </SimpleFlex>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex
        backgroundcolor="transparent"
        height="auto"
        alignitems="center"
        justifycontent="space-between"
      >
        <SimpleFlex
          backgroundcolor="transparent"
          alignitems="center"
          gap="1.5rem"
        >
          <SimpleButton
            className="simple-button"
            padding="0.75rem 1.625rem"
            // loading={true}
          >
            <SimpleFlex
              width="auto"
              gap="0.5rem"
              height="auto"
              borderradius="0.5rem"
              alignitems="center"
              cursor="pointer"
            >
              <P
                fontWeight="var(--font-500)"
                color="var(--nav-color)"
                cursor="pointer"
              >
                Filter
              </P>
              <Filter />
            </SimpleFlex>
          </SimpleButton>
          <SimpleFlex
            width="auto"
            gap="0.5rem"
            padding="1rem"
            height="auto"
            borderradius="0.5rem"
            className="simple-button"
          >
            <P fontWeight="var(--font-500)" color="var(--nav-color)">
              Show with reasons added
            </P>
            <SimpleSwitch
              // loading={true}
              size="small"
              onChange={handleFilterChange}
            ></SimpleSwitch>
          </SimpleFlex>
          <SimpleFlex
            height="auto"
            backgroundcolor="transparent"
            width="auto"
            alignitems="center"
            gap="0.2rem"
          >
            <P
              color="var(--warning-color)"
              fontsize="0.875rem"
              fontWeight="var(--font-500)"
            >
              <InfoCircleFilled />
            </P>
            <P
              color="var(--warning-color)"
              fontsize="0.875rem"
              fontWeight="var(--font-500)"
            >
              {noReasonCount} Downtime have no reasons
            </P>
          </SimpleFlex>
        </SimpleFlex>
        <SimpleButton
          className="simple-button"
          padding="0.75rem 1.625rem"
          // loading={true}
        >
          <SimpleFlex
            height="auto"
            width="auto"
            cursor="pointer"
            gap="0.2rem"
            alignitems="center"
            borderradius="0.5rem"
          >
            <P
              fontWeight="var(--font-500)"
              color="var(--nav-color)"
              cursor="pointer"
            >
              Export{" "}
            </P>
            <DownloadUnderlined />
          </SimpleFlex>
        </SimpleButton>
      </SimpleFlex>
      <SimpleFlex borderradius="18px">
        <Table
          bordered
          className="custom-downtime-table"
          columns={columns}
          dataSource={filteredDataSource}
          pagination={false}
        />
      </SimpleFlex>
      <SimpleFlex
        backgroundcolor="transparent"
        gap="1rem"
        justifycontent="space-between"
      >
        <PrimaryButton
          padding="0.938rem 2.125rem"
          className="primary-button"
          // loading={true}
          onClick={() => {
            setShowAddDownTime(true);
          }}
        >
          Add Downtime
        </PrimaryButton>
        <SimpleFlex backgroundcolor="transparent" gap="1rem" width="auto">
          <SimpleButton
            padding="0.938rem 2.125rem"
            className="simple-button"
            // loading={true}
          >
            Reset
          </SimpleButton>
          <PrimaryButton
            padding="0.938rem 2.125rem"
            className="primary-button"
            // loading={true}
          >
            Save
          </PrimaryButton>
        </SimpleFlex>
      </SimpleFlex>
      <AddDownTime
        showModal={showAddDownTime}
        closeButton={() => setShowAddDownTime(false)}
      />
    </SimpleFlex>
  );
}

export default DownTime;
