import { useState } from "react";
import { PrimaryInput } from "../../../Components/StyledComponents/Input";
import { PrimaryModal } from "../../../Components/StyledComponents/Modal";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { PrimarySelect } from "../../../Components/StyledComponents/Select";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { useDispatch } from "react-redux";
import { editUser } from "../../../redux/slice/Users/user";
import { Form } from "antd";
import { SimpleInput } from "../../../Components/StyledComponents/SimpleInput";

function EditUser({ closeButton, payload }) {
  const dispatch = useDispatch();
  const GROUPS = [
    {
      groupId: 1220,
      name: "Department 1",
    },
    {
      groupId: 1222,
      name: "Department 2",
    },
  ];

  const [selectedRow, setSelectedRow] = useState({
    email: payload?.email || "",
    firstName: payload?.firstName || "",
    lastName: payload?.lastName || "",
    password: payload?.password || "",
    phone: payload?.phone || "",
    whatsapp: payload?.whatsapp || "",
    loginSecret: payload?.loginSecret || "",
    empId: payload?.empId || "",
    externalId: payload?.externalId || "",
    status: payload?.status || 0,
    groups: payload?.groups || [],
    preferences: payload?.preferences || [],
  });

  const handleEditUser = () => {
    try {
      dispatch(editUser(selectedRow));
      console.log("User edited successfully!");
    } catch (error) {
      console.error("Error editing user:", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedRow((prevRow) => ({
      ...prevRow,
      [name]: value,
    }));
  };

  const handleNumberChange = (event) => {
    const { name, value } = event.target;
    const onlyNumbers = value.replace(/\D/g, "");

    if (onlyNumbers !== "" && !isNaN(onlyNumbers)) {
      setSelectedRow((prevRow) => ({
        ...prevRow,
        [name]: onlyNumbers,
      }));
    }
  };

  const handleOk = () => {
    handleEditUser();
    closeButton();
  };

  const handleCancel = () => {
    closeButton();
  };

  return (
    <PrimaryModal
      centered
      title="Edit User"
      open
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Save Changes"
      maskClosable={false}
    >
      <Form>
        <SimpleFlex flexdirection="column" gap="1rem">
          <SimpleFlex gap="1rem" alignitems="center">
            <SimpleFlex flexdirection="column" gap="0.5rem">
              <P color="var(--nav-color)" fontsize="0.75rem">
                First Name
              </P>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please input First Name!",
                  },
                ]}
                initialValue={selectedRow?.firstName}
                className="w-100 margin-0"
              >
                <SimpleInput
                  name="name"
                  className="input-border-bottom"
                  bordered={false}
                  onChange={handleInputChange}
                  borderradius="0"
                  padding="0.3rem 0"
                  fontsize="1rem"
                />
              </Form.Item>
            </SimpleFlex>
            <SimpleFlex flexdirection="column" gap="0.5rem">
              <P color="var(--nav-color)" fontsize="0.75rem">
                Last Name
              </P>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please input Last Name!",
                  },
                ]}
                initialValue={selectedRow?.lastName}
                className="w-100 margin-0"
              >
                <SimpleInput
                  name="name"
                  className="input-border-bottom"
                  bordered={false}
                  onChange={handleInputChange}
                  borderradius="0"
                  padding="0.3rem 0"
                  fontsize="1rem"
                />
              </Form.Item>
            </SimpleFlex>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="0.5rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Email
            </P>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input Email!",
                },
              ]}
              initialValue={selectedRow?.email}
              className="w-100 margin-0"
            >
              <SimpleInput
                name="email"
                className="input-border-bottom"
                bordered={false}
                onChange={handleInputChange}
                borderradius="0"
                padding="0.3rem 0"
                fontsize="1rem"
              />
            </Form.Item>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Phone Number
            </P>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input Phone Number!",
                },
              ]}
              initialValue={selectedRow?.phone}
              className="w-100 margin-0"
            >
              <SimpleInput
                name="phone"
                className="input-border-bottom"
                bordered={false}
                onChange={handleNumberChange}
                borderradius="0"
                padding="0.3rem 0"
                fontsize="1rem"
              />
            </Form.Item>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Whatsapp Number
            </P>
            <PrimaryInput
              bordered={false}
              name="whatsapp"
              defaultValue={selectedRow?.whatsapp}
              onChange={handleInputChange}
              style={{ borderBottom: "1px solid black" }}
              className="user-input"
              padding="0 0 0.3rem 0"
              color="var(--black)"
              borderradius="0"
              fontsize="1rem"
            />
          </SimpleFlex>
          <SimpleFlex gap="1rem">
            <SimpleFlex flexdirection="column" gap="1rem">
              <P color="var(--nav-color)" fontsize="0.75rem">
                Employee ID
              </P>
              <PrimaryInput
                bordered={false}
                name="empId"
                defaultValue={selectedRow?.empId}
                onChange={handleInputChange}
                style={{ borderBottom: "1px solid black" }}
                className="user-input"
                padding="0 0 0.3rem 0"
                color="var(--black)"
                borderradius="0"
                fontsize="1rem"
              />
            </SimpleFlex>
            <SimpleFlex flexdirection="column" gap="1rem">
              <P color="var(--nav-color)" fontsize="0.75rem">
                External ID
              </P>
              <PrimaryInput
                bordered={false}
                name="externalId"
                defaultValue={selectedRow?.externalId}
                onChange={handleInputChange}
                style={{ borderBottom: "1px solid black" }}
                className="user-input"
                padding="0 0 0.3rem 0"
                color="var(--black)"
                borderradius="0"
                fontsize="1rem"
              />
            </SimpleFlex>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Groups
            </P>
            <Form.Item
              name="groups"
              rules={[
                {
                  required: true,
                  message: "Please input Groups!",
                },
              ]}
              initialValue={selectedRow?.groups.map((group) => group.groupId)}
              className="w-100 margin-0"
            >
              <PrimarySelect
                className="multi-select"
                mode="multiple"
                bordered={false}
                allowClear={true}
                showSearch
                placeholder="Search to Select"
                // onChange={(value) => handleSelectChange(value, "factories")}
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={GROUPS}
                fieldNames={{
                  value: "groupId",
                  label: "name",
                }}
              />
            </Form.Item>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="1rem">
            <SimpleFlex flexdirection="column" gap="1rem">
              {selectedRow?.preferences.map((item) => {
                const capitalizedName =
                  item.name.charAt(0).toUpperCase() + item.name.slice(1);

                let name = item.name
                  .replace(/-([a-z])/g, (match) => match.toUpperCase())
                  .replaceAll("-", "");

                const input = {
                  language: (
                    <Form.Item
                      name="language"
                      rules={[
                        {
                          required: true,
                          message: "Please input Phone Number!",
                        },
                      ]}
                      className="w-100 margin-0"
                    >
                      <PrimarySelect
                        className="multi-select"
                        bordered={false}
                        allowClear={true}
                        showSearch
                        placeholder="Search to Select"
                        // onChange={(value) => handleSelectChange(value, "factories")}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={[
                          { value: "en-us", label: "EN-US" },
                          { value: "arsa", label: "AR-SA" },
                        ]}
                      />
                    </Form.Item>
                  ),
                  dateFormat: (
                    <Form.Item
                      name="dateFormat"
                      rules={[
                        {
                          required: true,
                          message: "Please input Date Format!",
                        },
                      ]}
                      className="w-100 margin-0"
                    >
                      <PrimarySelect
                        className="multi-select"
                        bordered={false}
                        allowClear={true}
                        showSearch
                        placeholder="Search to Select"
                        // onChange={(value) => handleSelectChange(value, "factories")}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={[
                          {
                            value: "UTC",
                            label: "UTC",
                          },
                          {
                            value: "ISO",
                            label: "ISO",
                          },
                        ]}
                      />
                    </Form.Item>
                  ),
                  timezone: (
                    <Form.Item
                      name="dateFormat"
                      rules={[
                        {
                          required: true,
                          message: "Please input Date Format!",
                        },
                      ]}
                      className="w-100 margin-0"
                      initialValue={item?.value}
                    >
                      <PrimarySelect
                        className="multi-select"
                        bordered={false}
                        allowClear={true}
                        showSearch
                        placeholder="Search to Select"
                        // onChange={(value) => handleSelectChange(value, "factories")}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={[
                          {
                            value: "UTC",
                            label: "UTC",
                          },
                          {
                            value: "ISO",
                            label: "ISO",
                          },
                        ]}
                      />
                    </Form.Item>
                  ),
                  emailLoginAlert: (
                    <Form.Item
                      name="dateFormat"
                      rules={[
                        {
                          required: true,
                          message: "Please input Date Format!",
                        },
                      ]}
                      className="w-100 margin-0"
                    >
                      {/* Select */}
                    </Form.Item>
                  ),
                };

                return (
                  <SimpleFlex flexdirection="column" gap="0.5rem">
                    <P color="var(--nav-color)" fontsize="0.75rem">
                      {capitalizedName}
                    </P>
                    <SimpleFlex>{input[name]}</SimpleFlex>
                  </SimpleFlex>
                );
              })}
            </SimpleFlex>
          </SimpleFlex>
        </SimpleFlex>
      </Form>
    </PrimaryModal>
  );
}

export default EditUser;
