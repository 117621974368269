import { useEffect, useState } from "react";
import { PrimaryModal } from "../../../../Components/StyledComponents/Modal";
import { SimpleFlex } from "../../../../Components/StyledComponents/SimpleFlex";
import { SimpleButton } from "../../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../../Components/StyledComponents/Button";
import { P } from "../../../../Components/StyledComponents/Paragraph";
import { PrimaryTooltip } from "../../../../Components/StyledComponents/Tooltip";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { PrimarySelect } from "../../../../Components/StyledComponents/Select";
import { Table } from "antd";
import { ReactComponent as DeleteOutlined } from "../../../../assets/svg/deleteOutlined.svg";
import { PrimaryInput } from "../../../../Components/StyledComponents/Input";
import "./EditMetricCard.scss";

function EditMetricCard({ showModal, closeButton }) {
  const [dataSource, setDataSource] = useState([
    {
      key: Date.now(),
      condition: "",
      value: "",
      color: "",
    },
  ]);
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [visible, setVisible] = useState(showModal);

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const columns = [
    {
      title: () => (
        <SimpleFlex
          backgroundcolor="transparent"
          alignitems="center"
          cursor="pointer"
          gap="0.3rem"
        >
          <PrimaryTooltip title={"Unique"} placement="top">
            <P color="var(--nav-color)" cursor="pointer">
              <InfoCircleOutlined />
            </P>
          </PrimaryTooltip>
          Condition
        </SimpleFlex>
      ),
      dataIndex: "condition",
      key: "condition",
      sorter: (a, b) => a?.condition?.localeCompare(b?.condition),
      render: (text, record) => (
        <PrimarySelect
          className="w-100"
          bordered={false}
          showSearch
          allowClear
          placeholder="Select"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input)
          }
          onChange={(value) => {
            const newData = [...dataSource];
            const index = newData.findIndex((item) => record.key === item.key);
            const item = newData[index];
            newData.splice(index, 1, {
              ...item,
              condition: value,
            });
            setDataSource(newData);
          }}
          options={[
            {
              value: "informative",
              label: "informative",
            },
            {
              value: "critical",
              label: "critical",
            },
            {
              value: "normal",
              label: "normal",
            },
          ]}
        ></PrimarySelect>
      ),
    },
    {
      title: () => (
        <SimpleFlex
          backgroundcolor="transparent"
          alignitems="center"
          cursor="pointer"
          gap="0.3rem"
        >
          <PrimaryTooltip title={"Unique"} placement="top">
            <P color="var(--nav-color)" cursor="pointer">
              <InfoCircleOutlined />
            </P>
          </PrimaryTooltip>
          Value
        </SimpleFlex>
      ),
      dataIndex: "value",
      key: "value",
      width: "6rem",
      align: "center",
      render: (_, record) => (
        <PrimaryInput
          padding="0.5rem"
          onChange={(e) => {
            const newData = [...dataSource];
            const index = newData.findIndex((item) => record.key === item.key);
            const item = newData[index];
            newData.splice(index, 1, {
              ...item,
              value: e.target.value,
            });
            setDataSource(newData);
          }}
        />
      ),
    },
    {
      title: () => (
        <SimpleFlex
          backgroundcolor="transparent"
          alignitems="center"
          cursor="pointer"
          gap="0.3rem"
        >
          <PrimaryTooltip title={"Unique"} placement="top">
            <P color="var(--nav-color)" cursor="pointer">
              <InfoCircleOutlined />
            </P>
          </PrimaryTooltip>
          Color
        </SimpleFlex>
      ),
      dataIndex: "color",
      key: "color",
      render: (text, record) => (
        <PrimarySelect
          className="w-100"
          bordered={false}
          showSearch
          allowClear
          placeholder="Select"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input)
          }
          onChange={(value) => {
            const newData = [...dataSource];
            const index = newData.findIndex((item) => record.key === item.key);
            const item = newData[index];
            newData.splice(index, 1, {
              ...item,
              color: value,
            });
            setDataSource(newData);
          }}
          options={[
            {
              value: "blue",
              label: (
                <SimpleFlex
                  alignitems="center"
                  gap="0.5rem"
                  backgroundcolor="transparent"
                >
                  <SimpleFlex
                    backgroundcolor="var(--navy-seal-color)"
                    width="1.5rem"
                    height="1.5rem"
                    borderradius="0.3rem"
                  />
                  Blue
                </SimpleFlex>
              ),
            },
            {
              value: "red",
              label: (
                <SimpleFlex
                  alignitems="center"
                  gap="0.5rem"
                  backgroundcolor="transparent"
                >
                  <SimpleFlex
                    backgroundcolor="var(--warning-color)"
                    width="1.5rem"
                    height="1.5rem"
                    borderradius="0.3rem"
                  />
                  Red
                </SimpleFlex>
              ),
            },
            {
              value: "gray",
              label: (
                <SimpleFlex
                  alignitems="center"
                  gap="0.5rem"
                  backgroundcolor="transparent"
                >
                  <SimpleFlex
                    backgroundcolor="var(--columbia-gray-color)"
                    width="1.5rem"
                    height="1.5rem"
                    borderradius="0.3rem"
                  />
                  Gray
                </SimpleFlex>
              ),
            },
          ]}
        ></PrimarySelect>
      ),
    },
    {
      title: "Actions",
      key: "action",
      width: "2rem",
      align: "center",
      render: (text, record) => (
        <SimpleButton
          padding="0.8rem"
          borderradius="50%"
          onClick={() => deleteRow(record.key)}
        >
          <DeleteOutlined />
        </SimpleButton>
      ),
    },
  ];

  const addRow = () => {
    setDataSource([
      ...dataSource,
      { key: Date.now(), condition: "", value: "", color: "" },
    ]);
  };

  const deleteRow = (key) => {
    setDataSource(dataSource.filter((row) => row.key !== key));
  };

  useEffect(() => {
    setVisible(showModal);
  }, [showModal]);

  const handleOk = () => {
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleClose = () => {
    setDataSource([
      {
        key: Date.now(),
        condition: "",
        value: "",
        color: "",
      },
    ]);
    setSelectedOption(null);
    setSelectedStatus(null);
    setVisible(false);
    closeButton();
  };

  const Modalfooter = (
    <SimpleFlex justifycontent="space-between">
      <SimpleButton padding="0.5rem 1.5rem" onClick={addRow}>
        <SimpleFlex gap="0.3rem" alignitems="center" cursor="pointer">
          Add Rule{" "}
          <P cursor="pointer">
            <PlusOutlined />
          </P>
        </SimpleFlex>
      </SimpleButton>
      <SimpleFlex gap="1rem" width="auto">
        <SimpleButton onClick={handleCancel} padding="0.5rem 1.5rem">
          Cancel
        </SimpleButton>
        <PrimaryButton onClick={handleOk} padding="0.5rem 1.5rem">
          Update
        </PrimaryButton>
      </SimpleFlex>
    </SimpleFlex>
  );

  return (
    <PrimaryModal
      centered
      className="add-comment-modal"
      title="Edit field for"
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      onClose={handleCancel}
      footer={Modalfooter}
      maskClosable={false}
      minwidth="40rem"
    >
      <SimpleFlex flexdirection="column" gap="1rem">
        <SimpleFlex flexdirection="column" gap="0.5rem">
          <SimpleFlex justifycontent="space-between">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Select Metric
            </P>
            <SimpleFlex width="auto" gap="0.3rem">
              <PrimaryTooltip title={"Unique"} placement="top">
                <P color="var(--nav-color)" cursor="pointer">
                  <InfoCircleOutlined />
                </P>
              </PrimaryTooltip>
              <P fontsize="0.75rem" color="var(--nav-color)">
                Measurable standard used for evaluation
              </P>
            </SimpleFlex>
          </SimpleFlex>
          <SimpleFlex>
            <PrimarySelect
              className="w-100 metric-card-select select"
              bordered={false}
              allowClear={true}
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              onChange={handleStatusChange}
              value={selectedStatus}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "notIdentified",
                  label: "Not Identified",
                },
                {
                  value: "closed",
                  label: "Closed",
                },
                {
                  value: "communicated",
                  label: "Communicated",
                },
                {
                  value: "identified",
                  label: "Identified",
                },
                {
                  value: "resolved",
                  label: "Resolved",
                },
                {
                  value: "cancelled",
                  label: "Cancelled",
                },
              ]}
            />
          </SimpleFlex>
        </SimpleFlex>
        <SimpleFlex flexdirection="column" gap="0.5rem">
          <SimpleFlex justifycontent="space-between">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Select Label
            </P>
            <SimpleFlex width="auto" gap="0.3rem">
              <PrimaryTooltip title={"Unique"} placement="top">
                <P color="var(--nav-color)" cursor="pointer">
                  <InfoCircleOutlined />
                </P>
              </PrimaryTooltip>
              <P fontsize="0.75rem" color="var(--nav-color)">
                A tag that provides information and identifies
              </P>
            </SimpleFlex>
          </SimpleFlex>
          <SimpleFlex>
            <PrimarySelect
              className="w-100 metric-card-select select"
              bordered={false}
              allowClear={true}
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              onChange={handleOptionChange}
              value={selectedOption}
              options={[
                {
                  value: "notIdentified",
                  label: "Not Identified",
                },
                {
                  value: "closed",
                  label: "Closed",
                },
                {
                  value: "communicated",
                  label: "Communicated",
                },
                {
                  value: "identified",
                  label: "Identified",
                },
                {
                  value: "resolved",
                  label: "Resolved",
                },
                {
                  value: "cancelled",
                  label: "Cancelled",
                },
              ]}
            />
          </SimpleFlex>
        </SimpleFlex>
        <SimpleFlex flexdirection="column" gap="0.5rem">
          <SimpleFlex justifycontent="space-between">
            <P fontsize="1.125rem" color="var(--nav-color)">
              Rules
            </P>
            <SimpleFlex width="auto" gap="0.3rem">
              <PrimaryTooltip title={"Unique"} placement="top">
                <P color="var(--nav-color)" cursor="pointer">
                  <InfoCircleOutlined />
                </P>
              </PrimaryTooltip>
              <P fontsize="0.75rem" color="var(--nav-color)">
                Edit rules directly in table rows
              </P>
            </SimpleFlex>
          </SimpleFlex>
          <SimpleFlex>
            <Table
              className="w-100"
              bordered
              columns={columns}
              dataSource={dataSource}
              pagination={false}
            />
          </SimpleFlex>
        </SimpleFlex>
      </SimpleFlex>
    </PrimaryModal>
  );
}

export default EditMetricCard;
