import React, { useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Form, message } from "antd";
import { ReactComponent as Proton } from "../../assets/svg/Proton.svg";
import { PrimaryButton } from "../../Components/StyledComponents/Button";
import { P } from "../../Components/StyledComponents/Paragraph";
import { PrimaryFlex } from "../../Components/StyledComponents/Flex";
import { PrimaryTooltip } from "../../Components/StyledComponents/Tooltip";
import { PasswordInput } from "../../Components/StyledComponents/PasswordInput";
import RegisterPageBg from "../../assets/png/RegisterPageBg.png";
import transition from "../../Transition";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import EntryPageCarousel from "../../Components/EntryPageCarousel/EntryPageCarousel";
import { SimpleFlex } from "../../Components/StyledComponents/SimpleFlex";
import "./ResetPassword.css";
import { resetPassword } from "../../redux/slice/ResetPassword/resetPassword";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../Components/LanguageSwitcher/LanguageSwitcher";

function ResetPassword() {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const payload = {
        newPassword: values?.newPassword,
        confirmPassword: values?.confirmPassword,
        email: "", //fetch email from body when user is redirected to this page
        token: "", //fetch token from url when user is redirected to this page
      };

      await dispatch(resetPassword(payload)).unwrap();

      setLoading(false);
      // navigate("/proton");
    } catch (error) {
      messageApi.open({
        type: "error",
        content: t("loginFailed"),
      });
      setLoading(false);
    }
  };

  return (
    <div
      className="container"
      style={{
        background: `url("${RegisterPageBg}"), lightgray 50% / cover no-repeat`,
      }}
    >
      {contextHolder}
      <SimpleFlex
        justifycontent="flex-end"
        height="auto"
        width="auto"
        backgroundcolor="transparent"
        position="absolute"
        className="positioning"
      >
        <LanguageSwitcher />
      </SimpleFlex>
      <div
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          zIndex: 1,
          opacity: 0.85,
          background: "#21223D",
        }}
      ></div>
      <div className="d-flex h-100">
        <Card
          style={{
            width: "auto",
            margin: "auto",
            zIndex: 2,
          }}
        >
          <PrimaryFlex flexdirection="column" gap="1rem">
            <PrimaryFlex justifycontent="space-between">
              <Proton />
            </PrimaryFlex>
            <PrimaryFlex gap="6.25rem">
              <PrimaryFlex>
                <EntryPageCarousel />
              </PrimaryFlex>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                <PrimaryFlex flexdirection="column" gap="1.875rem">
                  <PrimaryFlex flexdirection="column">
                    <P fontWeight="bolder" fontsize="2.5rem">
                      {t("resetPassword")}
                    </P>
                  </PrimaryFlex>
                  <PrimaryFlex flexdirection="column">
                    <SimpleFlex flexdirection="column">
                      <P
                        fontWeight="300"
                        fontsize="0.938rem"
                        color="var(--nav-color)"
                        margin="0 0 0.5rem 1rem"
                      >
                        {t("newPassword")}
                        <PrimaryTooltip title="Mandatory" placement="top">
                          <InfoCircleOutlined
                            style={{ marginLeft: "0.25rem" }}
                          />
                        </PrimaryTooltip>
                      </P>
                      <Form.Item
                        name="newPassword"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Password!",
                          },
                        ]}
                      >
                        <PasswordInput
                          width="22.875rem"
                          height="3.375rem"
                          placeholder={t("newPassword")}
                        />
                      </Form.Item>
                    </SimpleFlex>
                    <SimpleFlex flexdirection="column" margin="0 0 1rem 0">
                      <P
                        fontWeight="300"
                        fontsize="0.938rem"
                        color="var(--nav-color)"
                        margin="0 0 0.5rem 1rem"
                      >
                        {t("confirmPassword")}
                        <PrimaryTooltip title="Mandatory" placement="top">
                          <InfoCircleOutlined
                            style={{ marginLeft: "0.25rem" }}
                          />
                        </PrimaryTooltip>
                      </P>
                      <Form.Item
                        name="confirmPassword"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Password!",
                          },
                        ]}
                      >
                        <PasswordInput
                          width="22.875rem"
                          height="3.375rem"
                          placeholder={t("confirmPassword")}
                        />
                      </Form.Item>
                    </SimpleFlex>

                    <Form.Item>
                      <PrimaryButton
                        fontWeight="600"
                        padding="0 0.625rem"
                        width="22.875rem"
                        height="3.125rem"
                        fontsize="1.125rem"
                        htmlType="submit"
                        loading={loading}
                        justifycontent="center"
                      >
                        {t("submit")}
                      </PrimaryButton>
                    </Form.Item>
                  </PrimaryFlex>
                </PrimaryFlex>
              </Form>
            </PrimaryFlex>
          </PrimaryFlex>
        </Card>
      </div>
    </div>
  );
}
export default transition(ResetPassword);
