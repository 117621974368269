import { useEffect, useState } from "react";
import { PrimaryModal } from "../../../../Components/StyledComponents/Modal";
import { P } from "../../../../Components/StyledComponents/Paragraph";
import { PrimarySelect } from "../../../../Components/StyledComponents/Select";
import { SimpleFlex } from "../../../../Components/StyledComponents/SimpleFlex";
import { useDispatch } from "react-redux";
// import { addPlant } from "../../../../redux/slice/Plants/plant";
import { SimpleButton } from "../../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../../Components/StyledComponents/Button";
import { Form } from "antd";
import "./AddRejectionCode.scss";
import TextArea from "antd/es/input/TextArea";
import { PrimaryTooltip } from "../../../../Components/StyledComponents/Tooltip";
import { InfoCircleOutlined } from "@ant-design/icons";

function AddRejectionCode({ closeButton }) {
  const dispatch = useDispatch();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedRow, setSelectedRow] = useState({
    rejectionCode: "",
    description: "",
    severity: "",
  });

  const handleSelectChange = (selectedValues, field) => {
    setSelectedRow((prevState) => ({
      ...prevState,
      [field]: selectedValues ?? "",
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedRow((prevState) => {
      const newState = {
        ...prevState,
        [name]: value,
      };

      return newState;
    });
  };

  const handleOk = async () => {
    setLoading(true);

    try {
      // dispatch(addPlant(selectedRow));
      await new Promise((resolve) => setTimeout(resolve, 2000));
      console.log("Plant added successfully!");
    } catch (error) {
      console.error("Error adding plant:", error);
    } finally {
      setLoading(false);
      closeButton();
    }
  };

  const handleCancel = () => {
    closeButton();
  };

  useEffect(() => {
    const { rejectionCode, description, severity } = selectedRow;
    const formComplete =
      rejectionCode?.trim() !== "" &&
      description?.trim() !== "" &&
      severity?.trim() !== "";

    setIsFormComplete(formComplete);
  }, [selectedRow]);

  const AddModalfooter = (
    <SimpleFlex
      margin="1.5rem 0 0 0"
      alignitems="center"
      justifycontent="flex-end"
    >
      <SimpleFlex gap="1rem" width="auto">
        <SimpleButton onClick={handleCancel} padding="0.5rem 1rem">
          Cancel
        </SimpleButton>
        <PrimaryButton
          disabled={!isFormComplete}
          loading={loading}
          onClick={handleOk}
          padding="0.5rem 1rem"
        >
          Add
        </PrimaryButton>
      </SimpleFlex>
    </SimpleFlex>
  );

  return (
    <PrimaryModal
      centered
      title="Add Rejection Code"
      open
      onOk={handleOk}
      onCancel={handleCancel}
      footer={AddModalfooter}
      maskClosable={false}
    >
      <Form>
        <SimpleFlex flexdirection="column" gap="1rem">
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Rejection Code
            </P>
            <Form.Item
              name="rejectionCode"
              className="margin-0"
              rules={[
                {
                  required: true,
                  message: "Please input Rejection Code!",
                },
              ]}
              initialValue={selectedRow?.rejectionCode}
            >
              <PrimarySelect
                showSearch
                allowClear
                onChange={(value) => {
                  handleSelectChange(value, "rejectionCode");
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "stop-10",
                    label: "Stop-10",
                  },
                  {
                    value: "stop-20",
                    label: "Stop-20",
                  },
                  {
                    value: "stop-30",
                    label: "Stop-30",
                  },
                ]}
              />
            </Form.Item>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="1rem">
            <SimpleFlex justifycontent="space-between">
              <P color="var(--nav-color)" fontsize="0.75rem">
                Description
              </P>

              <SimpleFlex width="auto" gap="0.3rem" alignitems="center">
                <PrimaryTooltip
                  title={"Unique"}
                  placement="top"
                  fontsize="0.75rem"
                >
                  <P color="var(--nav-color)" cursor="pointer">
                    <InfoCircleOutlined />
                  </P>
                </PrimaryTooltip>
                <P color="var(--nav-color)" fontsize="0.75rem">
                  Max 500 Characters
                </P>
              </SimpleFlex>
            </SimpleFlex>
            <Form.Item
              name="description"
              className="margin-0 resize-none"
              rules={[
                {
                  required: true,
                  message: "Please input description!",
                },
              ]}
              initialValue={selectedRow?.description}
            >
              <TextArea
                name="description"
                onChange={handleInputChange}
                rows={4}
                placeholder="maxLength is 500"
                maxLength={500}
              />
            </Form.Item>
          </SimpleFlex>

          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Severity
            </P>
            <Form.Item
              name="severity"
              className="margin-0"
              rules={[
                {
                  required: true,
                  message: "Please input Severity!",
                },
              ]}
              initialValue={selectedRow?.severity}
            >
              <PrimarySelect
                showSearch
                allowClear
                onChange={(value) => {
                  handleSelectChange(value, "severity");
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "high",
                    label: "High",
                  },
                  {
                    value: "medium",
                    label: "Medium",
                  },
                  {
                    value: "critical",
                    label: "Critical",
                  },
                ]}
              />
            </Form.Item>
          </SimpleFlex>
        </SimpleFlex>
      </Form>
    </PrimaryModal>
  );
}
export default AddRejectionCode;
