import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

// Action to fetch all plants
export const fetchTags = createAsyncThunk("fetchTags", async () => {
  // const response = await fetch("https://your-api-endpoint/plants");
  // return response.json();

  // throw new Error("");
  // await new Promise((resolve) => setTimeout(resolve, 2000));
  return [
    {
      key: "1",
      name: "St louis mk00048",
      dataType: "XML",
      unit: "Volts",
      description: "Downtime tag",
      lastReadat: "12/10/2023",
      dataSourceType: "File System",
      channelSource: "OPC Server 2",
    },
    {
      key: "2",
      name: "St louis mk00048",
      dataType: "XML",
      unit: "Volts",
      description: "Downtime tag",
      lastReadat: "12/10/2023",
      dataSourceType: "File System",
      channelSource: "OPC Server 2",
    },
    {
      key: "3",
      name: "St louis mk00048",
      dataType: "XML",
      unit: "Volts",
      description: "Downtime tag",
      lastReadat: "12/10/2023",
      dataSourceType: "File System",
      channelSource: "OPC Server 2",
    },
  ];
});

// Action to add a new plant
export const addTags = createAsyncThunk("addTags", async (newTag) => {
  const response = await fetch("https://your-api-endpoint/plants", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newTag),
  });
  return response.json();
});

// Action to delete a plant
export const deleteTags = createAsyncThunk("deleteTags", async (tagId) => {
  const response = await fetch(`https://your-api-endpoint/plants/${tagId}`, {
    method: "DELETE",
  });
  return response.json();
});

// Action to edit a plant
export const editTags = createAsyncThunk("editTags", async (editTags) => {
  // const response = await fetch(
  //   `https://your-api-endpoint/plants/${editedPlant.key}`,
  //   {
  //     method: "PUT",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(editTags),
  //   }
  // );
  // return response.json();

  // plant is edited successfully
  const dispatch = useDispatch();
  dispatch(fetchTags());
  return editTags;
});

const tagSlice = createSlice({
  name: "tags",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    selectedTag: null,
  },
  reducers: {
    setSelectedTag: (state, action) => {
      state.selectedTag = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTags.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTags.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchTags.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(addTags.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
    });
    builder.addCase(deleteTags.fulfilled, (state, action) => {
      state.data = state.data.filter((tag) => tag.key !== action.payload.key);
    });
    builder.addCase(editTags.fulfilled, (state, action) => {
      state.data = state.data.map((tag) =>
        tag.key === action.payload.key ? action.payload : tag
      );
      state.selectedTag = null; // Clear selected plant after edit
    });
  },
});

export const { setSelectedTag } = tagSlice.actions;

export default tagSlice.reducer;
