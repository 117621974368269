import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const errorMessages = {
  en: {
    loginFailed: "User is not logged in",
    resetPwdFailed: "Error sending Password reset link to your email",
  },
  arsa: {
    loginFailed: "لم يتم تسجيل دخول المستخدم",
    resetPwdFailed:
      "حدث خطأ أثناء إرسال رابط إعادة تعيين كلمة المرور إلى بريدك الإلكتروني",
  },
};

const successMessages = {
  en: {
    loginSuccess: "User logged in successfully",
    resetPwdSuccess: "Password reset link is sent to your email",
  },
  arsa: {
    loginSuccess: "قام المستخدم بتسجيل الدخول بنجاح",
    resetPwdSuccess:
      "يتم إرسال رابط إعادة تعيين كلمة المرور إلى بريدك الإلكتروني",
  },
};

const sideMenu = {
  en: {
    dashboards: "Dashboards",
    liveDashboard: "Live Dashboard",
    standard: "Standard",
    displayBoard: "Display Board",
    alerts: "Alerts",
    conditionAndRules: "Condition and Rules",
    industrialManagement: "Industrial management",
    plants: "Plants",
    products: "Products",
    lines: "Lines",
    machines: "Machines",
    tags: "Tags",
    customLists: "Custom Lists",
    userManagement: "User management",
    users: "Users",
    roles: "Roles",
    shifts: "Shifts",
    holidays: "Holidays",
    gatewayConfiguration: "Gateway Configuration",
    closeMenu: "Close Menu",
  },
  arsa: {
    dashboards: "لوحات المعلومات",
    liveDashboard: "لوحة القيادة الحية",
    standard: "معيار",
    displayBoard: "لوحة العرض",
    alerts: "التنبيهات",
    conditionAndRules: "الشروط والقواعد",
    industrialManagement: "الإدارة الصناعية",
    plants: "النباتات",
    products: "منتجات",
    lines: "خطوط",
    machines: "آلات",
    tags: "العلامات",
    customLists: "قوائم مخصصة",
    userManagement: "إدارةالمستخدم",
    users: "المستخدمين",
    roles: "الأدوار",
    shifts: "التحولات",
    holidays: "العطل",
    gatewayConfiguration: "تكوين البوابة",
    closeMenu: "إغلاق القائمة",
  },
};

const pageTitles = {
  en: {
    standardDashboardManagement: "Standard Dashboard Management",
    displayBoardManagement: "Display Board Management",
    plantManagement: "Plant Management",
    productManagement: "Product Management",
    lineManagement: "Line Management",
    allMachines: "All Machines",
    tagManagement: "Tag Management",
    userManagement: "User Management",
    roleManagement: "Role Management",
    holidayManagement: "Holiday Management",
  },
  arsa: {
    standardDashboardManagement: "إدارة لوحة القيادة القياسية",
    displayBoardManagement: "إدارة لوحة العرض",
    plantManagement: "إدارة المصنع",
    productManagement: "ادارة المنتج",
    lineManagement: "إدارة الخط",
    allMachines: "جميع الآلات",
    tagManagement: "إدارة العلامات",
    userManagement: "إدارةالمستخدم",
    roleManagement: "إدارة الأدوار",
    holidayManagement: "إدارة العطلات",
  },
};

const registrationPage = {
  en: {
    createPassword: "Create Password",
    regPageMessage: "Create Password to login",
    passwordErrMsg: "Please input your Password!",
    termsAndCondErrMsg: "Please accept the terms and conditions",
  },
  arsa: {
    createPassword: "إنشاء كلمة المرور",
    regPageMessage: "إنشاء كلمة المرور لتسجيل الدخول",
    passwordErrMsg: "الرجاء إدخال كلمة المرور الخاصة بك!",
    termsAndCondErrMsg: "يرجى قبول الشروط والأحكام",
  },
};

const loginPageMessages = {
  en: {
    loginPageMessage: "Please choose any one method to login",
    loginWithQr: "Login with QR Code",
    qrMsg1: "Scan your QR Code with the scanner",
    qrMsg2: "connected to your computer",
    loginWithEmail: "Login with Email ID",
    emailLoginMsg: "Enter your email ID and Password to login",
  },
  arsa: {
    loginPageMessage: "الرجاء اختيار أي طريقة لتسجيل الدخول",
    loginWithQr: "تسجيل الدخول باستخدام رمز الاستجابة السريعة",
    qrMsg1: "قم بمسح رمز الاستجابة السريعة الخاص بك باستخدام الماسح الضوئي",
    qrMsg2: "متصل بجهاز الكمبيوتر الخاص بك",
    loginWithEmail: "تسجيل الدخول باستخدام معرف البريد الإلكتروني",
    emailLoginMsg: "أدخل معرف البريد الإلكتروني وكلمة المرور لتسجيل الدخول",
  },
};

const loginQrPage = {
  en: { employeeCode: "Employee Code" },
  arsa: { employeeCode: "رمز الموظف" },
};

const loginPage = {
  en: {
    login: "Login",
    emailID: "Email ID",
    userName: "Username",
    enterPassword: "Enter Password",
    password: "Password",
    forgotPassword: "Forgot Password",
    terms: "I Accept Terms & Conditions & Privacy Policy",
    submit: "Submit",
  },
  arsa: {
    login: "تسجيل الدخول",
    emailID: "عنوان الايميل",
    userName: "اسم المستخدم",
    enterPassword: "أدخل كلمة المرور",
    password: "كلمة المرور",
    forgotPassword: "هل نسيت كلمة السر",
    terms: "أوافق على الشروط والأحكام وسياسة الخصوصية",
    submit: "يُقدِّم",
  },
};

const resetPwdPage = {
  en: {
    resetPassword: "Reset Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
  },
  arsa: {
    resetPassword: "إعادة تعيين كلمة المرور",
    newPassword: "كلمة المرور الجديدة",
    confirmPassword: "تأكيد كلمة المرور",
  },
};

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        liveBoard: "Live Board",
        report: "Report",
        action: "Action",
        overallEquipment: "Overall Equipment",
        effectiveness: "Effectiveness",
        availability: "Availability",
        performance: "Performance",
        quality: "Quality",
        shift: "Shift",
        date: "Date",
        po: "PO",
        completed: "Completed",
        product: "Product",
        currentProductionStats: "Current Production Stats",
        currentPO: "Current PO",
        currentPartNo: "Current Part No",
        status: "Status",
        actualCycleTime: "Actual Cycle Time",
        estTimeRemaining: "Est. Time Remaining",
        partsMin: "Parts/Min",
        partsAhead: "Parts Ahead",
        reporting: "Reporting",
        reportDownTime: "Report Down Time",
        reportChangeOver: "Report Change Over",
        reportQuality: "Report Quality",
        stopBreakTime: "Stop Break Time",
        startBreakTime: "Start Break Time",
        notifications: "Notifications",
        severity: "Severity",
        reason: "Reason",
        duration: "Duration",
        type: "Type",
        dateTime: "Date/Time",
        category: "Category",
        viewAll: "View All",
        critical: "Critical",
        normal: "Normal",
        informative: "Informative",
        rejectionHistory: "Rejection History",
        reasonType: "Reason Type",
        rejectionCount: "Rejection Count",
        productionOrder: "Production Order",
        feeds: "Feeds",
        sender: "Sender",
        more: "More",
        edit: "Edit",
        name: "Name",
        description: "Description",
        view: "View",
        add: "Add",
        dashboard: "Dashboard",
        delete: "Delete",
        share: "Share",
        duplicate: "Duplicate",
        search: "Search",
        addDashboard: "Add Dashboard",
        addDisplayBoard: "Add Display Board",
        factory: "Factory",
        factoryLocation: "Factory Location",
        department: "Department",
        line: "Line",
        machine: "Machine",
        interval: "Interval",
        displayOption: "Display Option",
        addPlant: "Add Plant",
        productName: "Product Name",
        shortName: "Short Name",
        owners: "Owners",
        parent: "Parent",
        assignOwners: "Assign Owners",
        update: "Update",
        addProduct: "Add Product",
        uploadFile: "Upload File",
        sku: "SKU",
        externalID: "External ID",
        lineName: "Line Name",
        addLine: "Add Line",
        workCenter: "Work Center",
        productionLine: "Production Line",
        section: "Section",
        pinLocation: "Pin Location",
        addMachine: "Add Machine",
        dataType: "Data Type",
        unit: "Unit",
        lastReadAt: "Last Read At",
        dataSourceType: "Data Source Type",
        channelSource: "Channel Source",
        addBulkUser: "Add Bulk User",
        addUser: "Add User",
        sendInvite: "Send Invite",
        email: "Email",
        phoneNumber: "Phone Number",
        whatsappNumber: "Whatsapp Number",
        employeeID: "Employee ID",
        group: "Group",
        role: "Role",
        viewPermissions: "View Permissions",
        addHoliday: "Add Holiday",
        holidayName: "Holiday Name",
        ...errorMessages.en,
        ...successMessages.en,
        ...loginPage.en,
        ...sideMenu.en,
        ...pageTitles.en,
        ...loginPageMessages.en,
        ...registrationPage.en,
        ...loginQrPage.en,
        ...resetPwdPage.en,
      },
    },
    arsa: {
      translation: {
        liveBoard: "لوحة مباشرة",
        report: "تقرير",
        action: "فعل",
        overallEquipment: "المعدات الشاملة",
        effectiveness: "فعالية",
        availability: "التوفر",
        performance: "أداء",
        quality: "جودة",
        shift: "يحول",
        date: "تاريخ",
        po: "ص",
        completed: "مكتمل",
        product: "منتج",
        currentProductionStats: "إحصائيات الإنتاج الحالية",
        currentPO: "أمر الشراء الحالي",
        currentPartNo: "رقم الجزء الحالي",
        status: "حالة",
        actualCycleTime: "وقت الدورة الفعلي",
        estTimeRemaining: "الوقت المتبقي هو الوقت المتوقع",
        partsMin: "أجزاء / دقيقة",
        partsAhead: "الأجزاء المقبلة",
        reporting: "الإبلاغ",
        reportDownTime: "الإبلاغ عن وقت التوقف",
        reportChangeOver: "الإبلاغ عن التغيير",
        reportQuality: "جودة التقرير",
        stopBreakTime: "توقف عن وقت الاستراحة",
        startBreakTime: "بدء وقت الاستراحة",
        notifications: "إشعارات",
        severity: "خطورة",
        reason: "سبب",
        duration: "مدة",
        type: "يكتب",
        dateTime: "التاريخ/الوقت",
        category: "فئة",
        viewAll: "عرض الكل",
        critical: "شديد الأهمية",
        normal: "طبيعي",
        informative: "غنيا بالمعلومات",
        rejectionHistory: "تاريخ الرفض",
        reasonType: "نوع السبب",
        rejectionCount: "عدد الرفض",
        productionOrder: "أمر الإنتاج",
        feeds: "يغذي",
        sender: "مرسل",
        more: "أكثر",
        edit: "يحرر",
        name: "اسم",
        description: "وصف",
        view: "منظر",
        add: "يضيف",
        dashboard: "لوحة القيادة",
        delete: "يمسح",
        share: "يشارك",
        duplicate: "ينسخ",
        search: "يبحث",
        addDashboard: "إضافة لوحة التحكم",
        addDisplayBoard: "إضافة لوحة العرض",
        factory: "مصنع",
        factoryLocation: "مصنع الموقع",
        department: "قسم",
        line: "خط",
        machine: "آلة",
        interval: "فاصلة",
        displayOption: "خيار العرض",
        addPlant: "أضف النبات",
        productName: "اسم المنتج",
        shortName: "اسم قصير",
        owners: "أصحاب",
        parent: "الأبوين",
        assignOwners: "تعيين أصحاب",
        update: "تحديث",
        addProduct: "أضف منتج",
        uploadFile: "رفع ملف",
        sku: "رمز التخزين التعريفي",
        externalID: "معرف خارجي",
        lineName: "اسم الخط",
        addLine: "إضافة خط",
        workCenter: "مركز العمل",
        productionLine: "خط الإنتاج",
        section: "قسم",
        pinLocation: "دبوس الموقع",
        addMachine: "أضف آلة",
        dataType: "نوع البيانات",
        unit: "وحدة",
        lastReadAt: "آخر قراءة في",
        dataSourceType: "نوع مصدر البيانات",
        channelSource: "مصدر القناة",
        addBulkUser: "إضافة مستخدم بالجملة",
        addUser: "إضافة مستخدم",
        sendInvite: "ارسل دعوة",
        email: "بريد إلكتروني",
        phoneNumber: "رقم التليفون",
        whatsappNumber: "رقم واتس اب",
        employeeID: "هوية الموظف",
        group: "مجموعة",
        role: "دور",
        viewPermissions: "عرض الأذونات",
        addHoliday: "أضف عطلة",
        holidayName: "اسم العطلة",
        ...errorMessages.arsa,
        ...successMessages.arsa,
        ...loginPage.arsa,
        ...sideMenu.arsa,
        ...pageTitles.arsa,
        ...loginPageMessages.arsa,
        ...registrationPage.arsa,
        ...loginQrPage.arsa,
        ...resetPwdPage.arsa,
      },
    },
  },
});

export default i18n;
