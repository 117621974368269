import React from "react";
import styled from "styled-components";
import { ConfigProvider, Space, Tooltip } from "antd";

const CustomTooltip = styled(Tooltip)`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  padding: ${(props) => props.padding || "0rem"};
  font-weight: ${(props) => props.fontWeight || "var(--font-400)"};
  font-size: ${(props) => props.fontsize || "0.875rem"};
`;

export const PrimaryTooltip = (props) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#21223d",
        },
      }}
    >
      <Space>
        <CustomTooltip color="#21223d" {...props}>
          {props.children}
        </CustomTooltip>
      </Space>
    </ConfigProvider>
  );
};
