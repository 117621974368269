import React, { useState } from "react";
import { Card, Form, message } from "antd";
import { ReactComponent as Proton } from "../../assets/svg/Proton.svg";
import { P } from "../../Components/StyledComponents/Paragraph";
import { PrimaryFlex } from "../../Components/StyledComponents/Flex";
import RegisterPageBg from "../../assets/png/RegisterPageBg.png";
import { ReactComponent as QrCode } from "../../assets/svg/qrCode.svg";
import "./LoginQR.css";
import transition from "../../Transition";
import { SimpleFlex } from "../../Components/StyledComponents/SimpleFlex";
import { SimpleInput } from "../../Components/StyledComponents/SimpleInput";
import { PrimaryButton } from "../../Components/StyledComponents/Button";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginQRUser } from "../../redux/slice/loginQR";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../Components/LanguageSwitcher/LanguageSwitcher";

function LoginQR() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await dispatch(loginQRUser({ code: values?.code })).unwrap();

      setLoading(false);
      navigate("/proton");
    } catch (error) {
      console.error("Login failed:", error);
      messageApi.open({
        type: "error",
        content: "User is not logged in",
      });
      setLoading(false);
    }
  };

  return (
    <div
      className="container"
      style={{
        background: `url("${RegisterPageBg}"), lightgray 50% / cover no-repeat`,
      }}
    >
      {contextHolder}
      <SimpleFlex
        justifycontent="flex-end"
        height="auto"
        width="auto"
        backgroundcolor="transparent"
        position="absolute"
        className="positioning"
      >
        <LanguageSwitcher />
      </SimpleFlex>
      <div
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          zIndex: 1,
          opacity: 0.85,
          background: "#21223D",
        }}
      ></div>
      <div className="d-flex h-100">
        <Card
          style={{
            width: "auto",
            margin: "auto",
            zIndex: 2,
          }}
        >
          <PrimaryFlex flexdirection="column" gap="1rem">
            <PrimaryFlex justifycontent="space-between">
              <Proton />
            </PrimaryFlex>
            <PrimaryFlex gap="8rem">
              <PrimaryFlex flexdirection="column" gap="1rem">
                <SimpleFlex flexdirection="column">
                  <P fontWeight="bolder" fontsize="2.125rem" lineheight="2rem">
                    Proton is
                  </P>
                  <P fontWeight="bolder" fontsize="2.125rem" lineheight="2rem">
                    scanning
                  </P>
                  <P fontWeight="bolder" fontsize="2.125rem" lineheight="2rem">
                    your ID
                  </P>
                </SimpleFlex>
                <Form
                  name="normal_login"
                  className="login-form"
                  onFinish={onFinish}
                >
                  <SimpleFlex
                    flexdirection="column"
                    gap="1rem"
                    aligitems="center"
                    justifycontent="center"
                  >
                    <Form.Item
                      className="margin-0"
                      name="code"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Code!",
                        },
                      ]}
                    >
                      <SimpleInput
                        fontsize="1rem"
                        placeholder={t("employeeCode")}
                      ></SimpleInput>
                    </Form.Item>
                    <Form.Item className="margin-0">
                      <PrimaryButton
                        justifycontent="center"
                        padding="0.8rem 0"
                        htmlType="submit"
                        fontWeight="600"
                        width="22.875rem"
                        height="3.125rem"
                        fontsize="1.125rem"
                        loading={loading}
                      >
                        {t("login")}
                      </PrimaryButton>
                    </Form.Item>
                  </SimpleFlex>
                </Form>
              </PrimaryFlex>

              <QrCode />
            </PrimaryFlex>
          </PrimaryFlex>
        </Card>
      </div>
    </div>
  );
}

export default transition(LoginQR);
