import { message } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowUserLoginSuccess } from "../../redux/slice/login";
import { setShowUserLoginQRSuccess } from "../../redux/slice/loginQR";
import { useTranslation } from "react-i18next";

const LoginSuccess = () => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const { login, loginQR } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (login?.userLoginSuccess || loginQR?.userLoginSuccess) {
      setTimeout(() => {
        messageApi.open({
          type: "success",
          content: t("loginSuccess"),
        });
        if (login?.userLoginSuccess) {
          dispatch(setShowUserLoginSuccess(false));
        } else if (loginQR?.userLoginSuccess) {
          dispatch(setShowUserLoginQRSuccess(false));
        }
      }, 200);
    }
  }, [dispatch]);

  return <div>{contextHolder}</div>;
};

export default LoginSuccess;
