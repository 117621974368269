import React, { useEffect, useState } from "react";
import { Card, Form, message } from "antd";
import { ReactComponent as Proton } from "../../assets/svg/Proton.svg";
import { P } from "../../Components/StyledComponents/Paragraph";
import { PrimaryFlex } from "../../Components/StyledComponents/Flex";
import RegisterPageBg from "../../assets/png/RegisterPageBg.png";
import { ReactComponent as RightArrow } from "../../assets/svg/rightArrow.svg";
import { useNavigate } from "react-router";
import transition from "../../Transition";
import { useDispatch, useSelector } from "react-redux";
import { registerInitial } from "../../redux/slice/register";
import EntryPageCarousel from "../../Components/EntryPageCarousel/EntryPageCarousel";
import { SimpleFlex } from "../../Components/StyledComponents/SimpleFlex";
import LanguageSwitcher from "../../Components/LanguageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import "./Login.css";

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [messageShown, setMessageShown] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const registerState = useSelector((state) => state.registration);

  useEffect(() => {
    if (registerState.isRegistered && !messageShown) {
      messageApi.open({
        type: "success",
        content: "User Is Registered Successfully",
        onClose: () => {
          setMessageShown(true);
          dispatch(registerInitial());
        },
      });
    }
  }, [registerState.isRegistered, dispatch, messageApi, messageShown]);

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };

  return (
    <div
      className="container"
      style={{
        background: `url("${RegisterPageBg}"), lightgray 50% / cover no-repeat`,
      }}
    >
      {contextHolder}
      <SimpleFlex
        justifycontent="flex-end"
        height="auto"
        width="auto"
        backgroundcolor="transparent"
        position="absolute"
        className="positioning"
      >
        <LanguageSwitcher />
      </SimpleFlex>
      <div
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          zIndex: 1,
          opacity: 0.85,
          background: "#21223D",
        }}
      ></div>
      <div className="d-flex h-100">
        <Card
          style={{
            width: "auto",
            margin: "auto",
            zIndex: 2,
          }}
        >
          <PrimaryFlex flexdirection="column" gap="1rem">
            <PrimaryFlex>
              <Proton />
            </PrimaryFlex>
            <PrimaryFlex gap="6.25rem">
              <PrimaryFlex>
                <EntryPageCarousel />
              </PrimaryFlex>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                <PrimaryFlex flexdirection="column" gap="1.875rem">
                  <PrimaryFlex flexdirection="column">
                    <P fontWeight="bolder" fontsize="2.5rem">
                      {t("login")}
                    </P>
                    <P
                      fontWeight="400"
                      color="var(--nav-color)"
                      fontsize="0.938rem"
                    >
                      {t("loginPageMessage")}
                    </P>
                  </PrimaryFlex>
                  <PrimaryFlex
                    backgroundcolor="var(--primary-color)"
                    gap="5rem"
                    padding="0.688rem 1rem"
                    borderradius="0.375rem"
                    justifycontent="space-between"
                    cursor="pointer"
                    onClick={() => {
                      return navigate("/login/qr-code");
                    }}
                  >
                    <PrimaryFlex
                      flexdirection="column"
                      backgroundcolor="transparent"
                      gap="0.2rem"
                    >
                      <P
                        fontWeight="var(--font-600)"
                        fontsize="1.063rem"
                        color="var(--white)"
                        cursor="pointer"
                      >
                        {t("loginWithQr")}
                      </P>
                      <PrimaryFlex
                        backgroundcolor="transparent"
                        flexdirection="column"
                        cursor="pointer"
                      >
                        <P
                          fontsize="0.688rem"
                          color="var(--white)"
                          cursor="pointer"
                        >
                          {t("qrMsg1")}
                        </P>
                        <P
                          fontsize="0.688rem"
                          color="var(--white)"
                          cursor="pointer"
                        >
                          {t("qrMsg2")}
                        </P>
                      </PrimaryFlex>
                    </PrimaryFlex>
                    <PrimaryFlex backgroundcolor="transparent" cursor="pointer">
                      <RightArrow />
                    </PrimaryFlex>
                  </PrimaryFlex>
                  <PrimaryFlex
                    backgroundcolor="var(--primary-color)"
                    gap="5rem"
                    padding="0.688rem 1rem"
                    borderradius="0.375rem"
                    justifycontent="space-between"
                    cursor="pointer"
                    onClick={() => {
                      return navigate("/login/email-password");
                    }}
                  >
                    <PrimaryFlex
                      flexdirection="column"
                      backgroundcolor="transparent"
                      gap="0.2rem"
                    >
                      <P
                        fontWeight="var(--font-600)"
                        fontsize="1.063rem"
                        color="var(--white)"
                        cursor="pointer"
                      >
                        {t("loginWithEmail")}
                      </P>
                      <PrimaryFlex
                        backgroundcolor="transparent"
                        flexdirection="column"
                      >
                        <P
                          fontsize="0.688rem"
                          color="var(--white)"
                          cursor="pointer"
                        >
                          {t("emailLoginMsg")}
                        </P>
                      </PrimaryFlex>
                    </PrimaryFlex>
                    <PrimaryFlex backgroundcolor="transparent" cursor="pointer">
                      <RightArrow />
                    </PrimaryFlex>
                  </PrimaryFlex>
                </PrimaryFlex>
              </Form>
            </PrimaryFlex>
          </PrimaryFlex>
        </Card>
      </div>
    </div>
  );
}
export default transition(Login);
