import { useState } from "react";
import { PrimaryInput } from "../../../Components/StyledComponents/Input";
import { PrimaryModal } from "../../../Components/StyledComponents/Modal";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { PrimarySelect } from "../../../Components/StyledComponents/Select";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";

function AddUser({ closeButton }) {
  const [selectedRow, setSelectedRow] = useState({
    email: "",
    name: "",
    firstName: "",
    lastName: "",
    password: "",
    phone: "",
    whatsapp: "",
    loginSecret: "",
    empId: "",
    externalId: "",
    status: 0,
    groups: [],
    preferences: [],
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedRow((prevState) => {
      const newState = {
        ...prevState,
        [name]: value,
      };

      return newState;
    });
  };

  const handleOk = () => {
    closeButton();
  };

  const handleCancel = () => {
    closeButton();
  };

  return (
    <PrimaryModal
      centered
      title="Add User"
      open
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Save Changes"
      maskClosable={false}
    >
      <SimpleFlex flexdirection="column" gap="1.5rem">
        <SimpleFlex flexdirection="column" gap="1rem">
          <P color="var(--nav-color)" fontsize="0.75rem">
            Name
          </P>
          <PrimaryInput
            bordered={false}
            name="name"
            defaultValue={selectedRow?.name}
            onChange={handleInputChange}
            style={{ borderBottom: "1px solid black" }}
            className="user-input"
            padding="0 0 0.3rem 0"
            color="var(--black)"
            borderradius="0"
            fontsize="1rem"
          />
        </SimpleFlex>
        <SimpleFlex flexdirection="column" gap="1rem">
          <P color="var(--nav-color)" fontsize="0.75rem">
            Email
          </P>
          <PrimaryInput
            bordered={false}
            name="email"
            defaultValue={selectedRow?.email}
            onChange={handleInputChange}
            style={{ borderBottom: "1px solid black" }}
            className="user-input"
            padding="0 0 0.3rem 0"
            color="var(--black)"
            borderradius="0"
            fontsize="1rem"
          />
        </SimpleFlex>
        <SimpleFlex flexdirection="column" gap="1rem">
          <P color="var(--nav-color)" fontsize="0.75rem">
            Phone Number
          </P>
          <PrimaryInput
            bordered={false}
            name="phoneNumber"
            defaultValue={selectedRow?.phoneNumber}
            onChange={handleInputChange}
            style={{ borderBottom: "1px solid black" }}
            className="user-input"
            padding="0 0 0.3rem 0"
            color="var(--black)"
            borderradius="0"
            fontsize="1rem"
          />
        </SimpleFlex>
        <SimpleFlex flexdirection="column" gap="1rem">
          <P color="var(--nav-color)" fontsize="0.75rem">
            Whatsapp Number
          </P>
          <PrimaryInput
            bordered={false}
            name="whatsappNumber"
            defaultValue={selectedRow?.whatsappNumber}
            onChange={handleInputChange}
            style={{ borderBottom: "1px solid black" }}
            className="user-input"
            padding="0 0 0.3rem 0"
            color="var(--black)"
            borderradius="0"
            fontsize="1rem"
          />
        </SimpleFlex>
        <SimpleFlex gap="1rem">
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Employee ID
            </P>
            <PrimaryInput
              bordered={false}
              name="employeeID"
              defaultValue={selectedRow?.employeeID}
              onChange={handleInputChange}
              style={{ borderBottom: "1px solid black" }}
              className="user-input"
              padding="0 0 0.3rem 0"
              color="var(--black)"
              borderradius="0"
              fontsize="1rem"
            />
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              External ID
            </P>
            <PrimaryInput
              bordered={false}
              name="externalID"
              defaultValue={selectedRow?.externalID}
              onChange={handleInputChange}
              style={{ borderBottom: "1px solid black" }}
              className="user-input"
              padding="0 0 0.3rem 0"
              color="var(--black)"
              borderradius="0"
              fontsize="1rem"
            />
          </SimpleFlex>
        </SimpleFlex>

        <SimpleFlex flexdirection="column" gap="1rem">
          <P color="var(--nav-color)" fontsize="0.75rem">
            Group
          </P>
          <PrimarySelect
            bordered={false}
            className="user-select"
            showSearch
            allowClear
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={[
              {
                value: "1",
                label: "Not Identified",
              },
              {
                value: "2",
                label: "Closed",
              },
              {
                value: "3",
                label: "Communicated",
              },
              {
                value: "4",
                label: "Identified",
              },
              {
                value: "5",
                label: "Resolved",
              },
              {
                value: "6",
                label: "Cancelled",
              },
              {
                value: "7",
                label: "3 in blank : 3 Round Blank",
              },
            ]}
          ></PrimarySelect>
        </SimpleFlex>
      </SimpleFlex>
    </PrimaryModal>
  );
}
export default AddUser;
