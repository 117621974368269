import { useEffect, useState } from "react";
import { PrimaryModal } from "../../../../Components/StyledComponents/Modal";
import { P } from "../../../../Components/StyledComponents/Paragraph";
import { PrimarySelect } from "../../../../Components/StyledComponents/Select";
import { SimpleFlex } from "../../../../Components/StyledComponents/SimpleFlex";
import { useDispatch } from "react-redux";
// import { addPlant } from "../../../../redux/slice/Plants/plant";
import { SimpleButton } from "../../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../../Components/StyledComponents/Button";
import { Form } from "antd";
import { SimpleInput } from "../../../../Components/StyledComponents/SimpleInput";
import "./EditTagMapping.scss";

function EditTagMapping({ closeButton, payload }) {
  const dispatch = useDispatch();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedRow, setSelectedRow] = useState({
    event: payload?.eventType || "",
    tagName: payload?.tagName || "",
    valueSource: payload?.valueSource || "",
    tagValue: payload?.tagValue || "",
    formula: payload?.formula || "",
  });

  const handleSelectChange = (selectedValues, field) => {
    setSelectedRow((prevState) => ({
      ...prevState,
      [field]: selectedValues ?? "",
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedRow((prevState) => {
      const newState = {
        ...prevState,
        [name]: value,
      };

      return newState;
    });
  };

  const handleOk = async () => {
    setLoading(true);

    try {
      // dispatch(addPlant(selectedRow));
      await new Promise((resolve) => setTimeout(resolve, 2000));
      console.log("Plant added successfully!");
    } catch (error) {
      console.error("Error adding plant:", error);
    } finally {
      setLoading(false);
      closeButton();
    }
  };

  const handleCancel = () => {
    closeButton();
  };

  useEffect(() => {
    const { event, tagName, valueSource, tagValue, formula } = selectedRow;
    const formComplete =
      event?.trim() !== "" &&
      tagName?.trim() !== "" &&
      valueSource?.trim() !== "" &&
      tagValue?.trim() !== "" &&
      formula?.trim() !== "";

    setIsFormComplete(formComplete);
  }, [selectedRow]);

  const AddModalfooter = (
    <SimpleFlex
      margin="1.5rem 0 0 0"
      alignitems="center"
      justifycontent="flex-end"
    >
      <SimpleFlex gap="1rem" width="auto">
        <SimpleButton onClick={handleCancel} padding="0.5rem 1rem">
          Cancel
        </SimpleButton>
        <PrimaryButton
          disabled={!isFormComplete}
          loading={loading}
          onClick={handleOk}
          padding="0.5rem 1rem"
        >
          Save Changes
        </PrimaryButton>
      </SimpleFlex>
    </SimpleFlex>
  );

  return (
    <PrimaryModal
      centered
      title="Edit Tag Mapping"
      open
      onOk={handleOk}
      onCancel={handleCancel}
      footer={AddModalfooter}
      maskClosable={false}
    >
      <Form>
        <SimpleFlex flexdirection="column" gap="1rem">
          <SimpleFlex gap="2rem">
            <SimpleFlex flexdirection="column" gap="1rem">
              <P color="var(--nav-color)" fontsize="0.75rem">
                Event
              </P>
              <Form.Item
                name="event"
                className="margin-0"
                rules={[
                  {
                    required: true,
                    message: "Please input Event!",
                  },
                ]}
                initialValue={selectedRow?.event}
              >
                <PrimarySelect
                  showSearch
                  allowClear
                  onChange={(value) => {
                    handleSelectChange(value, "event");
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "stop-10",
                      label: "Stop-10",
                    },
                    {
                      value: "stop-20",
                      label: "Stop-20",
                    },
                    {
                      value: "stop-30",
                      label: "Stop-30",
                    },
                  ]}
                />
              </Form.Item>
            </SimpleFlex>
            <SimpleFlex flexdirection="column" gap="1rem">
              <P color="var(--nav-color)" fontsize="0.75rem">
                Tag Name
              </P>
              <Form.Item
                name="tagName"
                className="margin-0"
                rules={[
                  {
                    required: true,
                    message: "Please input Tag Name!",
                  },
                ]}
                initialValue={selectedRow?.tagName}
              >
                <SimpleInput
                  name="tagName"
                  onChange={handleInputChange}
                  padding="0.275rem"
                  color="var(--black)"
                  borderradius="0.2rem"
                  fontsize="1rem"
                />
              </Form.Item>
            </SimpleFlex>
          </SimpleFlex>
          <SimpleFlex gap="2rem">
            <SimpleFlex flexdirection="column" gap="1rem">
              <P color="var(--nav-color)" fontsize="0.75rem">
                Value Source
              </P>
              <Form.Item
                name="valueSource"
                className="margin-0"
                rules={[
                  {
                    required: true,
                    message: "Please input Value Source!",
                  },
                ]}
                initialValue={selectedRow?.valueSource}
              >
                <PrimarySelect
                  showSearch
                  allowClear
                  onChange={(value) => {
                    handleSelectChange(value, "valueSource");
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "stop-10",
                      label: "Stop-10",
                    },
                    {
                      value: "stop-20",
                      label: "Stop-20",
                    },
                    {
                      value: "stop-30",
                      label: "Stop-30",
                    },
                  ]}
                />
              </Form.Item>
            </SimpleFlex>
            <SimpleFlex flexdirection="column" gap="1rem">
              <P color="var(--nav-color)" fontsize="0.75rem">
                Tag Value
              </P>
              <Form.Item
                name="tagValue"
                className="margin-0"
                rules={[
                  {
                    required: true,
                    message: "Please input Tag Value!",
                  },
                ]}
                initialValue={selectedRow?.tagValue}
              >
                <SimpleInput
                  name="tagValue"
                  onChange={handleInputChange}
                  padding="0.275rem"
                  color="var(--black)"
                  borderradius="0.2rem"
                  fontsize="1rem"
                />
              </Form.Item>
            </SimpleFlex>
          </SimpleFlex>
          <P fontsize="0.75rem" color="var(--nav-color)">
            Choose where to get the value mapped to this event.
          </P>
          <SimpleFlex flexdirection="column" gap="1rem">
            <P color="var(--nav-color)" fontsize="0.75rem">
              Formula
            </P>
            <Form.Item
              name="formula"
              className="margin-0"
              rules={[
                {
                  required: true,
                  message: "Please input Formula!",
                },
              ]}
              initialValue={selectedRow?.formula}
            >
              <SimpleInput
                name="formula"
                onChange={handleInputChange}
                padding="0.275rem"
                color="var(--black)"
                borderradius="0.2rem"
                fontsize="1rem"
              />
            </Form.Item>
          </SimpleFlex>
        </SimpleFlex>
      </Form>
    </PrimaryModal>
  );
}
export default EditTagMapping;
