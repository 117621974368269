import React from "react";
import styled from "styled-components";
import { ConfigProvider, Input } from "antd";

const CustomInput = styled(Input.Search)``;

export const PrimaryInputSearch = (props) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#21223d",
        },
      }}
    >
      <CustomInput {...props}>{props.children}</CustomInput>
    </ConfigProvider>
  );
};
