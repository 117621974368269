import React from "react";
import styled from "styled-components";
import { ConfigProvider, Space, Card } from "antd";

const CustomCard = styled(Card)`
  .ant-card-body {
    width: ${(props) => props.bodyWidth || "auto"};
    max-width: ${(props) => props.bodymaxwidth || "auto"};
    min-width: ${(props) => props.bodyminwidth || "auto"};
    padding: ${(props) => props.bodypadding || "1.25rem"};
    background-color: ${(props) => props.backgroundcolor || "var(--white)"};
    border-radius: ${(props) => props.borderradius || "18px"};
    border: ${(props) => props.border || "none"};
    cursor: ${(props) => props.cursor || "auto"};
  }
`;

export const PrimaryCard = (props) => {
  const { onClick } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#21223d",
          colorPrimaryHover: "white",
          colorPrimaryActive: "white",
        },
      }}
    >
      <CustomCard {...props} onClick={handleClick}>
        {props.children}
      </CustomCard>
    </ConfigProvider>
  );
};
