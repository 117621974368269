import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

// Action to fetch all users
export const fetchUserGroupDetail = createAsyncThunk(
  "fetchUserGroupDetail",
  async (key) => {
    //fetch the records base on key

    // const response = await fetch("https://your-api-endpoint/users");
    // return response.json();

    // throw new Error("");
    // await new Promise((resolve) => setTimeout(resolve, 2000));

    return [
      {
        key: "1",
        name: "Billal Ahmed",
        email: "john.doe@gmail.com",
        contact: "+91 988998646",
      },
      {
        key: "2",
        name: "Abrar Ahmed",
        email: "john.doe@gmail.com",
        contact: "+91 988998646",
      },
    ];
  }
);

// Action to edit a user
export const editUserGroupDetail = createAsyncThunk(
  "editUserGroupDetail",
  async (editedUserGroupDetail) => {
    // const response = await fetch(
    //   `https://your-api-endpoint/users/${editedUserGroupDetail.key}`,
    //   {
    //     method: "PUT",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(editedUser),
    //   }
    // );
    // return response.json();

    // user is edited successfully
    const dispatch = useDispatch();
    dispatch(fetchUserGroupDetail());
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return editedUserGroupDetail;
  }
);

const userGroupDetailSlice = createSlice({
  name: "user-group-detail",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    selectedUser: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserGroupDetail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUserGroupDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchUserGroupDetail.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(editUserGroupDetail.fulfilled, (state, action) => {
      state.data = state.data.map((user) =>
        user.key === action.payload.key ? action.payload : user
      );
      state.selectedUser = null; // Clear selected user after edit
    });
  },
});

export default userGroupDetailSlice.reducer;
