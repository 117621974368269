import React from "react";
import { PrimaryCarousel } from "../StyledComponents/Carousel";
import { ReactComponent as RegistrationPageLogo } from "../../assets/svg/registrationPageLogo.svg";
import { ReactComponent as BusiPresentation } from "../../assets/svg/businessPresentation.svg";
import { ReactComponent as GirlAnnouncing } from "../../assets/svg/girlAnnouncing.svg";
import { PrimaryFlex } from "../StyledComponents/Flex";
import { P } from "../StyledComponents/Paragraph";

const EntryPageCarousel = () => {
  return (
    <PrimaryCarousel effect="fade" style={{ width: "13rem", height: "22rem" }}>
      <PrimaryFlex flexdirection="column" gap="2rem">
        <GirlAnnouncing />
        <PrimaryFlex flexdirection="column">
          <PrimaryFlex flexdirection="column">
            <P fontWeight="bolder" fontsize="2.125rem" lineheight="1rem">
              Real-time
            </P>
            <P fontWeight="bolder" fontsize="2.125rem">
              Alerts
            </P>
          </PrimaryFlex>
          <PrimaryFlex flexdirection="column">
            <P fontWeight="300" color="var(--nav-color)">
              Spot upcoming issues before
            </P>
            <P fontWeight="300" color="var(--nav-color)">
              they happen
            </P>
          </PrimaryFlex>
        </PrimaryFlex>
      </PrimaryFlex>
      <PrimaryFlex flexdirection="column" gap="2rem">
        <RegistrationPageLogo />
        <PrimaryFlex flexdirection="column">
          <PrimaryFlex flexdirection="column">
            <P fontWeight="bolder" fontsize="2.125rem" lineheight="1rem">
              Visualize
            </P>
            <P fontWeight="bolder" fontsize="2.125rem">
              OEE Data
            </P>
          </PrimaryFlex>
          <PrimaryFlex flexdirection="column">
            <P fontWeight="300" color="var(--nav-color)">
              Grouped metrics that show
            </P>
            <P fontWeight="300" color="var(--nav-color)">
              how to improve production
            </P>
          </PrimaryFlex>
        </PrimaryFlex>
      </PrimaryFlex>
      <PrimaryFlex flexdirection="column" gap="2rem">
        <BusiPresentation />
        <PrimaryFlex flexdirection="column">
          <PrimaryFlex flexdirection="column">
            <P fontWeight="bolder" fontsize="2.125rem" lineheight="1rem">
              No Manual
            </P>
            <P fontWeight="bolder" fontsize="2.125rem">
              Reporting
            </P>
          </PrimaryFlex>
          <PrimaryFlex flexdirection="column">
            <P fontWeight="300" color="var(--nav-color)">
              Efficiency like never before
            </P>
            <P fontWeight="300" color="var(--nav-color)">
              with reduced manual work
            </P>
          </PrimaryFlex>
        </PrimaryFlex>
      </PrimaryFlex>
    </PrimaryCarousel>
  );
};

export default EntryPageCarousel;
