import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ConfigProvider, Space } from "antd";
import ErrorBoundary from "./ErrorBoundary";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <BrowserRouter>
      <Provider store={store}>
        <ConfigProvider
          theme={{
            components: {
              token: {
                colorPrimary: "#21223d",
              },
            },
          }}
        >
          <Space>
            <App />
          </Space>
        </ConfigProvider>
      </Provider>
    </BrowserRouter>
  </ErrorBoundary>
  // </React.StrictMode>
);
