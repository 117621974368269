// AuthContext.js
import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUser,
  setUser,
  setShowUserLoginSuccess,
} from "../../redux/slice/login";

import { clearQRUser } from "../../redux/slice/loginQR";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.login);
  const loggedInQRUser = useSelector((state) => state.loginQR);

  const login = (user) => {
    dispatch(setUser(user));
  };

  const logout = () => {
    if (!loggedInUser?.isError && !!loggedInUser?.user) {
      dispatch(clearUser());
    } else if (!loggedInQRUser?.isError && !!loggedInQRUser?.user) {
      dispatch(clearQRUser());
    }
  };

  const auth = !!loggedInUser?.user || !!loggedInQRUser?.user;

  return (
    <AuthContext.Provider
      value={{
        auth,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
