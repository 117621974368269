import { useEffect, useState } from "react";
import { PrimaryModal } from "../../../../Components/StyledComponents/Modal";
import { SimpleFlex } from "../../../../Components/StyledComponents/SimpleFlex";
import { SimpleButton } from "../../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../../Components/StyledComponents/Button";
import { P } from "../../../../Components/StyledComponents/Paragraph";
import { PrimaryInput } from "../../../../Components/StyledComponents/Input";
import { PrimarySelect } from "../../../../Components/StyledComponents/Select";
import { ReactComponent as DownArrow } from "../../../../assets/svg/downArrow.svg";
import "./FilterCard.scss";
import { DatePicker } from "antd";

function FilterCard({ showModal, closeButton }) {
  const [visible, setVisible] = useState(showModal);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const disabledDate = (current) => {
    // Disable all dates before dateFrom
    return current && current < dateFrom;
  };

  useEffect(() => {
    setVisible(showModal);
  }, [showModal]);

  const handleOk = () => {
    setVisible(false);
    closeButton();
  };

  const handleCancel = () => {
    setVisible(false);
    closeButton();
  };

  const MACHINE_OPTIONS = [
    "Richie Rich",
    "Adam Jons",
    "John Wills",
    "Mark Eve",
  ];
  const ESCILATION_OPTIONS = ["Coil Machine 1", "P10", "P002"];

  const [selectedItems, setSelectedItems] = useState([]);
  const [escilationSelect, setEscilationSelect] = useState([]);
  const [selectedShift, setSelectedShift] = useState([]);
  const filteredOptions = MACHINE_OPTIONS.filter(
    (o) => !selectedItems.includes(o)
  );
  const filteredEscilationOptions = ESCILATION_OPTIONS.filter(
    (o) => !escilationSelect.includes(o)
  );
  const filteredShiftOptions = ESCILATION_OPTIONS.filter(
    (o) => !selectedShift.includes(o)
  );

  const Modalfooter = (
    <SimpleFlex gap="1rem" justifycontent="flex-end">
      <SimpleButton onClick={handleCancel} padding="0.5rem 1.5rem">
        Reset
      </SimpleButton>
      <PrimaryButton
        onClick={handleOk}
        padding="0.5rem 1.5rem"
        disabled={false}
      >
        Filter
      </PrimaryButton>
    </SimpleFlex>
  );

  return (
    <PrimaryModal
      centered
      className="ant-modal-min-width"
      title="Filter Alerts"
      maskClosable={false}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      onClose={handleCancel}
      footer={Modalfooter}
    >
      <SimpleFlex flexdirection="column" gap="1.5rem">
        <SimpleFlex gap="3rem">
          <SimpleFlex flexdirection="column" gap="0.5rem">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Machine(s)
            </P>
            <PrimarySelect
              className="ant-select-min-width select"
              mode="multiple"
              bordered={false}
              allowClear={true}
              showSearch
              placeholder="Search to Select"
              value={selectedItems}
              onChange={setSelectedItems}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={filteredOptions.map((item) => ({
                value: item,
                label: item,
              }))}
            />
          </SimpleFlex>
        </SimpleFlex>
        <SimpleFlex gap="3rem">
          <SimpleFlex flexdirection="column" gap="0.5rem">
            <P fontsize="0.75rem" color="var(--nav-color)">
              device(s)
            </P>
            <PrimarySelect
              className="ant-select-min-width select"
              mode="multiple"
              bordered={false}
              allowClear={true}
              showSearch
              placeholder="Search to Select"
              value={escilationSelect}
              onChange={setEscilationSelect}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={filteredEscilationOptions.map((item) => ({
                value: item,
                label: item,
              }))}
            />
          </SimpleFlex>
        </SimpleFlex>
        <SimpleFlex gap="3rem">
          <SimpleFlex flexdirection="column" gap="0.3rem" width="auto">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Status
            </P>
            <PrimarySelect
              className="ant-select-min-width select"
              bordered={false}
              allowClear={true}
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "notIdentified",
                  label: "Not Identified",
                },
                {
                  value: "closed",
                  label: "Closed",
                },
                {
                  value: "communicated",
                  label: "Communicated",
                },
                {
                  value: "identified",
                  label: "Identified",
                },
                {
                  value: "resolved",
                  label: "Resolved",
                },
                {
                  value: "cancelled",
                  label: "Cancelled",
                },
              ]}
            />
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="0.3rem" width="auto">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Type
            </P>
            <PrimarySelect
              className="ant-select-min-width select"
              bordered={false}
              allowClear={true}
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "notIdentified",
                  label: "Not Identified",
                },
                {
                  value: "closed",
                  label: "Closed",
                },
                {
                  value: "communicated",
                  label: "Communicated",
                },
                {
                  value: "identified",
                  label: "Identified",
                },
                {
                  value: "resolved",
                  label: "Resolved",
                },
                {
                  value: "cancelled",
                  label: "Cancelled",
                },
              ]}
            />
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="0.3rem" width="auto">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Severity
            </P>
            <PrimarySelect
              className="ant-select-min-width select"
              bordered={false}
              allowClear={true}
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "notIdentified",
                  label: "Not Identified",
                },
                {
                  value: "closed",
                  label: "Closed",
                },
                {
                  value: "communicated",
                  label: "Communicated",
                },
                {
                  value: "identified",
                  label: "Identified",
                },
                {
                  value: "resolved",
                  label: "Resolved",
                },
                {
                  value: "cancelled",
                  label: "Cancelled",
                },
              ]}
            />
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="0.3rem" width="auto">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Duration
            </P>
            <PrimarySelect
              className="ant-select-min-width select"
              bordered={false}
              allowClear={true}
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "notIdentified",
                  label: "Not Identified",
                },
                {
                  value: "closed",
                  label: "Closed",
                },
                {
                  value: "communicated",
                  label: "Communicated",
                },
                {
                  value: "identified",
                  label: "Identified",
                },
                {
                  value: "resolved",
                  label: "Resolved",
                },
                {
                  value: "cancelled",
                  label: "Cancelled",
                },
              ]}
            />
          </SimpleFlex>
        </SimpleFlex>
        <SimpleFlex gap="3rem">
          <SimpleFlex flexdirection="column" gap="0.3rem" width="auto">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Date From
            </P>
            <DatePicker
              value={dateFrom}
              onChange={setDateFrom}
              format="YYYY-MM-DD"
            />
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="0.3rem" width="auto">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Date To
            </P>
            <DatePicker
              value={dateTo}
              onChange={setDateTo}
              format="YYYY-MM-DD"
              disabledDate={disabledDate}
            />
          </SimpleFlex>
        </SimpleFlex>
        <SimpleFlex gap="3rem">
          <SimpleFlex flexdirection="column" gap="0.5rem">
            <P fontsize="0.75rem" color="var(--nav-color)">
              shift
            </P>
            <PrimarySelect
              className="ant-select-min-width select"
              mode="multiple"
              bordered={false}
              allowClear={true}
              showSearch
              placeholder="Search to Select"
              value={selectedShift}
              onChange={setSelectedShift}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={filteredShiftOptions.map((item) => ({
                value: item,
                label: item,
              }))}
            />
          </SimpleFlex>
        </SimpleFlex>
      </SimpleFlex>
    </PrimaryModal>
  );
}

export default FilterCard;
