import { Breadcrumb, Result, Table } from "antd";
import { SimpleFlex } from "../../Components/StyledComponents/SimpleFlex";
import { P } from "../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../Components/StyledComponents/SimpleButton";
import { ReactComponent as PlusDark } from "../../assets/svg/plusDark.svg";
import { ReactComponent as Eye } from "../../assets/svg/eye.svg";
import { useEffect, useState } from "react";
import { PrimaryInputSearch } from "../../Components/StyledComponents/InputSearch";
import { fetchMachines } from "../../redux/slice/Machines/machine";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import transition from "../../Transition";
import { useTranslation } from "react-i18next";
import "./Machine.scss";

function Machine() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { machines = [] } = useSelector((state) => state);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const [searchValue, setSearchValue] = useState("");
  const dataSource = machines?.data || [];
  const [filteredData, setFilteredData] = useState(dataSource);

  useEffect(() => {
    setFilteredData(dataSource);
  }, [dataSource]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const breadcrumbItems = [
    { title: t("dashboard"), href: "/proton/live-dashboard" },
    { title: t("allMachines"), href: "" },
  ];

  const columns = [
    {
      title: t("machine"),
      dataIndex: "machine",
      key: "machine",
      sorter: (a, b) => a?.machine?.localeCompare(b?.machine),
    },
    {
      title: t("shifts"),
      dataIndex: "shifts",
      key: "shifts",
      sorter: (a, b) => a?.shifts?.localeCompare(b?.shifts),
    },
    {
      title: t("shortName"),
      dataIndex: "shortName",
      key: "shortName",
      sorter: (a, b) => a?.shortName?.localeCompare(b?.shortName),
    },
    {
      title: t("workCenter"),
      dataIndex: "workCenter",
      key: "workCenter",
      sorter: (a, b) => a?.workCenter?.localeCompare(b?.workCenter),
    },
    {
      title: t("productionLine"),
      dataIndex: "productionLine",
      key: "productionLine",
      sorter: (a, b) => a?.productionLine?.localeCompare(b?.productionLine),
    },
    {
      title: t("section"),
      dataIndex: "section",
      key: "section",
      sorter: (a, b) => a?.section?.localeCompare(b?.section),
    },
    {
      title: t("department"),
      dataIndex: "department",
      key: "department",
      sorter: (a, b) => a?.department?.localeCompare(b?.department),
    },
    {
      title: t("pinLocation"),
      dataIndex: "pinLocation",
      key: "pinLocation",
      sorter: (a, b) => a?.pinLocation?.localeCompare(b?.pinLocation),
    },
    {
      title: t("action"),
      dataIndex: "",
      key: "x",
      render: (record) => (
        <SimpleFlex gap="1rem" backgroundcolor="transparent">
          <SimpleButton
            padding="0.4rem 0.8rem"
            onClick={() => {
              setSelectedRow(record);
            }}
          >
            <SimpleFlex cursor="pointer" alignitems="center" gap="0.2rem">
              <P color="var(--black)" cursor="pointer" fontsize="1rem">
                {t("view")}
              </P>
              <Eye />
            </SimpleFlex>
          </SimpleButton>
        </SimpleFlex>
      ),
    },
  ];

  // Dispatch the action when the component mounts
  useEffect(() => {
    dispatch(fetchMachines());
  }, [dispatch]);

  //search in table
  useEffect(() => {
    if (searchValue) {
      setFilteredData(
        dataSource.filter(
          (item) =>
            item.machine.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.shifts.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.shortName.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.workCenter.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.productionLine
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            item.section.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.department.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.pinLocation.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else {
      setFilteredData(dataSource);
    }
  }, [searchValue]);

  if (machines?.isError) {
    return (
      <SimpleFlex justifycontent="center" backgroundcolor="transparent">
        <Result
          status="500"
          title="500"
          subTitle="Sorry, Failed to fetch plants"
        />
      </SimpleFlex>
    );
  }

  return (
    <SimpleFlex
      backgroundcolor="transparent"
      padding="1rem 2rem"
      overflow="scroll"
      flexdirection="column"
      gap="1rem"
      width="auto"
      height="auto"
    >
      <SimpleFlex
        width="auto"
        height="fit-content"
        backgroundcolor="transparent"
      >
        <Breadcrumb separator=">">
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>
              <a href={item.href}>{item.title}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        justifycontent="space-between"
      >
        <P fontsize="2.25rem" fontWeight="var(--font-600)">
          {t("allMachines")}
        </P>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        flexdirection="column"
        gap="1rem"
      >
        <SimpleFlex
          backgroundcolor="transparent"
          justifycontent="space-between"
        >
          <SimpleFlex
            width="auto"
            alignitems="center"
            backgroundcolor="transparent"
            className="transparent-search"
          >
            <PrimaryInputSearch
              allowClear
              placeholder={t("search")}
              onSearch={(value) => setSearchValue(value)}
            />
          </SimpleFlex>
          <SimpleFlex backgroundcolor="transparent" width="auto" gap="0.5rem">
            <SimpleButton
              padding="0.75rem 1.625rem"
              onClick={() => {
                navigate(`new`, { state: {} });
              }}
            >
              <SimpleFlex alignitems="center" gap="0.5rem" cursor="pointer">
                {t("addMachine")} <PlusDark />
              </SimpleFlex>
            </SimpleButton>
            <SimpleButton
              padding="0.75rem 1.625rem"
              disabled={selectedRowKeys.length === 0}
            >
              {t("duplicate")}
            </SimpleButton>
            <SimpleButton
              padding="0.75rem 1.625rem"
              disabled={selectedRowKeys.length === 0}
            >
              {t("delete")}
            </SimpleButton>
          </SimpleFlex>
        </SimpleFlex>
        <Table
          loading={machines?.isLoading}
          rowSelection={rowSelection}
          dataSource={filteredData}
          columns={columns}
          bordered
          className="w-100 custom-table"
          pagination={{
            position: ["bottomCenter"],
            pageSize: 10,
          }}
        />
      </SimpleFlex>
    </SimpleFlex>
  );
}
export default transition(Machine);
