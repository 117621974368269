import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { useDispatch } from "react-redux";
import { Breadcrumb, Collapse, Form, Table, Tabs, Upload } from "antd";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { PrimaryButton } from "../../../Components/StyledComponents/Button";

import { useState } from "react";
import "./AddMachine.scss";
import { SimpleInput } from "../../../Components/StyledComponents/SimpleInput";
import { PrimarySelect } from "../../../Components/StyledComponents/Select";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import Productivity from "../Productivity";
import RejectionCode from "../RejectionCode";
import StopCode from "../StopCode";
import TagMapping from "../TagMapping";
import { CloseOutlined } from "@ant-design/icons";

function AddMachine() {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [expandedMoreInfo, setExpandeMoreInfo] = useState(false);

  const [selectedRow, setSelectedRow] = useState({
    machine: "",
    shortName: "",
    workCenter: "",
    productionLine: "",
    department: "",
    section: "",
    pinLocation: "",
    shifts: [],
  });

  const breadcrumbItems = [
    { title: "Dashboard", href: "/proton/live-dashboard" },
    { title: "Add Machines", href: "/proton/machines" },
    { title: "Dynamic data", href: "" },
  ];

  const handleAccordionChange = (keys) => {
    setExpanded(!expanded);
  };

  const handleMoreInfoAccordionChange = (keys) => {
    setExpandeMoreInfo(!expandedMoreInfo);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedRow((prevState) => {
      const newState = {
        ...prevState,
        [name]: value,
      };

      return newState;
    });
  };

  const handleSelectChange = (selectedValues, field) => {
    setSelectedRow((prevState) => ({
      ...prevState,
      [field]: selectedValues ?? "",
    }));
  };

  const handleTableInputChange = (e, key) => {
    setCustomFieldDataSource((prevState) =>
      prevState.map((item) =>
        item.key === key
          ? { ...item, [e.target.name]: e.target.value ?? "" }
          : item
      )
    );
  };

  const handleTableSelectChange = (selectedValues, field, key) => {
    setCustomFieldDataSource((prevState) =>
      prevState.map((item) =>
        item.key === key ? { ...item, [field]: selectedValues ?? "" } : item
      )
    );
  };
  const items = [
    {
      key: "1",
      label: "Productivity",
      children: <Productivity />,
    },
    {
      key: "2",
      label: "Rejection codes",
      children: <RejectionCode />,
    },
    {
      key: "3",
      label: "Stop Codes",
      children: <StopCode />,
    },
    {
      key: "4",
      label: "Tags Mapping",
      children: <TagMapping />,
    },
  ];

  const [customFieldDataSource, setCustomFieldDataSource] = useState([
    {
      key: 0,
      customFieldName: "Custom Field",
      type: "high",
      customTextField: "Text",
    },
  ]);

  const [addCustomField, setAddCustomField] = useState(false);

  const handleAdd = () => {
    const newKey = customFieldDataSource.length + 1;
    setCustomFieldDataSource([...customFieldDataSource, { key: newKey }]);
  };

  const handleDelete = (key) => {
    setCustomFieldDataSource(
      customFieldDataSource.filter((item) => item.key !== key)
    );
  };

  const customFieldColumns = [
    {
      title: "Custom Field Name",
      dataIndex: "customFieldName",
      key: "customFieldName",
      sorter: (a, b) => a.customFieldName.localeCompare(b.customFieldName),
      render: (text, record) => (
        <Form>
          <Form.Item
            name="customFieldName"
            className="margin-0"
            rules={[
              {
                required: true,
                message: "Please input Custom Field Name!",
              },
            ]}
            initialValue={record?.customFieldName}
          >
            <SimpleInput
              fontsize="0.875rem"
              name="customFieldName"
              onChange={(e) => handleTableInputChange(e, record?.key)}
            />
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (text, record) => (
        <Form>
          <Form.Item
            name="type"
            className="margin-0"
            rules={[
              {
                required: true,
                message: "Please input Type!",
              },
            ]}
            initialValue={record?.type}
          >
            <PrimarySelect
              className="select-min-width"
              showSearch
              allowClear
              onChange={(value) => {
                handleTableSelectChange(value, "type", record?.key);
              }}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "medium",
                  label: "Medium",
                },
                {
                  value: "high",
                  label: "High",
                },
              ]}
            ></PrimarySelect>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Custom Text Field",
      dataIndex: "customTextField",
      key: "customTextField",
      render: (text, record) => (
        <Form>
          <Form.Item
            name="customTextField"
            className="margin-0"
            rules={[
              {
                required: true,
                message: "Please input Custom Text Field!",
              },
            ]}
            initialValue={record?.customTextField}
          >
            <SimpleInput
              fontsize="0.875rem"
              name="customTextField"
              onChange={(e) => handleTableInputChange(e, record?.key)}
            />
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "1rem",
      render: (text, record) => (
        <SimpleButton
          backgroundcolor="transparent"
          border="none"
          onClick={() => handleDelete(record.key)}
        >
          <P
            fontWeight="var(--font-600)"
            opacity="0.49"
            color="var(--nav-color)"
            cursor="pointer"
          >
            <CloseOutlined />
          </P>
        </SimpleButton>
      ),
    },
  ];

  return (
    <SimpleFlex
      backgroundcolor="transparent"
      height="auto"
      padding="1rem 2rem"
      overflow="scroll"
      flexdirection="column"
      gap="1rem"
    >
      <SimpleFlex
        width="auto"
        height="fit-content"
        backgroundcolor="transparent"
      >
        <Breadcrumb separator=">">
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>
              <a href={item.href}>{item.title}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        flexdirection="column"
      >
        <SimpleFlex
          height="auto"
          backgroundcolor="transparent"
          justifycontent="space-between"
        >
          <P fontsize="2.25rem" fontWeight="var(--font-600)">
            Add Machines
          </P>
          <PrimaryButton padding="0.5rem 1.5rem">Save</PrimaryButton>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex height="auto" borderradius="1.125rem">
        <Collapse
          accordion
          bordered={false}
          className="w-100"
          onChange={handleAccordionChange}
        >
          <Collapse.Panel
            showArrow={false}
            header={
              <SimpleFlex
                backgroundcolor="transparent"
                alignitems="center"
                justifycontent="space-between"
                padding="0 1rem"
                width="auto"
                cursor="pointer"
              >
                <SimpleFlex
                  backgroundcolor="transparent"
                  width="auto"
                  gap="0.5rem"
                >
                  <P
                    fontsize="1.25rem"
                    fontWeight="var(--font-600)"
                    color="var(--nav-color)"
                  >
                    Machine Information
                  </P>
                </SimpleFlex>
                {expanded ? (
                  <SimpleFlex
                    alignitems="center"
                    gap="0.2rem"
                    width="auto"
                    onClick={() => {
                      setExpanded(!expanded);
                    }}
                    backgroundcolor="transparent"
                  >
                    <P
                      color="var(--nav-color)"
                      fontsize="1.875rem"
                      cursor="pointer"
                      fontWeight="var(--font-600)"
                    >
                      -
                    </P>
                  </SimpleFlex>
                ) : (
                  <SimpleFlex
                    alignitems="center"
                    gap="0.2rem"
                    width="auto"
                    onClick={() => {
                      setExpanded(!expanded);
                    }}
                    backgroundcolor="transparent"
                  >
                    <P
                      color="var(--nav-color)"
                      fontsize="1.875rem"
                      cursor="pointer"
                    >
                      +
                    </P>
                  </SimpleFlex>
                )}
              </SimpleFlex>
            }
            key="conditionCard"
          >
            <Form>
              <SimpleFlex
                flexdirection="column"
                gap="2rem"
                backgroundcolor="transparent"
                padding="0 0.9rem"
                width="auto"
              >
                <SimpleFlex
                  flexdirection="column"
                  gap="1rem"
                  backgroundcolor="transparent"
                >
                  <SimpleFlex
                    backgroundcolor="transparent"
                    flexdirection="column"
                    gap="0.5rem"
                  >
                    <P fontsize="0.75rem" color="var(--nav-color)">
                      Machine Name
                    </P>
                    <Form.Item
                      name="machine"
                      className="margin-0"
                      rules={[
                        {
                          required: true,
                          message: "Please input Machine!",
                        },
                      ]}
                      initialValue={selectedRow?.machine}
                    >
                      <SimpleInput
                        name="machine"
                        onChange={handleInputChange}
                        color="var(--black)"
                        borderradius="0.25rem"
                        fontsize="1rem"
                      />
                    </Form.Item>
                  </SimpleFlex>
                  <SimpleFlex gap="2rem" backgroundcolor="transparent">
                    <SimpleFlex
                      backgroundcolor="transparent"
                      flexdirection="column"
                      gap="0.5rem"
                    >
                      <P fontsize="0.75rem" color="var(--nav-color)">
                        Short Name
                      </P>
                      <Form.Item
                        name="shortName"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Short Name!",
                          },
                        ]}
                        initialValue={selectedRow?.shortName}
                      >
                        <SimpleInput
                          name="shortName"
                          onChange={handleInputChange}
                          color="var(--black)"
                          borderradius="0.25rem"
                          fontsize="1rem"
                        />
                      </Form.Item>
                    </SimpleFlex>

                    <SimpleFlex
                      backgroundcolor="transparent"
                      flexdirection="column"
                      gap="0.5rem"
                    >
                      <P color="var(--nav-color)" fontsize="0.75rem">
                        Work Center
                      </P>
                      <Form.Item
                        name="workCenter"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Work Center!",
                          },
                        ]}
                      >
                        <PrimarySelect
                          height="2.3rem !important"
                          borderColor="var(--input-border-color) !important"
                          backgroundcolor="transparent !important"
                          showSearch
                          allowClear
                          onChange={(value) => {
                            handleSelectChange(value, "workCenter");
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "Abdul Nizam",
                              label: "Abdul Nizam",
                            },
                            {
                              value: "Billal Ahmed",
                              label: "Billal Ahmed",
                            },
                            {
                              value: "Mohammed Waris",
                              label: "Mohammed Waris",
                            },
                            {
                              value: "Mohammed Rizwan",
                              label: "Mohammed Rizwan",
                            },
                            {
                              value: "Babar Azam",
                              label: "Babar Azam",
                            },
                          ]}
                        />
                      </Form.Item>
                    </SimpleFlex>

                    <SimpleFlex
                      backgroundcolor="transparent"
                      flexdirection="column"
                      gap="0.5rem"
                    >
                      <P color="var(--nav-color)" fontsize="0.75rem">
                        Production Line
                      </P>
                      <Form.Item
                        name="productionLine"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Production Line!",
                          },
                        ]}
                      >
                        <PrimarySelect
                          height="2.3rem !important"
                          borderColor="var(--input-border-color) !important"
                          backgroundcolor="transparent !important"
                          showSearch
                          allowClear
                          onChange={(value) => {
                            handleSelectChange(value, "productionLine");
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "Abdul Nizam",
                              label: "Abdul Nizam",
                            },
                            {
                              value: "Billal Ahmed",
                              label: "Billal Ahmed",
                            },
                            {
                              value: "Mohammed Waris",
                              label: "Mohammed Waris",
                            },
                            {
                              value: "Mohammed Rizwan",
                              label: "Mohammed Rizwan",
                            },
                            {
                              value: "Babar Azam",
                              label: "Babar Azam",
                            },
                          ]}
                        />
                      </Form.Item>
                    </SimpleFlex>
                  </SimpleFlex>
                  <SimpleFlex gap="2rem" backgroundcolor="transparent">
                    <SimpleFlex
                      backgroundcolor="transparent"
                      flexdirection="column"
                      gap="0.5rem"
                    >
                      <P fontsize="0.75rem" color="var(--nav-color)">
                        Section
                      </P>
                      <Form.Item
                        name="section"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Section!",
                          },
                        ]}
                        initialValue={selectedRow?.shortName}
                      >
                        <PrimarySelect
                          height="2.3rem !important"
                          borderColor="var(--input-border-color) !important"
                          backgroundcolor="transparent !important"
                          showSearch
                          allowClear
                          onChange={(value) => {
                            handleSelectChange(value, "section");
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "Abdul Nizam",
                              label: "Abdul Nizam",
                            },
                            {
                              value: "Billal Ahmed",
                              label: "Billal Ahmed",
                            },
                            {
                              value: "Mohammed Waris",
                              label: "Mohammed Waris",
                            },
                            {
                              value: "Mohammed Rizwan",
                              label: "Mohammed Rizwan",
                            },
                            {
                              value: "Babar Azam",
                              label: "Babar Azam",
                            },
                          ]}
                        />
                      </Form.Item>
                    </SimpleFlex>

                    <SimpleFlex
                      backgroundcolor="transparent"
                      flexdirection="column"
                      gap="0.5rem"
                    >
                      <P color="var(--nav-color)" fontsize="0.75rem">
                        Department
                      </P>
                      <Form.Item
                        name="department"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Department!",
                          },
                        ]}
                      >
                        <PrimarySelect
                          height="2.3rem !important"
                          borderColor="var(--input-border-color) !important"
                          backgroundcolor="transparent !important"
                          showSearch
                          allowClear
                          onChange={(value) => {
                            handleSelectChange(value, "department");
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "Abdul Nizam",
                              label: "Abdul Nizam",
                            },
                            {
                              value: "Billal Ahmed",
                              label: "Billal Ahmed",
                            },
                            {
                              value: "Mohammed Waris",
                              label: "Mohammed Waris",
                            },
                            {
                              value: "Mohammed Rizwan",
                              label: "Mohammed Rizwan",
                            },
                            {
                              value: "Babar Azam",
                              label: "Babar Azam",
                            },
                          ]}
                        />
                      </Form.Item>
                    </SimpleFlex>

                    <SimpleFlex
                      backgroundcolor="transparent"
                      flexdirection="column"
                      gap="0.5rem"
                    >
                      <P color="var(--nav-color)" fontsize="0.75rem">
                        Pin Location
                      </P>
                      <SimpleFlex
                        alignitems="center"
                        backgroundcolor="transparent"
                        gap="1rem"
                      >
                        <Form.Item
                          name="pinLocation"
                          className="margin-0 w-100"
                          rules={[
                            {
                              required: true,
                              message: "Please input Pin Location!",
                            },
                          ]}
                        >
                          <PrimarySelect
                            height="2.3rem !important"
                            borderColor="var(--input-border-color) !important"
                            backgroundcolor="transparent !important"
                            showSearch
                            allowClear
                            onChange={(value) => {
                              handleSelectChange(value, "pinLocation");
                            }}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input)
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                            options={[
                              {
                                value: "Abdul Nizam",
                                label: "Abdul Nizam",
                              },
                              {
                                value: "Billal Ahmed",
                                label: "Billal Ahmed",
                              },
                              {
                                value: "Mohammed Waris",
                                label: "Mohammed Waris",
                              },
                              {
                                value: "Mohammed Rizwan",
                                label: "Mohammed Rizwan",
                              },
                              {
                                value: "Babar Azam",
                                label: "Babar Azam",
                              },
                            ]}
                          />
                        </Form.Item>
                        <Upload
                          accept="image/*"
                          maxCount={1}
                          className="custom-upload"
                        >
                          <P
                            fontsize="0.75rem"
                            textdecorationline="underline"
                            cursor="pointer"
                            width="4rem"
                          >
                            Upload File
                          </P>
                        </Upload>
                      </SimpleFlex>
                    </SimpleFlex>
                  </SimpleFlex>
                  <SimpleFlex
                    backgroundcolor="transparent"
                    flexdirection="column"
                    gap="0.5rem"
                  >
                    <P fontsize="0.75rem" color="var(--nav-color)">
                      Shift
                    </P>
                    <Form.Item
                      name="shifts"
                      className="margin-0"
                      rules={[
                        {
                          required: true,
                          message: "Please input Shift!",
                        },
                      ]}
                      initialValue={selectedRow?.shifts}
                    >
                      <PrimarySelect
                        bordered={false}
                        className="user-select"
                        mode="multiple"
                        showSearch
                        allowClear
                        onChange={(value) => {
                          handleSelectChange(value, "shifts");
                        }}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={[
                          {
                            value: "Abdul Nizam",
                            label: "Abdul Nizam",
                          },
                          {
                            value: "Billal Ahmed",
                            label: "Billal Ahmed",
                          },
                          {
                            value: "Mohammed Waris",
                            label: "Mohammed Waris",
                          },
                          {
                            value: "Mohammed Rizwan",
                            label: "Mohammed Rizwan",
                          },
                          {
                            value: "Babar Azam",
                            label: "Babar Azam",
                          },
                        ]}
                      />
                    </Form.Item>
                  </SimpleFlex>
                  <SimpleFlex
                    backgroundcolor="transparent"
                    flexdirection="column"
                    gap="0.5rem"
                  >
                    <P fontsize="0.75rem" color="var(--nav-color)">
                      Assign Owners
                    </P>
                    <Form.Item
                      name="assignOwners"
                      className="margin-0"
                      rules={[
                        {
                          required: true,
                          message: "Please input Assign Owners!",
                        },
                      ]}
                      initialValue={selectedRow?.assignOwners}
                    >
                      <PrimarySelect
                        bordered={false}
                        className="user-select"
                        mode="multiple"
                        showSearch
                        allowClear
                        onChange={(value) => {
                          handleSelectChange(value, "assignOwners");
                        }}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={[
                          {
                            value: "Abdul Nizam",
                            label: "Abdul Nizam",
                          },
                          {
                            value: "Billal Ahmed",
                            label: "Billal Ahmed",
                          },
                          {
                            value: "Mohammed Waris",
                            label: "Mohammed Waris",
                          },
                          {
                            value: "Mohammed Rizwan",
                            label: "Mohammed Rizwan",
                          },
                          {
                            value: "Babar Azam",
                            label: "Babar Azam",
                          },
                        ]}
                      />
                    </Form.Item>
                  </SimpleFlex>
                </SimpleFlex>
                {addCustomField ? (
                  <SimpleFlex
                    flexdirection="column"
                    backgroundcolor="transparent"
                    gap="1rem"
                  >
                    <SimpleFlex
                      justifycontent="space-between"
                      backgroundcolor="transparent"
                    >
                      <P fontsize="1.25rem" fontWeight="var(--font-600)">
                        {" "}
                        Add Custom Fields
                      </P>
                      <SimpleButton
                        color="var(--black)"
                        padding="0.5rem 1.5rem"
                        border="none"
                        backgroundcolor="transparent"
                        onClick={() => {
                          setAddCustomField(false);
                        }}
                      >
                        <P color="var(--warning-color)" cursor="pointer">
                          <CloseOutlined />
                        </P>
                      </SimpleButton>
                    </SimpleFlex>

                    <Table
                      pagination={false}
                      columns={customFieldColumns}
                      dataSource={customFieldDataSource}
                    />
                    <SimpleFlex
                      justifycontent="flex-end"
                      backgroundcolor="transparent"
                    >
                      <PrimaryButton
                        width="auto"
                        onClick={handleAdd}
                        padding="0.5rem 1rem"
                      >
                        Add Custom Field
                      </PrimaryButton>
                    </SimpleFlex>
                    <SimpleFlex
                      justifycontent="flex-end"
                      backgroundcolor="transparent"
                    >
                      <SimpleButton
                        color="var(--black)"
                        padding="0.5rem 1.5rem"
                      >
                        Cancel
                      </SimpleButton>
                    </SimpleFlex>
                  </SimpleFlex>
                ) : (
                  <SimpleFlex
                    justifycontent="space-between"
                    backgroundcolor="transparent"
                  >
                    <P
                      textdecorationline="underline"
                      fontsize="0.75rem"
                      cursor="pointer"
                      onClick={() => {
                        setAddCustomField(true);
                      }}
                    >
                      {" "}
                      Add Custom Fields
                    </P>
                    <SimpleButton color="var(--black)" padding="0.5rem 1.5rem">
                      Cancel
                    </SimpleButton>
                  </SimpleFlex>
                )}
              </SimpleFlex>
            </Form>
          </Collapse.Panel>
        </Collapse>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        borderradius="1.125rem"
        backgroundcolor="transparent"
      >
        <Collapse
          accordion
          bordered={false}
          className="w-100"
          ghost
          onChange={handleMoreInfoAccordionChange}
        >
          <Collapse.Panel
            showArrow={false}
            header={
              <SimpleFlex
                backgroundcolor="transparent"
                alignitems="center"
                justifycontent="space-between"
                width="auto"
              >
                <SimpleFlex
                  backgroundcolor="transparent"
                  width="auto"
                  gap="0.5rem"
                >
                  <P fontsize="1.25rem" fontWeight="var(--font-600)">
                    More Information
                  </P>
                </SimpleFlex>
                {expandedMoreInfo ? (
                  <SimpleFlex
                    alignitems="center"
                    gap="0.2rem"
                    width="auto"
                    onClick={() => {
                      setExpandeMoreInfo(!expandedMoreInfo);
                    }}
                    backgroundcolor="transparent"
                  >
                    <P
                      color="var(--nav-color)"
                      fontsize="1.875rem"
                      cursor="pointer"
                      fontWeight="var(--font-600)"
                    >
                      -
                    </P>
                  </SimpleFlex>
                ) : (
                  <SimpleFlex
                    alignitems="center"
                    gap="0.2rem"
                    width="auto"
                    onClick={() => {
                      setExpandeMoreInfo(!expandedMoreInfo);
                    }}
                    backgroundcolor="transparent"
                  >
                    <P
                      color="var(--nav-color)"
                      fontsize="1.875rem"
                      cursor="pointer"
                    >
                      +
                    </P>
                  </SimpleFlex>
                )}
              </SimpleFlex>
            }
            key="conditionCard"
          >
            <Tabs type="card" className="tabs customise-tab" items={items} />
          </Collapse.Panel>
        </Collapse>
      </SimpleFlex>
    </SimpleFlex>
  );
}
export default AddMachine;
