import { Collapse } from "antd";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { ReactComponent as CancelIcon } from "../../../assets/svg/cancel.svg";
import { ReactComponent as DownArrowDark } from "../../../assets/svg/downArrowDark.svg";
import { ReactComponent as UpArrowDark } from "../../../assets/svg/upArrowDark.svg";
import "./Filter.scss";
import { motion } from "framer-motion";
import { useState } from "react";

function Guide({ onClose }) {
  const item = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: 100 },
  };

  const mock = [
    {
      title: "Conditions",
      data: [
        "Allow your customers to select from fixed",
        "Allow your customers to select from fixed",
      ],
    },
    {
      title: "Actions",
      data: [
        "Allow your customers to select from fixed",
        "Allow your customers to select from fixed",
      ],
    },
  ];

  const mockItems = [
    "Allow your customers to select from fixed",
    "Allow your customers to select from fixed",
  ];

  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (keys) => {
    setExpanded(!expanded);
  };

  return (
    <motion.div initial="hidden" animate="visible" variants={item}>
      <SimpleFlex
        padding="1rem 2rem"
        width="auto"
        alignitems="baseline"
        className="border-radius"
        minwidth="15rem"
        backgroundcolor="transparent"
        border="1px solid var(--columbia-gray-color)"
        overflow="scroll"
      >
        <SimpleFlex
          flexdirection="column"
          gap="2rem"
          backgroundcolor="transparent"
        >
          <SimpleFlex
            flexdirection="column"
            gap="0.2rem"
            backgroundcolor="transparent"
            height="auto"
          >
            <SimpleFlex
              height="auto"
              alignitems="center"
              justifycontent="space-between"
              backgroundcolor="transparent"
            >
              <P fontWeight="var(--font-500)" fontsize="1.25rem">
                How to create an alert
              </P>
              <p onClick={onClose}>
                <CancelIcon cursor="pointer" />
              </p>
            </SimpleFlex>
            <P color="var(--nav-color)">
              Follow below steps to create an alert
            </P>
          </SimpleFlex>
          {mock?.map((data, index) => {
            return (
              <SimpleFlex height="auto" backgroundcolor="transparent">
                <Collapse
                  accordion
                  bordered={false}
                  className="w-100"
                  onChange={handleAccordionChange}
                >
                  <Collapse.Panel
                    showArrow={false}
                    header={
                      <SimpleFlex
                        backgroundcolor="transparent"
                        alignitems="center"
                        justifycontent="space-between"
                      >
                        <SimpleFlex
                          backgroundcolor="transparent"
                          width="auto"
                          gap="0.5rem"
                        >
                          <SimpleFlex
                            borderradius="50%"
                            border="1px solid var(--columbia-gray-color)"
                            padding="0.2rem 0.5rem"
                            backgroundcolor="transparent"
                          >
                            <P fontsize="0.625rem">{index + 1}</P>
                          </SimpleFlex>
                          <P>{data?.title}</P>
                        </SimpleFlex>
                        {expanded ? (
                          <SimpleFlex
                            alignitems="center"
                            gap="0.2rem"
                            width="auto"
                            onClick={() => {
                              setExpanded(!expanded);
                            }}
                            backgroundcolor="transparent"
                          >
                            <UpArrowDark />
                          </SimpleFlex>
                        ) : (
                          <SimpleFlex
                            alignitems="center"
                            gap="0.2rem"
                            width="auto"
                            onClick={() => {
                              setExpanded(!expanded);
                            }}
                            backgroundcolor="transparent"
                          >
                            <DownArrowDark />
                          </SimpleFlex>
                        )}
                      </SimpleFlex>
                    }
                    key="conditionCard"
                  >
                    <SimpleFlex
                      gap="1rem"
                      flexdirection="column"
                      backgroundcolor="transparent"
                    >
                      {data?.data?.map((item) => {
                        return <P>{item}</P>;
                      })}
                    </SimpleFlex>
                  </Collapse.Panel>
                </Collapse>
              </SimpleFlex>
            );
          })}
        </SimpleFlex>
      </SimpleFlex>
    </motion.div>
  );
}
export default Guide;
