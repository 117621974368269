import { motion } from "framer-motion";

const transition = (OgComponent) => {
  return () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ x: "100%", opacity: 0 }}
      transition={{ duration: 1 }}
      style={{
        height: "100%",
        width: "100%",
        overflowY: "scroll",
        position: "relative",
      }}
    >
      <OgComponent />
    </motion.div>
  );
};

export default transition;

// import styled from "styled-components";
// import { useEffect } from "react";
// import { motion } from "framer-motion";

// export const Container = styled.div`
//   background: #f4f5f8;
//   padding: 2px;
// `;

// export const Content = styled.div`
//   width: 100%;
//   background-color: #ffffff;
//   border-radius: 8px;
//   padding: 10px 10px 10px 10px;
// `;

// export const ContainerBase = styled.div`
//   width: 100%;
//   min-height: 760px;
//   border-radius: 8px;
//   display: flex;
// `;

// const AnimatedContainerBase = (props) => {
//   console.log("props: ", props);
//   useEffect(() => {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   }, []);

//   return (
//     <motion.main
//       className="main__container"
//       initial={{ opacity: 0 }}
//       animate={{ opacity: 1 }}
//       exit={{ x: "100%", opacity: 0 }}
//       transition={{ duration: 1 }}
//     >
//       <ContainerBase>{props.children}</ContainerBase>
//     </motion.main>
//   );
// };

// export default AnimatedContainerBase;
