import { Drawer } from "antd";
import { SimpleFlex } from "../../../../Components/StyledComponents/SimpleFlex";
import { P } from "../../../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../../../Components/StyledComponents/SimpleButton";
import { ReactComponent as PieChart } from "../../../../assets/svg/pieChart.svg";
import { ReactComponent as BarChart } from "../../../../assets/svg/barChart.svg";
import { ReactComponent as Tile } from "../../../../assets/svg/tile.svg";
import { ReactComponent as TableIcon } from "../../../../assets/svg/table.svg";
import { ReactComponent as Timeline } from "../../../../assets/svg/timeLine.svg";
import { ReactComponent as CellStatus } from "../../../../assets/svg/cellStatus.svg";
import { ReactComponent as Template } from "../../../../assets/svg/template.svg";
import { PrimaryInputSearch } from "../../../../Components/StyledComponents/InputSearch";
import { ReactComponent as CheckCircleFilled } from "../../../../assets/svg/checkCircleFilled.svg";
import { useEffect, useState } from "react";
import "./AddWidget.scss";
import { PrimaryButton } from "../../../../Components/StyledComponents/Button";
import { useNavigate } from "react-router-dom";

function AddWidget({ onClose, isOpen }) {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  const listOfCharts = [
    {
      icon: <PieChart />,
      title: "Pie or Donut Chart",
    },
    {
      icon: <BarChart />,
      title: "Line/Bar/Area Chart",
    },
    {
      icon: <Tile />,
      title: "Tile",
    },
    {
      icon: <TableIcon />,
      title: "Table",
    },
    {
      icon: <Timeline />,
      title: "Timeline",
    },
    {
      icon: <CellStatus />,
      title: "Cell Status",
    },
  ];

  const [filteredData, setFilteredData] = useState(listOfCharts);

  useEffect(() => {
    if (searchQuery) {
      const searchFilteredData = listOfCharts.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(searchFilteredData);
    } else {
      setFilteredData(listOfCharts);
    }
  }, [searchQuery]);

  return (
    <Drawer
      title="Add Widget"
      placement="right"
      onClose={onClose}
      open={isOpen}
      mask={false}
    >
      <SimpleFlex
        justifycontent="center"
        height="auto"
        flexdirection="column"
        gap="1rem"
        alignitems="center"
      >
        <SimpleButton padding="0.5rem 1rem" gap="0.6rem" width="auto">
          Choose from Templates <Template />
        </SimpleButton>
        <SimpleFlex alignitems="center" gap="0.5rem" justifycontent="center">
          <P fontsize="0.625rem" color="var(--nav-color)">
            OR
          </P>
          <P color="var(--nav-color)">Drag and Drop Chart Type</P>
        </SimpleFlex>
        <SimpleFlex
          flexdirection="column"
          width="auto"
          justifycontent="center"
          alignitems="center"
          gap="1rem"
        >
          <PrimaryInputSearch
            onSearch={(value) => setSearchQuery(value)}
            placeholder="Search"
            allowClear
          />
          <SimpleFlex flexdirection="column" gap="1rem">
            {filteredData.map((item, index) => {
              return (
                <SimpleFlex
                  flexdirection="column"
                  justifycontent="center"
                  alignitems="center"
                  gap="1rem"
                  backgroundcolor="var(--anti-flash-white)"
                  padding="0.5rem"
                  borderradius="0.375rem"
                  onClick={() => {
                    if (selectedItems.includes(item.title)) {
                      setSelectedItems(
                        selectedItems.filter((i) => i !== item.title)
                      );
                    } else {
                      setSelectedItems([...selectedItems, item.title]);
                    }
                  }}
                  className={
                    selectedItems.includes(item.title)
                      ? "selected-display-border"
                      : ""
                  }
                  position="relative"
                >
                  {selectedItems.includes(item.title) && (
                    <div className="tick-mark-position">
                      <CheckCircleFilled />
                    </div>
                  )}
                  <SimpleFlex
                    justifycontent="center"
                    backgroundcolor="var(--anti-flash-white)"
                    alignitems="center"
                  >
                    {item?.icon}
                  </SimpleFlex>
                  <P color="var(--nav-color)">{item?.title}</P>
                </SimpleFlex>
              );
            })}
          </SimpleFlex>
        </SimpleFlex>
        <SimpleFlex justifycontent="flex-end">
          <PrimaryButton
            padding="0.5rem 1rem"
            onClick={() => {
              navigate("/proton/new-dash-board/new");
            }}
          >
            Create
          </PrimaryButton>
        </SimpleFlex>
      </SimpleFlex>
    </Drawer>
  );
}

export default AddWidget;
