import React from "react";
import styled from "styled-components";
import { ConfigProvider, Space, Button } from "antd";

const CustomButton = styled(Button)`
  background-color: ${(props) =>
    props.backgroundcolor || "var(--primary-color)"};
  border-radius: ${(props) => props.borderradius || "6px"};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  display: ${(props) => props.display || "flex"};
  gap: ${(props) => props.gap || ""};
  justify-content: ${(props) => props.justifycontent || ""};
  align-items: ${(props) => props.alignItems || "center"};
  border: none;
  padding: ${(props) => props.padding || "0rem"};
  color: ${(props) => props.color || "var(--anti-flash-white)"};
  box-shadow: none;
  cursor: pointer;
  font-weight: ${(props) => props.fontWeight || "var(--font-400)"};
  font-size: ${(props) => props.fontsize || "0.875rem"};
  &:hover {
    background-color: ${(props) => props.hoverColor || "none"};
    color: ${(props) => props.hoverTextColor || "none"};
  }
`;

export const PrimaryButton = (props) => {
  const { onClick } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#21223d",
          colorPrimaryHover: "white",
          colorPrimaryActive: "white",
        },
      }}
    >
      <Space>
        <CustomButton {...props} onClick={handleClick}>
          {props.children}
        </CustomButton>
      </Space>
    </ConfigProvider>
  );
};
