import React from "react";
import styled from "styled-components";
import { ConfigProvider, Space, Button } from "antd";

const CustomButton = styled(Button)`
  background-color: ${(props) => props.backgroundcolor || "var(--white)"};
  border-radius: ${(props) => props.borderradius || "6px"};
  display: ${(props) => props.display || "flex"};
  align-items: ${(props) => props.alignItems || "center"};
  justify-content: ${(props) => props.justifycontent || ""};
  gap: ${(props) => props.gap || ""};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  border: ${(props) => props.border || "1px solid var(--columbia-gray-color)"};
  padding: ${(props) => props.padding || "0rem"};
  margin: ${(props) => props.margin || "0rem"};
  color: ${(props) => props.color || "var(--nav-color)"};
  box-shadow: none;
  cursor: pointer;
  font-weight: ${(props) => props.fontWeight || "var(--font-400)"};
  font-size: ${(props) => props.fontsize || "0.875rem"};
  &:hover {
    background-color: ${(props) => props.hoverColor || "none"};
    color: ${(props) => props.hoverTextColor || "none"};
  }
`;

export const SimpleButton = (props) => {
  const { onClick } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "white",
          colorPrimaryHover: "none",
          colorPrimaryActive: "none",
        },
      }}
    >
      <Space>
        <CustomButton {...props} onClick={handleClick}>
          {props.children}
        </CustomButton>
      </Space>
    </ConfigProvider>
  );
};
