import { SimpleFlex } from "../../../../../../Components/StyledComponents/SimpleFlex";
import { P } from "../../../../../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../../../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../../../../Components/StyledComponents/Button";
import { useEffect, useState } from "react";
import { PrimaryModal } from "../../../../../../Components/StyledComponents/Modal";
import { Radio, TimePicker } from "antd";
import { PrimarySelect } from "../../../../../../Components/StyledComponents/Select";
import { ReactComponent as CheckFilled } from "../../../../../../assets/svg/checkFilled.svg";
import { ReactComponent as CheckOutlined } from "../../../../../../assets/svg/checkOutlined.svg";
import "./AddDowntime.scss";

function AddDownTime({ showModal, closeButton }) {
  const [visible, setVisible] = useState(showModal);
  const [step, setStep] = useState(1);
  const [data, setData] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const categories = {
    Man: ["Lunch Break", "Knowledge gap", "Wrong Handling", "Handling"],
    Machine: ["AB", "CD"],
    Material: ["PQ", "RS"],
    Environment: ["PQ", "RS"],
  };
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const [selectedValue, setSelectedValue] = useState(null);
  const handleChange = (value) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    if (showModal) {
      setStep(1);
      setData(null);
    }
    setVisible(showModal);
  }, [showModal]);

  const handleOk = () => {
    if (step === 1) {
      setStep(2);
    } else {
      setVisible(false);
      setSelectedCategory(null);
      setSelectedSubCategory(null);
      setStartTime(null);
      setEndTime(null);
      setSelectedValue(null);
      closeButton();
    }
  };

  const handleCancel = () => {
    setStep(1);
    setData(null);
    setVisible(false);
    setSelectedCategory(null);
    setSelectedSubCategory(null);
    setStartTime(null);
    setEndTime(null);
    setSelectedValue(null);
    closeButton();
  };

  const handleTimeChange = (time) => {
    if (!time) {
      setStartTime(null);
      setEndTime(null);
    }
    setStartTime(time[0]);
    setEndTime(time[1]);

    const startTime = new Date(time[0]["$d"]);
    const endTime = new Date(time[1]["$d"]);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = endTime - startTime;

    // Convert the difference into hours and minutes
    const hours = Math.floor(differenceInMilliseconds / 1000 / 60 / 60);
    const minutes = Math.floor((differenceInMilliseconds / 1000 / 60) % 60);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedSubCategory(null);
  };

  const handleSubCategoryChange = (e) => {
    setSelectedSubCategory(e.target.value);
  };

  const handleDataChange = (newData) => {
    setData(newData);
  };

  const isNextButtonDisabled =
    step === 1 &&
    (!selectedCategory || !selectedSubCategory || !startTime || !endTime);

  const isAddButtonDisabled = step === 1 && !selectedValue;

  const Modalfooter = (
    <SimpleFlex margin="1.5rem 0 0 0">
      {step === 1 && (
        <SimpleFlex gap="1rem">
          <SimpleButton onClick={handleCancel} padding="0.5rem 1rem">
            Cancel
          </SimpleButton>
          <PrimaryButton
            onClick={handleOk}
            padding="0.5rem 1rem"
            disabled={isNextButtonDisabled}
          >
            {step < 2 ? "Next" : "Add"}
          </PrimaryButton>
        </SimpleFlex>
      )}
      {step === 2 && (
        <SimpleFlex gap="1rem">
          <SimpleButton onClick={() => setStep(1)} padding="0.5rem 1rem">
            Back
          </SimpleButton>
          <PrimaryButton
            onClick={handleOk}
            padding="0.5rem 1rem"
            disabled={isAddButtonDisabled}
          >
            {step < 2 ? "Next" : "Add"}
          </PrimaryButton>
        </SimpleFlex>
      )}
    </SimpleFlex>
  );

  return (
    <SimpleFlex flexdirection="column" backgroundcolor="transparent" gap="1rem">
      <PrimaryModal
        centered
        className="custom-modal"
        title="Add Downtime"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        onClose={handleCancel}
        footer={Modalfooter}
        maskClosable={false}
      >
        {step === 1 && (
          <SimpleFlex flexdirection="column" gap="1rem">
            <P>Coil Machine 1</P>
            <P fontsize="0.75rem">Select Downtime Type</P>
            <Radio.Group
              onChange={(e) => handleCategoryChange(e)}
              value={selectedCategory}
              className="radio-group"
              buttonStyle="solid"
            >
              {Object.keys(categories).map((category) => (
                <div>
                  <Radio.Button
                    key={category}
                    value={category}
                    className="radio-button"
                  >
                    <SimpleFlex
                      justifycontent="space-between"
                      alignitems="center"
                      backgroundcolor="transparent"
                      cursor="pointer"
                      fontsize="0.813rem"
                    >
                      {category}{" "}
                      {selectedCategory === category ? <CheckOutlined /> : ""}
                    </SimpleFlex>
                  </Radio.Button>
                </div>
              ))}
            </Radio.Group>
            {selectedCategory && (
              <Radio.Group
                className="radio-group"
                onChange={(e) => handleSubCategoryChange(e)}
                value={selectedSubCategory}
              >
                {categories[selectedCategory].map((subCategory) => (
                  <div>
                    <Radio.Button
                      key={subCategory}
                      value={subCategory}
                      className="radio-button"
                    >
                      <SimpleFlex
                        justifycontent="space-between"
                        alignitems="center"
                        backgroundcolor="transparent"
                        cursor="pointer"
                        fontsize="0.813rem"
                        gap="0.5rem"
                      >
                        {subCategory}{" "}
                        {selectedSubCategory === subCategory ? (
                          <CheckFilled />
                        ) : (
                          ""
                        )}
                      </SimpleFlex>
                    </Radio.Button>
                  </div>
                ))}
              </Radio.Group>
            )}
            <SimpleFlex flexdirection="column" gap="1rem">
              <P fontsize="0.75rem">Select Downtime</P>
              <TimePicker.RangePicker
                value={[startTime, endTime]}
                onChange={(time) => {
                  handleTimeChange(time);
                }}
              />
            </SimpleFlex>
          </SimpleFlex>
        )}
        {step === 2 && (
          <SimpleFlex flexdirection="column" gap="0.3rem">
            <SimpleFlex flexdirection="column" gap="1rem">
              <P>Coil Machine 1</P>
              <P fontsize="0.75rem">Select Downtime Reson</P>
            </SimpleFlex>
            <PrimarySelect
              bordered={false}
              allowClear={true}
              defaultValue={selectedValue}
              showSearch
              className="custom-select"
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "notIdentified",
                  label: "Not Identified",
                },
                {
                  value: "closed",
                  label: "Closed",
                },
                {
                  value: "communicated",
                  label: "Communicated",
                },
                {
                  value: "identified",
                  label: "Identified",
                },
                {
                  value: "resolved",
                  label: "Resolved",
                },
                {
                  value: "cancelled",
                  label: "Cancelled",
                },
              ]}
              onChange={handleChange}
            />
          </SimpleFlex>
        )}
      </PrimaryModal>
    </SimpleFlex>
  );
}

export default AddDownTime;
