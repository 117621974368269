import { P } from "../../../../Components/StyledComponents/Paragraph";
import { SimpleFlex } from "../../../../Components/StyledComponents/SimpleFlex";
import { ReactComponent as EditLeftFaced } from "../../../../assets/svg/editLeftFacedOutlined.svg";
import { ReactComponent as ColorPaletteCircled } from "../../../../assets/svg/colorPaletteCircled.svg";
import { ReactComponent as EditCircled } from "../../../../assets/svg/editCircled.svg";
import { ReactComponent as FullScreenCircled } from "../../../../assets/svg/fullScreenCircled.svg";
import { ReactComponent as PageCircled } from "../../../../assets/svg/pageCircled.svg";
import { ReactComponent as BarCircled } from "../../../../assets/svg/barCircled.svg";
import { ReactComponent as ThreeDotsCircled } from "../../../../assets/svg/threeDotsCircled.svg";
import { ReactComponent as PieChart } from "../../../../assets/svg/pieChart.svg";
import { ReactComponent as LineChart } from "../../../../assets/svg/lineChart.svg";
import { ReactComponent as Tile } from "../../../../assets/svg/tile.svg";
import { ReactComponent as Timeline } from "../../../../assets/svg/timeLine.svg";
import { ReactComponent as Table } from "../../../../assets/svg/table.svg";
import { ReactComponent as CellStatus } from "../../../../assets/svg/cellStatus.svg";
import { ReactComponent as FilterIcon } from "../../../../assets/svg/filter.svg";

import { SimpleButton } from "../../../../Components/StyledComponents/SimpleButton";
import { Dropdown } from "antd";
import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import ChartConfig from "../ChartConfig";
import ChartConfigII from "../ChartConfigII";
import Filter from "../Filter";
import "./ChartCard.scss";

function ChartCard() {
  const [chartType, setChartType] = useState("line");
  const [chartCard, setChartCard] = useState(false);
  const [displaySettings, setDisplaySettings] = useState(false);
  const [filter, setFilter] = useState(false);

  const onCloseFilter = () => {
    setFilter(false);
  };

  const onCloseDisplaySettings = () => {
    setDisplaySettings(false);
  };

  const onCloseChartCard = () => {
    setChartCard(false);
  };

  const handleChartTypeChange = ({ key }) => {
    setChartType(key);
  };

  const menuItems = [
    {
      label: (
        <SimpleFlex
          alignitems="center"
          gap="0.8rem"
          backgroundcolor="transparent"
          cursor="pointer"
          padding="0.2rem 0.5rem"
        >
          <PieChart height="1rem" width="1rem" />
          <P color="var(--nav-color)" fontsize="1rem" cursor="pointer">
            Pie or Donut Chart
          </P>
        </SimpleFlex>
      ),
      key: "pie",
    },
    {
      label: (
        <SimpleFlex
          alignitems="center"
          gap="0.8rem"
          backgroundcolor="transparent"
          cursor="pointer"
          padding="0.2rem 0.5rem"
        >
          <LineChart height="1rem" width="1rem" />
          <P color="var(--nav-color)" fontsize="1rem" cursor="pointer">
            Line/Bar/Area Chart
          </P>
        </SimpleFlex>
      ),
      key: "line",
    },
    {
      label: (
        <SimpleFlex
          alignitems="center"
          gap="0.8rem"
          backgroundcolor="transparent"
          cursor="pointer"
          padding="0.2rem 0.5rem"
        >
          <Tile height="1rem" width="1rem" />
          <P color="var(--nav-color)" fontsize="1rem" cursor="pointer">
            Tile
          </P>
        </SimpleFlex>
      ),
      key: "tile",
    },
    {
      label: (
        <SimpleFlex
          alignitems="center"
          gap="0.8rem"
          backgroundcolor="transparent"
          cursor="pointer"
          padding="0.2rem 0.5rem"
        >
          <Timeline height="1rem" width="1rem" />
          <P color="var(--nav-color)" fontsize="1rem" cursor="pointer">
            Timeline
          </P>
        </SimpleFlex>
      ),
      key: "timeline",
    },
    {
      label: (
        <SimpleFlex
          alignitems="center"
          gap="0.8rem"
          backgroundcolor="transparent"
          cursor="pointer"
          padding="0.2rem 0.5rem"
        >
          <Table height="1rem" width="1rem" />
          <P color="var(--nav-color)" fontsize="1rem" cursor="pointer">
            Table
          </P>
        </SimpleFlex>
      ),
      key: "table",
    },
    {
      label: (
        <SimpleFlex
          alignitems="center"
          gap="0.8rem"
          backgroundcolor="transparent"
          cursor="pointer"
          padding="0.2rem 0.5rem"
        >
          <CellStatus height="1rem" width="1rem" />
          <P color="var(--nav-color)" fontsize="1rem" cursor="pointer">
            Cell Status
          </P>
        </SimpleFlex>
      ),
      key: "cellStatus",
    },
  ];

  return (
    <SimpleFlex
      flexdirection="column"
      maxwidth="35rem"
      backgroundcolor="transparent"
      gap="1.5rem"
    >
      <SimpleFlex backgroundcolor="transparent" height="auto">
        <SimpleFlex
          backgroundcolor="transparent"
          alignitems="center"
          gap="0.5rem"
        >
          <EditLeftFaced />
          <P fontsize="1.125rem" fontWeight="var(--font-500)">
            Rejection Trends
          </P>
        </SimpleFlex>
        <SimpleFlex
          backgroundcolor="transparent"
          alignitems="center"
          gap="0.75rem"
        >
          <SimpleButton
            borderradius="50%"
            padding="0.8rem"
            onClick={() => setFilter(true)}
          >
            <FilterIcon />
          </SimpleButton>
          <SimpleButton border="none" borderradius="50%">
            <FullScreenCircled />
          </SimpleButton>
          <SimpleButton border="none" borderradius="50%">
            <ColorPaletteCircled onClick={() => setDisplaySettings(true)} />
          </SimpleButton>
          <SimpleButton border="none" borderradius="50%">
            <EditCircled onClick={() => setChartCard(true)} />
          </SimpleButton>
          <Dropdown
            menu={{
              items: menuItems,
              onClick: handleChartTypeChange,
            }}
            trigger={["click"]}
            placement="bottomRight"
          >
            <a onClick={(e) => e.preventDefault()}>
              <SimpleButton border="none" borderradius="50%">
                <BarCircled />
              </SimpleButton>
            </a>
          </Dropdown>

          <SimpleButton border="none" borderradius="50%">
            <ThreeDotsCircled />
          </SimpleButton>
        </SimpleFlex>
      </SimpleFlex>
      <Graph chartType={chartType} />
      <ChartConfig onClose={onCloseChartCard} isOpen={chartCard} />
      <ChartConfigII
        onClose={onCloseDisplaySettings}
        isOpen={displaySettings}
      />
      <Filter onClose={onCloseFilter} isOpen={filter} />
    </SimpleFlex>
  );
}

function Graph({ chartType }) {
  const pieChart = () => {
    (function (H) {
      H.seriesTypes.pie.prototype.animate = function (init) {
        const series = this,
          chart = series.chart,
          points = series.points,
          { animation } = series.options,
          { startAngleRad } = series;

        function fanAnimate(point, startAngleRad) {
          const graphic = point.graphic,
            args = point.shapeArgs;

          if (graphic && args) {
            graphic
              // Set inital animation values
              .attr({
                start: startAngleRad,
                end: startAngleRad,
                opacity: 1,
              })
              // Animate to the final position
              .animate(
                {
                  start: args.start,
                  end: args.end,
                },
                {
                  duration: animation.duration / points.length,
                },
                function () {
                  // On complete, start animating the next point
                  if (points[point.index + 1]) {
                    fanAnimate(points[point.index + 1], args.end);
                  }
                  // On the last point, fade in the data labels, then
                  // apply the inner size
                  if (point.index === series.points.length - 1) {
                    series.dataLabelsGroup.animate(
                      {
                        opacity: 1,
                      },
                      void 0,
                      function () {
                        points.forEach((point) => {
                          point.opacity = 1;
                        });
                        series.update(
                          {
                            enableMouseTracking: true,
                          },
                          false
                        );
                        chart.update({
                          plotOptions: {
                            pie: {
                              innerSize: "40%",
                              borderRadius: 8,
                            },
                          },
                        });
                      }
                    );
                  }
                }
              );
          }
        }

        if (init) {
          // Hide points on init
          points.forEach((point) => {
            point.opacity = 0;
          });
        } else {
          fanAnimate(points[0], startAngleRad);
        }
      };
    })(Highcharts);

    const data = [
      {
        name: "A",
        y: 10,
      },
      {
        name: "B",
        y: 10,
      },
      {
        name: "C",
        y: 10,
      },
      {
        name: "D",
        y: 15,
      },
      {
        name: "E",
        y: 25,
      },
      {
        name: "F",
        y: 15,
      },
      {
        name: "G",
        y: 15,
      },
    ];

    Highcharts.chart("container", {
      chart: {
        type: "pie",
        height: 250,
      },
      title: {
        text: "",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          borderWidth: 2,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b><br>{point.percentage}%",
            distance: 20,
          },
        },
      },
      series: [
        {
          enableMouseTracking: false,
          animation: {
            duration: 500,
          },
          colorByPoint: true,
          data,
        },
      ],
    });
  };

  const lineChart = () => {
    const data = [4, 6, 3, 2, 4, 1, 6, 7, 1, 2];
    Highcharts.chart("container", {
      chart: {
        type: "column",
        height: 250,
      },
      title: {
        text: null,
      },
      xAxis: {
        visible: false,
        categories: null,
      },
      yAxis: {
        visible: false,
        plotBands: [
          {
            color: "#000000", // Set the color to gray
            from: 0, // Start of the band
            to: 1, // End of the band
          },
        ],
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          stacking: "percent",
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      series: [
        {
          data,
          color: "var(--nav-color)",
          borderRadius: "10px 10px 0 0",
        },
      ],
    });
  };

  useEffect(() => {
    switch (chartType) {
      case "line":
        lineChart();
        break;
      case "pie":
        pieChart();
        break;
      case "tile":
        console.log("Value is tile");
        break;
      case "timeline":
        console.log("Value is timelinw");
        break;
      case "table":
        console.log("Value is table");
        break;
      case "cellStatus":
        console.log("Value is cellStatus");
        break;
      default:
        lineChart();
    }
  }, [chartType]);

  return (
    <figure className="highcharts-figure w-100 margin-0">
      <div id="container" className="border-radius-18"></div>
    </figure>
  );
}

export default ChartCard;
