import React from "react";
import styled from "styled-components";
import { ConfigProvider, Space, Divider } from "antd";

const CustomDivider = styled(Divider)`
  margin: ${(props) => props.margin || "1rem"};
`;

export const PrimaryDivider = (props) => {
  return (
    <ConfigProvider
      theme={{
        token: {},
      }}
    >
      <CustomDivider {...props}>{props.children}</CustomDivider>
    </ConfigProvider>
  );
};
