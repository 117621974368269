import { Empty, Pagination } from "antd";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../Components/StyledComponents/Button";
import { useEffect, useState } from "react";
import { PrimaryCheckBox } from "../../../Components/StyledComponents/CheckBox";
import { SimpleSwitch } from "../../../Components/StyledComponents/Switch";

function DataCard({ filteredData, searchValue }) {
  const [mutableFilteredData, setMutableFilteredData] = useState([]);

  useEffect(() => {
    // Update mutableFilteredData when filteredData changes
    setMutableFilteredData(
      filteredData.map((item) => ({ ...item, isChecked: false }))
    );
  }, [filteredData]);

  const [searchTerm, setSearchTerm] = useState(searchValue);

  useEffect(() => {
    setSearchTerm(searchValue);
    setCurrentPage(1);
  }, [searchValue]);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 2; // number of items to display per page
  // total number of items

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const filteredItemsToDisplay = mutableFilteredData?.filter((item) => {
    const searchString = `${item.title} ${item.description} ${item.createdOn}`;
    return searchString.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const itemsToDisplay = filteredItemsToDisplay?.slice(startIndex, endIndex);
  const totalItems = filteredItemsToDisplay?.length;

  const handleCheckboxChange = (id) => {
    setMutableFilteredData((prevData) =>
      prevData.map((item) =>
        item.key === id ? { ...item, isChecked: !item.isChecked } : item
      )
    );
  };

  const handleSwitchChange = (id) => {
    setMutableFilteredData((prevData) =>
      prevData.map((item) =>
        item.key === id ? { ...item, isEnabled: !item.isEnabled } : item
      )
    );
  };

  return (
    <>
      {itemsToDisplay?.map((data, index) => (
        <SimpleFlex
          height="auto"
          padding="1rem"
          width="auto"
          borderradius="0.625rem"
          key={data.key}
        >
          <SimpleFlex
            width="auto"
            alignitems="baseline"
            margin="0.2rem 1rem 0 0"
          >
            <PrimaryCheckBox
              onChange={() => handleCheckboxChange(data?.key)}
              checked={data.isChecked}
            />
          </SimpleFlex>
          <SimpleFlex
            flexdirection="column"
            gap="1rem"
            justifycontent="space-between"
          >
            <P fontsize="1.25rem" fontWeight="var(--font-500)">
              {data?.title}
            </P>
            {data?.data?.map((data, index) => (
              <SimpleFlex flexdirection="column" gap="1rem">
                <SimpleFlex flexdirection="column" gap="0.2rem">
                  <P fontsize="0.75rem" color="var(--nav-color)">
                    {data?.title} :
                  </P>
                  <P>{data?.description}</P>
                </SimpleFlex>
                {data?.data?.map((data, index) => (
                  <SimpleFlex
                    flexdirection="column"
                    gap="0.2rem"
                    margin="0 0 0 1rem"
                  >
                    <P fontsize="0.75rem" color="var(--nav-color)">
                      {data?.title} :
                    </P>
                    <P>{data?.description}</P>
                  </SimpleFlex>
                ))}
              </SimpleFlex>
            ))}
            <SimpleFlex flexdirection="column" height="auto">
              <P fontsize="0.75rem" color="var(--nav-color)">
                Created On :
              </P>
              <P>{data?.createdOn}</P>
            </SimpleFlex>
          </SimpleFlex>
          <SimpleFlex
            flexdirection="column"
            width="auto"
            alignitems="center"
            justifycontent="space-between"
          >
            <SimpleButton
              width="7rem"
              padding="0.5rem 0.625rem"
              justifycontent="center"
              gap="0.5rem"
            >
              {data?.isEnabled ? "Disable" : "Enable"}
              <SimpleSwitch
                onChange={(e) => {
                  handleSwitchChange(data?.key);
                }}
                size="small"
                checked={data?.isEnabled}
              ></SimpleSwitch>
            </SimpleButton>
            <PrimaryButton
              width="7rem"
              padding="0.5rem 2.125rem"
              justifycontent="center"
            >
              Edit
            </PrimaryButton>
          </SimpleFlex>
        </SimpleFlex>
      ))}
      {totalItems > 0 && (
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={totalItems}
          onChange={handlePageChange}
        />
      )}
      {totalItems === 0 && (
        <SimpleFlex
          alignitems="center"
          justifycontent="center"
          borderradius="1.125rem"
          height="auto"
        >
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </SimpleFlex>
      )}
    </>
  );
}
export default DataCard;
