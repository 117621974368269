import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

export const fetchStandardDashboardList = createAsyncThunk(
  "fetchStandardDashboardList",
  async () => {
    // const response = await fetch("https://your-api-endpoint/plants");
    // return response.json();

    // throw new Error("");
    // await new Promise((resolve) => setTimeout(resolve, 2000));
    return [
      {
        key: "1",
        name: "Rida plant 1",
        description: "Switch Making Plant",
        id: "abcd",
      },
      {
        key: "2",
        name: "Rida plant 2",
        description: "Switch Making Plant",
        id: "dcba",
      },
    ];
  }
);

// Action to add a new plant
export const addStandardDashboardList = createAsyncThunk(
  "addStandardDashboardList",
  async (newPlant) => {
    const response = await fetch("https://your-api-endpoint/plants", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newPlant),
    });
    return response.json();
  }
);

// Action to delete a plant
export const deleteStandardDashboardList = createAsyncThunk(
  "deleteStandardDashboardList",
  async (plantId) => {
    const response = await fetch(
      `https://your-api-endpoint/plants/${plantId}`,
      {
        method: "DELETE",
      }
    );
    return response.json();
  }
);

// Action to edit a plant
export const editStandardDashboardList = createAsyncThunk(
  "editStandardDashboardList",
  async (editStandardDashboardList) => {
    // const response = await fetch(
    //   `https://your-api-endpoint/plants/${editedPlant.key}`,
    //   {
    //     method: "PUT",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(editStandardDashboardList),
    //   }
    // );
    // return response.json();

    // plant is edited successfully
    const dispatch = useDispatch();
    dispatch(fetchStandardDashboardList());
    return editStandardDashboardList;
  }
);

const standardDashboardListSlice = createSlice({
  name: "plants",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    selectedStandardDashboardList: null,
  },
  reducers: {
    setSelectedStandardDashboardList: (state, action) => {
      state.selectedStandardDashboardList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStandardDashboardList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchStandardDashboardList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchStandardDashboardList.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(addStandardDashboardList.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
    });
    builder.addCase(deleteStandardDashboardList.fulfilled, (state, action) => {
      state.data = state.data.filter(
        (plant) => plant.key !== action.payload.key
      );
    });
    builder.addCase(editStandardDashboardList.fulfilled, (state, action) => {
      state.data = state.data.map((plant) =>
        plant.key === action.payload.key ? action.payload : plant
      );
      state.selectedStandardDashboardList = null; // Clear selected plant after edit
    });
  },
});

export const { setSelectedStandardDashboardList } =
  standardDashboardListSlice.actions;

export default standardDashboardListSlice.reducer;
