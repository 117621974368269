import { Breadcrumb, Table } from "antd";
import { SimpleFlex } from "../../Components/StyledComponents/SimpleFlex";
import { P } from "../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../Components/StyledComponents/Button";
import { ReactComponent as Plus } from "../../assets/svg/plus.svg";
import { ReactComponent as Edit } from "../../assets/svg/edit.svg";
import { ReactComponent as UploadFile } from "../../assets/svg/uploadFile.svg";
import { useEffect, useState } from "react";
import { PrimaryInputSearch } from "../../Components/StyledComponents/InputSearch";
import EditProduct from "./EditProductModal";
import AddProduct from "./AddProductModal";
import transition from "../../Transition";
import { useTranslation } from "react-i18next";
import "./Product.scss";

function Products() {
  const { t } = useTranslation();
  const breadcrumbItems = [
    { title: t("dashboard"), href: "/proton/live-dashboard" },
    { title: t("productManagement"), href: "" },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showEditProduct, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const dataSource = [
    {
      key: "1",
      productName: "M44Q Switch Board",
      description: "one",
      shortName: "OW Switch",
      sku: "SBM44Q",
      externalID: "EID-SBM44Q",
    },
    {
      key: "2",
      productName: "M44Q Switch Board",
      description: "two",
      shortName: "OW Switch",
      sku: "BBM44Q",
      externalID: "EID-SBM44Q",
    },
    {
      key: "3",
      productName: "M44Q Switch Board",
      description: "three",
      shortName: "OW Switch",
      sku: "ABM44Q",
      externalID: "EID-SBM44Q",
    },
  ];

  const columns = [
    {
      title: t("productName"),
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: t("description"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("shortName"),
      dataIndex: "shortName",
      key: "shortName",
    },
    {
      title: t("sku"),
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: t("externalID"),
      dataIndex: "externalID",
      key: "externalID",
    },
    {
      title: t("action"),
      dataIndex: "",
      key: "x",
      render: (record) => (
        <SimpleButton
          padding="0.4rem 0.8rem"
          onClick={() => {
            setSelectedRow(record);
            setShowEditModal(true);
          }}
        >
          <SimpleFlex cursor="pointer" alignitems="center" gap="0.2rem">
            <P color="var(--black)" cursor="pointer" fontsize="1rem">
              {t("edit")}
            </P>{" "}
            <P fontsize="0.3rem" color="var(--primary-color)" cursor="pointer">
              <Edit />
            </P>
          </SimpleFlex>
        </SimpleButton>
      ),
    },
  ];

  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState(dataSource);

  useEffect(() => {
    if (searchValue) {
      setFilteredData(
        dataSource.filter(
          (item) =>
            item.productName
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            item.description
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            item.shortName.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.sku.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.externalID.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else {
      setFilteredData(dataSource);
    }
  }, [searchValue]);

  return (
    <SimpleFlex
      backgroundcolor="transparent"
      padding="1rem 2rem"
      overflow="scroll"
      flexdirection="column"
      gap="1rem"
      width="auto"
      height="auto"
    >
      <SimpleFlex
        width="auto"
        height="fit-content"
        backgroundcolor="transparent"
      >
        <Breadcrumb separator=">">
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>
              <a href={item.href}>{item.title}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        justifycontent="space-between"
      >
        <P fontsize="2.25rem" fontWeight="var(--font-600)">
          {t("productManagement")}
        </P>
        <SimpleFlex width="auto" gap="1rem" backgroundcolor="transparent">
          <SimpleButton padding="0.75rem 1.625rem">
            <SimpleFlex cursor="pointer" alignitems="center" gap="0.5rem">
              <P
                color="var(--nav-color)"
                fontWeight="var(--font-500)"
                cursor="pointer"
              >
                {t("uploadFile")}
              </P>{" "}
              <UploadFile />
            </SimpleFlex>
          </SimpleButton>
          <PrimaryButton
            padding="0.75rem 1.625rem"
            onClick={() => {
              setShowAddModal(true);
            }}
          >
            <SimpleFlex
              cursor="pointer"
              alignitems="center"
              gap="0.5rem"
              backgroundcolor="transparent"
            >
              <P
                color="var(--columbia-gray-color)"
                fontWeight="var(--font-500)"
                cursor="pointer"
              >
                {t("addProduct")}
              </P>{" "}
              <Plus />
            </SimpleFlex>{" "}
          </PrimaryButton>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        flexdirection="column"
        gap="1rem"
      >
        <SimpleFlex
          backgroundcolor="transparent"
          justifycontent="space-between"
        >
          <SimpleFlex
            width="auto"
            alignitems="center"
            backgroundcolor="transparent"
            className="transparent-search"
          >
            <PrimaryInputSearch
              allowClear
              placeholder={t("search")}
              onSearch={(value) => setSearchValue(value)}
            />
          </SimpleFlex>
          <SimpleFlex backgroundcolor="transparent" width="auto" gap="0.5rem">
            <SimpleButton
              padding="0.75rem 1.625rem"
              disabled={selectedRowKeys.length === 0}
            >
              {t("delete")}
            </SimpleButton>
            <SimpleButton
              padding="0.75rem 1.625rem"
              disabled={selectedRowKeys.length === 0}
            >
              {t("update")}
            </SimpleButton>
          </SimpleFlex>
        </SimpleFlex>
        <Table
          rowSelection={rowSelection}
          dataSource={filteredData}
          columns={columns}
          bordered
          className="w-100 custom-table"
          pagination={{
            position: ["bottomCenter"],
            pageSize: 2,
          }}
        />
      </SimpleFlex>
      {!!showEditProduct && (
        <EditProduct
          payload={selectedRow}
          closeButton={() => {
            setShowEditModal(false);
          }}
        />
      )}
      {!!showAddModal && (
        <AddProduct
          closeButton={() => {
            setShowAddModal(false);
          }}
        />
      )}
    </SimpleFlex>
  );
}
export default transition(Products);
