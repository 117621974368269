import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { processPost } from "../../../HttpRequest";

export const sendPasswordResetEmail = createAsyncThunk(
  "sendPasswordResetEmail",
  async (email) => {
    try {
      const response = await processPost("user/passwordReset", {
        token: "2000",
      });
      console.log("response: ", response);
    } catch (error) {
      throw error;
    }
    // const response = await fetch("https://your-api-endpoint/plants");
    // return response.json();
    // throw new Error("");
    // await new Promise((resolve) => setTimeout(resolve, 2000));
  }
);

const forgotPasswordSlice = createSlice({
  name: "forgotPasswordName",
  initialState: {
    isLoading: false,
    isError: false,
    success: false,
  },
  extraReducers: (builder) => {
    builder.addCase(sendPasswordResetEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(sendPasswordResetEmail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = true;
    });
    builder.addCase(sendPasswordResetEmail.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export default forgotPasswordSlice.reducer;
