import { Form, Table } from "antd";
import { useEffect, useState } from "react";
import { SimpleInput } from "../../../Components/StyledComponents/SimpleInput";
import { PrimarySelect } from "../../../Components/StyledComponents/Select";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { PrimaryInputSearch } from "../../../Components/StyledComponents/InputSearch";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { PlusOutlined } from "@ant-design/icons";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { ReactComponent as Edit } from "../../../assets/svg/edit.svg";
import "./TagMapping.scss";
import AddTagMapping from "./AddTagMapping";
import EditTagMapping from "./EditTagMapping";

function TagMapping() {
  const dataSource = [
    {
      key: 1,
      eventType: "Reject_color_diff",
      reason: "Color differences",
      tagName: "St louis omb. downtimek",
      tagSource: "Specific Value",
      tagValue: "5",
      lastReatAt: "12/10/2023",
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [originalData, setOriginalData] = useState(dataSource);
  const [filteredData, setFilteredData] = useState(dataSource);
  const [searchValue, setSearchValue] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    if (searchValue) {
      setFilteredData(
        originalData.filter(
          (item) =>
            item.rejectionCode
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            item.description
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            item.severity.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else {
      setFilteredData(originalData);
    }
  }, [searchValue, originalData]);

  const handleInputChange = (e, key) => {
    setOriginalData((prevState) =>
      prevState.map((item) =>
        item.key === key
          ? { ...item, [e.target.name]: e.target.value ?? "" }
          : item
      )
    );
  };

  const handleSelectChange = (selectedValues, field, key) => {
    setOriginalData((prevState) =>
      prevState.map((item) =>
        item.key === key ? { ...item, [field]: selectedValues ?? "" } : item
      )
    );
  };

  const columns = [
    {
      title: "Event Type",
      dataIndex: "eventType",
      key: "eventType",
      sorter: (a, b) => a.eventType.localeCompare(b.eventType),
      render: (text, record) => (
        <Form>
          <Form.Item
            name="stopCode"
            className="margin-0"
            rules={[
              {
                required: true,
                message: "Please input Event Type!",
              },
            ]}
            initialValue={record?.eventType}
          >
            <PrimarySelect
              bordered={false}
              className="select-min-width"
              showSearch
              allowClear
              onChange={(value) => {
                handleSelectChange(value, "eventType", record?.key);
              }}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "medium",
                  label: "Medium",
                },
                {
                  value: "high",
                  label: "High",
                },
              ]}
            ></PrimarySelect>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      sorter: (a, b) => a.reason.localeCompare(b.reason),
      render: (text, record) => (
        <Form>
          <Form.Item
            name="reason"
            className="margin-0"
            rules={[
              {
                required: true,
                message: "Please input Reason!",
              },
            ]}
            initialValue={record?.reason}
          >
            <SimpleInput
              fontsize="0.875rem"
              name="reason"
              onChange={(e) => handleInputChange(e, record?.key)}
            />
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Tag Name",
      dataIndex: "tagName",
      key: "tagName",
      sorter: (a, b) => a.tagName.localeCompare(b.tagName),
      render: (text, record) => (
        <Form>
          <Form.Item
            name="tagName"
            className="margin-0"
            rules={[
              {
                required: true,
                message: "Please input Tag Name!",
              },
            ]}
            initialValue={record?.tagName}
          >
            <SimpleInput
              fontsize="0.875rem"
              name="tagName"
              onChange={(e) => handleInputChange(e, record?.key)}
            />
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Tag Source",
      dataIndex: "tagSource",
      key: "tagSource",
      sorter: (a, b) => a.tagSource.localeCompare(b.tagSource),
      render: (text, record) => (
        <Form>
          <Form.Item
            name="tagSource"
            className="margin-0"
            rules={[
              {
                required: true,
                message: "Please input Tag Source!",
              },
            ]}
            initialValue={record?.tagSource}
          >
            <PrimarySelect
              bordered={false}
              className="select-min-width"
              showSearch
              allowClear
              onChange={(value) => {
                handleSelectChange(value, "tagSource", record?.key);
              }}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "medium",
                  label: "Medium",
                },
                {
                  value: "high",
                  label: "High",
                },
              ]}
            ></PrimarySelect>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Tag Value",
      dataIndex: "tagValue",
      key: "tagValue",
      sorter: (a, b) => a.tagValue.localeCompare(b.tagValue),
      render: (text, record) => (
        <Form>
          <Form.Item
            name="tagValue"
            className="margin-0"
            rules={[
              {
                required: true,
                message: "Please input Tag Value!",
              },
            ]}
            initialValue={record?.tagValue}
          >
            <SimpleInput
              disabled
              fontsize="0.875rem"
              name="tagValue"
              onChange={(e) => handleInputChange(e, record?.key)}
            />
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Last Read At",
      dataIndex: "lastReatAt",
      key: "lastReatAt",
      sorter: (a, b) => a.lastReatAt.localeCompare(b.lastReatAt),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "8rem",
      render: (record) => (
        <SimpleFlex gap="1rem" backgroundcolor="transparent">
          <SimpleButton
            padding="0.4rem 0.8rem"
            onClick={() => {
              setSelectedRow(record);
              setShowEditModal(true);
            }}
          >
            <SimpleFlex cursor="pointer" alignitems="center" gap="0.2rem">
              <P color="var(--black)" cursor="pointer" fontsize="1rem">
                Edit
              </P>
              <P
                fontsize="0.3rem"
                color="var(--primary-color)"
                cursor="pointer"
              >
                <Edit />
              </P>
            </SimpleFlex>
          </SimpleButton>
        </SimpleFlex>
      ),
    },
  ];

  return (
    <SimpleFlex flexdirection="column" gap="1rem" padding="1.5rem" width="auto">
      <SimpleFlex
        width="auto"
        alignitems="center"
        className="transparent-search"
        justifycontent="space-between"
      >
        <SimpleFlex width="auto" maxwidth="12rem">
          <PrimaryInputSearch
            allowClear
            placeholder="Search"
            onSearch={(value) => setSearchValue(value)}
          />
        </SimpleFlex>
        <SimpleFlex gap="0.5rem" width="auto">
          <SimpleButton
            padding="0.5rem 1rem"
            onClick={() => setShowAddModal(true)}
          >
            <SimpleFlex gap="0.5rem" cursor="pointer">
              Add Tag Mapping <PlusOutlined />
            </SimpleFlex>
          </SimpleButton>
          <SimpleButton
            padding="0.5rem 1rem"
            disabled={selectedRowKeys.length === 0}
          >
            Delete
          </SimpleButton>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex alignitems="center" gap="0.5rem">
        <P
          color="var(--nav-color)"
          fontsize="1.125rem"
          fontWeight="var(--font-500)"
        >
          Tag Mapping :
        </P>
        <P color="var(--nav-color)" fontsize="0.75rem">
          {" "}
          View and Edit Tag Mapping
        </P>
      </SimpleFlex>
      <SimpleFlex
        backgroundcolor="transparent"
        flexdirection="column"
        gap="0.5rem"
      >
        <P fontsize="0.75rem" color="var(--nav-color)">
          Tag Name
        </P>
        <Form.Item
          name="tagName"
          className="margin-0"
          rules={[
            {
              required: true,
              message: "Please input Tag Name!",
            },
          ]}
          initialValue={selectedRow?.tagName}
        >
          <PrimarySelect
            bordered={false}
            className="user-select"
            mode="multiple"
            showSearch
            allowClear
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={[
              {
                value: "Abdul Nizam",
                label: "Abdul Nizam",
              },
              {
                value: "Billal Ahmed",
                label: "Billal Ahmed",
              },
              {
                value: "Mohammed Waris",
                label: "Mohammed Waris",
              },
              {
                value: "Mohammed Rizwan",
                label: "Mohammed Rizwan",
              },
              {
                value: "Babar Azam",
                label: "Babar Azam",
              },
            ]}
          />
        </Form.Item>
      </SimpleFlex>
      <Table
        rowSelection={rowSelection}
        dataSource={filteredData}
        columns={columns}
        bordered={false}
        className="w-100 "
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
        }}
      />

      {!!showEditModal && (
        <EditTagMapping
          payload={selectedRow}
          closeButton={() => {
            setShowEditModal(false);
          }}
        />
      )}

      {!!showAddModal && (
        <AddTagMapping
          closeButton={() => {
            setShowAddModal(false);
          }}
        />
      )}
    </SimpleFlex>
  );
}

export default TagMapping;
