import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { processPost } from "../../HttpRequest";

// Action
export const loginUser = createAsyncThunk("loginUser", async (userData) => {
  const response = await processPost("user/validate", {
    email: userData.username,
  });
  console.log("respone: ", response);

  return userData;
});

const loginSlice = createSlice({
  name: "login",
  initialState: {
    isLoading: false,
    user: JSON.parse(localStorage.getItem("user")) || null,
    isError: false,
    userLoginSuccess: false,
  },
  reducers: {
    setUser: (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload));
      state.user = action.payload;
    },
    clearUser: (state) => {
      localStorage.removeItem("user");
      state.user = null;
    },
    setShowUserLoginSuccess: (state, action) => {
      state.userLoginSuccess = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
      state.userLoginSuccess = true;
      localStorage.setItem("user", JSON.stringify(action.payload));
    });
    builder.addCase(loginUser.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.user = null;
    });
  },
});

export const { setUser, clearUser, setShowUserLoginSuccess } =
  loginSlice.actions;
export const selectUser = JSON.parse(localStorage.getItem("user")) || null;
export const selectLoading = (state) => state.login.isLoading;

export default loginSlice.reducer;
