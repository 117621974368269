import { Breadcrumb, Result, Table } from "antd";
import { SimpleFlex } from "../../Components/StyledComponents/SimpleFlex";
import { P } from "../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../Components/StyledComponents/SimpleButton";
import { ReactComponent as Edit } from "../../assets/svg/edit.svg";
import { ReactComponent as LeftArrowColered } from "../../assets/svg/leftArrowColered.svg";
import React, { useEffect, useState } from "react";
import { PrimaryInputSearch } from "../../Components/StyledComponents/InputSearch";
import { fetchUserGroupDetail } from "../../redux/slice/UserGroupDetail/userGroupDetail";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./UserGroupDetail.scss";

function UserGroupDetail() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const receivedData = location.state;

  const { userGroupDetail = [] } = useSelector((state) => state);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const dataSource = userGroupDetail?.data || [];
  const [filteredData, setFilteredData] = useState(dataSource);

  const handleGoBack = () => {
    navigate(-1); // Go back in the history
  };

  useEffect(() => {
    setFilteredData(dataSource);
  }, [dataSource]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const breadcrumbItems = [
    { title: "Dashboard", href: "/proton/live-dashboard" },
    { title: "User Group Management", href: "" },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email ID",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "10rem",
      render: (record) => (
        <SimpleFlex gap="1rem" backgroundcolor="transparent">
          <SimpleButton
            padding="0.4rem 0.8rem"
            onClick={() => {
              setSelectedRow(record);
            }}
          >
            <SimpleFlex cursor="pointer" alignitems="center" gap="0.2rem">
              <P color="var(--black)" cursor="pointer" fontsize="1rem">
                Edit
              </P>{" "}
              <P
                fontsize="0.3rem"
                color="var(--primary-color)"
                cursor="pointer"
              >
                <Edit />
              </P>
            </SimpleFlex>
          </SimpleButton>
        </SimpleFlex>
      ),
    },
  ];

  // Dispatch the action when the component mounts
  useEffect(() => {
    dispatch(fetchUserGroupDetail(receivedData.key));
  }, [dispatch]);

  //search in table
  useEffect(() => {
    if (searchValue) {
      setFilteredData(
        dataSource.filter((item) => {
          const searchLower = searchValue.toLowerCase();

          return (
            item.name.toLowerCase().includes(searchLower) ||
            item.email.toLowerCase().includes(searchLower) ||
            item.contact.toLowerCase().includes(searchLower)
          );
        })
      );
    } else {
      setFilteredData(dataSource);
    }
  }, [searchValue, dataSource]);

  if (userGroupDetail?.isError) {
    return (
      <SimpleFlex justifycontent="center" backgroundcolor="transparent">
        <Result
          status="500"
          title="500"
          subTitle="Sorry, Failed to fetch users"
        />
      </SimpleFlex>
    );
  }

  return (
    <SimpleFlex
      backgroundcolor="transparent"
      padding="1rem 2rem"
      overflow="scroll"
      flexdirection="column"
      gap="1rem"
    >
      <SimpleFlex
        width="auto"
        height="fit-content"
        backgroundcolor="transparent"
      >
        <Breadcrumb separator=">">
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>
              <a href={item.href}>{item.title}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        flexdirection="column"
        gap="0.5rem"
      >
        <P fontsize="2.25rem" fontWeight="var(--font-600)">
          User Group Management
        </P>
        <SimpleFlex
          gap="0.5rem"
          alignitems="center"
          backgroundcolor="transparent"
          cursor="pointer"
          onClick={handleGoBack}
        >
          <LeftArrowColered />
          <P fontsize="0.688rem" color="var(--nav-color)" cursor="pointer">
            Back to All User Groups
          </P>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        flexdirection="column"
        gap="1rem"
      >
        <SimpleFlex
          backgroundcolor="transparent"
          justifycontent="space-between"
        >
          <SimpleFlex backgroundcolor="transparent" width="auto" gap="0.5rem">
            <P fontsize="1.75rem" fontWeight="var(--font-700)">
              {receivedData?.groupName}
            </P>
          </SimpleFlex>
          <SimpleFlex
            width="auto"
            alignitems="center"
            backgroundcolor="transparent"
            className="transparent-search"
          >
            <PrimaryInputSearch
              allowClear
              placeholder="Search"
              onSearch={(value) => setSearchValue(value)}
            />
          </SimpleFlex>
        </SimpleFlex>
        <Table
          loading={userGroupDetail?.isLoading}
          rowSelection={rowSelection}
          dataSource={filteredData}
          columns={columns}
          bordered
          className="w-100 custom-table"
          pagination={{
            position: ["bottomCenter"],
            pageSize: 10,
          }}
        />
      </SimpleFlex>
    </SimpleFlex>
  );
}
export default UserGroupDetail;
