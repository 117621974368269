import { Breadcrumb, Col, Collapse, Form, Radio, Result, Row } from "antd";
import { SimpleFlex } from "../../Components/StyledComponents/SimpleFlex";
import { P } from "../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../Components/StyledComponents/Button";
import { useEffect, useState } from "react";
import { fetchCondAndRules } from "../../redux/slice/CondAndRules/CondAndRule";
import { ReactComponent as PlusDark } from "../../assets/svg/plusDark.svg";
import { ReactComponent as DownArrowDark } from "../../assets/svg/downArrowDark.svg";
import { ReactComponent as UpArrowDark } from "../../assets/svg/upArrowDark.svg";
import { useDispatch, useSelector } from "react-redux";
import "./CondAndRules.scss";
import Guide from "./Filter";
import DataCard from "./DataCard";
import { PrimarySelect } from "../../Components/StyledComponents/Select";
import { SimpleInput } from "../../Components/StyledComponents/SimpleInput";

function NewCondAndRule() {
  const dispatch = useDispatch();
  const { condAndRules = [] } = useSelector((state) => state);
  const [children, setChildren] = useState([]);
  const [actionChildren, setActionChildren] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState(null);

  const handleAddClick = () => {
    setChildren([...children, ""]);
  };

  const handleAddActionClick = () => {
    setActionChildren([...actionChildren, ""]);
  };

  const handleChange = (index, event) => {
    const newChildren = [...children];
    newChildren[index] = event.target.value;
    setChildren(newChildren);
  };

  const handleActionChange = (index, event) => {
    const newChildren = [...actionChildren];
    newChildren[index] = event.target.value;
    setActionChildren(newChildren);
  };

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
  };

  const showFilterClick = () => {
    setShowFilter(true);
  };

  const hideFilter = () => {
    setShowFilter(false);
  };

  const breadcrumbItems = [
    { title: "Dashboard", href: "/proton/live-dashboard" },
    { title: "Alert Condition & Rules", href: "" },
  ];

  // Dispatch the action when the component mounts
  useEffect(() => {
    dispatch(fetchCondAndRules());
  }, [dispatch]);

  if (condAndRules?.isError) {
    return (
      <SimpleFlex justifycontent="center" backgroundcolor="transparent">
        <Result
          status="500"
          title="500"
          subTitle="Sorry, Failed to fetch plants"
        />
      </SimpleFlex>
    );
  }

  return (
    <SimpleFlex backgroundcolor="transparent">
      <SimpleFlex
        backgroundcolor="transparent"
        padding="1rem 2rem"
        overflow="scroll"
        flexdirection="column"
        gap="1rem"
        height="auto"
      >
        <SimpleFlex
          width="auto"
          height="fit-content"
          backgroundcolor="transparent"
        >
          <Breadcrumb separator=">">
            {breadcrumbItems.map((item, index) => (
              <Breadcrumb.Item key={index}>
                <a href={item.href}>{item.title}</a>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </SimpleFlex>
        <SimpleFlex
          height="auto"
          backgroundcolor="transparent"
          justifycontent="space-between"
        >
          <P fontsize="2.25rem" fontWeight="var(--font-600)">
            New Alert
          </P>
          <SimpleFlex backgroundcolor="transparent" width="auto" gap="0.5rem">
            <SimpleButton padding="0.75rem 1.625rem" onClick={showFilterClick}>
              Cancel
            </SimpleButton>
            <PrimaryButton padding="0.75rem 1.625rem" disabled={false}>
              Save
            </PrimaryButton>
          </SimpleFlex>
        </SimpleFlex>
        <SimpleFlex
          height="auto"
          backgroundcolor="transparent"
          flexdirection="column"
          gap="1rem"
        >
          <SimpleFlex
            backgroundcolor="transparent"
            justifycontent="space-between"
          >
            <P fontsize="1.25rem" fontWeight="var(--font-500)">
              Conditions
            </P>
          </SimpleFlex>
          <Radio.Group
            onChange={handleRadioChange}
            value={selectedRadio}
            className="custom-radio-group"
          >
            <SimpleFlex
              height="auto"
              className={
                selectedRadio === "radio1"
                  ? "radio-group-selected"
                  : "radio-group-unselected"
              }
            >
              <Radio
                value="radio1"
                className={selectedRadio === "radio1" ? "selected" : ""}
              >
                <SimpleFlex flexdirection="column">
                  <P fontWeight="var(--font-600)">Match any of the below</P>
                  <P fontsize="0.75rem" color="var(--nav-color)">
                    Action will taken if any of the below conditions match
                  </P>
                </SimpleFlex>
              </Radio>
            </SimpleFlex>
            <SimpleFlex
              height="auto"
              className={
                selectedRadio === "radio2"
                  ? "radio-group-selected"
                  : "radio-group-unselected"
              }
            >
              <Radio
                value="radio2"
                className={selectedRadio === "radio2" ? "selected" : ""}
              >
                <SimpleFlex flexdirection="column">
                  <P fontWeight="var(--font-600)">Match all of the below</P>
                  <P fontsize="0.75rem" color="var(--nav-color)">
                    Action will only be taken if all the below conditions match
                  </P>
                </SimpleFlex>
              </Radio>
            </SimpleFlex>
          </Radio.Group>
          <SimpleFlex
            flexdirection="column"
            backgroundcolor="transparent"
            gap="1rem"
          >
            {children.map((child, index) => (
              <Condition
                key={index}
                value={child}
                onChange={(event) => handleChange(index, event)}
              />
            ))}
            <SimpleFlex
              onClick={handleAddClick}
              justifycontent="center"
              alignitems="center"
              gap="0.5rem"
              cursor="pointer"
              backgroundcolor="transparent"
            >
              Add New Condition <PlusDark />
            </SimpleFlex>
          </SimpleFlex>
        </SimpleFlex>
        <SimpleFlex
          height="auto"
          backgroundcolor="transparent"
          gap="1rem"
          flexdirection="column"
        >
          <SimpleFlex
            backgroundcolor="transparent"
            justifycontent="space-between"
          >
            <P fontsize="1.25rem" fontWeight="var(--font-500)">
              Perform this action
            </P>
          </SimpleFlex>
          {actionChildren.map((child, index) => (
            <Action
              key={index}
              value={child}
              onChange={(event) => handleActionChange(index, event)}
            />
          ))}
          <SimpleFlex
            onClick={handleAddActionClick}
            justifycontent="center"
            alignitems="center"
            gap="0.5rem"
            cursor="pointer"
            backgroundcolor="transparent"
          >
            Add New Action <PlusDark />
          </SimpleFlex>
        </SimpleFlex>
      </SimpleFlex>
      {showFilter && <Guide onClose={hideFilter} />}
    </SimpleFlex>
  );
}

function Condition() {
  const [selectedRow, setSelectedRow] = useState({});
  const [expanded, setExpanded] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedRow((prevState) => {
      const newState = {
        ...prevState,
        [name]: value,
      };

      return newState;
    });
  };

  const handleSelectChange = (selectedValues, field) => {
    setSelectedRow((prevState) => ({
      ...prevState,
      [field]: selectedValues ?? "",
    }));
  };

  const handleAccordionChange = (keys) => {
    setExpanded(!expanded);
  };

  return (
    <SimpleFlex borderradius="1.125rem" padding="1rem" width="auto">
      <Collapse
        accordion
        bordered={false}
        className="w-100 custom-collapse"
        onChange={handleAccordionChange}
      >
        <Collapse.Panel
          showArrow={false}
          header={
            <SimpleFlex
              backgroundcolor="transparent"
              alignitems="center"
              justifycontent="flex-end"
            >
              {expanded ? (
                <SimpleFlex
                  alignitems="center"
                  gap="0.2rem"
                  width="auto"
                  onClick={() => {
                    setExpanded(!expanded);
                  }}
                >
                  <P
                    fontsize="0.625rem"
                    color="var(--nav-color)"
                    cursor="pointer"
                  >
                    Collapse
                  </P>
                  <UpArrowDark />
                </SimpleFlex>
              ) : (
                <SimpleFlex
                  alignitems="center"
                  gap="0.2rem"
                  width="auto"
                  onClick={() => {
                    setExpanded(!expanded);
                  }}
                >
                  <P
                    fontsize="0.625rem"
                    color="var(--nav-color)"
                    cursor="pointer"
                  >
                    Expand
                  </P>{" "}
                  <DownArrowDark />
                </SimpleFlex>
              )}
            </SimpleFlex>
          }
          key="conditionCard"
        >
          <Form>
            <SimpleFlex flexdirection="column" gap="1.5rem">
              <SimpleFlex gap="0.8rem" position="relative">
                <p className="position-absolute">If</p>
                <Row gutter={16} className="w-100">
                  <Col span={8}>
                    <SimpleFlex flexdirection="column" gap="0.5rem">
                      <P color="var(--nav-color)" fontsize="0.75rem">
                        Choose Machine
                      </P>
                      <Form.Item
                        name="machine"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Machine!",
                          },
                        ]}
                        initialValue={selectedRow?.machine}
                      >
                        <PrimarySelect
                          className="user-select"
                          bordered={false}
                          showSearch
                          allowClear
                          onChange={(value) => {
                            handleSelectChange(value, "condition");
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "1",
                              label: "1 Hrs",
                            },
                            {
                              value: "2",
                              label: "2 Hrs",
                            },
                            {
                              value: "3",
                              label: "3 Hrs",
                            },
                            {
                              value: "4",
                              label: "4 Hrs",
                            },
                            {
                              value: "5",
                              label: "5 Hrs",
                            },
                            {
                              value: "6",
                              label: "6 Hrs",
                            },
                          ]}
                        />
                      </Form.Item>
                    </SimpleFlex>
                  </Col>
                  <Col span={8}>
                    <SimpleFlex flexdirection="column" gap="0.5rem">
                      <P color="var(--nav-color)" fontsize="0.75rem">
                        Choose Value
                      </P>
                      <Form.Item
                        name="value"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Value!",
                          },
                        ]}
                        initialValue={selectedRow?.value}
                      >
                        {/* <P>If</P> */}
                        <PrimarySelect
                          className="user-select"
                          bordered={false}
                          showSearch
                          allowClear
                          onChange={(value) => {
                            handleSelectChange(value, "value");
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "1",
                              label: "1 Hrs",
                            },
                            {
                              value: "2",
                              label: "2 Hrs",
                            },
                            {
                              value: "3",
                              label: "3 Hrs",
                            },
                            {
                              value: "4",
                              label: "4 Hrs",
                            },
                            {
                              value: "5",
                              label: "5 Hrs",
                            },
                            {
                              value: "6",
                              label: "6 Hrs",
                            },
                          ]}
                        />
                      </Form.Item>
                    </SimpleFlex>
                  </Col>
                  <Col span={8}>
                    <SimpleFlex flexdirection="column" gap="0.5rem">
                      <P color="var(--nav-color)" fontsize="0.75rem">
                        Choose condition
                      </P>
                      <Form.Item
                        name="input"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Condition!",
                          },
                        ]}
                        initialValue={selectedRow?.condition}
                      >
                        <PrimarySelect
                          className="user-select"
                          bordered={false}
                          showSearch
                          allowClear
                          onChange={(value) => {
                            handleSelectChange(value, "action");
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "1",
                              label: "1 Hrs",
                            },
                            {
                              value: "2",
                              label: "2 Hrs",
                            },
                            {
                              value: "3",
                              label: "3 Hrs",
                            },
                            {
                              value: "4",
                              label: "4 Hrs",
                            },
                            {
                              value: "5",
                              label: "5 Hrs",
                            },
                            {
                              value: "6",
                              label: "6 Hrs",
                            },
                          ]}
                        />
                      </Form.Item>
                    </SimpleFlex>
                  </Col>
                </Row>
              </SimpleFlex>
              <Row gutter={16} className="w-100">
                <Col span={8}>
                  <SimpleFlex flexdirection="column" gap="0.5rem">
                    <P fontsize="0.75rem" color="var(--nav-color)">
                      Enter Value
                    </P>
                    <Form.Item
                      name="inputValue"
                      className="margin-0"
                      rules={[
                        {
                          required: true,
                          message: "Please input Value!",
                        },
                      ]}
                      initialValue={selectedRow?.inputValue}
                    >
                      <SimpleFlex alignitems="center" gap="1rem">
                        <SimpleInput
                          name="inputValue"
                          onChange={handleInputChange}
                          padding="0.4rem"
                          color="var(--black)"
                          fontsize="0.875rem"
                        />
                        <P>For</P>
                      </SimpleFlex>
                    </Form.Item>
                  </SimpleFlex>
                </Col>
                <Col span={16}>
                  <SimpleFlex gap="0.8rem">
                    <SimpleFlex flexdirection="column" gap="0.5rem">
                      <P color="var(--nav-color)" fontsize="0.75rem">
                        Choose condition
                      </P>
                      <Form.Item
                        name="value"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Value!",
                          },
                        ]}
                        initialValue={selectedRow?.value}
                      >
                        {/* <P>If</P> */}
                        <PrimarySelect
                          showSearch
                          allowClear
                          onChange={(value) => {
                            handleSelectChange(value, "value");
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "1",
                              label: "1 Hrs",
                            },
                            {
                              value: "2",
                              label: "2 Hrs",
                            },
                            {
                              value: "3",
                              label: "3 Hrs",
                            },
                            {
                              value: "4",
                              label: "4 Hrs",
                            },
                            {
                              value: "5",
                              label: "5 Hrs",
                            },
                            {
                              value: "6",
                              label: "6 Hrs",
                            },
                          ]}
                        />
                      </Form.Item>
                    </SimpleFlex>
                    <SimpleFlex
                      flexdirection="column"
                      gap="0.5rem"
                      maxwidth="8rem"
                    >
                      <P fontsize="0.75rem" color="var(--nav-color)">
                        Enter duration
                      </P>
                      <Form.Item
                        name="inputValue"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Value!",
                          },
                        ]}
                        initialValue={selectedRow?.inputValue}
                      >
                        <SimpleInput
                          name="inputValue"
                          onChange={handleInputChange}
                          padding="0.4rem"
                          color="var(--black)"
                          fontsize="0.875rem"
                        />
                      </Form.Item>
                    </SimpleFlex>
                    <SimpleFlex
                      flexdirection="column"
                      gap="0.5rem"
                      maxwidth="8rem"
                    >
                      <P color="var(--nav-color)" fontsize="0.75rem">
                        Duration type
                      </P>
                      <Form.Item
                        name="input"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Condition!",
                          },
                        ]}
                        initialValue={selectedRow?.condition}
                      >
                        <PrimarySelect
                          showSearch
                          allowClear
                          onChange={(value) => {
                            handleSelectChange(value, "action");
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "1",
                              label: "1 Hrs",
                            },
                            {
                              value: "2",
                              label: "2 Hrs",
                            },
                            {
                              value: "3",
                              label: "3 Hrs",
                            },
                            {
                              value: "4",
                              label: "4 Hrs",
                            },
                            {
                              value: "5",
                              label: "5 Hrs",
                            },
                            {
                              value: "6",
                              label: "6 Hrs",
                            },
                          ]}
                        />
                      </Form.Item>
                    </SimpleFlex>
                  </SimpleFlex>
                </Col>
              </Row>
              <Row gutter={16} className="w-100">
                <Col span={8}>
                  <SimpleFlex flexdirection="column" gap="0.5rem">
                    <P color="var(--nav-color)" fontsize="0.75rem">
                      Choose condition
                    </P>
                    <Form.Item
                      name="input"
                      className="margin-0"
                      rules={[
                        {
                          required: true,
                          message: "Please input Condition!",
                        },
                      ]}
                      initialValue={selectedRow?.condition}
                    >
                      <PrimarySelect
                        className="user-select"
                        bordered={false}
                        showSearch
                        allowClear
                        onChange={(value) => {
                          handleSelectChange(value, "action");
                        }}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={[
                          {
                            value: "1",
                            label: "1 Hrs",
                          },
                          {
                            value: "2",
                            label: "2 Hrs",
                          },
                          {
                            value: "3",
                            label: "3 Hrs",
                          },
                          {
                            value: "4",
                            label: "4 Hrs",
                          },
                          {
                            value: "5",
                            label: "5 Hrs",
                          },
                          {
                            value: "6",
                            label: "6 Hrs",
                          },
                        ]}
                      />
                    </Form.Item>
                  </SimpleFlex>
                </Col>
                <Col span={8}>
                  <SimpleFlex flexdirection="column" gap="0.5rem">
                    <P color="var(--nav-color)" fontsize="0.75rem">
                      Severity Level
                    </P>
                    <Form.Item
                      name="machine"
                      className="margin-0"
                      rules={[
                        {
                          required: true,
                          message: "Please input Machine!",
                        },
                      ]}
                      initialValue={selectedRow?.machine}
                    >
                      <PrimarySelect
                        className="user-select"
                        bordered={false}
                        showSearch
                        allowClear
                        onChange={(value) => {
                          handleSelectChange(value, "condition");
                        }}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={[
                          {
                            value: "1",
                            label: "1 Hrs",
                          },
                          {
                            value: "2",
                            label: "2 Hrs",
                          },
                          {
                            value: "3",
                            label: "3 Hrs",
                          },
                          {
                            value: "4",
                            label: "4 Hrs",
                          },
                          {
                            value: "5",
                            label: "5 Hrs",
                          },
                          {
                            value: "6",
                            label: "6 Hrs",
                          },
                        ]}
                      />
                    </Form.Item>
                  </SimpleFlex>
                </Col>
              </Row>
            </SimpleFlex>
          </Form>
        </Collapse.Panel>
      </Collapse>
    </SimpleFlex>
  );
}

function Action() {
  const [selectedRow, setSelectedRow] = useState({});
  const [expanded, setExpanded] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedRow((prevState) => {
      const newState = {
        ...prevState,
        [name]: value,
      };

      return newState;
    });
  };

  const handleSelectChange = (selectedValues, field) => {
    setSelectedRow((prevState) => ({
      ...prevState,
      [field]: selectedValues ?? "",
    }));
  };

  const handleAccordionChange = (keys) => {
    setExpanded(!expanded);
  };

  return (
    <SimpleFlex borderradius="1.125rem" padding="1rem" width="auto">
      <Collapse
        accordion
        bordered={false}
        className="w-100 custom-collapse"
        onChange={handleAccordionChange}
      >
        <Collapse.Panel
          showArrow={false}
          header={
            <SimpleFlex
              backgroundcolor="transparent"
              alignitems="center"
              justifycontent="flex-end"
            >
              {expanded ? (
                <SimpleFlex
                  alignitems="center"
                  gap="0.2rem"
                  width="auto"
                  onClick={() => {
                    setExpanded(!expanded);
                  }}
                >
                  <P
                    fontsize="0.625rem"
                    color="var(--nav-color)"
                    cursor="pointer"
                  >
                    Collapse
                  </P>
                  <UpArrowDark />
                </SimpleFlex>
              ) : (
                <SimpleFlex
                  alignitems="center"
                  gap="0.2rem"
                  width="auto"
                  onClick={() => {
                    setExpanded(!expanded);
                  }}
                >
                  <P
                    fontsize="0.625rem"
                    color="var(--nav-color)"
                    cursor="pointer"
                  >
                    Expand
                  </P>{" "}
                  <DownArrowDark />
                </SimpleFlex>
              )}
            </SimpleFlex>
          }
          key="conditionCard"
        >
          <Form>
            <SimpleFlex flexdirection="column" gap="1.5rem">
              <SimpleFlex flexdirection="column" gap="0.5rem">
                <P color="var(--nav-color)" fontsize="0.75rem">
                  Email Notification to
                </P>
                <Form.Item
                  name="machine"
                  className="margin-0"
                  rules={[
                    {
                      required: true,
                      message: "Please input Machine!",
                    },
                  ]}
                  initialValue={selectedRow?.machine}
                >
                  <PrimarySelect
                    className="user-select"
                    bordered={false}
                    showSearch
                    allowClear
                    onChange={(value) => {
                      handleSelectChange(value, "condition");
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={[
                      {
                        value: "1",
                        label: "1 Hrs",
                      },
                      {
                        value: "2",
                        label: "2 Hrs",
                      },
                      {
                        value: "3",
                        label: "3 Hrs",
                      },
                      {
                        value: "4",
                        label: "4 Hrs",
                      },
                      {
                        value: "5",
                        label: "5 Hrs",
                      },
                      {
                        value: "6",
                        label: "6 Hrs",
                      },
                    ]}
                  />
                </Form.Item>
              </SimpleFlex>
              <SimpleFlex flexdirection="column" gap="0.5rem">
                <P color="var(--nav-color)" fontsize="0.75rem">
                  Add Users
                </P>
                <Form.Item
                  name="machine"
                  className="margin-0"
                  rules={[
                    {
                      required: true,
                      message: "Please input Machine!",
                    },
                  ]}
                  initialValue={selectedRow?.machine}
                >
                  <PrimarySelect
                    className="user-select"
                    mode="multiple"
                    bordered={false}
                    showSearch
                    allowClear
                    onChange={(value) => {
                      handleSelectChange(value, "condition");
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={[
                      {
                        value: "1",
                        label: "1 Hrs",
                      },
                      {
                        value: "2",
                        label: "2 Hrs",
                      },
                      {
                        value: "3",
                        label: "3 Hrs",
                      },
                      {
                        value: "4",
                        label: "4 Hrs",
                      },
                      {
                        value: "5",
                        label: "5 Hrs",
                      },
                      {
                        value: "6",
                        label: "6 Hrs",
                      },
                    ]}
                  />
                </Form.Item>
              </SimpleFlex>
            </SimpleFlex>
          </Form>
        </Collapse.Panel>
      </Collapse>
    </SimpleFlex>
  );
}

export default NewCondAndRule;
