import React, { useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Form, message } from "antd";
import { ReactComponent as Proton } from "../../assets/svg/Proton.svg";
import { PrimaryButton } from "../../Components/StyledComponents/Button";
import { P } from "../../Components/StyledComponents/Paragraph";
import { PrimaryFlex } from "../../Components/StyledComponents/Flex";
import { PrimaryCheckBox } from "../../Components/StyledComponents/CheckBox";
import { PrimaryInput } from "../../Components/StyledComponents/Input";
import { PrimaryTooltip } from "../../Components/StyledComponents/Tooltip";
import { PasswordInput } from "../../Components/StyledComponents/PasswordInput";
import RegisterPageBg from "../../assets/png/RegisterPageBg.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  registerFailure,
  registerPending,
  registerSuccess,
} from "../../redux/slice/register";
import transition from "../../Transition";
import EntryPageCarousel from "../../Components/EntryPageCarousel/EntryPageCarousel";
import { useTranslation } from "react-i18next";
import { SimpleFlex } from "../../Components/StyledComponents/SimpleFlex";
import LanguageSwitcher from "../../Components/LanguageSwitcher/LanguageSwitcher";
import "./Register.css";

function Register() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    try {
      setLoading(true);
      dispatch(registerPending());

      // Perform the asynchronous registration logic here - make an API call
      // const registrationResult = await yourRegistrationApiCall();

      // Check the result and dispatch success or failure accordingly
      //registrationResult.success - replace this in if condition
      if (true) {
        dispatch(registerSuccess());
        // Additional logic if needed
        setLoading(false);
        //assuming that user's password is created - redirecting him/her to login page
        navigate("/login");
      } else {
        dispatch(registerFailure());
        throw new Error();
      }
    } catch (error) {
      console.error("Error during registration:", error);
      dispatch(registerFailure());
      setLoading(false);
      messageApi.open({
        type: "error",
        content: "Error Occured while registering user",
      });
    }
  };

  return (
    <div
      className="container"
      style={{
        background: `url("${RegisterPageBg}"), lightgray 50% / cover no-repeat`,
      }}
    >
      {contextHolder}
      <SimpleFlex
        justifycontent="flex-end"
        height="auto"
        width="auto"
        backgroundcolor="transparent"
        position="absolute"
        className="positioning"
      >
        <LanguageSwitcher />
      </SimpleFlex>
      <div
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          zIndex: 1,
          opacity: 0.85,
          background: "#21223D",
        }}
      ></div>
      <div className="d-flex h-100">
        <Card
          style={{
            width: "auto",
            margin: "auto",
            zIndex: 2,
          }}
        >
          <PrimaryFlex flexdirection="column" gap="1rem">
            <div>
              <Proton />
            </div>
            <PrimaryFlex gap="6.25rem">
              <PrimaryFlex>
                <EntryPageCarousel />
              </PrimaryFlex>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                  username: "johh.methew@proton.com", //populate this field via api call
                }}
                onFinish={onFinish}
              >
                <PrimaryFlex flexdirection="column" gap="1.875rem">
                  <PrimaryFlex flexdirection="column">
                    <P fontWeight="bolder" fontsize="2.5rem">
                      {t("createPassword")}
                    </P>
                    <P
                      fontWeight="300"
                      color="var(--nav-color)"
                      fontsize="0.938rem"
                    >
                      {t("regPageMessage")}
                    </P>
                  </PrimaryFlex>
                  <PrimaryFlex flexdirection="column">
                    <P
                      fontWeight="300"
                      fontsize="0.938rem"
                      color="var(--nav-color)"
                      margin="0 0 0.5rem 1rem"
                    >
                      {t("emailID")}
                      <PrimaryTooltip title="Mandatory" placement="top">
                        <InfoCircleOutlined style={{ marginLeft: "0.25rem" }} />
                      </PrimaryTooltip>
                    </P>
                    <Form.Item
                      name="username"
                      rules={[
                        {
                          message: "Please input your Username!",
                        },
                      ]}
                    >
                      <PrimaryInput
                        width="22.875rem"
                        height="3.375rem"
                        placeholder="Username"
                        disabled={true}
                        opacity="0.7"
                        borderColor="transparent !important"
                        backgroundcolor="var(--disabled-color) !important"
                      />
                    </Form.Item>
                    <P
                      fontWeight="300"
                      fontsize="0.938rem"
                      color="var(--nav-color)"
                      margin="0 0 0.5rem 1rem"
                    >
                      {t("createPassword")}
                      <PrimaryTooltip title="Mandatory" placement="top">
                        <InfoCircleOutlined style={{ marginLeft: "0.25rem" }} />
                      </PrimaryTooltip>
                    </P>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: t("passwordErrMsg"),
                        },
                      ]}
                    >
                      <PasswordInput
                        width="22.875rem"
                        height="3.375rem"
                        placeholder={t("password")}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Form.Item
                        name="remember"
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(t("termsAndCondErrMsg")),
                          },
                        ]}
                      >
                        <PrimaryCheckBox>
                          <P
                            fontWeight="300"
                            color="var(--nav-color)"
                            fontsize="0.75rem"
                          >
                            {" "}
                            I Accept{" "}
                          </P>{" "}
                          <P fontsize="0.75rem">Terms & Conditions </P>{" "}
                          <P
                            fontWeight="300"
                            color="var(--nav-color)"
                            fontsize="0.75rem"
                          >
                            &{" "}
                          </P>{" "}
                          <P fontsize="0.75rem">Privacy Policy</P>
                        </PrimaryCheckBox>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item>
                      <PrimaryButton
                        fontWeight="600"
                        padding="0 0.625rem"
                        width="22.875rem"
                        height="3.125rem"
                        fontsize="1.125rem"
                        htmlType="submit"
                        loading={loading}
                        justifycontent="center"
                      >
                        {t("submit")}
                      </PrimaryButton>
                    </Form.Item>
                  </PrimaryFlex>
                </PrimaryFlex>
              </Form>
            </PrimaryFlex>
          </PrimaryFlex>
        </Card>
      </div>
    </div>
  );
}
export default transition(Register);
