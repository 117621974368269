import { PrimaryFlex } from "../../../Components/StyledComponents/Flex";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { PrimaryCard } from "../../../Components/StyledComponents/Card";
import { useState } from "react";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { ConfigProvider, Radio } from "antd";
import { PrimaryTooltip } from "../../../Components/StyledComponents/Tooltip";
import { InfoCircleOutlined } from "@ant-design/icons";
import { PrimarySelect } from "../../../Components/StyledComponents/Select";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../Components/StyledComponents/Button";
import { ReactComponent as CheckOutlined } from "../../../assets/svg/checkOutlined.svg";
import { ReactComponent as DownArrow } from "../../../assets/svg/downArrow.svg";
import { PrimaryInput } from "../../../Components/StyledComponents/Input";
import Stopwatch from "../StopWatch";
import AddDownTime from "../Machines/:child/downtime/add";
import { useTranslation } from "react-i18next";
import "./Reporting.scss";

function DashboardReporting() {
  const { t } = useTranslation();

  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [quantity, setQuantity] = useState("");
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [showAddDownTime, setShowAddDownTime] = useState(false);

  const isSaveDisabled = !selectedCategory || !quantity;

  const handleCardClick = (title) => {
    setSelectedCard((prevSelected) => (prevSelected === title ? null : title));
  };

  const handleTimerRunning = (isRunning) => {
    setIsTimerRunning(isRunning);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };
  const options = [
    {
      label: "Bad Quality",
      value: "Bad Quality",
    },
    {
      label: "Good Quality",
      value: "Good Quality",
    },
  ];

  return (
    <PrimaryFlex className="dashboard-card" padding="1.563rem" width="auto">
      <PrimaryFlex width="auto" flexdirection="column">
        <P fontsize="1.25rem" fontWeight="var(--font-500)">
          {t("reporting")}
        </P>
        <PrimaryFlex gap="2rem" margin="1.5rem 0 0 0" flexwrap="wrap">
          <DashboardReportCard
            title={t("reportDownTime")}
            value={"Report when production  is not happening"}
            onClick={() => {
              handleCardClick("Report Down Time");
              if (selectedCard === null) {
                setShowAddDownTime(true);
              }
            }}
            selected={selectedCard === "Report Down Time"}
          />
          <DashboardReportCard
            title={t("reportChangeOver")}
            value={"Report change over from one product to another"}
            onClick={() => handleCardClick("Report Change Over")}
            selected={selectedCard === "Report Change Over"}
          />
          <DashboardReportCard
            title={t("reportQuality")}
            value={"Report Rejections or good items"}
            onClick={() => handleCardClick("Report Quality")}
            selected={selectedCard === "Report Quality"}
          />
          <DashboardReportCard
            title={isTimerRunning ? t("stopBreakTime") : t("startBreakTime")}
            value={"Report when machine is not working"}
            onClick={() => handleCardClick("Start Break Time")}
            selected={selectedCard === "Start Break Time"}
            handleTimerRunning={(isRunning) => {
              handleTimerRunning(isRunning);
            }}
          />
        </PrimaryFlex>
        {selectedCard === "Report Quality" && (
          <SimpleFlex
            flexdirection="column"
            gap="1rem"
            width="45%"
            padding="2rem 0 0 0"
          >
            <SimpleFlex flexdirection="column" gap="1rem">
              <SimpleFlex gap="0.3rem">
                <P fontWeight="var(--font-500)" fontsize="1rem">
                  Report Quality :
                </P>
                <P
                  color="var(--navy-seal-color)"
                  fontWeight="var(--font-500)"
                  fontsize="1rem"
                >
                  Order No. ABKH4646
                </P>
              </SimpleFlex>
              <P>Current Order </P>
            </SimpleFlex>

            <SimpleFlex flexdirection="column" gap="1.5rem">
              <SimpleFlex flexdirection="column" gap="1rem">
                <P color="var(--nav-color)" fontsize="0.75rem">
                  Enter Quantity
                </P>
                <SimpleFlex gap="0.5rem">
                  <PrimaryInput
                    padding="0.2rem 0.5rem"
                    color="var(--black)"
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#21223d",
                      },
                    }}
                  >
                    <Radio.Group
                      onChange={(e) => handleCategoryChange(e)}
                      className="report-radio-group"
                      buttonStyle="solid"
                    >
                      {options.map((category) => (
                        <div>
                          <Radio.Button
                            key={category.label}
                            value={category.value}
                            className="report-radio-button"
                          >
                            <SimpleFlex
                              justifycontent="space-between"
                              alignitems="center"
                              backgroundcolor="transparent"
                              cursor="pointer"
                              fontsize="0.813rem"
                              gap="0.3rem"
                            >
                              {category.value}{" "}
                              {selectedCategory === category.value ? (
                                <CheckOutlined />
                              ) : (
                                ""
                              )}
                            </SimpleFlex>
                          </Radio.Button>
                        </div>
                      ))}
                    </Radio.Group>
                  </ConfigProvider>
                </SimpleFlex>
              </SimpleFlex>
              <SimpleFlex flexdirection="column" gap="1rem">
                <SimpleFlex justifycontent="space-between">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Enter Reason
                  </P>
                  <SimpleFlex gap="0.2rem" width="auto">
                    <P
                      color="var(--nav-color)"
                      fontsize="0.688rem"
                      cursor="pointer"
                    >
                      <PrimaryTooltip title="optional" placement="top">
                        <InfoCircleOutlined />
                      </PrimaryTooltip>
                    </P>
                    <P color="var(--nav-color)" fontsize="0.75rem">
                      Optional
                    </P>
                  </SimpleFlex>
                </SimpleFlex>
                <PrimarySelect
                  bordered={false}
                  className="report-select"
                  suffixIcon={<DownArrow />}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "1",
                      label: "Not Identified",
                    },
                    {
                      value: "2",
                      label: "Closed",
                    },
                    {
                      value: "3",
                      label: "Communicated",
                    },
                    {
                      value: "4",
                      label: "Identified",
                    },
                    {
                      value: "5",
                      label: "Resolved",
                    },
                    {
                      value: "6",
                      label: "Cancelled",
                    },
                    {
                      value: "7",
                      label: "3 in blank : 3 Round Blank",
                    },
                  ]}
                ></PrimarySelect>
              </SimpleFlex>
              <SimpleFlex flexdirection="column" gap="1rem">
                <SimpleFlex justifycontent="space-between">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Enter Comment
                  </P>
                  <SimpleFlex gap="0.2rem" width="auto">
                    <P
                      color="var(--nav-color)"
                      fontsize="0.688rem"
                      cursor="pointer"
                    >
                      <PrimaryTooltip title="optional" placement="top">
                        <InfoCircleOutlined />
                      </PrimaryTooltip>
                    </P>
                    <P color="var(--nav-color)" fontsize="0.75rem">
                      Optional
                    </P>
                  </SimpleFlex>
                </SimpleFlex>
                <PrimaryInput
                  allowClear
                  padding="0.5rem"
                  color="var(--black)"
                />
              </SimpleFlex>
              <SimpleFlex gap="1rem">
                <SimpleButton padding="0.938rem 2.125rem">Cancel</SimpleButton>
                <PrimaryButton
                  padding="0.938rem 2.125rem"
                  disabled={isSaveDisabled}
                >
                  Save
                </PrimaryButton>
              </SimpleFlex>
            </SimpleFlex>
          </SimpleFlex>
        )}
      </PrimaryFlex>
      <AddDownTime
        showModal={showAddDownTime}
        closeButton={() => setShowAddDownTime(false)}
      />
    </PrimaryFlex>
  );
}

function DashboardReportCard({
  title,
  value,
  onClick,
  selected,
  handleTimerRunning,
}) {
  return (
    <PrimaryCard
      // loading={true}
      bodymaxwidth="15rem"
      bodyWidth="15rem"
      bodyminwidth="15rem"
      bodypadding="1.25rem !important"
      cursor="pointer"
      hoverable
      bordered={true}
      style={{
        border: selected
          ? "3px solid var(--Nav-Text-Color, #63658E)"
          : "3px solid var(--anti-flash-white)",
      }}
      className={
        selected &&
        title !== "Start Break Time" &&
        title !== "Stop Break Time" &&
        title !== "Report Down Time"
          ? "selected-card"
          : ""
      }
      onClick={onClick}
    >
      <PrimaryFlex flexdirection="column">
        <SimpleFlex gap="0.5rem">
          <P fontWeight="var(--font-600)" cursor="pointer">
            {title}
          </P>
          <P>
            {(title === "Start Break Time" || title === "Stop Break Time") && (
              <Stopwatch onTimerToggle={handleTimerRunning} />
            )}
          </P>
        </SimpleFlex>
        <P color="var(--nav-color)" cursor="pointer">
          {value}
        </P>
      </PrimaryFlex>
    </PrimaryCard>
  );
}

export default DashboardReporting;
