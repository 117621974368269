import { Breadcrumb, Form } from "antd";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { ReactComponent as Share } from "../../../assets/svg/share.svg";
import { ReactComponent as DownArrow } from "../../../assets/svg/downArrow.svg";
import { ReactComponent as Filter } from "../../../assets/svg/filter.svg";
import { ReactComponent as Edit } from "../../../assets/svg/editLeftFacedOutlined.svg";
import { PrimaryButton } from "../../../Components/StyledComponents/Button";
import { useState } from "react";

import ChartCard from "./ChartCard";
import "./StandardDashBoard.scss";
import { SimpleInput } from "../../../Components/StyledComponents/SimpleInput";

function StandardDashBoard() {
  const [data, setData] = useState({
    name: "PO xyz performance and shift",
    description: "Demo Desc",
  });

  const [isNameEditing, setIsNameEditing] = useState(false);
  const [isDescriptionEditing, setIsDescriptionEditing] = useState(false);

  const handleBlur = (event) => {
    const { name, value } = event.target;
    setData((prevState) => {
      const newState = {
        ...prevState,
        [name]: value,
      };

      return newState;
    });
    setIsNameEditing(false);
    setIsDescriptionEditing(false);
  };

  const breadcrumbItems = [
    { title: "Dashboard", href: "/proton/live-dashboard" },
    { title: "New Standard Dashboard", href: "" },
  ];

  return (
    <SimpleFlex
      height="auto"
      backgroundcolor="transparent"
      padding="1rem 2rem"
      overflow="scroll"
      flexdirection="column"
      gap="1rem"
    >
      <SimpleFlex
        width="auto"
        height="fit-content"
        backgroundcolor="transparent"
      >
        <Breadcrumb separator=">">
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>
              <a href={item.href}>{item.title}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        flexdirection="column"
        gap="1rem"
      >
        <SimpleFlex
          height="auto"
          backgroundcolor="transparent"
          alignitems="center"
          justifycontent="space-between"
        >
          <P fontsize="2.25rem" fontWeight="var(--font-600)">
            Configure Your Dashboard
          </P>
          <SimpleFlex
            backgroundcolor="transparent"
            alignitems="center"
            width="auto"
            gap="1rem"
          >
            <SimpleButton padding="0.8rem">
              <Filter />
            </SimpleButton>
            <SimpleButton padding="0.8rem">
              <Share />
            </SimpleButton>
            <PrimaryButton padding="0.6rem 1.5rem" gap="0.5rem">
              Add Widget <DownArrow />
            </PrimaryButton>
          </SimpleFlex>
        </SimpleFlex>
        <Form>
          <SimpleFlex
            width="auto"
            backgroundcolor="transparent"
            gap="1rem"
            alignitems="center"
          >
            <SimpleFlex
              backgroundcolor="transparent"
              gap="0.5rem"
              alignitems="center"
              width="auto"
            >
              <P
                cursor="pointer"
                onClick={() => {
                  setIsNameEditing(true);
                  setIsDescriptionEditing(false);
                }}
              >
                <Edit />
              </P>
              <Form.Item
                name="name"
                className="margin-0"
                rules={[
                  {
                    required: true,
                    message: "Please input Name!",
                  },
                ]}
                initialValue={data?.name}
              >
                {isNameEditing ? (
                  <SimpleInput
                    name="name"
                    onBlur={handleBlur}
                    color="var(--black)"
                    fontsize="1rem"
                    maxLength={25}
                  />
                ) : (
                  <P fontsize="1.125rem">{data?.name}</P>
                )}
              </Form.Item>
            </SimpleFlex>
            <SimpleFlex
              backgroundcolor="transparent"
              gap="0.5rem"
              alignitems="center"
              width="auto"
            >
              <P
                cursor="pointer"
                onClick={() => {
                  setIsDescriptionEditing(true);
                  setIsNameEditing(false);
                }}
              >
                <Edit />
              </P>
              <Form.Item
                name="description"
                className="margin-0"
                rules={[
                  {
                    required: true,
                    message: "Please input Description!",
                  },
                ]}
                initialValue={data?.description}
              >
                {isDescriptionEditing ? (
                  <SimpleInput
                    onBlur={handleBlur}
                    name="description"
                    color="var(--black)"
                    fontsize="1rem"
                    maxLength={25}
                  />
                ) : (
                  <P fontsize="1.125rem">{data?.description}</P>
                )}
              </Form.Item>
            </SimpleFlex>
          </SimpleFlex>
        </Form>
      </SimpleFlex>
      <ChartCard />
    </SimpleFlex>
  );
}

export default StandardDashBoard;
