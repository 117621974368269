import { Breadcrumb, Col, List, Row } from "antd";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { ReactComponent as Share } from "../../../assets/svg/share.svg";
import { ReactComponent as Settings } from "../../../assets/svg/settings.svg";
import { ReactComponent as Edit } from "../../../assets/svg/editLeftFacedOutlined.svg";
import { ReactComponent as PieChart } from "../../../assets/svg/pieChart.svg";
import { ReactComponent as BarChart } from "../../../assets/svg/barChart.svg";
import { ReactComponent as Tile } from "../../../assets/svg/tile.svg";
import { ReactComponent as TableIcon } from "../../../assets/svg/table.svg";
import { ReactComponent as Timeline } from "../../../assets/svg/timeLine.svg";
import { ReactComponent as CellStatus } from "../../../assets/svg/cellStatus.svg";
import { PrimaryButton } from "../../../Components/StyledComponents/Button";
import { useState } from "react";
import AddWidget from "./AddWidget";
import transition from "../../../Transition";
import "./NewStandardDashBoard.scss";

function NewStandardDashBoard() {
  const [addWidget, setAddWidget] = useState(false);

  const onCloseAddWidget = () => {
    setAddWidget(false);
  };

  const breadcrumbItems = [
    { title: "Dashboard", href: "/proton/live-dashboard" },
    { title: "New Standard Dashboard", href: "" },
  ];

  const data = [
    { title: "Downtime", description: "Total downtime grouped by association" },
    { title: "Alerts", description: "Total alerts grouped by association" },
    { title: "OEE", description: "Total OEE score grouped by association" },
    { title: "Timeline", description: "Total downtime grouped by association" },
  ];

  return (
    <SimpleFlex
      height="auto"
      width="auto"
      backgroundcolor="transparent"
      padding="1rem 2rem"
      overflow="scroll"
      flexdirection="column"
      gap="1rem"
    >
      <SimpleFlex
        width="auto"
        height="fit-content"
        backgroundcolor="transparent"
      >
        <Breadcrumb separator=">">
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>
              <a href={item.href}>{item.title}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        flexdirection="column"
        gap="0.5rem"
      >
        <SimpleFlex
          height="auto"
          backgroundcolor="transparent"
          alignitems="center"
          justifycontent="space-between"
        >
          <P fontsize="2.25rem" fontWeight="var(--font-600)">
            Create Your Dashboard
          </P>
          <SimpleFlex
            backgroundcolor="transparent"
            alignitems="center"
            width="auto"
            gap="1rem"
          >
            <SimpleButton padding="0.8rem">
              <Share />
            </SimpleButton>
            <SimpleButton padding="0.6rem 1.5rem">
              <SimpleFlex alignitems="center" gap="0.3rem" cursor="pointer">
                Settings <Settings />
              </SimpleFlex>
            </SimpleButton>
          </SimpleFlex>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex
        backgroundcolor="transparent"
        justifycontent="center"
        height="auto"
        flexdirection="column"
        gap="1rem"
      >
        <SimpleFlex
          justifycontent="center"
          height="auto"
          backgroundcolor="transparent"
        >
          <P fontsize="2.25rem" fontWeight="var(--font-600)">
            Add Widget
          </P>
        </SimpleFlex>
        <SimpleFlex
          justifycontent="space-between"
          backgroundcolor="transparent"
        >
          <SimpleFlex
            width="45%"
            borderradius="1.125rem"
            flexdirection="column"
            padding="1.125rem"
            height="auto"
            gap="2rem"
          >
            <SimpleFlex flexdirection="column" gap="0.5rem" height="auto">
              <P fontWeight="var(--font-500)" fontsize="1.375rem">
                Start from scratch
              </P>
              <P color="var(--nav-color)">
                Click on start or drag and drop widgets from the side bar
              </P>
            </SimpleFlex>

            <Row gutter={16}>
              <Col span={8}>
                <SimpleFlex
                  flexdirection="column"
                  width="auto"
                  alignitems="center"
                  backgroundcolor="var(--disabled-color)"
                  padding="0.5rem"
                  gap="0.5rem"
                  borderradius="0.375rem"
                >
                  <PieChart />
                  <P>Pie or Donut Chart</P>
                </SimpleFlex>
              </Col>
              <Col span={8}>
                <SimpleFlex
                  flexdirection="column"
                  width="auto"
                  alignitems="center"
                  backgroundcolor="var(--disabled-color)"
                  padding="0.5rem"
                  gap="0.5rem"
                  borderradius="0.375rem"
                >
                  <BarChart />
                  <P>Line/Bar/Area Chart</P>
                </SimpleFlex>
              </Col>
              <Col span={8}>
                <SimpleFlex
                  flexdirection="column"
                  width="auto"
                  alignitems="center"
                  backgroundcolor="var(--disabled-color)"
                  padding="0.5rem"
                  gap="0.5rem"
                  borderradius="0.375rem"
                >
                  <Tile />
                  <P>Tile</P>
                </SimpleFlex>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <SimpleFlex
                  flexdirection="column"
                  width="auto"
                  alignitems="center"
                  backgroundcolor="var(--disabled-color)"
                  padding="0.5rem"
                  gap="0.5rem"
                  borderradius="0.375rem"
                >
                  <TableIcon />
                  <P>Pie or Donut Chart</P>
                </SimpleFlex>
              </Col>
              <Col span={8}>
                <SimpleFlex
                  flexdirection="column"
                  width="auto"
                  alignitems="center"
                  backgroundcolor="var(--disabled-color)"
                  padding="0.5rem"
                  gap="0.5rem"
                  borderradius="0.375rem"
                >
                  <Timeline />
                  <P>Line/Bar/Area Chart</P>
                </SimpleFlex>
              </Col>
              <Col span={8}>
                <SimpleFlex
                  flexdirection="column"
                  width="auto"
                  alignitems="center"
                  backgroundcolor="var(--disabled-color)"
                  padding="0.5rem"
                  gap="0.5rem"
                  borderradius="0.375rem"
                  justifycontent="center"
                >
                  <CellStatus />
                  <P>Cell Status</P>
                </SimpleFlex>
              </Col>
            </Row>
            <SimpleFlex justifycontent="flex-end">
              <PrimaryButton
                padding="0.9rem 3rem"
                onClick={() => setAddWidget(true)}
              >
                Start
              </PrimaryButton>
            </SimpleFlex>
          </SimpleFlex>
          <SimpleFlex
            width="45%"
            borderradius="1.125rem"
            flexdirection="column"
            padding="1.125rem"
            gap="1rem"
            height="auto"
          >
            <SimpleFlex flexdirection="column" gap="0.5rem" height="auto">
              <P fontWeight="var(--font-500)" fontsize="1.375rem">
                Select from templates
              </P>
              <P color="var(--nav-color)">
                Select from the templates below and get started!
              </P>
            </SimpleFlex>
            <List
              className="border-none"
              bordered
              dataSource={data}
              renderItem={(item) => (
                <List.Item className="padding-1">
                  <SimpleFlex gap="1rem" alignitems="center">
                    <SimpleFlex width="auto" justifycontent="center">
                      {item.title === "Downtime" && (
                        <PieChart className="svg-height-width" />
                      )}
                      {item.title === "Alerts" && (
                        <BarChart className="svg-height-width" />
                      )}
                      {item.title === "OEE" && (
                        <Tile className="svg-height-width" />
                      )}
                      {item.title === "Timeline" && (
                        <Timeline className="svg-height-width" />
                      )}
                    </SimpleFlex>
                    <SimpleFlex flexdirection="column">
                      <SimpleFlex>
                        <P fontWeight="var(--font-500)" fontsize="1rem">
                          {item?.title}
                        </P>
                      </SimpleFlex>
                      <SimpleFlex>
                        <P color="var(--nav-color)">{item?.description}</P>
                      </SimpleFlex>
                    </SimpleFlex>
                  </SimpleFlex>
                </List.Item>
              )}
            />
            <SimpleFlex justifycontent="space-between">
              <P color="var(--nav-color)" cursor="pointer">
                View All Templates{" "}
              </P>
              <PrimaryButton padding="0.938rem 1.688rem">
                Get Started
              </PrimaryButton>
            </SimpleFlex>
          </SimpleFlex>
        </SimpleFlex>
      </SimpleFlex>
      <AddWidget onClose={onCloseAddWidget} isOpen={addWidget} />
    </SimpleFlex>
  );
}

export default transition(NewStandardDashBoard);
