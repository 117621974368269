// ErrorBoundary.js
import React, { Component } from "react";
import { Result, Button } from "antd";
import { SimpleFlex } from "../Components/StyledComponents/SimpleFlex";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorCode: null,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      errorCode: error.code || null,
    };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  renderErrorPage() {
    const { errorCode } = this.state;

    return (
      <SimpleFlex justifycontent="center" alignitems="center" height="100vh">
        <Result
          status="error"
          title={`Error ${errorCode || "Unknown"}`}
          subTitle="An unexpected error occurred."
          extra={[
            <Button
              type="primary"
              key="refresh"
              onClick={() => window.location.reload()}
            >
              Refresh Page
            </Button>,
          ]}
        />
      </SimpleFlex>
    );
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? this.renderErrorPage() : children;
  }
}

export default ErrorBoundary;
