import { SimpleFlex } from "../../../../../Components/StyledComponents/SimpleFlex";
import { Table } from "antd";
import { P } from "../../../../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../../../Components/StyledComponents/Button";
import { ReactComponent as Complete } from "../../../../../assets/svg/complete.svg";
import { ReactComponent as Filter } from "../../../../../assets/svg/filter.svg";
import { ReactComponent as DownloadUnderlined } from "../../../../../assets/svg/downloadUnderlined.svg";
import { ReactComponent as DownArrowFilled } from "../../../../../assets/svg/downArrowFilled.svg";
import { ReactComponent as LeftArrowFilled } from "../../../../../assets/svg/leftArrowFilled.svg";
import { ReactComponent as RightArrowFilled } from "../../../../../assets/svg/rightArrowFilled.svg";
import { useState } from "react";
import { SimpleSwitch } from "../../../../../Components/StyledComponents/Switch";
import { InfoCircleFilled } from "@ant-design/icons";
import "./Rejections.scss";
import { PrimarySelect } from "../../../../../Components/StyledComponents/Select";
import AddComment from "./add";
import { PrimaryInputNumber } from "../../../../../Components/StyledComponents/InputNumber";

function Rejections() {
  const [filter, setFilter] = useState(false);
  const [showAddDownTime, setShowAddDownTime] = useState(false);
  const dataSource = [
    {
      key: "1",
      quantity: 1,
      part: "3 in blank : 3 Round Blank",
      chooseReason: "Color difference",
      action: "this is new comment",
    },
    {
      key: "2",
      quantity: 2,
      part: "3 in blank : 3 Round Blank",
      chooseReason: "Wrong material used ",
      action: "",
    },
    {
      key: "3",
      quantity: 1,
      part: "3 in blank : 3 Round Blank",
      chooseReason: "",
      action: "",
    },
    {
      key: "4",
      quantity: 1,
      part: "3 in blank : 3 Round Blank",
      chooseReason: "",
      action: "",
    },
    {
      key: "5",
      quantity: 1,
      part: "3 in blank : 3 Round Blank",
      chooseReason: "",
      action: "",
    },
  ];

  const columns = [
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
      render: (text, record) => (
        <PrimaryInputNumber
          className="rejection-input"
          min={1}
          defaultValue={text}
          onChange={(value) => {
            record.quantity = value;
          }}
          upHandler={<RightArrowFilled />}
          downHandler={<LeftArrowFilled />}
        />
      ),
    },
    {
      title: "Part",
      dataIndex: "part",
      key: "part",
      sorter: (a, b) => a.part.localeCompare(b.part),
      render: (text, record) => (
        <PrimarySelect
          showSearch
          suffixIcon={<DownArrowFilled />}
          className="rejection-select"
          defaultValue={text}
          onChange={(value) => {
            record.part = value;
          }}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={[
            {
              value: "1",
              label: "Not Identified",
            },
            {
              value: "2",
              label: "Closed",
            },
            {
              value: "3",
              label: "Communicated",
            },
            {
              value: "4",
              label: "Identified",
            },
            {
              value: "5",
              label: "Resolved",
            },
            {
              value: "6",
              label: "Cancelled",
            },
            {
              value: "7",
              label: "3 in blank : 3 Round Blank",
            },
          ]}
        ></PrimarySelect>
      ),
    },
    {
      title: "Choose Reason",
      dataIndex: "chooseReason",
      key: "chooseReason",
      sorter: (a, b) => a.chooseReason.localeCompare(b.chooseReason),
      render: (text, record) => (
        <PrimarySelect
          showSearch
          suffixIcon={<DownArrowFilled />}
          className="rejection-select"
          defaultValue={text}
          onChange={(value) => {
            record.part = value;
          }}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={[
            {
              value: "1",
              label: "Color Difference",
            },
            {
              value: "2",
              label: "Wrong material used ",
            },
            {
              value: "3",
              label: " Finishing not up to the mark",
            },
          ]}
        ></PrimarySelect>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      sorter: (a, b) => a.action.localeCompare(b.action),
      render: (text, record) => {
        return (
          text || (
            <SimpleFlex backgroundcolor="transparent" gap="0.2rem">
              <P
                textdecorationline="underline"
                lineheight="126%"
                color="var(--nav-color)"
                cursor="pointer"
                onClick={() => {
                  setShowAddDownTime(true);
                }}
              >
                {" "}
                Add Comment
              </P>
            </SimpleFlex>
          )
        );
      },
    },
  ];

  const handleFilterChange = (checked) => {
    setFilter(checked);
  };

  const filteredDataSource = dataSource.filter((record) => {
    return filter ? Boolean(record.chooseReason) : true;
  });

  const noReasonCount = dataSource.filter(
    (record) => !record.chooseReason
  ).length;

  return (
    <SimpleFlex flexdirection="column" backgroundcolor="transparent" gap="1rem">
      <SimpleFlex backgroundcolor="transparent" justifycontent="space-between">
        <SimpleFlex
          backgroundcolor="transparent"
          gap="0.5rem"
          alignitems="center"
        >
          <P fontsize="2.25rem" fontWeight="var(--font-600)">
            Downtime :
          </P>
          <P fontsize="2.25rem" fontWeight="var(--font-300)">
            Total 10
          </P>
        </SimpleFlex>
        <SimpleFlex gap="1rem" backgroundcolor="transparent" width="auto">
          <SimpleButton padding="0.75rem 1.625rem" fontWeight="var(--font-500)">
            Save and finish later
          </SimpleButton>
          <PrimaryButton
            padding="0.75rem 1.625rem"
            fontWeight="var(--font-500)"
          >
            <SimpleFlex
              backgroundcolor="transparent"
              cursor="pointer"
              alignitems="center"
              gap="0.5rem"
            >
              Complete <Complete />
            </SimpleFlex>
          </PrimaryButton>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex
        backgroundcolor="transparent"
        flexdirection="column"
        gap="1rem"
      >
        <P fontsize="1.75rem" fontWeight="var(--font-600)">
          Coil Machine 1
        </P>
        <SimpleFlex gap="2rem" backgroundcolor="transparent" width="auto">
          <SimpleFlex backgroundcolor="transparent" width="auto">
            <P
              fontWeight="var(--font-500)"
              color="var(--nav-color)"
              fontsize="0.688rem"
            >
              Product :{" "}
            </P>
            <P fontWeight="var(--font-500)" fontsize="0.75rem">
              &nbsp;Switch Board{" "}
            </P>
          </SimpleFlex>
          <SimpleFlex backgroundcolor="transparent" width="auto">
            <P
              fontWeight="var(--font-500)"
              color="var(--nav-color)"
              fontsize="0.688rem"
            >
              PO :{" "}
            </P>
            <P fontWeight="var(--font-500)" fontsize="0.75rem">
              &nbsp;R-1234{" "}
            </P>
          </SimpleFlex>
          <SimpleFlex backgroundcolor="transparent" width="auto">
            <P
              fontWeight="var(--font-500)"
              color="var(--nav-color)"
              fontsize="0.688rem"
            >
              Shift :{" "}
            </P>
            <P fontWeight="var(--font-500)" fontsize="0.75rem">
              &nbsp;1st Shift{" "}
            </P>
          </SimpleFlex>
          <SimpleFlex backgroundcolor="transparent" width="auto">
            <P
              fontWeight="var(--font-500)"
              color="var(--nav-color)"
              fontsize="0.688rem"
            >
              Date :{" "}
            </P>
            <P fontWeight="var(--font-500)" fontsize="0.75rem">
              &nbsp;04/28/2023{" "}
            </P>
          </SimpleFlex>
          <SimpleFlex backgroundcolor="transparent" width="auto">
            <P
              fontWeight="var(--font-500)"
              color="var(--nav-color)"
              fontsize="0.688rem"
            >
              Unknown Down Time :
            </P>
            <P fontWeight="var(--font-500)" fontsize="0.75rem">
              &nbsp;20 Mins{" "}
            </P>
          </SimpleFlex>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex
        backgroundcolor="transparent"
        height="auto"
        alignitems="center"
        justifycontent="space-between"
      >
        <SimpleFlex
          backgroundcolor="transparent"
          alignitems="center"
          gap="1.5rem"
        >
          <SimpleButton
            className="simple-button"
            padding="0.75rem 1.625rem"
            // loading={true}
          >
            <SimpleFlex
              width="auto"
              gap="0.5rem"
              height="auto"
              borderradius="0.5rem"
              alignitems="center"
              cursor="pointer"
            >
              <P
                fontWeight="var(--font-500)"
                color="var(--nav-color)"
                cursor="pointer"
              >
                Filter
              </P>
              <Filter />
            </SimpleFlex>
          </SimpleButton>
          <SimpleFlex
            width="auto"
            gap="0.5rem"
            padding="1rem"
            height="auto"
            borderradius="0.5rem"
            className="simple-button"
          >
            <P fontWeight="var(--font-500)" color="var(--nav-color)">
              Show with reasons added
            </P>
            <SimpleSwitch
              // loading={true}
              size="small"
              onChange={handleFilterChange}
            ></SimpleSwitch>
          </SimpleFlex>
          <SimpleFlex
            height="auto"
            backgroundcolor="transparent"
            width="auto"
            alignitems="center"
            gap="0.2rem"
          >
            <P
              color="var(--warning-color)"
              fontsize="0.875rem"
              fontWeight="var(--font-500)"
            >
              <InfoCircleFilled />
            </P>
            <P
              color="var(--warning-color)"
              fontsize="0.875rem"
              fontWeight="var(--font-500)"
            >
              {noReasonCount} {noReasonCount > 1 ? "Rejections" : "Rejection"}{" "}
              have no reasons
            </P>
          </SimpleFlex>
        </SimpleFlex>
        <SimpleButton
          className="simple-button"
          padding="0.75rem 1.625rem"
          // loading={true}
        >
          <SimpleFlex
            height="auto"
            width="auto"
            cursor="pointer"
            gap="0.2rem"
            alignitems="center"
            borderradius="0.5rem"
          >
            <P
              fontWeight="var(--font-500)"
              color="var(--nav-color)"
              cursor="pointer"
            >
              Export{" "}
            </P>
            <DownloadUnderlined />
          </SimpleFlex>
        </SimpleButton>
      </SimpleFlex>
      <SimpleFlex borderradius="18px">
        <Table
          bordered
          className="custom-downtime-table"
          columns={columns}
          dataSource={filteredDataSource}
          pagination={false}
        />
      </SimpleFlex>

      <SimpleFlex
        backgroundcolor="transparent"
        gap="1rem"
        width="auto"
        justifycontent="end"
      >
        <SimpleButton
          padding="0.938rem 2.125rem"
          className="simple-button"
          // loading={true}
        >
          Reset
        </SimpleButton>
        <PrimaryButton
          padding="0.938rem 2.125rem"
          className="primary-button"
          // loading={true}
        >
          Save
        </PrimaryButton>
      </SimpleFlex>
      <AddComment
        showModal={showAddDownTime}
        closeButton={() => setShowAddDownTime(false)}
      />
    </SimpleFlex>
  );
}

export default Rejections;
