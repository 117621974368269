import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { processGet, processPost } from "../../../HttpRequest";

// Action to fetch all users
export const fetchUserGroups = createAsyncThunk("fetchUserGroups", async () => {
  const response = await processGet("usergroup/list");

  return response.map((item, index) => {
    return {
      ...item,
      key: index,
    };
  });
});

// Action to add a new user
export const addUserGroup = createAsyncThunk(
  "addUserGroup",
  async (newUserGroup) => {
    const response = await processPost("usergroup/add", {
      ...newUserGroup,
    });
    return response;
  }
);

// Action to delete a user
export const deleteUserGroup = createAsyncThunk(
  "deleteUser",
  async (userId) => {
    const response = await fetch(
      `https://your-api-endpoint/user-groups/${userId}`,
      {
        method: "DELETE",
      }
    );
    return response.json();
  }
);

// Action to edit a user
export const editUserGroup = createAsyncThunk(
  "editUserGroup",
  async (editedUserGroup) => {
    // const response = await fetch(
    //   `https://your-api-endpoint/users/${editedUserGroup.key}`,
    //   {
    //     method: "PUT",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(editedUser),
    //   }
    // );
    // return response.json();

    // user is edited successfully
    const dispatch = useDispatch();
    dispatch(fetchUserGroups());
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return editedUserGroup;
  }
);

const userGroupSlice = createSlice({
  name: "user-groups",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    selectedUser: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserGroups.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUserGroups.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchUserGroups.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(addUserGroup.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
    });
    builder.addCase(deleteUserGroup.fulfilled, (state, action) => {
      state.data = state.data.filter((user) => user.key !== action.payload.key);
    });
    builder.addCase(editUserGroup.fulfilled, (state, action) => {
      state.data = state.data.map((user) =>
        user.key === action.payload.key ? action.payload : user
      );
      state.selectedUser = null; // Clear selected user after edit
    });
  },
});

export default userGroupSlice.reducer;
