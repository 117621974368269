import { Breadcrumb, Empty, Pagination, Result } from "antd";
import { SimpleFlex } from "../../Components/StyledComponents/SimpleFlex";
import { P } from "../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../Components/StyledComponents/Button";
import { ReactComponent as Plus } from "../../assets/svg/plus.svg";
import { ReactComponent as FilterIcon } from "../../assets/svg/filter.svg";
import { useEffect, useState } from "react";
import { PrimaryInputSearch } from "../../Components/StyledComponents/InputSearch";
import { fetchCondAndRules } from "../../redux/slice/CondAndRules/CondAndRule";

import { useDispatch, useSelector } from "react-redux";
import "./CondAndRules.scss";
import Filter from "./Filter";
import DataCard from "./DataCard";
import transition from "../../Transition";

function CondAndRules() {
  const dispatch = useDispatch();
  const { condAndRules = [] } = useSelector((state) => state);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (e) => {
    setSearchValue(e);
  };

  const dataSource = condAndRules?.data || [];
  const [filteredData, setFilteredData] = useState(dataSource);

  const [showFilter, setShowFilter] = useState(false);

  const showFilterClick = () => {
    setShowFilter(true);
  };

  const hideFilter = () => {
    setShowFilter(false);
  };

  useEffect(() => {
    setFilteredData(dataSource);
  }, [dataSource]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const breadcrumbItems = [
    { title: "Dashboard", href: "/proton/live-dashboard" },
    { title: "Alert Condition & Rules", href: "" },
  ];

  // Dispatch the action when the component mounts
  useEffect(() => {
    dispatch(fetchCondAndRules());
  }, [dispatch]);

  if (condAndRules?.isError) {
    return (
      <SimpleFlex justifycontent="center" backgroundcolor="transparent">
        <Result
          status="500"
          title="500"
          subTitle="Sorry, Failed to fetch plants"
        />
      </SimpleFlex>
    );
  }

  return (
    <SimpleFlex backgroundcolor="transparent">
      <SimpleFlex
        backgroundcolor="transparent"
        padding="1rem 2rem"
        overflow="scroll"
        flexdirection="column"
        gap="1rem"
        height="auto"
      >
        <SimpleFlex
          width="auto"
          height="fit-content"
          backgroundcolor="transparent"
        >
          <Breadcrumb separator=">">
            {breadcrumbItems.map((item, index) => (
              <Breadcrumb.Item key={index}>
                <a href={item.href}>{item.title}</a>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </SimpleFlex>
        <SimpleFlex
          height="auto"
          backgroundcolor="transparent"
          justifycontent="space-between"
        >
          <P fontsize="2.25rem" fontWeight="var(--font-600)">
            Alert Condition & Rules
          </P>
          <SimpleFlex width="auto" gap="1rem" backgroundcolor="transparent">
            <PrimaryButton
              padding="0.75rem 1.625rem"
              onClick={() => {
                setShowAddModal(true);
              }}
            >
              <SimpleFlex
                cursor="pointer"
                alignitems="center"
                gap="0.5rem"
                backgroundcolor="transparent"
              >
                <P
                  color="var(--columbia-gray-color)"
                  fontWeight="var(--font-500)"
                  cursor="pointer"
                >
                  New Alert
                </P>{" "}
                <Plus />
              </SimpleFlex>{" "}
            </PrimaryButton>
          </SimpleFlex>
        </SimpleFlex>
        <SimpleFlex
          height="auto"
          backgroundcolor="transparent"
          flexdirection="column"
          gap="1rem"
        >
          <SimpleFlex
            backgroundcolor="transparent"
            justifycontent="space-between"
          >
            <SimpleFlex
              width="auto"
              alignitems="center"
              backgroundcolor="transparent"
              className="transparent-search"
            >
              <PrimaryInputSearch
                allowClear
                placeholder="Search"
                onSearch={(value) => handleSearch(value)}
              />
            </SimpleFlex>
            <SimpleFlex backgroundcolor="transparent" width="auto" gap="0.5rem">
              <SimpleButton
                padding="0.75rem 1.625rem"
                onClick={showFilterClick}
              >
                <SimpleFlex cursor="pointer" alignitems="center" gap="0.5rem">
                  Filter
                  <FilterIcon />
                </SimpleFlex>
              </SimpleButton>
              <SimpleButton
                padding="0.75rem 1.625rem"
                disabled={selectedRowKeys.length === 0}
              >
                Delete
              </SimpleButton>
            </SimpleFlex>
          </SimpleFlex>
        </SimpleFlex>
        <DataCard searchValue={searchValue} filteredData={filteredData} />
      </SimpleFlex>
      {showFilter && <Filter onClose={hideFilter} />}
    </SimpleFlex>
  );
}

export default transition(CondAndRules);
