import axios from "./Axios";

export const getCsrfToken = () =>
  new Promise((resolve, reject) => {
    axios
      .get("csrfToken", {
        crossDomain: true,
        withCredentials: true,
        mode: "cors",
        xhrFields: {
          withCredentials: true,
        },
      })
      .then((response) => {
        if (!response.data) {
          reject(response._csrf);
        } else {
          resolve(response.data._csrf);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });

export const processGet = (url) =>
  new Promise((resolve, reject) => {
    getCsrfToken()
      .then((token) => {
        if (url.includes("?")) {
          url = url + "&_csrf=" + token;
        } else {
          url = url + "?_csrf=" + token;
        }
        axios.defaults.withCredentials = true;
        axios
          .get(url, {
            crossDomain: true,
            withCredentials: true,
            mode: "cors",
            xhrFields: {
              withCredentials: true,
            },
          })
          .then((response) => {
            if (response.data && response.data.error) {
              reject(response.data.error);
            } else {
              resolve(response.data);
            }
          })
          .catch((e) => {
            reject(e);
          });
      })
      .catch((e) => {
        reject(e);
      });
  });

export const processExternal = (url) =>
  new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response.data && response.data.error) {
          reject(response.data.error);
        } else {
          resolve(response.data);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });

export const processPost = (url, data) =>
  new Promise((resolve, reject) => {
    getCsrfToken()
      .then((token) => {
        axios.defaults.headers["x-csrf-token"] = token;
        axios.defaults.withCredentials = true;
        axios
          .post(url, data, {
            crossDomain: true,
            withCredentials: true,
            mode: "cors",
            xhrFields: {
              withCredentials: true,
            },
          })
          .then((response) => {
            console.log("response: ", response);
            if (response.data && response.data.error) {
              reject(response.data.error);
            } else {
              resolve(response.data);
            }
          })
          .catch((e) => {
            reject(e);
          });
      })
      .catch((e) => {
        reject(e);
      });
  });

export const processPut = (url, data) =>
  new Promise((resolve, reject) => {
    getCsrfToken()
      .then((token) => {
        axios.defaults.headers["x-csrf-token"] = token;
        axios.defaults.withCredentials = true;

        axios
          .put(url, data)
          .then((response) => {
            if (response.data && response.data.error) {
              reject(response.data.error);
            } else {
              resolve(response.data);
            }
          })
          .catch((e) => {
            reject(e);
          });
      })
      .catch((e) => {
        reject(e);
      });
  });

export const uploadFileProcessPost = (url, data) =>
  new Promise((resolve, reject) => {
    getCsrfToken()
      .then((token) => {
        axios.defaults.headers["x-csrf-token"] = token;
        axios.defaults.withCredentials = true;

        let formData = new FormData();
        formData.append("file", data);
        axios
          .post(url, formData)
          .then((response) => {
            if (response.data && response.data.error) {
              reject(response.data.error);
            } else {
              resolve(response.data);
            }
          })
          .catch((e) => {
            reject(e);
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
