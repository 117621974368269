import { Breadcrumb, Result, Table } from "antd";
import { SimpleFlex } from "../../Components/StyledComponents/SimpleFlex";
import { P } from "../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../Components/StyledComponents/Button";
import { ReactComponent as Plus } from "../../assets/svg/plus.svg";
import { ReactComponent as PlusDark } from "../../assets/svg/plusDark.svg";
import { ReactComponent as Edit } from "../../assets/svg/edit.svg";
import { useEffect, useState } from "react";
import { PrimaryInputSearch } from "../../Components/StyledComponents/InputSearch";
import { fetchPlants } from "../../redux/slice/Plants/plant";

import EditPlant from "./EditPlantModal";
import AddPlant from "./AddPlantModal";
import { useDispatch, useSelector } from "react-redux";
import transition from "../../Transition";
import { useTranslation } from "react-i18next";
import "./Plant.scss";

function Plants() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { plants = [] } = useSelector((state) => state);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const dataSource = plants?.data || [];
  const [filteredData, setFilteredData] = useState(dataSource);

  useEffect(() => {
    setFilteredData(dataSource);
  }, [dataSource]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const breadcrumbItems = [
    { title: t("dashboard"), href: "/proton/live-dashboard" },
    { title: t("industrialManagement"), href: "" },
  ];

  const columns = [
    {
      title: t("productName"),
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: t("description"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("shortName"),
      dataIndex: "shortName",
      key: "shortName",
    },
    {
      title: t("owners"),
      dataIndex: "owners",
      key: "owners",
    },
    {
      title: t("parent"),
      dataIndex: "parent",
      key: "parent",
    },
    {
      title: t("action"),
      dataIndex: "",
      key: "x",
      render: (record) => (
        <SimpleFlex gap="1rem" backgroundcolor="transparent">
          <SimpleButton
            padding="0.4rem 0.8rem"
            onClick={() => {
              setSelectedRow(record);
              setShowEditModal(true);
            }}
          >
            <SimpleFlex cursor="pointer" alignitems="center" gap="0.2rem">
              <P color="var(--black)" cursor="pointer" fontsize="1rem">
                {t("edit")}
              </P>
              <P
                fontsize="0.3rem"
                color="var(--primary-color)"
                cursor="pointer"
              >
                <Edit />
              </P>
            </SimpleFlex>
          </SimpleButton>
          <SimpleButton padding="0.4rem 0.8rem" onClick={() => {}}>
            <SimpleFlex cursor="pointer" alignitems="center" gap="0.2rem">
              <P color="var(--black)" cursor="pointer" fontsize="1rem">
                {t("assignOwners")}
              </P>
              <PlusDark />
            </SimpleFlex>
          </SimpleButton>
        </SimpleFlex>
      ),
    },
  ];

  // Dispatch the action when the component mounts
  useEffect(() => {
    dispatch(fetchPlants());
  }, [dispatch]);

  //search in table
  useEffect(() => {
    if (searchValue) {
      setFilteredData(
        dataSource.filter(
          (item) =>
            item.productName
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            item.description
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            item.shortName.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.owners.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.parent.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else {
      setFilteredData(dataSource);
    }
  }, [searchValue]);

  if (plants?.isError) {
    return (
      <SimpleFlex justifycontent="center" backgroundcolor="transparent">
        <Result
          status="500"
          title="500"
          subTitle="Sorry, Failed to fetch plants"
        />
      </SimpleFlex>
    );
  }

  return (
    <SimpleFlex
      backgroundcolor="transparent"
      padding="1rem 2rem"
      overflow="scroll"
      flexdirection="column"
      gap="1rem"
      width="auto"
      height="auto"
    >
      <SimpleFlex
        width="auto"
        height="fit-content"
        backgroundcolor="transparent"
      >
        <Breadcrumb separator=">">
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>
              <a href={item.href}>{item.title}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        justifycontent="space-between"
      >
        <P fontsize="2.25rem" fontWeight="var(--font-600)">
          {t("plantManagement")}
        </P>
        <SimpleFlex width="auto" gap="1rem" backgroundcolor="transparent">
          <PrimaryButton
            padding="0.75rem 1.625rem"
            onClick={() => {
              setShowAddModal(true);
            }}
          >
            <SimpleFlex
              cursor="pointer"
              alignitems="center"
              gap="0.5rem"
              backgroundcolor="transparent"
            >
              <P
                color="var(--columbia-gray-color)"
                fontWeight="var(--font-500)"
                cursor="pointer"
              >
                {t("addPlant")}
              </P>{" "}
              <Plus />
            </SimpleFlex>{" "}
          </PrimaryButton>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        flexdirection="column"
        gap="1rem"
      >
        <SimpleFlex
          backgroundcolor="transparent"
          justifycontent="space-between"
        >
          <SimpleFlex
            width="auto"
            alignitems="center"
            backgroundcolor="transparent"
            className="transparent-search"
          >
            <PrimaryInputSearch
              allowClear
              placeholder={t("search")}
              onSearch={(value) => setSearchValue(value)}
            />
          </SimpleFlex>
          <SimpleFlex backgroundcolor="transparent" width="auto" gap="0.5rem">
            <SimpleButton
              padding="0.75rem 1.625rem"
              disabled={selectedRowKeys.length === 0}
            >
              {t("delete")}
            </SimpleButton>
            <SimpleButton
              padding="0.75rem 1.625rem"
              disabled={selectedRowKeys.length === 0}
            >
              {t("duplicate")}
            </SimpleButton>
            <SimpleButton
              padding="0.75rem 1.625rem"
              disabled={selectedRowKeys.length === 0}
            >
              {t("update")}
            </SimpleButton>
          </SimpleFlex>
        </SimpleFlex>
        <Table
          loading={plants?.isLoading}
          rowSelection={rowSelection}
          dataSource={filteredData}
          columns={columns}
          bordered
          className="w-100 custom-table"
          pagination={{
            position: ["bottomCenter"],
            pageSize: 10,
          }}
        />
      </SimpleFlex>
      {!!showEditModal && (
        <EditPlant
          payload={selectedRow}
          closeButton={() => {
            setShowEditModal(false);
          }}
        />
      )}

      {!!showAddModal && (
        <AddPlant
          closeButton={() => {
            setShowAddModal(false);
          }}
        />
      )}
    </SimpleFlex>
  );
}
export default transition(Plants);
