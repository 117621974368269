import { Breadcrumb, Result, Table } from "antd";
import { SimpleFlex } from "../../Components/StyledComponents/SimpleFlex";
import { P } from "../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../Components/StyledComponents/Button";
import { ReactComponent as Plus } from "../../assets/svg/plus.svg";
import { ReactComponent as Edit } from "../../assets/svg/edit.svg";
import React, { useEffect, useState } from "react";
import { PrimaryInputSearch } from "../../Components/StyledComponents/InputSearch";
import { fetchUserGroups } from "../../redux/slice/UserGroups/userGroup";

import EditUser from "./EditUserGroupModal";
import AddUser from "./AddUserGroupModal";
import { useDispatch, useSelector } from "react-redux";
import "./UserGroup.scss";
import { useNavigate } from "react-router-dom";

function UserGroups() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userGroups = [] } = useSelector((state) => state);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const dataSource = userGroups?.data || [];
  const [filteredData, setFilteredData] = useState(dataSource);

  useEffect(() => {
    setFilteredData(dataSource);
  }, [dataSource]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const breadcrumbItems = [
    { title: "Dashboard", href: "/proton/live-dashboard" },
    { title: "User Group Management", href: "" },
  ];

  const columns = [
    {
      title: "GroupId",
      dataIndex: "groupId",
      key: "groupId",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Assigned Users",
      dataIndex: "users",
      key: "users",
      render: (record) => (
        <SimpleFlex gap="0.2rem" backgroundcolor="transparent">
          {record.map((item, index) => (
            <React.Fragment key={index}>
              <SimpleFlex width="auto" backgroundcolor="transparent">
                <P>{item.name}</P>
                {index < record.length - 1 && <span>, </span>}
              </SimpleFlex>
            </React.Fragment>
          ))}
        </SimpleFlex>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "12rem",
      render: (record) => (
        <SimpleFlex gap="1rem" backgroundcolor="transparent">
          <SimpleButton
            padding="0.4rem 0.8rem"
            onClick={() => {
              console.log("record: ", record);
              navigate(`detail`, { state: record });
            }}
          >
            View Details
          </SimpleButton>
          <SimpleButton
            padding="0.4rem 0.8rem"
            onClick={() => {
              setSelectedRow(record);
              setShowEditModal(true);
            }}
          >
            <SimpleFlex cursor="pointer" alignitems="center" gap="0.2rem">
              <P color="var(--black)" cursor="pointer" fontsize="1rem">
                Edit
              </P>{" "}
              <P
                fontsize="0.3rem"
                color="var(--primary-color)"
                cursor="pointer"
              >
                <Edit />
              </P>
            </SimpleFlex>
          </SimpleButton>
        </SimpleFlex>
      ),
    },
  ];

  // Dispatch the action when the component mounts
  useEffect(() => {
    dispatch(fetchUserGroups());
  }, [dispatch]);

  //search in table
  useEffect(() => {
    if (searchValue) {
      setFilteredData(
        dataSource.filter((item) => {
          const searchLower = searchValue.toLowerCase();

          return (
            item.groupId.toString().toLowerCase().includes(searchLower) ||
            item.name.toLowerCase().includes(searchLower) ||
            item.description.toLowerCase().includes(searchLower) ||
            item.users.some((user) =>
              user.name.toLowerCase().includes(searchLower)
            )
          );
        })
      );
    } else {
      setFilteredData(dataSource);
    }
  }, [searchValue, dataSource]);

  if (userGroups?.isError) {
    return (
      <SimpleFlex justifycontent="center" backgroundcolor="transparent">
        <Result
          status="500"
          title="500"
          subTitle="Sorry, Failed to fetch users"
        />
      </SimpleFlex>
    );
  }

  return (
    <SimpleFlex
      backgroundcolor="transparent"
      padding="1rem 2rem"
      overflow="scroll"
      flexdirection="column"
      gap="1rem"
    >
      <SimpleFlex
        width="auto"
        height="fit-content"
        backgroundcolor="transparent"
      >
        <Breadcrumb separator=">">
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>
              <a href={item.href}>{item.title}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        justifycontent="space-between"
      >
        <P fontsize="2.25rem" fontWeight="var(--font-600)">
          User Group Management
        </P>
        <SimpleFlex width="auto" gap="1rem" backgroundcolor="transparent">
          <PrimaryButton
            padding="0.75rem 1.625rem"
            onClick={() => {
              setShowAddModal(true);
            }}
          >
            <SimpleFlex
              cursor="pointer"
              alignitems="center"
              gap="0.5rem"
              backgroundcolor="transparent"
            >
              <P
                color="var(--columbia-gray-color)"
                fontWeight="var(--font-500)"
                cursor="pointer"
              >
                Add Group
              </P>{" "}
              <Plus />
            </SimpleFlex>{" "}
          </PrimaryButton>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        flexdirection="column"
        gap="1rem"
      >
        <SimpleFlex
          backgroundcolor="transparent"
          justifycontent="space-between"
        >
          <SimpleFlex
            width="auto"
            alignitems="center"
            backgroundcolor="transparent"
            className="transparent-search"
          >
            <PrimaryInputSearch
              allowClear
              placeholder="Search"
              onSearch={(value) => setSearchValue(value)}
            />
          </SimpleFlex>
          <SimpleFlex backgroundcolor="transparent" width="auto" gap="0.5rem">
            <SimpleButton
              padding="0.75rem 1.625rem"
              disabled={selectedRowKeys.length === 0}
            >
              Delete
            </SimpleButton>
          </SimpleFlex>
        </SimpleFlex>
        <Table
          loading={userGroups?.isLoading}
          rowSelection={rowSelection}
          dataSource={filteredData}
          columns={columns}
          bordered
          className="w-100 custom-table"
          pagination={{
            position: ["bottomCenter"],
            pageSize: 10,
          }}
        />
      </SimpleFlex>
      {!!showEditModal && (
        <EditUser
          payload={selectedRow}
          closeButton={() => {
            setShowEditModal(false);
          }}
        />
      )}

      {!!showAddModal && (
        <AddUser
          closeButton={() => {
            setShowAddModal(false);
          }}
        />
      )}
    </SimpleFlex>
  );
}
export default UserGroups;
