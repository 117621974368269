import { Form, Table } from "antd";
import { useEffect, useState } from "react";
import { SimpleInput } from "../../../Components/StyledComponents/SimpleInput";
import { PrimarySelect } from "../../../Components/StyledComponents/Select";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { PrimaryInputSearch } from "../../../Components/StyledComponents/InputSearch";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { PlusOutlined } from "@ant-design/icons";
import { P } from "../../../Components/StyledComponents/Paragraph";
import "./Productivity.scss";

function RejectionCode() {
  const dataSource = [
    {
      key: 1,
      product: "Compact RMU",
      cycleTime: { quantity: "2", unit: "hours" },
      plannedRunningTime: "480",
    },
    {
      key: 2,
      product: "RMU",
      cycleTime: { quantity: "1", unit: "mins" },
      plannedRunningTime: "500",
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [originalData, setOriginalData] = useState(dataSource);
  const [filteredData, setFilteredData] = useState(dataSource);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleInputChange = (e, key) => {
    setOriginalData((prevState) =>
      prevState.map((item) =>
        item.key === key
          ? { ...item, [e.target.name]: e.target.value ?? "" }
          : item
      )
    );
  };

  const handleCycleTimeChange = (e, key) => {
    setOriginalData((prevState) =>
      prevState.map((item) =>
        item.key === key
          ? {
              ...item,
              [e.target.name.split(".")[0]]: {
                ...item[e.target.name.split(".")[0]],
                [e.target.name.split(".")[1]]: e.target.value ?? "",
              },
            }
          : item
      )
    );
  };

  const handleCycleTimeUnitChange = (selectedValues, field, key) => {
    setOriginalData((prevState) =>
      prevState.map((item) =>
        item.key === key
          ? {
              ...item,
              [field]: {
                ...item[field],
                unit: Array.isArray(selectedValues)
                  ? selectedValues.join(", ")
                  : selectedValues ?? "",
              },
            }
          : item
      )
    );
  };

  const columns = [
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      sorter: (a, b) => a.product.localeCompare(b.product),
    },
    {
      title: "Cycle Time",
      dataIndex: "cycleTime",
      key: "cycleTime",
      sorter: (a, b) =>
        parseInt(b.cycleTime.quantity) - parseInt(a.cycleTime.quantity),
      render: (text, record) => (
        <Form>
          <SimpleFlex
            width="auto"
            alignitems="center"
            gap="1rem"
            backgroundcolor="transparent"
          >
            <Form.Item
              name="cycleTime.quantity"
              className="margin-0"
              rules={[
                {
                  required: true,
                  message: "Please input Cycle Time Quantity!",
                },
              ]}
              initialValue={record?.cycleTime?.quantity}
            >
              <SimpleInput
                fontsize="0.875rem"
                name="cycleTime.quantity"
                onChange={(e) => handleCycleTimeChange(e, record?.key)}
              />
            </Form.Item>
            <P fontsize="0.75rem" color="var(--nav-color)">
              no. of units per{" "}
            </P>
            <Form.Item
              name="cycleTime.unit"
              className="margin-0"
              rules={[
                {
                  required: true,
                  message: "Please input Unit!",
                },
              ]}
              initialValue={record?.cycleTime?.unit}
            >
              <PrimarySelect
                borderColor="var(--input-border-color) !important"
                className="select-min-width"
                height="2.2rem !important"
                showSearch
                allowClear
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                onChange={(value) => {
                  handleCycleTimeUnitChange(value, "cycleTime", record?.key);
                }}
                options={[
                  {
                    value: "mins",
                    label: "Mins",
                  },
                  {
                    value: "hours",
                    label: "Hours",
                  },
                  {
                    value: "demo",
                    label: "Demo",
                  },
                ]}
              ></PrimarySelect>
            </Form.Item>
          </SimpleFlex>
        </Form>
      ),
    },
    {
      title: "Planned Running Time",
      dataIndex: "plannedRunningTime",
      key: "plannedRunningTime",
      render: (text, record) => (
        <SimpleFlex
          width="auto"
          alignitems="center"
          gap="1rem"
          backgroundcolor="transparent"
        >
          <Form>
            <Form.Item
              name="plannedRunningTime"
              className="margin-0"
              rules={[
                {
                  required: true,
                  message: "Please input Planned Running Time!",
                },
              ]}
              initialValue={record?.plannedRunningTime}
            >
              <SimpleInput
                maxWidth="6rem"
                fontsize="0.875rem"
                name="plannedRunningTime"
                onChange={(e) => handleInputChange(e, record?.key)}
              />
            </Form.Item>
          </Form>

          <P fontsize="0.75rem" color="var(--nav-color)">
            Mins per shift
          </P>
        </SimpleFlex>
      ),
    },
  ];

  return (
    <SimpleFlex flexdirection="column" gap="1rem" padding="1.5rem" width="auto">
      <SimpleFlex alignitems="center" gap="0.5rem">
        <P
          color="var(--nav-color)"
          fontsize="1.125rem"
          fontWeight="var(--font-500)"
        >
          Productivity :
        </P>
        <P color="var(--nav-color)" fontsize="0.75rem">
          {" "}
          View and Edit machine’s Productivity
        </P>
      </SimpleFlex>
      <Table
        rowSelection={rowSelection}
        dataSource={filteredData}
        columns={columns}
        bordered={false}
        className="w-100 "
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
        }}
      />
    </SimpleFlex>
  );
}

export default RejectionCode;
