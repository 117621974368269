import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { processPost } from "../../../HttpRequest";

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (payload) => {
    try {
      const response = await processPost(
        `user/passwordResetToken?token=${payload?.token}`,
        {
          email: payload?.email,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const resetPasswordSlice = createSlice({
  name: "resetPasswordName",
  initialState: {
    isLoading: false,
    isError: false,
    success: false,
  },
  extraReducers: (builder) => {
    builder.addCase(resetPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = true;
    });
    builder.addCase(resetPassword.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export default resetPasswordSlice.reducer;
