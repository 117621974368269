import {
  Col,
  Divider,
  Empty,
  List,
  Progress,
  Result,
  Row,
  Skeleton,
} from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { fetchProductionStats } from "../../../redux/slice/Dashboard/productionStats";
import { PrimaryFlex } from "../../../Components/StyledComponents/Flex";
import { PercentageOutlined } from "@ant-design/icons";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { useTranslation } from "react-i18next";
import "./ProductionStats.scss";

function DashboardProductionStats() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { productionStats } = useSelector((state) => state);

  const isLoading = productionStats?.isLoading;
  const isError = productionStats?.isError;
  const productionStatsData = productionStats?.data?.data.productionStats || {};
  const hasProductionStatsData = Object.keys(productionStatsData).length > 0;

  useEffect(() => {
    // Dispatch the action when the component mounts
    dispatch(fetchProductionStats());
  }, [dispatch]);

  const customFormat = (percent) => (
    <div>
      <PrimaryFlex width="auto">
        <P
          fontWeight="var(--font-700)"
          color="var(--snow-pea-green)"
          fontsize="2rem"
        >
          {percent}
        </P>
        <PercentageOutlined
          style={{ color: "var(--snow-pea-green)", fontSize: "1rem" }}
        />
      </PrimaryFlex>
      <P color="var(--nav-color)">{t("completed")}</P>
    </div>
  );

  return (
    <PrimaryFlex className="dashboard-card" padding="1.563rem" width="auto">
      <PrimaryFlex width="auto" flexdirection="column">
        <P fontsize="1.25rem" fontWeight="var(--font-500)">
          {t("currentProductionStats")}
        </P>
        <PrimaryFlex margin="1rem 0 0 0">
          {isLoading && (
            <SimpleFlex>
              <Skeleton active loading={true} />
            </SimpleFlex>
          )}
          {!isLoading && !hasProductionStatsData && !isError && (
            // Show "no data" state if there is no data
            <SimpleFlex justifycontent="center">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </SimpleFlex>
          )}
          {isError && (
            // Show error state if there's an error
            <SimpleFlex justifycontent="center">
              <Result
                status="500"
                title="500"
                subTitle="Sorry, Failed to fetch notifications."
              />
            </SimpleFlex>
          )}
          {!isLoading && hasProductionStatsData && (
            <SimpleFlex gap="3rem">
              <PrimaryFlex width="auto" gap="1rem">
                <Progress
                  type="circle"
                  percent={"95"}
                  format={customFormat}
                  strokeColor={"var(--snow-pea-green)"}
                />
                <PrimaryFlex flexdirection="column">
                  <P
                    color="var(--snow-pea-green)"
                    fontsize="2.188rem"
                    fontWeight="var(--font-600)"
                  >
                    305,088
                  </P>
                  <PrimaryFlex gap="0.5rem">
                    <P
                      fontsize="0.875rem"
                      color="var(--columbia-gray-color)"
                      fontWeight="var(--font-300)"
                    >
                      /
                    </P>
                    <P
                      fontsize="0.875rem"
                      color="var(--nav-color)"
                      fontWeight="var(--font-600)"
                    >
                      320,000
                    </P>
                  </PrimaryFlex>
                </PrimaryFlex>
              </PrimaryFlex>
              <PrimaryFlex flexdirection="column" gap="1rem">
                <PrimaryFlex>
                  <P
                    fontsize="1.063rem"
                    fontWeight="var(--font-500)"
                    color="var(--nav-color)"
                  >
                    {t("product")} : &nbsp;
                  </P>
                  <P fontsize="1.063rem" fontWeight="var(--font-500)">
                    {productionStatsData.product}
                  </P>
                </PrimaryFlex>
                <PrimaryFlex>
                  <Row gutter={[20, 24]}>
                    <Col className="gutter-row" span={8}>
                      <PrimaryFlex flexdirection="column" gap="0.3rem">
                        <P fontsize="1.125rem" fontWeight="var(--font-600)">
                          {productionStatsData.currentPO}
                        </P>
                        <P fontsize="0.875rem" color="var(--nav-color)">
                          {t("currentPO")}
                        </P>
                      </PrimaryFlex>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <PrimaryFlex flexdirection="column" gap="0.3rem">
                        <P fontsize="1.125rem" fontWeight="var(--font-600)">
                          {productionStatsData.currentPartNo}
                        </P>
                        <P fontsize="0.875rem" color="var(--nav-color)">
                          {t("currentPartNo")}
                        </P>
                      </PrimaryFlex>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <PrimaryFlex flexdirection="column" gap="0.3rem">
                        <P fontsize="1.125rem" fontWeight="var(--font-600)">
                          {productionStatsData.status}
                        </P>
                        <PrimaryFlex flexdirection="column">
                          <P fontsize="0.875rem" color="var(--nav-color)">
                            {t("status")}
                          </P>
                          <P fontsize="0.688rem" color="var(--nav-color)">
                            {t("actualCycleTime")}
                          </P>
                        </PrimaryFlex>
                      </PrimaryFlex>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <PrimaryFlex flexdirection="column" gap="0.3rem">
                        <P fontsize="1.125rem" fontWeight="var(--font-600)">
                          {productionStatsData.timeRemaining}
                        </P>
                        <P fontsize="0.875rem" color="var(--nav-color)">
                          {t("estTimeRemaining")}
                        </P>
                      </PrimaryFlex>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <PrimaryFlex flexdirection="column" gap="0.3rem">
                        <P fontsize="1.125rem" fontWeight="var(--font-600)">
                          {productionStatsData.cycleTime}
                        </P>
                        <PrimaryFlex flexdirection="column">
                          <P fontsize="0.875rem" color="var(--nav-color)">
                            {t("actualCycleTime")}
                          </P>
                          <P fontsize="0.688rem" color="var(--nav-color)">
                            {t("partsMin")}
                          </P>
                        </PrimaryFlex>
                      </PrimaryFlex>
                    </Col>
                    <Col className="gutter-row" span={8}>
                      <PrimaryFlex flexdirection="column" gap="0.3rem">
                        <P fontsize="1.125rem" fontWeight="var(--font-600)">
                          {productionStatsData.quality}
                        </P>
                        <PrimaryFlex flexdirection="column">
                          <P fontsize="0.875rem" color="var(--nav-color)">
                            {t("quality")}
                          </P>
                          <P fontsize="0.688rem" color="var(--nav-color)">
                            {t("partsAhead")}
                          </P>
                        </PrimaryFlex>
                      </PrimaryFlex>
                    </Col>
                  </Row>
                </PrimaryFlex>
              </PrimaryFlex>
            </SimpleFlex>
          )}
        </PrimaryFlex>
      </PrimaryFlex>
    </PrimaryFlex>
  );
}

export default DashboardProductionStats;
