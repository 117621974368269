import React from "react";
import styled from "styled-components";
import { ConfigProvider, Space } from "antd";

const CustomFlex = styled.div`
  display: flex !important;
  background-color: ${(props) => props.backgroundcolor || "white"};
  justify-content: ${(props) => props.justifycontent || ""};
  align-items: ${(props) => props.alignitems || ""};
  border-radius: ${(props) => props.borderradius || "0rem"};
  margin: ${(props) => props.margin || "0rem"};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  border: ${(props) => props.border || "none"};
  padding: ${(props) => props.padding || "0rem"};
  box-shadow: ${(props) => props.boxShadow || "none"};
  cursor: ${(props) => props.cursor || "auto"};
  font-weight: ${(props) => props.fontWeight || "var(--font-400)"};
  font-size: ${(props) => props.fontsize || "0.875rem"};
  flex-direction: ${(props) => props.flexdirection || "row"};
  gap: ${(props) => props.gap || "0rem"};
  max-width: ${(props) => props.maxwidth || "100%"};
  flex-wrap: ${(props) => props.flexwrap || "wrap"};
  &:hover {
    background-color: ${(props) => props.hoverColor || "none"};
    color: ${(props) => props.hoverTextColor || "none"};
  }
`;

export const PrimaryFlex = (props) => {
  const { onClick } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <ConfigProvider theme={{}}>
      <Space>
        <CustomFlex {...props} onClick={handleClick}>
          {props.children}
        </CustomFlex>
      </Space>
    </ConfigProvider>
  );
};
