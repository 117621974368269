import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

// Action to fetch all plants
export const fetchConfigurations = createAsyncThunk(
  "fetchConfigurations",
  async () => {
    // const response = await fetch("https://your-api-endpoint/plants");
    // return response.json();

    // throw new Error("");
    // await new Promise((resolve) => setTimeout(resolve, 2000));
    return [
      {
        key: "1",
        type: "Work Center A",
        label: "Department 1",
        value: "Dep 1",
      },
      {
        key: "2",
        type: "Work Center C",
        label: "Department 3",
        value: "Dep 2",
      },
      {
        key: "3",
        type: "Work Center D",
        label: "Department 2",
        value: "Dep 10",
      },
    ];
  }
);

// Action to add a new plant
export const addConfiguration = createAsyncThunk(
  "addConfiguration",
  async (newConfiguration) => {
    const response = await fetch("https://your-api-endpoint/plants", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newConfiguration),
    });
    return response.json();
  }
);

// Action to delete a plant
export const deleteConfiguration = createAsyncThunk(
  "deleteConfiguration",
  async (configurationId) => {
    const response = await fetch(
      `https://your-api-endpoint/plants/${configurationId}`,
      {
        method: "DELETE",
      }
    );
    return response.json();
  }
);

// Action to edit a plant
export const editConfiguration = createAsyncThunk(
  "editConfiguration",
  async (editConfiguration) => {
    // const response = await fetch(
    //   `https://your-api-endpoint/plants/${editedPlant.key}`,
    //   {
    //     method: "PUT",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(editConfiguration),
    //   }
    // );
    // return response.json();

    // plant is edited successfully
    const dispatch = useDispatch();
    dispatch(fetchConfigurations());
    return editConfiguration;
  }
);

const configurationSlice = createSlice({
  name: "configurations",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    selectedConfiguration: null,
  },
  reducers: {
    setSelectedConfiguration: (state, action) => {
      state.selectedConfiguration = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConfigurations.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchConfigurations.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchConfigurations.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(addConfiguration.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
    });
    builder.addCase(deleteConfiguration.fulfilled, (state, action) => {
      state.data = state.data.filter(
        (configuration) => configuration.key !== action.payload.key
      );
    });
    builder.addCase(editConfiguration.fulfilled, (state, action) => {
      state.data = state.data.map((configuration) =>
        configuration.key === action.payload.key
          ? action.payload
          : configuration
      );
      state.selectedConfiguration = null; // Clear selected plant after edit
    });
  },
});

export const { setSelectedConfiguration } = configurationSlice.actions;

export default configurationSlice.reducer;
