import { useEffect, useState } from "react";
import { PrimaryModal } from "../../../Components/StyledComponents/Modal";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { PrimarySelect } from "../../../Components/StyledComponents/Select";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import { useDispatch } from "react-redux";
import { editTags } from "../../../redux/slice/Tags/tag";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../Components/StyledComponents/Button";
import { Checkbox, Col, Collapse, Form, Radio, Row, Tabs } from "antd";
import { SimpleInput } from "../../../Components/StyledComponents/SimpleInput";
import TextArea from "antd/es/input/TextArea";
import { PrimaryTooltip } from "../../../Components/StyledComponents/Tooltip";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ReactComponent as CheckCircleOutlined } from "../../../assets/svg/checkCircleOutlined.svg";
import { ReactComponent as Edit } from "../../../assets/svg/edit.svg";
import { ReactComponent as PlusDark } from "../../../assets/svg/plusDark.svg";
import { ReactComponent as DownArrowDark } from "../../../assets/svg/downArrowDark.svg";
import { ReactComponent as UpArrowDark } from "../../../assets/svg/upArrowDark.svg";
import "./EditTag.scss";

function EditTag({ closeButton, payload }) {
  const dispatch = useDispatch();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [formula, setFormula] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [children, setChildren] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState("General");
  const [selectedRow, setSelectedRow] = useState({
    name: payload?.name || "",
    tagType: payload?.tagType || "",
    dataType: payload?.dataType || "",
    unit: payload?.unit || "",
    description: payload?.description || "",
    dataSourceType: payload?.dataSourceType || "",
    channelSource: payload?.channelSource || "",
  });

  const handleAddClick = () => {
    setChildren([...children, ""]);
  };

  const handleChange = (index, event) => {
    const newChildren = [...children];
    newChildren[index] = event.target.value;
    setChildren(newChildren);
  };

  const handleSave = () => {
    setIsDisabled(true);
  };

  const handleEdit = () => {
    setIsDisabled(false);
  };

  const handleSelectChange = (selectedValues, field) => {
    setSelectedRow((prevState) => ({
      ...prevState,
      [field]: selectedValues ?? "",
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedRow((prevState) => {
      const newState = {
        ...prevState,
        [name]: value,
      };

      return newState;
    });
  };

  const handleOk = async () => {
    setLoading(true);

    try {
      dispatch(editTags(selectedRow));
      await new Promise((resolve) => setTimeout(resolve, 2000));
      console.log("Tag edited successfully!");
    } catch (error) {
      console.error("Error editing tag:", error);
    } finally {
      setLoading(false);
      closeButton();
    }
  };

  const handleCancel = () => {
    closeButton();
  };

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setSelectedCheckboxes([...selectedCheckboxes, e.target.value]);
    } else {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((checkbox) => checkbox !== e.target.value)
      );
    }
  };

  const handleTabChange = (key) => {
    setActiveTabKey(key);
    const {
      name,
      tagType,
      dataType,
      unit,
      description,
      dataSourceType,
      channelSource,
    } = selectedRow;

    const formComplete =
      activeTabKey === "Advance Settings"
        ? name?.trim() !== "" &&
          tagType?.trim() !== "" &&
          dataType?.trim() !== "" &&
          unit?.trim() !== "" &&
          description?.trim() !== "" &&
          dataSourceType?.trim() !== "" &&
          channelSource?.trim() !== ""
        : false;

    setIsFormComplete(formComplete);
  };

  useEffect(() => {
    const {
      name,
      tagType,
      dataType,
      unit,
      description,
      dataSourceType,
      channelSource,
    } = selectedRow;
    const formComplete =
      activeTabKey === "General"
        ? name?.trim() !== "" &&
          tagType?.trim() !== "" &&
          dataType?.trim() !== "" &&
          unit?.trim() !== "" &&
          description?.trim() !== "" &&
          dataSourceType?.trim() !== "" &&
          channelSource?.trim() !== ""
        : false;

    setIsFormComplete(formComplete);
  }, [selectedRow]);

  const EditModalfooter = (
    <SimpleFlex
      margin="1.5rem 0 0 0"
      alignitems="center"
      justifycontent="flex-end"
    >
      <SimpleFlex gap="1rem" width="auto">
        <SimpleButton onClick={handleCancel} padding="0.5rem 1rem">
          Cancel
        </SimpleButton>
        <PrimaryButton
          disabled={!isFormComplete}
          loading={loading}
          onClick={handleOk}
          padding="0.5rem 1rem"
        >
          Save Changes
        </PrimaryButton>
      </SimpleFlex>
    </SimpleFlex>
  );

  const tabItems = [
    {
      key: "General",
      label: "General",
      children: (
        <Form>
          <SimpleFlex flexdirection="column" gap="1rem">
            <SimpleFlex flexdirection="column" gap="1rem">
              <P color="var(--nav-color)" fontsize="0.75rem">
                Tag Name
              </P>
              <Form.Item
                name="name"
                className="margin-0"
                rules={[
                  {
                    required: true,
                    message: "Please input Tag Name!",
                  },
                ]}
                initialValue={selectedRow?.name}
              >
                <SimpleInput
                  bordered={false}
                  name="name"
                  onChange={handleInputChange}
                  style={{ borderBottom: "1px solid black" }}
                  className="user-input"
                  padding="0 0 0.3rem 0"
                  color="var(--black)"
                  borderradius="0"
                  fontsize="1rem"
                />
              </Form.Item>
            </SimpleFlex>
            <Row gutter={16}>
              <Col span={8}>
                <SimpleFlex flexdirection="column">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Tag Type
                  </P>
                  <Form.Item
                    name="tagType"
                    className="margin-0"
                    rules={[
                      {
                        required: true,
                        message: "Please input Tag Type!",
                      },
                    ]}
                    initialValue={selectedRow?.tagType}
                  >
                    <PrimarySelect
                      bordered={false}
                      className="user-select"
                      showSearch
                      allowClear
                      onChange={(value) => {
                        handleSelectChange(value, "tagType");
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "1",
                          label: "1 Hrs",
                        },
                        {
                          value: "2",
                          label: "2 Hrs",
                        },
                        {
                          value: "3",
                          label: "3 Hrs",
                        },
                        {
                          value: "4",
                          label: "4 Hrs",
                        },
                        {
                          value: "5",
                          label: "5 Hrs",
                        },
                        {
                          value: "6",
                          label: "6 Hrs",
                        },
                      ]}
                    />
                  </Form.Item>
                </SimpleFlex>
              </Col>
              <Col span={8}>
                <SimpleFlex flexdirection="column">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Data Type
                  </P>
                  <Form.Item
                    name="dataType"
                    className="margin-0"
                    rules={[
                      {
                        required: true,
                        message: "Please input Data Type!",
                      },
                    ]}
                    initialValue={selectedRow?.dataType}
                  >
                    <PrimarySelect
                      bordered={false}
                      className="user-select"
                      showSearch
                      allowClear
                      onChange={(value) => {
                        handleSelectChange(value, "dataType");
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "1",
                          label: "1 Hrs",
                        },
                        {
                          value: "2",
                          label: "2 Hrs",
                        },
                        {
                          value: "3",
                          label: "3 Hrs",
                        },
                        {
                          value: "4",
                          label: "4 Hrs",
                        },
                        {
                          value: "5",
                          label: "5 Hrs",
                        },
                        {
                          value: "6",
                          label: "6 Hrs",
                        },
                      ]}
                    />
                  </Form.Item>
                </SimpleFlex>
              </Col>
              <Col span={8}>
                <SimpleFlex flexdirection="column">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Unit
                  </P>
                  <Form.Item
                    name="unit"
                    className="margin-0"
                    rules={[
                      {
                        required: true,
                        message: "Please input Stop Time Name!",
                      },
                    ]}
                    initialValue={selectedRow?.unit}
                  >
                    <PrimarySelect
                      bordered={false}
                      className="user-select"
                      showSearch
                      allowClear
                      onChange={(value) => {
                        handleSelectChange(value, "unit");
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "1",
                          label: "1 Hrs",
                        },
                        {
                          value: "2",
                          label: "2 Hrs",
                        },
                        {
                          value: "3",
                          label: "3 Hrs",
                        },
                        {
                          value: "4",
                          label: "4 Hrs",
                        },
                        {
                          value: "5",
                          label: "5 Hrs",
                        },
                        {
                          value: "6",
                          label: "6 Hrs",
                        },
                      ]}
                    />
                  </Form.Item>
                </SimpleFlex>
              </Col>
            </Row>
            <SimpleFlex flexdirection="column" gap="0.5rem">
              <SimpleFlex justifycontent="space-between">
                <P color="var(--nav-color)" fontsize="0.75rem">
                  Description
                </P>

                <SimpleFlex width="auto" gap="0.3rem" alignitems="center">
                  <PrimaryTooltip
                    title={"Unique"}
                    placement="top"
                    fontsize="0.75rem"
                  >
                    <P color="var(--nav-color)" cursor="pointer">
                      <InfoCircleOutlined />
                    </P>
                  </PrimaryTooltip>
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Max 500 Characters
                  </P>
                </SimpleFlex>
              </SimpleFlex>
              <Form.Item
                name="description"
                className="margin-0"
                rules={[
                  {
                    required: true,
                    message: "Please input description!",
                  },
                ]}
                initialValue={selectedRow?.description}
              >
                <TextArea
                  name="description"
                  onChange={handleInputChange}
                  rows={6}
                  placeholder="maxLength is 500"
                  maxLength={500}
                />
              </Form.Item>
            </SimpleFlex>
            <Row gutter={16}>
              <Col span={12}>
                <SimpleFlex flexdirection="column">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Data Source Type
                  </P>
                  <Form.Item
                    name="dataSourceType"
                    className="margin-0"
                    rules={[
                      {
                        required: true,
                        message: "Please input Data Source Type!",
                      },
                    ]}
                    initialValue={selectedRow?.dataSourceType}
                  >
                    <PrimarySelect
                      bordered={false}
                      className="user-select"
                      showSearch
                      allowClear
                      onChange={(value) => {
                        handleSelectChange(value, "dataSourceType");
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "1",
                          label: "1 Hrs",
                        },
                        {
                          value: "2",
                          label: "2 Hrs",
                        },
                        {
                          value: "3",
                          label: "3 Hrs",
                        },
                        {
                          value: "4",
                          label: "4 Hrs",
                        },
                        {
                          value: "5",
                          label: "5 Hrs",
                        },
                        {
                          value: "6",
                          label: "6 Hrs",
                        },
                      ]}
                    />
                  </Form.Item>
                </SimpleFlex>
              </Col>
              <Col span={12}>
                <SimpleFlex flexdirection="column">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Channel Source
                  </P>
                  <Form.Item
                    name="channelSource"
                    className="margin-0"
                    rules={[
                      {
                        required: true,
                        message: "Please input Channel Source!",
                      },
                    ]}
                    initialValue={selectedRow?.channelSource}
                  >
                    <PrimarySelect
                      bordered={false}
                      className="user-select"
                      showSearch
                      allowClear
                      onChange={(value) => {
                        handleSelectChange(value, "channelSource");
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "1",
                          label: "1 Hrs",
                        },
                        {
                          value: "2",
                          label: "2 Hrs",
                        },
                        {
                          value: "3",
                          label: "3 Hrs",
                        },
                        {
                          value: "4",
                          label: "4 Hrs",
                        },
                        {
                          value: "5",
                          label: "5 Hrs",
                        },
                        {
                          value: "6",
                          label: "6 Hrs",
                        },
                      ]}
                    />
                  </Form.Item>
                </SimpleFlex>
              </Col>
            </Row>
          </SimpleFlex>
        </Form>
      ),
    },
    {
      key: "Advance Settings",
      label: "Advance Settings",
      children: (
        <SimpleFlex flexdirection="column" gap="1rem">
          <SimpleFlex flexdirection="column" gap="0.75rem">
            <Checkbox value="checkbox1" onChange={handleCheckboxChange}>
              <P fontsize="0.75rem" color="var(--nav-color)">
                Enable advance formula
              </P>
            </Checkbox>
            {selectedCheckboxes.includes("checkbox1") && (
              <SimpleFlex
                className={
                  selectedCheckboxes.includes("checkbox1")
                    ? "slide-in"
                    : "slide-out"
                }
                flexdirection="column"
                gap="0.5rem"
              >
                <P fontsize="0.75rem" color="var(--nav-color)">
                  Formula
                </P>
                <SimpleFlex
                  alignitems="center"
                  border="1px solid var(--anti-flash-white)"
                  borderradius="0.375rem"
                  backgroundcolor={isDisabled ? "var(--anti-flash-white)" : ""}
                >
                  <SimpleInput
                    bordered={false}
                    value={formula}
                    onChange={(e) => setFormula(e.target.value)}
                    disabled={isDisabled}
                    color="var(--black) !important"
                    style={{ marginRight: "10px" }}
                    fontsize="1rem"
                  />
                  {isDisabled ? (
                    <SimpleButton
                      backgroundcolor="transparent"
                      border="0px"
                      icon={<Edit height="1rem" width="1rem" />}
                      onClick={handleEdit}
                    />
                  ) : (
                    <SimpleButton
                      backgroundcolor="transparent"
                      border="0px"
                      icon={<CheckCircleOutlined />}
                      onClick={handleSave}
                    />
                  )}
                </SimpleFlex>
              </SimpleFlex>
            )}
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="1rem">
            <Checkbox value="checkbox2" onChange={handleCheckboxChange}>
              <P fontsize="0.75rem" color="var(--nav-color)">
                Input conditioning
              </P>
            </Checkbox>
            {selectedCheckboxes.includes("checkbox2") && (
              <SimpleFlex
                className={
                  selectedCheckboxes.includes("checkbox2")
                    ? "slide-in"
                    : "slide-out"
                }
                flexdirection="column"
                gap="1rem"
              >
                <P fontsize="1rem">Add Conditioning</P>
                {children.map((child, index) => (
                  <Conditioning
                    key={index}
                    value={child}
                    onChange={(event) => handleChange(index, event)}
                  />
                ))}
                <SimpleFlex
                  onClick={handleAddClick}
                  justifycontent="center"
                  alignitems="center"
                  gap="0.5rem"
                  cursor="pointer"
                >
                  Add New Condition <PlusDark />
                </SimpleFlex>
              </SimpleFlex>
            )}
          </SimpleFlex>
        </SimpleFlex>
      ),
    },
  ];
  return (
    <PrimaryModal
      centered
      title="Edit Tag"
      open
      minwidth="40rem"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={EditModalfooter}
      maskClosable={false}
    >
      <Tabs
        className="tabs custom-tab-title"
        items={tabItems}
        onChange={handleTabChange}
      />
    </PrimaryModal>
  );
}

function Conditioning() {
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [expanded, setExpanded] = useState(false);

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedRow((prevState) => {
      const newState = {
        ...prevState,
        [name]: value,
      };

      return newState;
    });
  };

  const handleSelectChange = (selectedValues, field) => {
    setSelectedRow((prevState) => ({
      ...prevState,
      [field]: selectedValues ?? "",
    }));
  };

  const handleAccordionChange = (keys) => {
    setExpanded(!expanded);
  };

  return (
    <SimpleFlex
      border="1px solid var(--columbia-gray-color)"
      borderradius="1.125rem"
      width="auto"
    >
      <Collapse
        accordion
        bordered={false}
        className="w-100 custom-collapse"
        onChange={handleAccordionChange}
      >
        <Collapse.Panel
          showArrow={false}
          header={
            <Form>
              <SimpleFlex
                backgroundcolor="transparent"
                alignitems="center"
                justifycontent="flex-end"
              >
                {!expanded && (
                  <Row gutter={16} className="w-100 align-items-center">
                    <Col span={2}>
                      <P fontsize="1rem">If</P>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="value"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Value!",
                          },
                        ]}
                        initialValue={selectedRow?.value}
                      >
                        <PrimarySelect
                          showSearch
                          allowClear
                          onChange={(value) => {
                            handleSelectChange(value, "value");
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "1",
                              label: "1 Hrs",
                            },
                            {
                              value: "2",
                              label: "2 Hrs",
                            },
                            {
                              value: "3",
                              label: "3 Hrs",
                            },
                            {
                              value: "4",
                              label: "4 Hrs",
                            },
                            {
                              value: "5",
                              label: "5 Hrs",
                            },
                            {
                              value: "6",
                              label: "6 Hrs",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="condition"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Condition!",
                          },
                        ]}
                        initialValue={selectedRow?.condition}
                      >
                        <PrimarySelect
                          showSearch
                          allowClear
                          onChange={(value) => {
                            handleSelectChange(value, "condition");
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "1",
                              label: "1 Hrs",
                            },
                            {
                              value: "2",
                              label: "2 Hrs",
                            },
                            {
                              value: "3",
                              label: "3 Hrs",
                            },
                            {
                              value: "4",
                              label: "4 Hrs",
                            },
                            {
                              value: "5",
                              label: "5 Hrs",
                            },
                            {
                              value: "6",
                              label: "6 Hrs",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                {expanded ? (
                  <SimpleFlex
                    alignitems="center"
                    gap="0.2rem"
                    width="auto"
                    onClick={() => {
                      setExpanded(!expanded);
                    }}
                  >
                    <P
                      fontsize="0.625rem"
                      color="var(--nav-color)"
                      cursor="pointer"
                    >
                      Collapse
                    </P>
                    <UpArrowDark />
                  </SimpleFlex>
                ) : (
                  <SimpleFlex
                    alignitems="center"
                    gap="0.2rem"
                    width="auto"
                    onClick={() => {
                      setExpanded(!expanded);
                    }}
                  >
                    <P
                      fontsize="0.625rem"
                      color="var(--nav-color)"
                      cursor="pointer"
                    >
                      Expand
                    </P>{" "}
                    <DownArrowDark />
                  </SimpleFlex>
                )}
              </SimpleFlex>
            </Form>
          }
          key="radioGroup"
        >
          <Form>
            <SimpleFlex
              gap="1rem"
              flexdirection="column"
              padding="0 1.5rem"
              width="auto"
            >
              <Radio.Group
                onChange={handleRadioChange}
                value={selectedRadio}
                className="custom-radio-group"
              >
                <SimpleFlex
                  height="auto"
                  className={
                    selectedRadio === "radio1"
                      ? "radio-group-selected"
                      : "radio-group-unselected"
                  }
                >
                  <Radio
                    value="radio1"
                    className={selectedRadio === "radio1" ? "selected" : ""}
                  >
                    <SimpleFlex flexdirection="column">
                      <P fontWeight="var(--font-600)">Match any of the below</P>
                      <P fontsize="0.75rem" color="var(--nav-color)">
                        Action will taken if any of the below conditions match
                      </P>
                    </SimpleFlex>
                  </Radio>
                </SimpleFlex>
                <SimpleFlex
                  height="auto"
                  className={
                    selectedRadio === "radio2"
                      ? "radio-group-selected"
                      : "radio-group-unselected"
                  }
                >
                  <Radio
                    value="radio2"
                    className={selectedRadio === "radio2" ? "selected" : ""}
                  >
                    <SimpleFlex flexdirection="column">
                      <P fontWeight="var(--font-600)">Match all of the below</P>
                      <P fontsize="0.75rem" color="var(--nav-color)">
                        Action will only be taken if all the below conditions
                        match
                      </P>
                    </SimpleFlex>
                  </Radio>
                </SimpleFlex>
              </Radio.Group>
              <SimpleFlex gap="0.8rem" position="relative">
                <p className="position-absolute">If</p>
                <SimpleFlex flexdirection="column" gap="0.5rem">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Choose Value
                  </P>
                  <Form.Item
                    name="value"
                    className="margin-0"
                    rules={[
                      {
                        required: true,
                        message: "Please input Value!",
                      },
                    ]}
                    initialValue={selectedRow?.value}
                  >
                    {/* <P>If</P> */}
                    <PrimarySelect
                      showSearch
                      allowClear
                      onChange={(value) => {
                        handleSelectChange(value, "value");
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "1",
                          label: "1 Hrs",
                        },
                        {
                          value: "2",
                          label: "2 Hrs",
                        },
                        {
                          value: "3",
                          label: "3 Hrs",
                        },
                        {
                          value: "4",
                          label: "4 Hrs",
                        },
                        {
                          value: "5",
                          label: "5 Hrs",
                        },
                        {
                          value: "6",
                          label: "6 Hrs",
                        },
                      ]}
                    />
                  </Form.Item>
                </SimpleFlex>
                <SimpleFlex flexdirection="column" gap="0.5rem">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Choose condition
                  </P>
                  <Form.Item
                    name="condition"
                    className="margin-0"
                    rules={[
                      {
                        required: true,
                        message: "Please input Condition!",
                      },
                    ]}
                    initialValue={selectedRow?.condition}
                  >
                    <PrimarySelect
                      showSearch
                      allowClear
                      onChange={(value) => {
                        handleSelectChange(value, "condition");
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "1",
                          label: "1 Hrs",
                        },
                        {
                          value: "2",
                          label: "2 Hrs",
                        },
                        {
                          value: "3",
                          label: "3 Hrs",
                        },
                        {
                          value: "4",
                          label: "4 Hrs",
                        },
                        {
                          value: "5",
                          label: "5 Hrs",
                        },
                        {
                          value: "6",
                          label: "6 Hrs",
                        },
                      ]}
                    />
                  </Form.Item>
                </SimpleFlex>
                <SimpleFlex flexdirection="column" gap="0.5rem">
                  <P color="var(--nav-color)" fontsize="0.75rem">
                    Input
                  </P>
                  <Form.Item
                    name="input"
                    className="margin-0"
                    rules={[
                      {
                        required: true,
                        message: "Please input Input!",
                      },
                    ]}
                    initialValue={selectedRow?.input}
                  >
                    <SimpleInput
                      name="input"
                      onChange={handleInputChange}
                      padding="0.4rem"
                      color="var(--black)"
                      fontsize="0.875rem"
                    />
                  </Form.Item>
                </SimpleFlex>
              </SimpleFlex>
              <SimpleFlex
                flexdirection="column"
                border="1px solid var(--columbia-gray-color)"
                padding="1rem"
                width="auto"
                borderradius="0.25rem"
                gap="0.5rem"
              >
                <SimpleFlex alignitems="center" justifycontent="space-between">
                  <P fontsize="0.75rem">Action</P>
                  <P
                    fontsize="0.75rem"
                    textdecorationline="underline"
                    cursor="pointer"
                  >
                    {" "}
                    Add Action
                  </P>
                </SimpleFlex>
                <Row gutter={16}>
                  <Col span={14}>
                    <SimpleFlex flexdirection="column" gap="0.5rem">
                      <P fontsize="0.75rem" color="var(--nav-color)">
                        Choose action
                      </P>
                      <Form.Item
                        name="action"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Action!",
                          },
                        ]}
                        initialValue={selectedRow?.action}
                      >
                        <PrimarySelect
                          showSearch
                          allowClear
                          onChange={(value) => {
                            handleSelectChange(value, "action");
                          }}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "1",
                              label: "1 Hrs",
                            },
                            {
                              value: "2",
                              label: "2 Hrs",
                            },
                            {
                              value: "3",
                              label: "3 Hrs",
                            },
                            {
                              value: "4",
                              label: "4 Hrs",
                            },
                            {
                              value: "5",
                              label: "5 Hrs",
                            },
                            {
                              value: "6",
                              label: "6 Hrs",
                            },
                          ]}
                        />
                      </Form.Item>
                    </SimpleFlex>
                  </Col>
                  <Col span={10}>
                    <SimpleFlex flexdirection="column" gap="0.5rem">
                      <P fontsize="0.75rem" color="var(--nav-color)">
                        Input Value
                      </P>
                      <Form.Item
                        name="inputValue"
                        className="margin-0"
                        rules={[
                          {
                            required: true,
                            message: "Please input Value!",
                          },
                        ]}
                        initialValue={selectedRow?.inputValue}
                      >
                        <SimpleInput
                          name="inputValue"
                          onChange={handleInputChange}
                          padding="0.4rem"
                          color="var(--black)"
                          fontsize="0.875rem"
                        />
                      </Form.Item>
                    </SimpleFlex>
                  </Col>
                </Row>
              </SimpleFlex>
            </SimpleFlex>
          </Form>
        </Collapse.Panel>
      </Collapse>
    </SimpleFlex>
  );
}
export default EditTag;
