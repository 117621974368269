import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

// Action to fetch all plants
export const fetchPlants = createAsyncThunk("fetchPlants", async () => {
  // const response = await fetch("https://your-api-endpoint/plants");
  // return response.json();

  // throw new Error("");
  // await new Promise((resolve) => setTimeout(resolve, 2000));
  return [
    {
      key: "1",
      productName: "Rida plant 1",
      description: "Switch Making Plant",
      shortName: "Switch Factory (SWFA)",
      owners: "Group B,A",
      parent: "Aplhanar",
      assignOwners: ["Mohammed Waris", "Abdul Nizam"],
    },
    {
      key: "2",
      productName: "Rida plant 2",
      description: "Switch Making Plant",
      shortName: "Switch Factory (SWFA)",
      owners: "Group B,A",
      parent: "Aplhanar",
      assignOwners: ["Abdul Nizam", "Billal Ahmed"],
    },
    {
      key: "3",
      productName: "Rida plant 3",
      description: "Switch Making Plant",
      shortName: "Switch Factory (SWFA)",
      owners: "Group B,A",
      parent: "Aplhanar",
    },
  ];
});

// Action to add a new plant
export const addPlant = createAsyncThunk("addPlant", async (newPlant) => {
  const response = await fetch("https://your-api-endpoint/plants", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newPlant),
  });
  return response.json();
});

// Action to delete a plant
export const deletePlant = createAsyncThunk("deletePlant", async (plantId) => {
  const response = await fetch(`https://your-api-endpoint/plants/${plantId}`, {
    method: "DELETE",
  });
  return response.json();
});

// Action to edit a plant
export const editPlant = createAsyncThunk("editPlant", async (editPlant) => {
  // const response = await fetch(
  //   `https://your-api-endpoint/plants/${editedPlant.key}`,
  //   {
  //     method: "PUT",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(editPlant),
  //   }
  // );
  // return response.json();

  // plant is edited successfully
  const dispatch = useDispatch();
  dispatch(fetchPlants());
  return editPlant;
});

const plantSlice = createSlice({
  name: "plants",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    selectedPlant: null,
  },
  reducers: {
    setSelectedPlant: (state, action) => {
      state.selectedPlant = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlants.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPlants.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchPlants.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(addPlant.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
    });
    builder.addCase(deletePlant.fulfilled, (state, action) => {
      state.data = state.data.filter(
        (plant) => plant.key !== action.payload.key
      );
    });
    builder.addCase(editPlant.fulfilled, (state, action) => {
      state.data = state.data.map((plant) =>
        plant.key === action.payload.key ? action.payload : plant
      );
      state.selectedPlant = null; // Clear selected plant after edit
    });
  },
});

export const { setSelectedPlant } = plantSlice.actions;

export default plantSlice.reducer;
