import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Action
export const fetchProductionStats = createAsyncThunk(
  "dashboard/fetchProductionStats",
  async () => {
    // Replace the URL with your actual endpoint to fetch dashboard data
    // const response = await fetch("https://api.example.com/dashboard");
    // return response.json();

    await new Promise((resolve) => setTimeout(resolve, 2000));
    return {
      data: {
        productionStats: {
          product: "Switch Board",
          currentPO: "SW46464",
          currentPartNo: "22",
          status: "14912",
          timeRemaining: "04:46:30",
          cycleTime: "52.04",
          quality: "100%",
        },
      },
    };
  }
);

// Dashboard Slice
const dashboardSlice = createSlice({
  name: "dashboardFeeds",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductionStats.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(fetchProductionStats.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchProductionStats.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      console.error("Error fetching dashboard data:", action.error.message);
    });
  },
});

export default dashboardSlice.reducer;
