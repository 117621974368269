import { useEffect, useState } from "react";
import { PrimaryModal } from "../../../../Components/StyledComponents/Modal";
import { SimpleFlex } from "../../../../Components/StyledComponents/SimpleFlex";
import { SimpleButton } from "../../../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../../../Components/StyledComponents/Button";
import { P } from "../../../../Components/StyledComponents/Paragraph";
import { PrimaryInput } from "../../../../Components/StyledComponents/Input";
import { PrimarySelect } from "../../../../Components/StyledComponents/Select";
import { ReactComponent as DownArrow } from "../../../../assets/svg/downArrow.svg";
import "./CardDetail.scss";

function AlertCardDetail({ showModal, closeButton, payload }) {
  const [visible, setVisible] = useState(showModal);

  const [data, setData] = useState(payload);
  const [severity, setSelectedSeverity] = useState(data?.severity);
  const [description, setDescription] = useState(data?.description || "");

  const handleSelectSeverityChange = (value) => {
    setSelectedSeverity(value);
  };

  useEffect(() => {
    setVisible(showModal);
  }, [showModal]);

  useEffect(() => {
    setData(payload);
    setSelectedSeverity(payload?.severity);
  }, [payload]);

  useEffect(() => {
    if (visible) {
      setSelectedSeverity(data?.severity);
      setDescription(data?.description || "");
    }
  }, [visible, data]);

  const handleOk = () => {
    setVisible(false);
    setSelectedSeverity(null);
    closeButton();
  };

  const handleCancel = () => {
    setVisible(false);
    setSelectedSeverity(null);
    closeButton();
  };

  const handleFieldBlur = () => {
    setDescription(null);
  };

  const handleDescription = (value) => {
    setDescription(value);
  };

  const MACHINE_OPTIONS = [
    "Richie Rich",
    "Adam Jons",
    "John Wills",
    "Mark Eve",
  ];
  const ESCILATION_OPTIONS = ["Coil Machine 1", "P10", "P002"];

  const [selectedItems, setSelectedItems] = useState([]);
  const [escilationSelect, setEscilationSelect] = useState([]);
  const filteredOptions = MACHINE_OPTIONS.filter(
    (o) => !selectedItems.includes(o)
  );
  const filteredEscilationOptions = ESCILATION_OPTIONS.filter(
    (o) => !escilationSelect.includes(o)
  );

  const Modalfooter = (
    <SimpleFlex gap="1rem" justifycontent="flex-end">
      <SimpleButton onClick={handleCancel} padding="0.5rem 1rem">
        Reset
      </SimpleButton>
      <PrimaryButton onClick={handleOk} padding="0.5rem 1rem" disabled={false}>
        Save & Acknowledged
      </PrimaryButton>
    </SimpleFlex>
  );

  return (
    <PrimaryModal
      centered
      className="ant-modal-min-width"
      title={data?.title}
      maskClosable={false}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      onClose={handleCancel}
      footer={Modalfooter}
    >
      <SimpleFlex flexdirection="column" gap="1.5rem">
        <SimpleFlex gap="3rem">
          <SimpleFlex flexdirection="column" gap="0.3rem" width="auto">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Status
            </P>
            <P>{data?.status}</P>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="0.3rem" width="auto">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Alert
            </P>
            <P>System</P>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="0.3rem" width="auto">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Severity
            </P>
            <PrimarySelect
              className="ant-select-min-width select"
              bordered={false}
              suffixIcon={<DownArrow />}
              value={severity}
              showSearch
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "informative",
                  label: "informative",
                },
                {
                  value: "critical",
                  label: "critical",
                },
                {
                  value: "normal",
                  label: "normal",
                },
              ]}
              onChange={handleSelectSeverityChange}
            ></PrimarySelect>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="0.3rem" width="auto">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Duration
            </P>
            <P>{data?.duration}</P>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="0.3rem" width="auto">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Triggered
            </P>
            <P>{data?.triggered_due_to}</P>
          </SimpleFlex>
        </SimpleFlex>
        <SimpleFlex flexdirection="column" gap="0.5rem">
          <P fontsize="0.75rem" color="var(--nav-color)">
            Description
          </P>
          <PrimaryInput
            padding="0.2rem 0.8rem"
            value={description}
            autoFocus
            allowClear
            onChange={(e) => handleDescription(e.target.value)}
          />
        </SimpleFlex>
        <SimpleFlex gap="3rem">
          <SimpleFlex flexdirection="column" gap="0.3rem" width="auto">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Esclation Status
            </P>
            <P>Escalated</P>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="0.3rem" width="auto">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Primary Assignee
            </P>
            <PrimarySelect
              className="ant-select-min-width select"
              bordered={false}
              allowClear={true}
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "notIdentified",
                  label: "Not Identified",
                },
                {
                  value: "closed",
                  label: "Closed",
                },
                {
                  value: "communicated",
                  label: "Communicated",
                },
                {
                  value: "identified",
                  label: "Identified",
                },
                {
                  value: "resolved",
                  label: "Resolved",
                },
                {
                  value: "cancelled",
                  label: "Cancelled",
                },
              ]}
            />
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="0.3rem" width="auto">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Currently Assigned To
            </P>
            <PrimarySelect
              className="ant-select-min-width select"
              bordered={false}
              allowClear={true}
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "notIdentified",
                  label: "Not Identified",
                },
                {
                  value: "closed",
                  label: "Closed",
                },
                {
                  value: "communicated",
                  label: "Communicated",
                },
                {
                  value: "identified",
                  label: "Identified",
                },
                {
                  value: "resolved",
                  label: "Resolved",
                },
                {
                  value: "cancelled",
                  label: "Cancelled",
                },
              ]}
            />
          </SimpleFlex>
        </SimpleFlex>
        <SimpleFlex gap="3rem">
          <SimpleFlex flexdirection="column" gap="0.5rem">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Esclation To
            </P>
            <PrimarySelect
              className="ant-select-min-width select"
              mode="multiple"
              bordered={false}
              allowClear={true}
              showSearch
              placeholder="Search to Select"
              value={escilationSelect}
              onChange={setEscilationSelect}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={filteredEscilationOptions.map((item) => ({
                value: item,
                label: item,
              }))}
            />
          </SimpleFlex>
        </SimpleFlex>
        <SimpleFlex gap="3rem">
          <SimpleFlex flexdirection="column" gap="0.5rem">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Machine(s)
            </P>
            <PrimarySelect
              className="ant-select-min-width select"
              mode="multiple"
              bordered={false}
              allowClear={true}
              showSearch
              placeholder="Search to Select"
              value={selectedItems}
              onChange={setSelectedItems}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={filteredOptions.map((item) => ({
                value: item,
                label: item,
              }))}
            />
          </SimpleFlex>
        </SimpleFlex>
        <SimpleFlex gap="3rem">
          <SimpleFlex width="auto">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Created at :
            </P>
            <P>{data?.created_at}</P>
          </SimpleFlex>
          <SimpleFlex width="auto">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Updated at :
            </P>
            <P>21 Oct 2023, 07:42 PM</P>
          </SimpleFlex>
        </SimpleFlex>
        <SimpleFlex gap="3rem">
          <SimpleFlex width="auto">
            <P fontsize="0.75rem" color="var(--nav-color)">
              SLA :
            </P>
            <P>1 Hr</P>
          </SimpleFlex>
          <SimpleFlex width="auto">
            <P fontsize="0.75rem" color="var(--nav-color)">
              Associated Tag :
            </P>
            <P>sensorM1645H</P>
          </SimpleFlex>
        </SimpleFlex>
      </SimpleFlex>
    </PrimaryModal>
  );
}

export default AlertCardDetail;
