import { Breadcrumb, Result, Table } from "antd";
import { SimpleFlex } from "../../Components/StyledComponents/SimpleFlex";
import { P } from "../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../Components/StyledComponents/SimpleButton";
import { PrimaryButton } from "../../Components/StyledComponents/Button";
import { ReactComponent as Plus } from "../../assets/svg/plus.svg";
import { ReactComponent as Edit } from "../../assets/svg/edit.svg";
import { useEffect, useState } from "react";
import { PrimaryInputSearch } from "../../Components/StyledComponents/InputSearch";
import { fetchConfigurations } from "../../redux/slice/ConfigurationMgmt/Configuration";
import { ReactComponent as UploadFile } from "../../assets/svg/uploadFile.svg";
import EditConfiguration from "./EditConfigurationModal";
import AddConfiguration from "./AddConfigurationModal";
import { useDispatch, useSelector } from "react-redux";
import "./Configuration.scss";

function Configurations() {
  const dispatch = useDispatch();
  const { configurations = [] } = useSelector((state) => state);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const dataSource = configurations?.data || [];
  const [filteredData, setFilteredData] = useState(dataSource);

  useEffect(() => {
    setFilteredData(dataSource);
  }, [dataSource]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const breadcrumbItems = [
    { title: "Dashboard", href: "/proton/live-dashboard" },
    { title: "Configuration", href: "" },
  ];

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "8rem",
      render: (record) => (
        <SimpleFlex gap="1rem" backgroundcolor="transparent">
          <SimpleButton
            padding="0.4rem 0.8rem"
            onClick={() => {
              setSelectedRow(record);
              setShowEditModal(true);
            }}
          >
            <SimpleFlex cursor="pointer" alignitems="center" gap="0.2rem">
              <P color="var(--black)" cursor="pointer" fontsize="1rem">
                Edit
              </P>
              <P
                fontsize="0.3rem"
                color="var(--primary-color)"
                cursor="pointer"
              >
                <Edit />
              </P>
            </SimpleFlex>
          </SimpleButton>
        </SimpleFlex>
      ),
    },
  ];

  // Dispatch the action when the component mounts
  useEffect(() => {
    dispatch(fetchConfigurations());
  }, [dispatch]);

  //search in table
  useEffect(() => {
    if (searchValue) {
      setFilteredData(
        dataSource.filter(
          (item) =>
            item.type.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.label.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.value.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else {
      setFilteredData(dataSource);
    }
  }, [searchValue]);

  if (configurations?.isError) {
    return (
      <SimpleFlex justifycontent="center" backgroundcolor="transparent">
        <Result
          status="500"
          title="500"
          subTitle="Sorry, Failed to fetch plants"
        />
      </SimpleFlex>
    );
  }

  return (
    <SimpleFlex
      backgroundcolor="transparent"
      padding="1rem 2rem"
      overflow="scroll"
      flexdirection="column"
      gap="1rem"
    >
      <SimpleFlex
        width="auto"
        height="fit-content"
        backgroundcolor="transparent"
      >
        <Breadcrumb separator=">">
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>
              <a href={item.href}>{item.title}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        justifycontent="space-between"
      >
        <P fontsize="2.25rem" fontWeight="var(--font-600)">
          Custom List (configuration) Management
        </P>
        <SimpleFlex width="auto" gap="1rem" backgroundcolor="transparent">
          <SimpleButton padding="0.75rem 1.625rem">
            <SimpleFlex cursor="pointer" alignitems="center" gap="0.5rem">
              <P
                color="var(--nav-color)"
                fontWeight="var(--font-500)"
                cursor="pointer"
              >
                Upload File
              </P>{" "}
              <UploadFile />
            </SimpleFlex>
          </SimpleButton>
          <PrimaryButton
            padding="0.75rem 1.625rem"
            onClick={() => {
              setShowAddModal(true);
            }}
          >
            <SimpleFlex
              cursor="pointer"
              alignitems="center"
              gap="0.5rem"
              backgroundcolor="transparent"
            >
              <P
                color="var(--columbia-gray-color)"
                fontWeight="var(--font-500)"
                cursor="pointer"
              >
                Add Type
              </P>{" "}
              <Plus />
            </SimpleFlex>{" "}
          </PrimaryButton>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex
        height="auto"
        backgroundcolor="transparent"
        flexdirection="column"
        gap="1rem"
      >
        <SimpleFlex
          backgroundcolor="transparent"
          justifycontent="space-between"
        >
          <SimpleFlex
            width="auto"
            alignitems="center"
            backgroundcolor="transparent"
            className="transparent-search"
          >
            <PrimaryInputSearch
              allowClear
              placeholder="Search"
              onSearch={(value) => setSearchValue(value)}
            />
          </SimpleFlex>
          <SimpleFlex backgroundcolor="transparent" width="auto" gap="0.5rem">
            <SimpleButton
              padding="0.75rem 1.625rem"
              disabled={selectedRowKeys.length === 0}
            >
              Delete
            </SimpleButton>
          </SimpleFlex>
        </SimpleFlex>
        <Table
          loading={configurations?.isLoading}
          rowSelection={rowSelection}
          dataSource={filteredData}
          columns={columns}
          bordered
          className="w-100 custom-table"
          pagination={{
            position: ["bottomCenter"],
            pageSize: 10,
          }}
        />
      </SimpleFlex>
      {!!showEditModal && (
        <EditConfiguration
          payload={selectedRow}
          closeButton={() => {
            setShowEditModal(false);
          }}
        />
      )}

      {!!showAddModal && (
        <AddConfiguration
          closeButton={() => {
            setShowAddModal(false);
          }}
        />
      )}
    </SimpleFlex>
  );
}
export default Configurations;
