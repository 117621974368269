import { useState } from "react";
import { PrimaryButton } from "../../../Components/StyledComponents/Button";
import { P } from "../../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../../Components/StyledComponents/SimpleButton";
import { SimpleFlex } from "../../../Components/StyledComponents/SimpleFlex";
import transition from "../../../Transition";
import { ReactComponent as StarBadge } from "../../../assets/svg/starBadge.svg";
import { ReactComponent as Edit } from "../../../assets/svg/edit.svg";
import {
  Breadcrumb,
  ConfigProvider,
  Input,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import "./Machines.scss";
import { InfoCircleOutlined } from "@ant-design/icons";
import { PrimaryTooltip } from "../../../Components/StyledComponents/Tooltip";
import { PrimaryInput } from "../../../Components/StyledComponents/Input";
import { PrimaryInputSearch } from "../../../Components/StyledComponents/InputSearch";

function Machines() {
  const data = [
    {
      key: "1",
      status: "Pending",
      startDate: "13 Oct 2023",
      dueDate: "13 Oct 2023",
      jobNo: "ADD64646S",
      part: "RMU",
      required: "2",
      completed: "2",
      changeOver: "0",
      cycleTime: "0",
    },
    {
      key: "2",
      status: "Start",
      startDate: "13 Oct 2023",
      dueDate: "13 Oct 2023",
      jobNo: "ADD64646S",
      part: "RMU",
      required: "2",
      completed: "2",
      changeOver: "0",
      cycleTime: "0",
    },
    {
      key: "3",
      status: "Closed",
      startDate: "13 Oct 2023",
      dueDate: "13 Oct 2023",
      jobNo: "ADD64646S",
      part: "RMU",
      required: "2",
      completed: "2",
      changeOver: "0",
      cycleTime: "0",
    },
    {
      key: "4",
      status: "In-Progress",
      startDate: "13 Oct 2023",
      dueDate: "13 Oct 2023",
      jobNo: "ADD64646S",
      part: "RMU Compact",
      required: "2",
      completed: "2",
      changeOver: "0",
      cycleTime: "0",
    },
    {
      key: "5",
      status: "Closed",
      startDate: "13 Oct 2023",
      dueDate: "13 Oct 2023",
      jobNo: "ADD64646S",
      part: "RMU Compact",
      required: "2",
      completed: "2",
      changeOver: "0",
      cycleTime: "0",
    },
    {
      key: "6",
      status: "Start",
      startDate: "13 Oct 2023",
      dueDate: "13 Oct 2023",
      jobNo: "ADD64646S",
      part: "RMU Compact",
      required: "2",
      completed: "2",
      changeOver: "0",
      cycleTime: "0",
    },
  ];
  const columns = [
    {
      title: "Action/Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <SimpleFlex gap="0.5rem" backgroundcolor="transparent">
          <P className={getColorForStatus(text.toLowerCase())}>{text}</P>
          <SimpleButton padding="0.3rem 0.7rem">
            <PrimaryTooltip title={text} placement="top">
              <InfoCircleOutlined />
            </PrimaryTooltip>
          </SimpleButton>
          <SimpleButton padding="0.53rem 0.7rem">
            <Edit />
          </SimpleButton>
        </SimpleFlex>
      ),
    },
    { title: "Start Date", dataIndex: "startDate", key: "startDate" },
    { title: "Due Date", dataIndex: "dueDate", key: "dueDate" },
    { title: "Job Number", dataIndex: "jobNo", key: "jobNo" },
    { title: "Part", dataIndex: "part", key: "part" },
    { title: "Required", dataIndex: "required", key: "required" },
    { title: "Completed", dataIndex: "completed", key: "completed" },
    { title: "Changeover", dataIndex: "changeOver", key: "changeOver" },
    { title: "Cycle Time", dataIndex: "cycleTime", key: "cycleTime" },
  ];
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  function getColorForStatus(status) {
    if (status === "pending") {
      return "pending-tag";
    } else if (status === "start") {
      return "start-tag";
    } else if (status === "closed") {
      return "closed-tag";
    } else if (status === "in-progress") {
      return "inProgress-tag";
    }
    return "default";
  }

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      const filteredData = data.filter((item) =>
        Object.values(item).some((field) =>
          String(field).toLowerCase().includes(value.toLowerCase())
        )
      );
      setFilteredData(filteredData);
    } else {
      setFilteredData(data);
    }
  };

  const items = [
    {
      key: "1",
      label: "Orders Queue",
      children: (
        <SimpleFlex
          width="auto"
          flexdirection="column"
          height="auto"
          gap="0.5rem"
          backgroundcolor="transparent"
        >
          <Table
            bordered={true}
            className="custom-table"
            columns={columns}
            dataSource={filteredData}
            pagination={false}
          />
          <SimpleFlex
            flexdirection="row"
            borderradius="1.125rem"
            height="auto"
            gap="0.5rem"
            backgroundcolor="transparent"
            position="relative"
            justifycontent="space-between"
            margin="0 0 1rem 0"
          >
            <SimpleButton padding="0.75rem 1.625rem">Cancel</SimpleButton>
            <PrimaryButton padding="0.75rem 1.625rem">
              Change to no work
            </PrimaryButton>
          </SimpleFlex>
        </SimpleFlex>
      ),
    },
    {
      key: "2",
      label: "Unscheduled Orders",
      children: "Content of Tab Pane 2",
    },
    {
      key: "3",
      label: "Demo",
      children: "Content of Tab Pane 3",
    },
  ];
  const breadcrumbItems = [
    { title: "Dashboard", href: "/proton/live-dashboard" },
    { title: "Machines", href: "" },
    { title: "Application List", href: "" },
    { title: "Change Over" },
  ];

  const searchBox = () => {
    return (
      <SimpleFlex
        backgroundcolor="transparent"
        width="auto"
        className="transparent-search"
      >
        <PrimaryInputSearch
          onSearch={(value) => handleSearch(value)}
          placeholder="Search"
          allowClear
        />
      </SimpleFlex>
    );
  };
  return (
    <div
      style={{
        width: "100%",
        gap: "1rem",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "scroll",
        padding: "0 2rem",
      }}
    >
      <SimpleFlex
        margin="1rem 0 0 0"
        backgroundcolor="transparent"
        height="auto"
      >
        <Breadcrumb separator=">">
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item key={index}>
              <a href={item.href}>{item.title}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </SimpleFlex>
      <SimpleFlex
        width="auto"
        backgroundcolor="transprent"
        gap="0.4rem"
        height="auto"
      >
        <P fontsize="2.25rem" fontWeight="var(--font-700)">
          Change Over -{" "}
        </P>
        <P fontsize="2.25rem" fontWeight="var(--font-300)">
          PO : ADD64646S
        </P>
      </SimpleFlex>
      <SimpleFlex
        width="auto"
        flexdirection="column"
        borderradius="1.125rem"
        height="auto"
        padding="1.563rem 6.125rem"
        gap="1rem"
      >
        <P textAlign="center" fontWeight="var(--font-500)" fontsize="1rem">
          Mark this job Complete?
        </P>
        <P textAlign="center" fontsize="1rem" color="var(--nav-color)">
          Choose this option if you want to mark the work order as complete
        </P>
        <SimpleFlex justifycontent="center" gap="0.5rem">
          <StarBadge />
          <P color="var(--lagoon-color)">Required quantity has been met!</P>
        </SimpleFlex>
        <SimpleFlex justifycontent="center" gap="1rem">
          <SimpleButton padding="0.75rem 1.625rem">No</SimpleButton>
          <PrimaryButton padding="0.75rem 1.625rem">Yes</PrimaryButton>
        </SimpleFlex>
      </SimpleFlex>
      <SimpleFlex
        width="auto"
        flexdirection="column"
        borderradius="1.125rem"
        height="auto"
        gap="0.5rem"
        backgroundcolor="transparent"
      >
        <SimpleFlex backgroundcolor="transparent" className="tab-container">
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "var(--primary-color)",
              },
            }}
          >
            <Tabs
              className="tabs"
              tabBarExtraContent={searchBox()}
              items={items}
            />
          </ConfigProvider>
        </SimpleFlex>
      </SimpleFlex>
    </div>
  );
}

export default Machines;
