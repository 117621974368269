import { Drawer, Form } from "antd";
import { SimpleFlex } from "../../../../Components/StyledComponents/SimpleFlex";
import { P } from "../../../../Components/StyledComponents/Paragraph";
import { SimpleButton } from "../../../../Components/StyledComponents/SimpleButton";
import { ReactComponent as PlusDark } from "../../../../assets/svg/plusDark.svg";
import { ReactComponent as FilterGray } from "../../../../assets/svg/filterGray.svg";
import { useEffect, useState } from "react";
import { PrimaryButton } from "../../../../Components/StyledComponents/Button";
import { SimpleInput } from "../../../../Components/StyledComponents/SimpleInput";
import "./ChartConfig.scss";
import { PrimarySelect } from "../../../../Components/StyledComponents/Select";
import ChartConfigII from "../ChartConfigII";

function ChartConfig({ onClose, isOpen }) {
  const [inputs, setInputs] = useState([{ value: "" }]);
  const [metrics, setMetrics] = useState([<MetricCard />]);
  const [displaySettings, setDisplaySettings] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(isOpen);

  useEffect(() => {
    setIsDrawerOpen(isOpen);
  }, [isOpen]);

  const onCloseDrawer = () => {
    setIsDrawerOpen(false);
    onClose();
  };

  const onCloseDisplaySettings = () => {
    setDisplaySettings(false);
    setIsDrawerOpen(true);
  };

  const onOpenDisplaySettings = () => {
    setDisplaySettings(true);
    setIsDrawerOpen(false);
  };

  const handleAddMetricClick = () => {
    setMetrics((prevMetrics) => [...prevMetrics, <MetricCard />]);
  };

  const handleAddInput = () => {
    setInputs([...inputs, { value: "" }]);
  };

  const handleInputChange = (event, index) => {
    const values = [...inputs];
    values[index].value = event.target.value;
    setInputs(values);
  };

  return (
    <Drawer
      title="Chart Configuration"
      width={420}
      placement="right"
      onClose={onCloseDrawer}
      open={isDrawerOpen}
      mask={false}
    >
      <SimpleFlex
        flexdirection="column"
        height="auto"
        gap="1rem"
        alignitems="center"
      >
        <SimpleFlex flexdirection="column" gap="0.8rem">
          <SimpleFlex
            alignitems="center"
            justifycontent="space-between"
            height="auto"
          >
            <P fontsize="1rem" fontWeight="var(--font-500)">
              Metrics
            </P>
            <SimpleButton
              gap="0.2rem"
              border="none"
              onClick={handleAddMetricClick}
            >
              <P
                cursor="pointer"
                color="var(--primary-color)"
                fontWeight="var(--font-500)"
              >
                Add
              </P>{" "}
              <PlusDark />
            </SimpleButton>
          </SimpleFlex>
          {metrics.map((_, index) => (
            <MetricCard key={index} />
          ))}
        </SimpleFlex>
        <SimpleFlex flexdirection="column" gap="0.8rem">
          <SimpleFlex
            alignitems="center"
            justifycontent="space-between"
            height="auto"
          >
            <P fontsize="1rem" fontWeight="var(--font-500)">
              Group By
            </P>
            <SimpleButton gap="0.2rem" border="none" onClick={handleAddInput}>
              <P
                cursor="pointer"
                color="var(--primary-color)"
                fontWeight="var(--font-500)"
              >
                Add
              </P>{" "}
              <PlusDark />
            </SimpleButton>
          </SimpleFlex>
          {inputs.map((input, index) => (
            <SimpleInput
              fontSize="1rem"
              value={input.value}
              onChange={(event) => handleInputChange(event, index)}
              key={index}
            />
          ))}
        </SimpleFlex>
        <SimpleFlex justifycontent="flex-end">
          <PrimaryButton padding="0.5rem 2rem">Apply</PrimaryButton>
        </SimpleFlex>
        <SimpleFlex>
          <SimpleButton padding="0.5rem 2rem" onClick={onOpenDisplaySettings}>
            Display Settings
          </SimpleButton>
        </SimpleFlex>
      </SimpleFlex>
      <ChartConfigII
        onClose={onCloseDisplaySettings}
        isOpen={displaySettings}
      />
    </Drawer>
  );
}

function MetricCard() {
  return (
    <SimpleFlex
      borderradius="1.125rem"
      flexdirection="column"
      gap="1rem"
      border="1px solid var(--columbia-gray-color)"
    >
      <SimpleFlex
        padding="0.5rem 1rem"
        justifycontent="space-between"
        backgroundcolor="var(--primary-color)"
        alignitems="center"
        width="auto"
        borderradius="none"
        className="custom-border-top-right-radius custom-border-top-left-radius"
      >
        <P color="var(--white)">OEE Score</P>
        <FilterGray />
      </SimpleFlex>
      <Form>
        <SimpleFlex
          width="auto"
          padding="0.5rem 1rem"
          backgroundcolor="transparent"
          flexdirection="column"
          gap="0.5rem"
        >
          <SimpleFlex gap="0.8rem">
            <SimpleFlex flexdirection="column">
              <P fontsize="0.75rem" color="var(--nav-color)">
                Metrics
              </P>
              <Form.Item
                name="machine"
                className="margin-0"
                rules={[
                  {
                    required: true,
                    message: "Please input Machine!",
                  },
                ]}
              >
                <PrimarySelect
                  bordered={false}
                  className="user-select"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "Abdul Nizam",
                      label: "Abdul Nizam",
                    },
                    {
                      value: "Billal Ahmed",
                      label: "Billal Ahmed",
                    },
                    {
                      value: "Mohammed Waris",
                      label: "Mohammed Waris",
                    },
                    {
                      value: "Mohammed Rizwan",
                      label: "Mohammed Rizwan",
                    },
                    {
                      value: "Babar Azam",
                      label: "Babar Azam",
                    },
                  ]}
                />
              </Form.Item>
            </SimpleFlex>
            <SimpleFlex flexdirection="column">
              <P fontsize="0.75rem" color="var(--nav-color)">
                Data type{" "}
              </P>
              <Form.Item
                name="machine"
                className="margin-0"
                rules={[
                  {
                    required: true,
                    message: "Please input Machine!",
                  },
                ]}
              >
                <PrimarySelect
                  bordered={false}
                  className="user-select"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "Abdul Nizam",
                      label: "Abdul Nizam",
                    },
                    {
                      value: "Billal Ahmed",
                      label: "Billal Ahmed",
                    },
                    {
                      value: "Mohammed Waris",
                      label: "Mohammed Waris",
                    },
                    {
                      value: "Mohammed Rizwan",
                      label: "Mohammed Rizwan",
                    },
                    {
                      value: "Babar Azam",
                      label: "Babar Azam",
                    },
                  ]}
                />
              </Form.Item>
            </SimpleFlex>
          </SimpleFlex>
          <SimpleFlex flexdirection="column" gap="1rem">
            <P fontsize="0.75rem" color="var(--nav-color)">
              From Production Status
            </P>
            <SimpleInput />
          </SimpleFlex>
        </SimpleFlex>
      </Form>
    </SimpleFlex>
  );
}

export default ChartConfig;
