import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

// Action to fetch all lines
export const fetchLines = createAsyncThunk("fetchLines", async () => {
  // const response = await fetch("https://your-api-endpoint/lines");
  // return response.json();

  // throw new Error("");
  // await new Promise((resolve) => setTimeout(resolve, 2000));
  return [
    {
      key: "1",
      lineName: "Line 1",
      description: "3 round, 3 black parts assembly",
      shortName: "LA2 ASsemble",
      owners: "Group D",
      assignOwners: ["Coil Line Operators", "Coil Line Manager"],
    },
    {
      key: "2",
      lineName: "Line 2",
      description: "Switch Making Plant",
      shortName: "Switch Factory (SWFA)",
      owners: "Group B,A",
      assignOwners: ["Coil Line Manager", "Billal Ahmed"],
    },
    {
      key: "3",
      lineName: "Line 3",
      description: "Switch Making Plant",
      shortName: "LA2 ASsemble",
      owners: "Group A",
    },
  ];
});

// Action to add a new line
export const addLine = createAsyncThunk("addLine", async (newLine) => {
  const response = await fetch("https://your-api-endpoint/lines", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newLine),
  });
  return response.json();
});

// Action to delete a line
export const deleteLine = createAsyncThunk("deleteLine", async (lineId) => {
  const response = await fetch(`https://your-api-endpoint/lines/${lineId}`, {
    method: "DELETE",
  });
  return response.json();
});

// Action to edit a line
export const editLine = createAsyncThunk("editLine", async (editLine) => {
  // const response = await fetch(
  //   `https://your-api-endpoint/lines/${editedLine.key}`,
  //   {
  //     method: "PUT",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(editLine),
  //   }
  // );
  // return response.json();

  // line is edited successfully
  const dispatch = useDispatch();
  dispatch(fetchLines());
  return editLine;
});

const lineSlice = createSlice({
  name: "lines",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    selectedLine: null,
  },
  reducers: {
    setSelectedLine: (state, action) => {
      state.selectedLine = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLines.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLines.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchLines.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(addLine.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
    });
    builder.addCase(deleteLine.fulfilled, (state, action) => {
      state.data = state.data.filter((line) => line.key !== action.payload.key);
    });
    builder.addCase(editLine.fulfilled, (state, action) => {
      state.data = state.data.map((line) =>
        line.key === action.payload.key ? action.payload : line
      );
      state.selectedLine = null; // Clear selected line after edit
    });
  },
});

export const { setSelectedLine } = lineSlice.actions;

export default lineSlice.reducer;
